import * as React from "react";
import { NavLink, useParams } from "react-router-dom";
import { Button, Container, styled, Typography, useTheme } from "@mui/material";
import Divider from "@mui/material/Divider";
import logo from "../../assets/images/logo.svg";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const FooterContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: theme.spacing(4),
  position: "relative",
  backgroundColor: theme.palette.common.white,
  scrollSnapAlign: "top",
}));

const FooterRow = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "row",
  color: theme.palette.homepage.main,

  "& .MuiTypography-root": {
    color: "inherit",
  },

  "&.footer-details": {},

  [theme.breakpoints.down("md")]: {
    justifyContent: "center",

    "&.footer-details": {
      flexDirection: "column",
    },

    "&.footer-details > div": {
      marginTop: "1rem",
    },
  },
}));

const FooterBox = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  "& img": {
    width: "125px",
  },
}));

const FooterDivider = styled(Divider)(({ theme }) => ({
  width: "100%",
  margin: theme.spacing(4, 0),
}));

const Footer = () => {
  const theme = useTheme();
  const { locale } = useParams();
  const lang = locale ? locale : "en";

  return (
    <FooterContainer>
      <FooterRow>
        <NavLink to="/" style={{ textDecoration: "none", color: "inherit" }}>
          <FooterBox>
            {/* <Icon icon='Logo' size={35} color={theme.palette.homepage.main}/> */}
            <img src={logo} alt="balancey logo"></img>
            {/* <Typography variant='logo' sx={{ml: 1}} color={theme.palette.homepage.main}>Balancey</Typography> */}
          </FooterBox>
        </NavLink>
      </FooterRow>
      {/* <FooterDivider/> */}
      <FooterRow sx={{ mt: 3 }} className="footer-details">
        <FooterBox>
          <NavLink
            to={`/${lang}/faq`}
            style={{
              fontFamily: "ApfelGrotezkRegular",
              textDecoration: "none",
              color: "inherit",
              fontSize: "14px",
              marginLeft: 16,
            }}
          >
            {t("FAQ")}
          </NavLink>
          <NavLink
            to={`/${lang}/terms`}
            style={{
              fontFamily: "ApfelGrotezkRegular",
              textDecoration: "none",
              color: "inherit",
              fontSize: "14px",
              marginLeft: 16,
            }}
          >
            Terms & Conditions
          </NavLink>
          <NavLink
            to={`/${lang}/privacy`}
            style={{
              fontFamily: "ApfelGrotezkRegular",
              textDecoration: "none",
              color: "inherit",
              fontSize: "14px",
              marginLeft: 16,
            }}
          >
            Privacy Policy
          </NavLink>
          <NavLink
            to={`/${lang}/legal`}
            style={{
              fontFamily: "ApfelGrotezkRegular",
              textDecoration: "none",
              color: "inherit",
              fontSize: "14px",
              marginLeft: 16,
            }}
          >
            {t("Legal Notice")}
          </NavLink>
        </FooterBox>
        <FooterBox>
          <Typography
            sx={{
              fontSize: "14px",
              textAlign: "left",
              width: "100%",
              opacity: 0.5,
            }}
          >
            &copy; {new Date().getFullYear()} Vanilla Noir
          </Typography>
        </FooterBox>
      </FooterRow>
    </FooterContainer>
  );
};
export default Footer;
