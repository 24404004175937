import { Button, styled } from '@mui/material';
import * as React from 'react';
import { useAppContext } from '../../@common/contexts/AppContext';
import { useTranslation } from 'react-i18next';

const GroupContainer = styled("div")(({ theme }) => ({
    position:"relative",
    display: "flex",
    justifyContent: "space-between"
   
   })); 

const Spotlight = styled("div")(({ theme }) => ({
    position:"absolute",
    width: "100px",
    height: "40px",
    backgroundColor: theme.palette.primary.main,
    zIndex: 1,
    top: 0,
    transition: "all 0.2s ease",
    borderRadius: "25px",
    margin: '0 5px',
   }));

   const CustomButton = styled(Button)(({ theme }) => ({
    width: "100px",
    height: "40px",
    zIndex: 3,
    margin: '0 5px',

    '&.active': {
        color: theme.palette.primary.contrastText,
    }
   
   }));


const OverviewButtonGroup = () => {
    const {overviewState, setOverviewState} = useAppContext();
    const spotlightRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const {t} = useTranslation();

    const slideSpotlight = (pos : number) => {
        spotlightRef.current.style.left = pos+"px";
    }

    React.useEffect(() => {
      if (overviewState === "planned")
      {
        slideSpotlight(0);
      }
      else if (overviewState === "remaining")
      {
        slideSpotlight(110);
      }
      else if (overviewState === "spent")
      {
        slideSpotlight(220);
      }
    }, [overviewState])
    

  return (
    <GroupContainer>
        <CustomButton onClick={() => {setOverviewState("planned")}} className={overviewState==="planned"? "active" : ""}>{t("Planned")}</CustomButton>
        <CustomButton onClick={() => {setOverviewState("remaining")}}className={overviewState==="remaining"? "active" : ""}>{t("Remaining")}</CustomButton>
        <CustomButton onClick={() => {setOverviewState("spent")}}className={overviewState==="spent"? "active" : ""}>{t("Spent")}</CustomButton>
        <Spotlight ref={spotlightRef}/>
    </GroupContainer>
  )
}

export default OverviewButtonGroup