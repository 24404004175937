import React from 'react';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Item from '@mui/material/Stack';
import { motion } from "framer-motion";
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material';
import target from '../../assets/images/people.png';
import StoreButtons from './StoreButtons';



const snappy = { duration: 0.5, ease: "easeInOut" };
const fadeInUp = { duration: 2, ease: [0.47,.21,0.05,1], delay: 0.4};
const fadeInUp2 = { duration: 2, ease: [0.47,.21,0.05,1], delay: 0.45};

const Section = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(30),
  paddingRight: theme.spacing(30),


  //Small
  [theme.breakpoints.down("lg")]: {
    padding: theme.spacing(25, 10, 30, 10)
    },
  //Mobile
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(20, 5, 20, 5),
  },

}));

const ContactContainer = styled('div')(({ theme }) => ({
  width: "80vw",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  color: theme.palette.homepage.main,
  scrollSnapAlign: 'start',
  position: 'relative',



 '& img' : {
  position: 'absolute',
  width: '22vw',
  right: '14rem',
  top: '-4rem',
 },


 '& .line-wrapper' : {
    overflow: 'hidden',
    height: '100%'
  },

  '& .line-wrapper > div' : {
    display: 'inline-block',
  },

  '& .storebutton-container' : {
    display: 'flex',
    justifyContent: 'flex-start',
    transform: 'scale(0.8)',
    transformOrigin: 'left',
  },

  '& .storebutton-container div' : {
    transformOrigin: 'left',
  },

  '& .storebutton-container div a' : {
    marginLeft: '-15px',
  },
 

   //Small
   [theme.breakpoints.down("lg")]: {
    width: "100%",

    '& img' : {
      width: '280px',
      right: '-2rem',
      top: '2rem',
      marginTop: '1rem',
     },
  
  },

  //Mobile
  [theme.breakpoints.down("md")]: {

   '& img' : {
    position: 'relative',
    width: '200px',
    alignSelf: 'flex-start',
    marginBottom: '2rem',
   },

   '& button' : {
    fontSize: "1rem",
    maxWidth: '100%',
   },

   '& .storebutton-container div' : {
      flexDirection: 'row',
    },
  },

}));

const HeroText = styled(Typography)(({ theme }) => ({
  fontFamily: "objectivitymedium",
  fontSize: '4vw',
  lineHeight: 1.3,
  letterSpacing: '-0.3rem',
  color: "inherit",
  margin: theme.spacing(0,50,0,0),
  maxWidth: '50vw',

  [theme.breakpoints.down("lg")]: {
    fontSize: '3.2rem',
    letterSpacing: '-0.25rem',
    maxWidth: '60vw',
    marginRight: 0,
    lineHeight: 1.2
   },

   [theme.breakpoints.down("md")]: {
    maxWidth: '100vw',
    fontSize: '3rem',
    letterSpacing: '-0.2rem'
   },


}));



const Contact = () => {
  const theme = useTheme();

  return (
    <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={snappy} viewport={{ once: false }}>
    <Section sx={{backgroundColor: theme.palette.homepage.light, pt: 35, pb: 55}}>
      <ContactContainer>
        <div className='line-wrapper'>
        <motion.div initial={{ y: '70%', opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={fadeInUp} viewport={{ once: false }}>
          <HeroText variant='h2'>Ready to change your spending habits?</HeroText>
        </motion.div>
      </div>
      <img src={target} alt='three young people with mobile devices'></img>
      <div style={{marginTop: 16, marginBottom: 24, display: 'flex'}}>
      <motion.div className="storebutton-container" initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={fadeInUp} viewport={{ once: false }}>
        <StoreButtons/>
      </motion.div>
      </div>
 
          
         

      </ContactContainer>
    </Section>
    </motion.div>
  );
};

export default Contact;
