import React from 'react';
import { styled } from '@mui/system';
import { Button, Dialog, Typography, useTheme } from '@mui/material';
import { funIcons, generalIcons, houseIcons, livingIcons, otherIcons, travelIcons, generalPaidIcons, housePaidIcons, livingPaidIcons, otherPaidIcons, travelPaidIcons, funPaidIcons } from '../../@common/theme/Theme';
import { useUser } from '../../@common/contexts/UserContext';
import Icon from '../../Icon';
import { useTranslation } from 'react-i18next';
import StarIcon from '@mui/icons-material/Star';
import { CategoryType } from '../../main/resources/UserResources';



const StyledDialog = styled(Dialog)(({ theme }) => ({

    '& .MuiPaper-root':{
      borderRadius: "10px",
      maxWidth: "415px",
    }
}));

const StyledContainer = styled("div")(({ theme }) => ({
    margin: theme.spacing(2, 4),
    position:"relative",

    '& h6' : {
      color: theme.palette.text.disabled,
      marginTop: '1rem'
    }
  }));

  const Block = styled('div')(({ theme }) => ({
    display: "flex",
    flexDirection: "column"
  }));

  const Row = styled('div')(({ theme }) => ({
    display:"flex",
    flexWrap:"wrap"
  }));

  const IconButton = styled(Button)(({ theme }) => ({
    minWidth: "auto",
    width: "50px",
    height: "50px",
    borderRadius: "15px"
  }));



type CategoryIconPickerType = {
  open: boolean,
  setOpen: (open: boolean) => void,
  category: CategoryType,
  blockIndex : number
}

const CategoryIconPicker = ({open, setOpen, category, blockIndex} : CategoryIconPickerType) => {
  const theme = useTheme();
  const {t} = useTranslation();

const {updateCategory, premium} = useUser();

	const handleClose = (e : any) => {
    e.stopPropagation();
		setOpen(false);
	};


	const onIconPick = (e : any, icon : string) => {
    
		let temp = {...category};
    temp.icon = icon;
    updateCategory(temp, category, blockIndex);
    
    handleClose(e);
	};
    

  return (
      <StyledDialog
      open={open}
      onClose={handleClose}
      >
      <StyledContainer>
      <Typography variant='h5' sx={{mt: 2, textAlign: 'center'}}>{t("Icon Select")}</Typography>
        <Block>
        <Typography variant='h6'>{t("General")}</Typography>
        <Row>
        {generalIcons.map((icon : string) => {
          return(
            <IconButton key={icon} onClick={(e) => onIconPick(e,icon)}><Icon icon={icon} size={24} color={theme.palette.text.primary}/></IconButton>
          )
        })}
        {generalPaidIcons.map((icon : string) => {
          return(
            <div key={icon} style={{position: "relative"}}>
            <IconButton onClick={premium? (e) => onIconPick(e,icon) : undefined}><Icon icon={icon} size={24} color={premium? theme.palette.text.primary : theme.palette.divider}/></IconButton>
            {premium?
            <></>
              :
            //<StarIcon style={{color: theme.palette.info.contrastText, fontSize: 12, position: "absolute", top: 8, right: 0, padding: 2, borderRadius: 100, backgroundColor: theme.palette.info.main}}></StarIcon>
            <div style={{color: theme.palette.info.contrastText, position: "absolute", display:'flex', top: 8, right: 0, padding: 3, borderRadius: 100, backgroundColor: theme.palette.info.main}}>
              <Icon icon='Star-filled' size={10} color='white'></Icon>
            </div>
            }
            </div>

          )
        })}
        </Row>
        </Block>
        <Block>
        <Typography variant='h6'>{t("Housing")}</Typography>
        <Row>
        {houseIcons.map((icon : string) => {
          return(
            <IconButton key={icon} onClick={(e) => onIconPick(e,icon)}><Icon icon={icon} size={24} color={theme.palette.text.primary}/></IconButton>
          )
        })}
        {housePaidIcons.map((icon : string) => {
          return(
            <div key={icon} style={{position: "relative"}}>
            <IconButton onClick={premium? (e) => onIconPick(e,icon) : undefined}><Icon icon={icon} size={24} color={premium? theme.palette.text.primary : theme.palette.divider}/></IconButton>
            {premium?
            <></>
              :
            <div style={{color: theme.palette.info.contrastText, position: "absolute", display:'flex', top: 8, right: 0, padding: 3, borderRadius: 100, backgroundColor: theme.palette.info.main}}>
              <Icon icon='Star-filled' size={10} color='white'></Icon>
            </div>            }
            </div>

          )
        })}
        </Row>
        </Block>
        <Block>
        <Typography variant='h6'>{t("Living")}</Typography>
        <Row>
        {livingIcons.map((icon : string) => {
          return(
            <IconButton key={icon} onClick={(e) => onIconPick(e,icon)}><Icon icon={icon} size={24} color={theme.palette.text.primary}/></IconButton>
          )
        })}
        {livingPaidIcons.map((icon : string) => {
          return(
            <div key={icon} style={{position: "relative"}}>
            <IconButton onClick={premium? (e) => onIconPick(e,icon) : undefined}><Icon icon={icon} size={24} color={premium? theme.palette.text.primary : theme.palette.divider}/></IconButton>
            {premium?
            <></>
              :
            <div style={{color: theme.palette.info.contrastText, position: "absolute", display:'flex', top: 8, right: 0, padding: 3, borderRadius: 100, backgroundColor: theme.palette.info.main}}>
              <Icon icon='Star-filled' size={10} color='white'></Icon>
            </div>            }
            </div>

          )
        })}
        </Row>
        </Block>
        <Block>
        <Typography variant='h6'>{t("Fun")}</Typography>
        <Row>
        {funIcons.map((icon : string) => {
          return(
            <IconButton key={icon} onClick={(e) => onIconPick(e,icon)}><Icon icon={icon} size={24} color={theme.palette.text.primary}/></IconButton>
          )
        })}
        {funPaidIcons.map((icon : string) => {
          return(
            <div key={icon} style={{position: "relative"}}>
            <IconButton onClick={premium? (e) => onIconPick(e,icon) : undefined}><Icon icon={icon} size={24} color={premium? theme.palette.text.primary : theme.palette.divider}/></IconButton>
            {premium?
            <></>
              :
            <div style={{color: theme.palette.info.contrastText, position: "absolute", display:'flex', top: 8, right: 0, padding: 3, borderRadius: 100, backgroundColor: theme.palette.info.main}}>
              <Icon icon='Star-filled' size={10} color='white'></Icon>
            </div>            }
            </div>

          )
        })}
        </Row>
        </Block>
        <Block>
        <Typography variant='h6'>{t("Travel")}</Typography>
        <Row>
        {travelIcons.map((icon : string) => {
          return(
            <IconButton key={icon} onClick={(e) => onIconPick(e,icon)}><Icon icon={icon} size={24} color={theme.palette.text.primary}/></IconButton>
          )
        })}
        {travelPaidIcons.map((icon : string) => {
          return(
            <div key={icon} style={{position: "relative"}}>
            <IconButton onClick={premium? (e) => onIconPick(e,icon) : undefined}><Icon icon={icon} size={24} color={premium? theme.palette.text.primary : theme.palette.divider}/></IconButton>
            {premium?
            <></>
              :
            <div style={{color: theme.palette.info.contrastText, position: "absolute", display:'flex', top: 8, right: 0, padding: 3, borderRadius: 100, backgroundColor: theme.palette.info.main}}>
              <Icon icon='Star-filled' size={10} color='white'></Icon>
            </div>            }
            </div>

          )
        })}
        </Row>
        </Block>
        <Block>
        <Typography variant='h6'>{t("Other")}</Typography>
        <Row>
        {otherIcons.map((icon : string) => {
          return(
            <IconButton key={icon} onClick={(e) => onIconPick(e,icon)}><Icon icon={icon} size={24} color={theme.palette.text.primary}/></IconButton>
          )
        })}
        {otherPaidIcons.map((icon : string) => {
          return(
            <div key={icon} style={{position: "relative"}}>
            <IconButton onClick={premium? (e) => onIconPick(e,icon) : undefined}><Icon icon={icon} size={24} color={premium? theme.palette.text.primary : theme.palette.divider}/></IconButton>
            {premium?
            <></>
              :
            <div style={{color: theme.palette.info.contrastText, position: "absolute", display:'flex', top: 8, right: 0, padding: 3, borderRadius: 100, backgroundColor: theme.palette.info.main}}>
              <Icon icon='Star-filled' size={10} color='white'></Icon>
            </div>            }
            </div>

          )
        })}
        </Row>
        </Block>
       
      </StyledContainer>

      </StyledDialog>
   
    
  );
};

export default CategoryIconPicker;
