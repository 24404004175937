import React from "react";
import { styled } from "@mui/system";
import Typography from "@mui/material/Typography";
import Navigation from "../navigation/Navigation";
import Footer from "../../home/components/Footer";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Page = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(10),
  display: "flex",
  flexDirection: "column",
  alignItems: "space-between",
  backgroundColor: theme.palette.homepage.light,
}));

const Section = styled("div")(({ theme }) => ({
  paddingLeft: theme.spacing(20),
  paddingRight: theme.spacing(20),
  minHeight: "80vh",

  //Small
  [theme.breakpoints.down("lg")]: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
  },
  //Mobile
  [theme.breakpoints.down("md")]: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
}));

const Container = styled("div")(({ theme }) => ({
  width: "80vw",
  maxWidth: "1000px",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  color: theme.palette.homepage.main,
  marginBottom: theme.spacing(20),

  "& .MuiTypography-root": {
    color: "inherit",
  },
}));

const LinkList = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: "flex",
  justifyContent: "flex-start",
}));

const TermsConditions = () => {
  const { t, i18n } = useTranslation();
  const { locale } = useParams();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navigation />
      <Page>
        <Section className="introduction">
          <Container>
            <LinkList>
              <NavLink
                to={`/en/terms`}
                style={{
                  fontFamily: "ApfelGrotezkRegular",
                  textDecoration: "none",
                  fontSize: "14px",
                }}
              >
                EN
              </NavLink>
              <hr style={{ marginInline: 10 }} />
              <NavLink
                to={`/de/terms`}
                style={{
                  fontFamily: "ApfelGrotezkRegular",
                  textDecoration: "none",
                  fontSize: "14px",
                }}
              >
                DE
              </NavLink>
            </LinkList>
            <Typography variant="h2" sx={{ mb: 2 }}>
              {t("Terms and Conditions", { lng: locale })}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {t(
                "The following terms and conditions are standard clauses about software products. We still advise you to carefully read these before using the app.",
                { lng: locale }
              )}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {t(
                "By using Balancey (herein referred to as the “Software”), you agree to the terms and conditions set forth in this End-User License Agreement (“EULA”). This agreement is between you and Vanilla Noir Ltd (herein referred to as the “Owner”, “we”, “us”, or “our”), and not Apple, Inc. or any other party involved in the distribution of the Software. The Owner, not Apple or any other party involved in the distribution of the Software, is solely responsible for the Software and the content thereof. It is essential that you read and understand the terms contained in this EULA before installing or using the Software.",
                { lng: locale }
              )}
            </Typography>
            <Typography variant="body1">
              {t(
                "You agree to comply with all applicable third party terms of agreement when using the Software. You agree that Apple, Inc. and its subsidiaries are third party beneficiaries of this License Agreement and that, upon your acceptance of the terms and conditions of this License Agreement, Apple, Inc. will have the right (and will be deemed to have accepted the right) to enforce the License Agreement against you as a third party beneficiary thereof.",
                { lng: locale }
              )}
            </Typography>
            <Typography variant="h5" sx={{ mb: 2, mt: 4 }}>
              {t("1. LICENSE", { lng: locale })}
            </Typography>
            <Typography variant="body1">
              {t(
                "The Owner grants you a non-exclusive, non-transferable, non-sublicensable, limited right and license to use one copy of the Software for your personal, non-commercial use on a single device that you own or control. The rights granted herein are subject to your compliance with this EULA. The Software is being licensed to you, and you hereby acknowledge that no title or ownership in the Software is being transferred or assigned, and this EULA is not to be construed as a sale of any rights in the Software.",
                { lng: locale }
              )}
            </Typography>
            <Typography variant="h5" sx={{ mb: 2, mt: 4 }}>
              {t("2. RESTRICTIONS OF USE", { lng: locale })}
            </Typography>
            <Typography variant="body1">
              {t(
                "Unless the Owner has authorized you to distribute the Software, you shall not make or distribute copies of the Software or transfer the Software from one device to another. You shall not decompile, reverse engineer, disassemble, include in other software, translate the Software, or use the Software for any commercial purposes. You shall not modify, alter, change or otherwise make any modification to the Software or create derivative works based upon the Software. You shall not rent, lease, resell, sub-license, assign, distribute or otherwise transfer the Software or this license. Any attempt to do so shall be void and of no effect.",
                { lng: locale }
              )}
            </Typography>
            <Typography variant="h5" sx={{ mb: 2, mt: 4 }}>
              {t("3. COPYRIGHT", { lng: locale })}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {t(
                "You acknowledge that no title to the intellectual property in the Software is transferred to you. You further acknowledge that title and full ownership rights to the Software will remain the exclusive property of the Owner, and you will not acquire any rights to the Software. You shall not remove or obscure the Owner's copyright, trademark or other proprietary notices from any of the materials contained in this package or downloaded together with the Software.",
                { lng: locale }
              )}
            </Typography>
            <Typography variant="body1">
              {t(
                "In the event of any third party claim that the Software or your possession and use of that Software infringes that third party’s intellectual property rights, the Owner, not Apple, Inc. or other parties involved in the distribution of the Software, reserves the right to investigate, defend, settle, and discharge of any such intellectual property infringement claim.",
                { lng: locale }
              )}
            </Typography>
            <Typography variant="h5" sx={{ mb: 2, mt: 4 }}>
              {t("4. DISCLAIMER OF WARRANTY", { lng: locale })}
            </Typography>
            <Typography variant="body1">
              {t(
                "The Software is provided “AS IS”, without warranty of any kind. The Owner disclaims and make no express or implied warranties and specifically disclaim the warranties of merchantability, fitness for a particular purpose and non-infringement of third-party rights, both for itself and on behalf of Apple, Inc. and each of their respective licensors and affiliates and all other parties involved in the distribution of the Software. The entire risk as to the quality and performance of the Software is with you. We do not warrant that the functions contained in the Software will meet your requirements or that the operation of the Software will be error-free.",
                { lng: locale }
              )}
            </Typography>
            <Typography variant="h5" sx={{ mb: 2, mt: 4 }}>
              {t("5. LIMITATION OF LIABILITY", { lng: locale })}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {t(
                "In no event will the Owner, Apple, Inc. and their respective affiliates, or any other parties involved in the distribution of the Software be liable for special, incidental or consequential damages resulting from possession, access, use or malfunction of the Software, including but not limited to damages to property, loss of goodwill, computer or mobile device malfunction and, to the extent permitted by law, damages for personal injuries, property damage, lost profits or punitive damages from any causes of action arising out of or related to this EULA or the Software, whether arising in tort (including negligence), contract, strict liability or otherwise and whether or not the Owner has been advised of the possibility of such damages.",
                { lng: locale }
              )}
            </Typography>
            <Typography variant="body1">
              {t(
                "Because some states/countries do not allow certain limitations of liability, this limitation of liability shall apply to the fullest extent permitted by law in the applicable jurisdiction. This limitation of liability shall not be applied solely to the extent that any specific provision of this limitation of liability is prohibited by any federal, state, or municipal law, which cannot be pre-empted. This EULA gives you specific legal rights, and you may have other rights that vary from jurisdiction to jurisdiction. In no event shall the Owner's liability for all damages (except as required by applicable law) exceed the actual price paid by you for use of the Software.",
                { lng: locale }
              )}
            </Typography>
            <Typography variant="h5" sx={{ mb: 2, mt: 4 }}>
              {t("6. PRIVACY POLICY", { lng: locale })}
            </Typography>
            <Typography variant="body1">
              {t(
                "Our Privacy Policy can be found on our website. We advise you to read it in its entirety.",
                { lng: locale }
              )}
            </Typography>
            <Typography variant="h5" sx={{ mb: 2, mt: 4 }}>
              {t("7. INDEMNIFICATION", { lng: locale })}
            </Typography>
            <Typography variant="body1">
              {t(
                "By using the Software according to the terms of the EULA, you agree to indemnify, defend, and hold the Owner harmless from any and all damages, losses, and expenses directly or indirectly arising from your acts and omissions or your breach of this EULA.",
                { lng: locale }
              )}
            </Typography>
            <Typography variant="h5" sx={{ mb: 2, mt: 4 }}>
              {t("8. UPDATES AND MODIFICATIONS", { lng: locale })}
            </Typography>
            <Typography variant="body1">
              {t(
                "The Owner may periodically release updates or modifications to the Software, which may include adding, modifying, or removing features or functionality. You may be required to accept and install these updates or modifications. You acknowledge and agree that Apple, Inc. has no obligation whatsoever to furnish any maintenance and support services with respect to the Software.",
                { lng: locale }
              )}
            </Typography>
            <Typography variant="h5" sx={{ mb: 2, mt: 4 }}>
              {t("9. FINANCIAL DISCLAIMER", { lng: locale })}
            </Typography>
            <Typography variant="body1">
              {t(
                "The information provided by this app is for educational and informational purposes only and is not intended to be a substitute for professional financial advice. The app is designed to help you track your expenses and manage your budget, but it is important to remember that individual financial situations may vary. The app owner and its affiliates are not responsible for any financial decisions made based on information obtained from the app. Before making any financial decisions, it is recommended that you consult with a qualified financial advisor.",
                { lng: locale }
              )}
            </Typography>
            <Typography variant="h5" sx={{ mb: 2, mt: 4 }}>
              {t("10. SUBSCRIPTION TERMS AND PURCHASES", { lng: locale })}
            </Typography>
            <Typography variant="body1">
              {t(
                "There are various ways to access the Software, including the Free Version, Free Trial, and Paid Subscription. You can only access the Subscription Program while your subscription is active and in good standing. You can purchase a subscription to Balancey within the app, subject to the policies of the respective Application Marketplace Partners. Please note that purchases made through the Apple iTunes Store or our iPhone application are final, and refunds are not provided. Similarly, if you purchase a subscription through the Google Play store, the sale is final, and refunds are not provided. Balancey offers monthly and yearly subscription options, with a month defined as 30 calendar days and a year as 365 calendar days. Your monthly subscription will be automatically renewed each month until canceled, while your yearly subscription is paid for by a one-time upfront payment with automatic annual renewals.",
                { lng: locale }
              )}
            </Typography>
            <Typography variant="h5" sx={{ mb: 2, mt: 4 }}>
              {t("11. CONTENT", { lng: locale })}
            </Typography>
            <Typography variant="body1">
              {t(
                "During the Free Trial Period and refund period provided by our Application Marketplace Partners, users can evaluate the Content of our Product to determine if it meets their needs. If a user is dissatisfied with the Product during this period, they can cancel the Trial without incurring any fees. If a user continues using the Product without canceling the Trial, they will be responsible for purchasing the Product, and the Content will not be a reason for compensation. Please note that the scope of the Content might be specific to the platform or device on which the Software is being used. Users can cancel their Trial anytime by following the instructions provided in the Application Marketplace Partner platform.",
                { lng: locale }
              )}
            </Typography>
            <Typography variant="h5" sx={{ mb: 2, mt: 4 }}>
              {t("12. LEGAL COMPLIANCE", { lng: locale })}
            </Typography>
            <Typography variant="body1">
              {t(
                "You will comply with all applicable laws in connection with your use of the Software. You acknowledge that the Software may be subject to U.S. or foreign export and import laws or regulations. You will comply with all applicable import, export and re-export laws and regulations of the United States or foreign agencies or authorities. You represent and warrant that (i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.",
                { lng: locale }
              )}
            </Typography>
            <Typography variant="h5" sx={{ mb: 2, mt: 4 }}>
              {t("13. CHANGES TO TERMS", { lng: locale })}
            </Typography>
            <Typography variant="body1">
              {t(
                "We reserve the right to modify or replace these Terms at any time.",
                { lng: locale }
              )}
            </Typography>
            <Typography variant="h5" sx={{ mb: 2, mt: 4 }}>
              {t("14. CONTACT INFORMATION", { lng: locale })}
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              {t(
                "Should you have any questions, complaints or claims with respect to the Software, please contact the Owner at:",
                { lng: locale }
              )}
            </Typography>
            <Typography variant="body1">Vanilla Noir Ltd</Typography>
            <Typography variant="body1">35 Medallion House</Typography>
            <Typography variant="body1">Joseph Terry Grove</Typography>
            <Typography variant="body1">York YO23 1FL</Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              United Kingdom
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              info@vanilla-noir.com
            </Typography>
            <Typography variant="body1">
              {t(
                "Vanilla Noir Ltd, and not Apple, Inc. or any other parties involved in the distribution of the Software, is responsible for addressing claims relating to the Software and your possession and/or use of that Software.",
                { lng: locale }
              )}
            </Typography>
          </Container>
        </Section>
        <Footer />
      </Page>
    </>
  );
};

export default TermsConditions;
