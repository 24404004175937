import {
  collection,
  deleteDoc,
  doc,
  getDocsFromCache,
  setDoc,
  Timestamp,
  updateDoc,
  query,
  where,
  orderBy,
  or,
  FieldValue,
  serverTimestamp,
  getDocsFromServer,
  and,
} from "firebase/firestore";
import { firestore } from "../../@common/firebase/firebase";
import { ConstructionOutlined } from "@mui/icons-material";

//Types
export interface EntryType {
  type: string;
  category: string;
  blockIndex: number | null;
  date: Timestamp;
  created: Timestamp;
  lastModified?: Timestamp | FieldValue;
  deleted: boolean;
  creator: string;
  merchant: string;
  price: string;
  payer: string;
  payee: any;
  recurring: boolean;
  secret: boolean;
  id: string;
}

const getSortedEntries = (entries: EntryType[]) => {
  const sortedArray = entries.sort((a: EntryType, b: EntryType) => {
    return a.date.seconds - b.date.seconds;
  });

  return sortedArray;
};

const getFirebaseEntries = async (householdId: string, userId: string) => {
  //Now with caching
  //Step 1: Check cache for current lastModified
  const lastUserString = localStorage.getItem("userLastUsed") || null; //This is so that changing users resets cache
  const dateString =
    lastUserString === userId
      ? localStorage.getItem("entriesLastChecked") || "2000-01-01 00:00:00"
      : "2000-01-01 00:00:00";
  const lastChecked = new Date(dateString);
  const lastTimeStamp = Timestamp.fromDate(lastChecked);

  // const testQuery = query(collection(firestore, "households", householdId, 'entries'), where("secret", "==", false));
  // const testQuery2 = query(collection(firestore, "households", householdId, 'entries'), where("secret", "==", true), where("creator", "==", userId));

  // const testDocs1 = await getDocs(testQuery)
  // const testDocs2 = await getDocs(testQuery2)

  // console.log(testDocs1.size + testDocs2.size)

  //Step 2: Query everything AFTER our lastChecked from Server
  const qServer = query(
    collection(firestore, "households", householdId, "entries"),
    or(
      and(
        where("secret", "==", false),
        where("lastModified", ">", lastTimeStamp),
        where("deleted", "==", false)
      ),
      and(
        where("secret", "==", true),
        where("creator", "==", userId),
        where("deleted", "==", false),
        where("lastModified", ">", lastTimeStamp)
      )
    ),
    orderBy("lastModified", "desc")
  );

  const serverEntries = await getDocsFromServer(qServer);

  //console.log(serverEntries.size + " from the server")

  //Step 3: Query everything BEFORE our lastChecked from Cache
  const qCache = query(
    collection(firestore, "households", householdId, "entries"),
    or(
      and(
        where("secret", "==", false),
        where("deleted", "==", false),
        where("lastModified", "<=", lastTimeStamp)
      ),
      and(
        where("secret", "==", true),
        where("creator", "==", userId),
        where("deleted", "==", false),
        where("lastModified", "<=", lastTimeStamp)
      )
    ),
    orderBy("lastModified", "desc")
  );

  const cacheEntries = await getDocsFromCache(qCache);

  //console.log(cacheEntries.size + " from Cache");

  const entryArray: EntryType[] = [];

  cacheEntries.forEach((doc) => {
    //  console.log(doc.data());
    entryArray.push(doc.data() as EntryType);
  });

  let latestServerTimestamp: any = null;

  serverEntries.forEach((doc) => {
    const docData = doc.data() as EntryType;
    const lastModified = docData.lastModified;

    // Track the latest lastModified timestamp
    if (
      latestServerTimestamp == null ||
      (lastModified && lastModified > latestServerTimestamp)
    ) {
      latestServerTimestamp = lastModified;
    }

    entryArray.push(docData);
  });

  // Convert the latest server timestamp to a Date object
  let latestServerDate = latestServerTimestamp
    ? latestServerTimestamp.toDate()
    : null;

  // Retrieve the current entriesLastChecked date from localStorage
  let lastCheckedDate = new Date(dateString);

  // Check if we need to update the entriesLastChecked date
  if (latestServerDate) {
    let oneMonthBeforeLatest = new Date(latestServerDate);
    oneMonthBeforeLatest.setMonth(latestServerDate.getMonth() - 1);

    if (lastCheckedDate < oneMonthBeforeLatest) {
      lastCheckedDate = oneMonthBeforeLatest;
    }
  }

  localStorage.setItem("entriesLastChecked", lastCheckedDate.toString());
  localStorage.setItem("userLastUsed", userId);

  return getSortedEntries(entryArray);
};

const setFirebaseEntries = async (entry: EntryType, ref: any) => {
  let thisEntry = { ...entry };
  thisEntry.lastModified = serverTimestamp();

  const docref = await setDoc(ref, thisEntry);
  return docref;
};

const updateFirebaseEntry = async (
  entryId: string,
  householdId: string,
  entry: EntryType
) => {
  let thisEntry = { ...entry };
  thisEntry.lastModified = serverTimestamp();

  const ref = doc(firestore, "households", householdId, "entries", entryId);
  const docref = await updateDoc(ref, thisEntry);
  return docref;
};

const deleteFirebaseEntry = async (entryId: any, householdId: string) => {
  const ref = doc(firestore, "households", householdId, "entries", entryId);
  const res = await updateDoc(ref, {
    deleted: true,
    lastModified: serverTimestamp(),
  });
  return res;
};

const createRecurringIncome = async (
  entries: EntryType[],
  month: number,
  year: number,
  addEntry: (values: EntryType[]) => void
) => {
  if (entries.length > 0) {
    let lastMonth = null;
    let lastYear = null;
    let toAdd: EntryType[] = [];

    //We go backwards through all entries, so newest come first
    for (let i = entries.length - 1; i >= 0; i--) {
      const thisDate = entries[i].date.toDate();

      //First we need to find the newest income, so we know which month we need to check
      if (
        lastMonth == null &&
        lastYear == null &&
        entries[i].type == "income" &&
        ((thisDate.getMonth() < month && thisDate.getFullYear() == year) ||
          thisDate.getFullYear() < year)
      ) {
        lastMonth = thisDate.getMonth();
        lastYear = thisDate.getFullYear();
      }

      //Stop looking if the year is smaller than our search year
      if (
        lastMonth !== null &&
        lastYear !== null &&
        thisDate.getFullYear() < lastYear
      ) {
        break;
      }
      //Stop looking if the month is smaller than our search month, assuming we are in the right year
      if (
        lastMonth !== null &&
        lastYear !== null &&
        thisDate.getMonth() < lastMonth &&
        thisDate.getFullYear() === lastYear
      ) {
        break;
      }
      //This entry is either in the right month or later. If it's in the right month, we check it
      if (
        lastYear !== null &&
        lastMonth !== null &&
        thisDate.getMonth() === lastMonth &&
        thisDate.getFullYear() === lastYear &&
        entries[i].type === "income" &&
        entries[i].recurring === true
      ) {
        const value: EntryType = {
          id: "",
          blockIndex: entries[i].blockIndex,
          type: entries[i].type,
          category: entries[i].category,
          date: Timestamp.fromDate(new Date(Date.UTC(year, month, 1))),
          created: Timestamp.fromDate(new Date(Date.UTC(year, month, 1))),
          creator: entries[i].creator,
          merchant: entries[i].merchant,
          price: entries[i].price,
          payer: entries[i].payer,
          payee: entries[i].payee,
          recurring: entries[i].recurring,
          secret: entries[i].secret,
          deleted: entries[i].deleted,
          lastModified: serverTimestamp(),
        };
        toAdd.push(value);
      }
    }
    await addEntry(toAdd);
  }
};

const deleteRecurringIncome = async (
  entries: EntryType[],
  month: number,
  year: number,
  removeEntry: (values: EntryType[]) => Promise<void>
) => {
  let toRemove: EntryType[] = [];

  //We go backwards through all entries, so newest come first
  for (let i = entries.length - 1; i >= 0; i--) {
    const thisDate = entries[i].date.toDate();
    //Stop looking if the year is smaller than our search year
    if (thisDate.getFullYear() < year) break;
    //Stop looking if the month is smaller than our search month, assuming we are in the right year
    if (thisDate.getMonth() < month && thisDate.getFullYear() === year) break;
    //This entry is either in the right month or later. If it's in the right month, we check it
    if (
      thisDate.getMonth() === month &&
      thisDate.getFullYear() === year &&
      entries[i].type === "income" &&
      entries[i].recurring === true
    ) {
      toRemove.push(entries[i]);
    }
  }
  await removeEntry(toRemove);
};

const removeDeletedCategory = (
  entries: EntryType[],
  month: number,
  year: number,
  categoryLabel: string,
  updateEntry: (id: string, value: EntryType) => void
) => {
  //We go backwards through all entries, so newest come first
  for (let i = entries.length - 1; i >= 0; i--) {
    const thisDate = entries[i].date.toDate();
    //Stop looking if the year is smaller than our search year
    if (thisDate.getFullYear() < year) return;
    //Stop looking if the month is smaller than our search month, assuming we are in the right year
    if (thisDate.getMonth() < month && thisDate.getFullYear() === year) return;
    //This entry is either in the right month or later. If it's in the right month, we check it
    if (
      thisDate.getMonth() === month &&
      thisDate.getFullYear() === year &&
      entries[i].category === categoryLabel
    ) {
      let newEntry = entries[i];
      newEntry.category = "";
      updateEntry(newEntry.id, newEntry);
    }
  }
};

const firebaseDatetoDay = (timeStamp: Timestamp) => {
  return Math.floor(timeStamp.seconds / 60 / 60 / 24);
};

export {
  getFirebaseEntries,
  setFirebaseEntries,
  updateFirebaseEntry,
  deleteFirebaseEntry,
  firebaseDatetoDay,
  createRecurringIncome,
  deleteRecurringIncome,
  getSortedEntries,
  removeDeletedCategory,
};
