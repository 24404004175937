import NumberFormat from "react-number-format";
import { months } from "../../@common/theme/Theme";

export const reorder = <T>(
    list: T[],
    startIndex: number,
    endIndex: number
  ): T[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };


  export const timeStampToDate = (timestamp : string) => {
    const year = timestamp.substring(0,4);
    const month = months[Number(timestamp.substring(4,timestamp.length))];

    return {timeStampMonth: month, timeStampYear: year};
  }

  export const makeTimeStamp = (year: number, month: number) => {
    let m = "";
    let y = String(year);
    if(month < 10) {
      m = "0" + String(month);
    }
    else {
      m = String(month);
    }

    return y + m;
  } 
