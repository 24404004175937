import { createTheme } from '@mui/material/styles';
import { keyframes } from '@emotion/react';
import type {} from '@mui/x-date-pickers/themeAugmentation';

declare module '@mui/material/styles' {

    interface Palette {
        homepage: Palette['primary'];
        balance: Palette['primary'];
        interactable: Palette['divider'];
    }
    interface PaletteOptions {
        homepage: PaletteOptions['primary'];
        balance: PaletteOptions['primary'];
        interactable: PaletteOptions['divider'];
    }  
    interface TypographyVariants {
      strong: React.CSSProperties;
      logo: React.CSSProperties;
    }
    interface TypographyVariantsOptions {
      strong?: React.CSSProperties;
      logo?: React.CSSProperties;
    }
    interface PaletteColor {
      muted?: string;
      textStyle?: string;

    }
 
    interface SimplePaletteColorOptions {
      muted?: string;
      textStyle?: string;

    }
  }

  declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
      homepage: true;
    }
  }
  
  declare module '@mui/material/Fab' {
    interface FabPropsColorOverrides {
      homepage: true;
    }
  }

  declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
      strong: true;
      logo: true;
    }
  }


const breakpoints = {
    values: {
      xs: 0,
      sm: 420,
      md: 768,
      lg: 1300,
      xl: 1536,
    }
}

export const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
export const monthNumbers = [0,1,2,3,4,5,6,7,8,9,10,11];
export const defaultBlocks = ["Fixed Expenses", "Living", "Fun", "Other", "Savings"];
export const defaultCategories = [["Rent", "Utilities", "Internet", "Phone", "Subscriptions"],
["Groceries", "Household", "Health & Cosmetics", "Clothing", "Transport"],
["Eating Out", "Entertainment", "Vacation"],
["Unexpected Expenses", "Gifts", "Purchases"],
["Emergency Fund", "Savings Fund"]];
export const generalIcons = [
  "Banknote",
  "Subscriptions",
  "Bank",
  "Coins",
  "Wallet",
  "Savings",
  "Unexpected",
  "Phone",
  ];
  export const generalPaidIcons = [
    "Tag",
    "Shield-02",
    "Student",
    "Tablet",
    "Telephone",
  ];
  export const houseIcons = [
  "Rent",
  "Utilities",
  "House-gear",
  "Water",
  "Internet",
  "Household",
  "Truck",
  
  ];
  export const housePaidIcons = [
    "House",
    "Bolt",
    "Spanner",
    "Screwdriver",
    "Box",
    "Brush"

  ];
  export const livingIcons = [
  "Bag",
  "Purchases",
  "Groceries",
  "Armchair",
   "Lamp",
   "Clothing",
   "Shirt",
   "Cutlery",
   "Eating",
   "Burger",
   "Coffee",
   "Pet",
   "Emergency",
   "Health",
   "Bandaid-single",  

  ];
  export const livingPaidIcons = [
    "Kiosk",
    "Sofa",
    "Dress",
    "Shoe",
    "Cup",
    "Leaves",
    "Heart",
    "Bandaid",
    "Pill",

  ];
  export const funIcons = [
  "Basketball",
  "Dumbbell",
  "Vacation",
  "Book",
  "TV",
  "Entertainment",
  "Palette",
  "Music",
  
  ];
  export const funPaidIcons = [
    "Tennisball",
  "Volleyball",
  "Filmroll",
  "Popcorn",
  "Camera",
  "Microphone",
  "Controller",
  "Cake",
  "Teddy",
  "Pokerchip"
  ];
  export const travelIcons = [
  "Transport",
  "Airplane",
  "Train",
  "Pin",
  "World",
  ];
  export const travelPaidIcons = [
    "Ship",
    "Ticket",
    "Bike",
    "Map",
    "Location",
  ];
  export const otherIcons = [
  "Star",
  "Cloud",
  "Drop",
  "Gifts",
  "List",
  "Letter",
  ];
  export const otherPaidIcons = [
    "Diamond",
    "Calendar",
    "Paperclip",
    "Communication",
    "Lock"
  ];

export const allCurrencies = [
  {val: "ARS", name: "Argentine Peso"},
  {val: "AUD", name: "Australian Dollar"},
  {val: "BRL", name: "Brazilian Real"},
  {val: "GBP", name: "British Pound Sterling"},
  {val: "CAD", name: "Canadian Dollar"},
  {val: "CLP", name: "Chilean Peso"},
  {val: "CNY", name: "Chinese Yuan Renminbi"},
  {val: "COP", name: "Colombian Peso"},
  {val: "CZK", name: "Czech Koruna"},
  {val: "DKK", name: "Danish Krone"},
  {val: "EUR", name: "Euro"},
  {val: "HKD", name: "Hong Kong Dollar"},
  {val: "HUF", name: "Hungarian Forint"},
  {val: "INR", name: "Indian Rupee"},
  {val: "JPY", name: "Japanese Yen"},
  {val: "KRW", name: "Korean Won"},
  {val: "MYR", name: "Malaysian Ringgit"},
  {val: "MXN", name: "Mexican Peso"},
  {val: "MAD", name: "Moroccan Dirham"},
  {val: "NZD", name: "New Zealand Dollar"},
  {val: "NOK", name: "Norwegian Krone"},
  {val: "PHP", name: "Philippine Peso"},
  {val: "PLN", name: "Polish Zloty"},
  {val: "RUB", name: "Russian Ruble"},
  {val: "SAR", name: "Saudi Riyal"},
  {val: "SGD", name: "Singapore Dollar"},
  {val: "ZAR", name: "South African Rand"},
  {val: "SEK", name: "Swedish Krona"},
  {val: "CHF", name: "Swiss Franc"},
  {val: "THB", name: "Thai Baht"},
  {val: "TRY", name: "Turkish Lira"},
  {val: "USD", name: "US Dollar"},
  {val: "VND", name: "Vietnamese Dong"},

]

export const allLanguages = [
  {val: "en", name: "English (US)"},
  {val: "en-gb", name: "English (UK)"},
  {val: "de", name: "Deutsch"},


]

export const REVENUECAT_API_KEY = 'strp_mcEcYMfDnbXlEWrIgbpJcEvifKF';


export const font_family_strong = 'objectivitymedium';
export const font_family = [
    'ApfelGrotezkRegular',
    'Roboto',
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Helvetica Neue',
    'sans-serif',
  ].join(',');



  export const expandAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;
export const shrinkAnimation = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(50px);
  }
`;
export const opacityInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;


export const LightTheme = createTheme({
    breakpoints,
    palette: {
      common: {
        white: "#ffffff",
        black: "#000000"
      },
      homepage: {
        main: '#300434',
        light: '#F8F9F9',
        dark: '#300434',
        contrastText:'#FFFDFC',
      },
          primary: {
            main: '#300434',
            light: '#300434',
            dark: '#300434',
            contrastText:'#FFFDFC',
          },
          secondary: {
            main: '#E5DCDC',
            light: '#E5DCDC',
            dark: '#E5DCDC',
            contrastText:'#300434',
          },
          warning: {
            main: '#F39C81',
            light: '#FEEADE',
            contrastText: '#E56F4A',
          },
          success: {
            main: '#7BC197',
            light: '#E6F2DF',
            textStyle: '#6CBC8B',
            contrastText:'#2B9956',
          },
          error: {
            main: '#ED7373',
            light: '#FFDAD8',
            textStyle: '#EA7878',
            contrastText:'#DB4D4D',
          },
          info: {
            light: '#EAF4F2',
            main: '#7BA99E',
            contrastText:'#386C67',
          },
          background: {
            default: '#FAF8F8',
            paper: '#ffffff',
          },
          text: {
            primary: '#210124',
            secondary: '#8C7E8D',
            disabled: '#8C7E8D',
          },
          divider: '#D9D9D9',
          interactable: '#F1EEF1',
          balance: {
            light: '#E9D8FF',
            main: '#A76BF5'
          }

    },
    shape: {
      borderRadius: 10,
    },
    typography: {
      fontSize: 16,
      fontFamily: font_family,
      h1: {
        fontFamily: font_family_strong,
        letterSpacing: '-0.35rem',
        fontSize: '3.8rem',
        [createTheme({breakpoints}).breakpoints.down('lg')]: {
          fontSize: '3.5rem',
        },
        [createTheme({breakpoints}).breakpoints.down('md')]: {
          fontSize: '3rem',
          letterSpacing: '-0.3rem',
        },
      },
      h2: {
        //fontFamily: font_family_strong,
        fontSize: '3.2rem',
        letterSpacing: '-0.05rem',
        [createTheme({breakpoints}).breakpoints.down('lg')]: {
          fontSize: '2.5rem',
        },
        [createTheme({breakpoints}).breakpoints.down('md')]: {
          fontSize: '2.3rem',
        },
      },
      h3: {
        //fontFamily: font_family_strong,
        letterSpacing: '-0.1rem',
        fontSize: '2.2rem',
        lineHeight: 1.25,
        [createTheme({breakpoints}).breakpoints.down('lg')]: {
          fontSize: '2rem',
        },
        [createTheme({breakpoints}).breakpoints.down('md')]: {
          fontSize: '1.8rem',
        },
      },
      h4: {
        //fontFamily: font_family_strong,
        letterSpacing: '-0.02rem',
        fontSize: '1.7rem',
        [createTheme({breakpoints}).breakpoints.down('md')]: {
          fontSize: '1.6rem',
        },
      },
      h5: {
        //fontFamily: font_family_strong,
        letterSpacing: '-0.03rem',
        fontSize: '1.4rem',
        [createTheme({breakpoints}).breakpoints.down('md')]: {
          fontSize: '1.3rem',
        },
      },
      h6: {
        fontFamily: font_family,
        fontSize: '1rem',
        [createTheme({breakpoints}).breakpoints.down('md')]: {
          fontSize: '0.9rem',
        },
      },
      body1: {
        fontSize: '16px'
      },
      body2: {
        fontSize: '18px',
        fontWeight: 200,
      },
      button: {
        fontSize: '16px',
      },
      strong: {
        fontFamily: font_family_strong,
        fontWeight: 700,
      },
      logo: {
        fontSize: '1.8rem',
      },
      caption: {
        fontSize: '0.8rem',
      },
      
    },
    components: {
       MuiTypography: {
         styleOverrides: {
           root: {
             color: "#300434",
           },
         },
       },
            MuiButtonGroup: {
              styleOverrides: {
                root: {
                  "&.MuiButtonGroup-root": {
                    marginLeft: 0,
                    marginRight: 1,
                  }
                },
              },
            },
            MuiTextField: {
              defaultProps: {
                variant: 'outlined' as const,
              },
              styleOverrides: {
                  root: {
                    '& .MuiInputBase-root': {
                      borderRadius: '4px',
                    }
                  }
              }
            },
            MuiButton: {
              styleOverrides: {
                root: {
                  borderRadius: '99em',
                  textTransform: 'none',
                },
                sizeSmall: {
                  paddingLeft: '1rem',
                  paddingRight: '1rem',
                  marginLeft: '-1rem',
                  marginRight: '1rem',
                },
                contained: {
                  boxShadow: 'none',
                }
              },
            },
            MuiTable: {
              styleOverrides: {
                root: {
                  "&.MuiTable-root": {
                    marginRight: 0,
                    width: '100%',
                  }
                },
              },
          },
          MuiInputBase: {
            styleOverrides: {
              root: {
                "&.MuiInputBase-root": {
                  borderRadius: '4px',
                }
              },
            },
        },
    

    }
  })

  export const DarkTheme = createTheme({
    breakpoints,
    palette: {
      common: {
        white: "#ffffff",
        black: "#000000"
      },
      homepage: {
        main: '#300434',
        light: '#F2F5F5',
        dark: '#300434',
        contrastText:'#FFFDFC',
      },
          primary: {
            main: '#FFC6BE',
            light: '#FFC6BE',
            dark: '#FFC6BE',
            contrastText:'#272527',
          },
          secondary: {
            main: '#E5DCDC',
            light: '#E5DCDC',
            dark: '#E5DCDC',
            contrastText:'#210124',
          },
          warning: {
            main: '#EB8768',
            light: '#FFDCC8',
            contrastText: '#C84A22',
          },
          success: {
            main: '#5FB07E',
            light: '#E6F2DF',
            textStyle: '#92DAAE',
            contrastText:'#277445',
          },
          error: {
            main: '#DF5B5B',
            light: '#F9C9C6',
            textStyle: '#FB8F8F',
            contrastText:'#BB3535',
          },
          info: {
            light: '#E2F5F2',
            main: '#5BAEA6',
            contrastText:'#35716B',
          },
          background: {
            default: '#272527',
            paper: '#413D41',
          },
          text: {
            primary: '#FAF8F8',
            secondary: '#D2CFCD',
            disabled: '#D2CFCD',
          },
          divider: '#D9D9D9',
          interactable: '#383438',
 
          balance: {
            light: '#C8B9DC',
            main: '#854BD0'
          }

    },
    shape: {
      borderRadius: 10,
    },
    typography: {
      fontSize: 16,
      fontFamily: font_family,
      h1: {
        fontFamily: font_family_strong,
        letterSpacing: '-0.35rem',
        fontSize: '3.8rem',
        [createTheme({breakpoints}).breakpoints.down('lg')]: {
          fontSize: '3.5rem',
        },
        [createTheme({breakpoints}).breakpoints.down('md')]: {
          fontSize: '3rem',
          letterSpacing: '-0.3rem',
        },
      },
      h2: {
        //fontFamily: font_family_strong,
        fontSize: '3.2rem',
        letterSpacing: '-0.05rem',
        [createTheme({breakpoints}).breakpoints.down('lg')]: {
          fontSize: '2.5rem',
        },
        [createTheme({breakpoints}).breakpoints.down('md')]: {
          fontSize: '2.3rem',
        },
      },
      h3: {
        //fontFamily: font_family_strong,
        letterSpacing: '-0.1rem',
        fontSize: '2.2rem',
        lineHeight: 1.25,
        [createTheme({breakpoints}).breakpoints.down('lg')]: {
          fontSize: '2rem',
        },
        [createTheme({breakpoints}).breakpoints.down('md')]: {
          fontSize: '1.8rem',
        },
      },
      h4: {
        //fontFamily: font_family_strong,
        letterSpacing: '-0.02rem',
        fontSize: '1.7rem',
        [createTheme({breakpoints}).breakpoints.down('md')]: {
          fontSize: '1.6rem',
        },
      },
      h5: {
        //fontFamily: font_family_strong,
        letterSpacing: '-0.03rem',
        fontSize: '1.4rem',
        [createTheme({breakpoints}).breakpoints.down('md')]: {
          fontSize: '1.3rem',
        },
      },
      h6: {
        fontFamily: font_family,
        fontSize: '1rem',
        [createTheme({breakpoints}).breakpoints.down('md')]: {
          fontSize: '0.9rem',
        },
      },
      body1: {
        fontSize: '16px'
      },
      body2: {
        fontSize: '18px',
        fontWeight: 200,
      },
      button: {
        fontSize: '16px',
      },
      strong: {
        fontFamily: font_family_strong,
        fontWeight: 700,
      },
      logo: {
        fontSize: '1.8rem',
      },
      caption: {
        fontSize: '0.8rem',
      },

      
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            color: "#FFFDFC",
          },
        },
      },
            MuiButtonGroup: {
              styleOverrides: {
                root: {
                  "&.MuiButtonGroup-root": {
                    marginLeft: 0,
                    marginRight: 1,
                  }
                },
              },
            },
            MuiTextField: {
              defaultProps: {
                variant: 'outlined' as const,
              },
              styleOverrides: {
                root: {
                  "& .MuiTextField-root": {
                    borderRadius: '4px',
                  }
                }
              }
            },
            MuiButton: {
              styleOverrides: {
                root: {
                  borderRadius: '99em',
                  textTransform: 'none',
                },
                sizeSmall: {
                  paddingLeft: '1rem',
                  paddingRight: '1rem',
                  marginLeft: '-1rem',
                  marginRight: '1rem',
                },
                contained: {
                  boxShadow: 'none',
                }
              },
            },
            MuiTable: {
              styleOverrides: {
                root: {
                  "&.MuiTable-root": {
                    marginRight: 0,
                    width: '100%',
                  }
                },
              },
          },
          MuiInputBase: {
            styleOverrides: {
              root: {
                "&.MuiInputBase-root": {
                  borderRadius: '4px',
                }
              },
            },
        },

    }
  })

