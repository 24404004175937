import { Auth, confirmPasswordReset } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, TextField, Typography, styled, useTheme } from "@mui/material";

type PasswordResetType = {
  email: string;
  auth: Auth;
  actionCode: string;
  lang: string;
};

const CustomTextfield = styled(TextField)(({ theme }) => ({
  margin: theme.spacing(0,0,2,0),
  width: "100%"
}));

const PasswordResetContainer = styled('div')(({ theme }) => ({
  width: '450px',
  textAlign: 'center',
   }
 ));


const PasswordReset = ({
  email,
  auth,
  actionCode,
  lang,
}: PasswordResetType) => {
  const {t, i18n} = useTranslation();
  const [newPasswordOne, setNewPasswordOne] = useState<string>("");
  const [newPasswordTwo, setNewPasswordTwo] = useState<string>("");
  const [errorState, setErrorState] = React.useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const theme = useTheme();



  // TODO: Show the reset screen with the user's email and ask the user for
  // the new password.

  const ApplyChanges = () => {
    setMessage("") 
    // Save the new password.
    if (newPasswordOne === newPasswordTwo && newPasswordOne.length > 5) {
      setErrorState(false);
      confirmPasswordReset(auth, actionCode, newPasswordOne)
        .then((resp) => {
          // Password reset has been confirmed and new password updated.
          // Provide a success message on the screen and ask user to log back in
          setMessage(
            t(
              "Success! You can now log into your account with your new password.",
              { lng: lang }
            ) as string
          );
        })
        .catch((error) => {
          // Error occurred during confirmation. The code might have expired or the
          // password is too weak.
          setMessage(
            t(
              "Your request is expired or your password is too weak. Please try again.",
              { lng: lang }
            ) as string
          );
        });
    }
    else {
      setErrorState(true);
    }
  };


  return (
    <>
      <Typography variant='h5' sx={{mb:2, textAlign: "center"}}>{t("Let's reset your password.", { lng: lang })}</Typography>
      <Typography variant="body1">{t("Enter a new password to reset the password on your account", { lng: lang })}</Typography>
      <Typography sx={{mb: 6}}>{email}</Typography>

      <PasswordResetContainer>
        <CustomTextfield 
          id="new-password-input"
          label={t("New Password", { lng: lang })}
          aria-labelledby={t("New Password", { lng: lang }) || "New Password"}
          aria-lang={i18n.language}
          type="password"
          inputProps={{ maxLength: 30 }}
          variant="outlined"
          value={newPasswordOne}
          onChange={(e) => setNewPasswordOne(e.target.value)}
          onKeyDown={(e : any) => {
            if (e.key === "Enter") ApplyChanges();
          }}
        />
        <CustomTextfield 
          id="new-password-input"
          label={t("Confirm New Password", { lng: lang })}
          aria-labelledby={t("New Password", { lng: lang }) || "New Password"}
          aria-lang={i18n.language}
          type="password"
          inputProps={{ maxLength: 30 }}
          variant="outlined"
          value={newPasswordTwo}
          onChange={(e) => setNewPasswordTwo(e.target.value)}
          onKeyDown={(e : any) => {
            if (e.key === "Enter") ApplyChanges();
          }}
        />
        {errorState === true?
          <Typography color={theme.palette.error.main} sx={{mb: 2, textAlign: 'left'}}>
            {t("Something went wrong. Make sure both passwords are the same and longer than 4 characters.", { lng: lang })}
          </Typography>
          :
          <></>
          }
          <Typography color={theme.palette.error.main} sx={{mb: 2, textAlign: 'left'}}>{message}</Typography>
      <Button variant='contained' onClick={ApplyChanges} sx={{mt:2}}>{t("Reset password", { lng: lang })}</Button>
      </PasswordResetContainer>
      
    </>
  );
};

export default PasswordReset;
