import { useEffect, useState } from "react";
import { Auth, applyActionCode, verifyPasswordResetCode } from "firebase/auth";
import { auth } from "../../@common/firebase/firebase";
import { FirebaseError } from "firebase/app";
import { useTranslation } from "react-i18next";
import { Typography, styled } from "@mui/material";
import Navigation from "../../@common/navigation/Navigation";
import PasswordReset from "./PasswordReset";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(3),
  backgroundColor: theme.palette.homepage.light,
  color: theme.palette.homepage.contrastText,
  height: "90vh",
}));

const UserManagement = () => {
  const [message, setMessage] = useState<string>("");
  const [passwordResetInfo, setPasswordResetInfo] = useState<{
    email: string;
    auth: Auth;
    actionCode: string;
    lang: string;
  } | null>(null);
  const { t } = useTranslation();

  const handleVerifyEmail = async (
    auth: Auth,
    actionCode: string,
    lang: string
  ) => {
    try {
      await applyActionCode(auth, actionCode);
      setMessage(
        t(
          "Email verified successfully. You can now sign in with your email and password.",
          { lng: lang }
        ) as string
      );
    } catch (error) {
      console.error("Email verification error:", error);
      handleEmailVerificationError(error as FirebaseError, lang);
    }
  };

  const handleEmailVerificationError = (error: FirebaseError, lang: string) => {
    switch (error.code) {
      case "auth/expired-action-code":
        setMessage(
          t(
            "The verification code has expired. Please request a new verification email.",
            { lng: lang }
          ) as string
        );
        break;
      case "auth/invalid-action-code":
        setMessage(
          t(
            "The verification code is invalid. Please check the code and try again.",
            { lng: lang }
          ) as string
        );
        break;
      case "auth/user-disabled":
        setMessage(
          t("The user account associated with this email has been disabled.", {
            lng: lang,
          }) as string
        );
        break;
      case "auth/user-not-found":
        setMessage(
          t(
            "No user found corresponding to the verification code. The account may have been deleted.",
            { lng: lang }
          ) as string
        );
        break;
      default:
        setMessage(
          t(
            "An unknown error occurred during email verification. Please try again.",
            { lng: lang }
          ) as string
        );
        break;
    }
  };

  const handleResetPassword = async (
    auth: Auth,
    actionCode: string,
    lang: string
  ) => {
    // Localize the UI to the selected language as determined by the lang
    // parameter.

    // Verify the password reset code is valid.
    verifyPasswordResetCode(auth, actionCode)
      .then((email) => {
        setPasswordResetInfo({
          email: email,
          auth: auth,
          actionCode: actionCode,
          lang: lang,
        });
      })
      .catch((error) => {
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
        setPasswordResetInfo(null);
        setMessage(t("Your request is expired. Please try again.") as string);
      });
  };

  const handleError = () => {
    setMessage(t("Something went wrong.") as string);
  };

  useEffect(() => {
    if (typeof window === "undefined") return;

    const urlParams = new URLSearchParams(window.location.search);
    const actionCode = urlParams.get("oobCode");
    const mode = urlParams.get("mode");
    const lang = urlParams.get("lang") || "en";

    if (!actionCode) {
      handleError();
      return;
    }

    switch (mode) {
      case "resetPassword":
        handleResetPassword(auth, actionCode, lang);
        break;
      case "recoverEmail":
        //  handleRecoverEmail(auth, actionCode, lang);
        break;
      case "verifyEmail":
        handleVerifyEmail(auth, actionCode, lang);
        break;
      default:
        handleError();
    }
  }, []);

  return (
    <>
      <Navigation />
      <Container>
        {passwordResetInfo ? (
          <PasswordReset {...passwordResetInfo} />
        ) : (
          <Typography variant="body1">{message}</Typography>
        )}
      </Container>
    </>
  );
};

export default UserManagement;
