import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { Button, Dialog, TextField, Typography } from '@mui/material';
import Portal from '@mui/material/Portal';
import LoadingScreen from '../pages/LoadingScreen';

  type LoadingModalType = {
    open: boolean,
  }

  const LoadingModal = ({open} : LoadingModalType) => {
    const container = React.useRef(null);

  if (!open) return <></>;

  return (
    <Portal container={container.current}>
    <LoadingScreen/>
  </Portal>

    
  );
    

};

export default LoadingModal;
