import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, doc, DocumentData } from "firebase/firestore";
import React, { useState, useContext, createContext, useEffect } from "react";
import {
  BlockType,
  CategoryType,
  HouseholdType,
  MemberType,
  UserType,
  createFirebaseHousehold,
  createFirebaseUser,
  deleteFirebaseUser,
  getFirebaseUser,
  updateFirebaseBlocks,
  updateFirebaseHousehold,
  updateFirebaseUser,
} from "../../main/resources/UserResources";
import { firestore } from "../firebase/firebase";
import useAuth from "../hooks/useAuth";
import axios from "axios";
import { REVENUECAT_API_KEY } from "../theme/Theme";

const UserContext = createContext(null);

const useUser = () => {
  const { user, household, blocks, userSetup, premium, isAdmin } =
    useContext<any>(UserContext);
  const [thisUser, setThisUser] = user;
  const [userIsSetup, setUserIsSetup] = userSetup;
  const [currentHousehold, setCurrentHousehold] = household;
  const [currentBlocks, setCurrentBlocks] = blocks;

  const handleCreate = (
    name: string,
    id: string,
    currency: string,
    householdName: string
  ) => {
    createFirebaseUser(id, name)
      .then((response) => {
        let createdUser = response;
        const members: MemberType[] = [
          {
            id: createdUser.id,
            name: createdUser.name,
          },
        ];
        let newHousehold = {
          id: "0",
          owner: response.id,
          name: householdName,
          members: members,
          currency: currency,
        };
        createFirebaseHousehold(newHousehold)
          .then((response) => {
            createdUser.household = response.id;
            updateFirebaseUser(createdUser);
            setCurrentHousehold(newHousehold);
            setThisUser(createdUser);
            setUserIsSetup(true);
            setCurrentBlocks(createdUser.blocks);
          })
          .catch((e) => {
            console.log("Error with creating household. Message: " + e);
          });
      })
      .catch((e) => {
        console.log("Error with creating user. Message: " + e);
      });
  };

  const handleCreateHousehold = (householdName: string, currency: string) => {
    const members: MemberType[] = [
      {
        id: thisUser.id,
        name: thisUser.name,
      },
    ];
    const newHousehold = {
      id: "0",
      owner: thisUser.id,
      name: householdName,
      members: members,
      currency: currency,
    };

    createFirebaseHousehold(newHousehold)
      .then((response) => {
        setCurrentHousehold(response);
      })
      .catch((e) => {
        console.log("Error with creating household. Message: " + e);
      });
  };

  const setUserandHousehold = (
    newUser: UserType,
    newHousehold: HouseholdType
  ) => {
    setThisUser(newUser);
    setCurrentHousehold(newHousehold);
  };

  const updateUser = (user: UserType) => {
    updateFirebaseUser(user)
      .then((response) => {
        setThisUser(user);
      })
      .catch((e) => {
        console.log("Error with retrieving user.");
      });
  };

  const updateHousehold = (household: HouseholdType) => {
    updateFirebaseHousehold(household)
      .then((response) => {
        setCurrentHousehold(household);
      })
      .catch((e) => {
        console.log("Error with retrieving user.");
      });
  };

  const updateCategory = (
    newCategory: CategoryType,
    oldCategory: CategoryType,
    blockIndex: number
  ) => {
    const temp = [...currentBlocks];

    const index = temp[blockIndex].categories.findIndex(
      (cat: CategoryType) => cat.id === oldCategory.id
    );
    if (index > -1) {
      // only splice array when item is found
      temp[blockIndex].categories[index] = newCategory;
    }

    setCurrentBlocks(temp);

    updateFirebaseBlocks(thisUser.id, temp).catch((e) => {
      console.log("Error updating blocks. Message: " + e);
    });
  };

  const updateCategoryFund = (
    newFund: number,
    category: CategoryType,
    monthStamp: string,
    blockIndex: number
  ) => {
    const temp = [...currentBlocks];

    const index = temp[blockIndex].categories.findIndex(
      (cat: CategoryType) => cat.id === category.id
    );
    temp[blockIndex].categories[index].budget[monthStamp] = newFund;
    setCurrentBlocks(temp);

    updateFirebaseBlocks(thisUser.id, temp).catch((e) => {
      console.log("Error updating blocks. Message: " + e);
    });
  };

  const updateCategoryStartingValue = (
    newStartingValue: number,
    category: CategoryType,
    blockIndex: number
  ) => {
    const temp = [...currentBlocks];

    const index = temp[blockIndex].categories.findIndex(
      (cat: CategoryType) => cat.id === category.id
    );
    temp[blockIndex].categories[index].startingBalance = newStartingValue;
    setCurrentBlocks(temp);

    updateFirebaseBlocks(thisUser.id, temp).catch((e) => {
      console.log("Error updating blocks. Message: " + e);
    });
  };

  const updateAllBlocks = (newBlocks: BlockType[]) => {
    updateFirebaseBlocks(thisUser.id, newBlocks).catch((e) => {
      console.log("Error updating categories. Message: " + e);
    });
  };

  return {
    user: thisUser as UserType,
    currentHousehold: currentHousehold as HouseholdType,
    blocks: currentBlocks as BlockType[],
    setBlocks: setCurrentBlocks,
    userIsSetup,
    premium,
    isAdmin,
    setUserandHousehold: setUserandHousehold,
    createUser: handleCreate,
    createHousehold: handleCreateHousehold,
    updateHousehold: updateHousehold,
    updateUser: updateUser,
    updateCategory: updateCategory,
    updateCategoryFund: updateCategoryFund,
    updateCategoryStartingValue: updateCategoryStartingValue,
    updateAllBlocks: updateAllBlocks,
  };
};

type UserProviderTypes = {
  children: React.ReactNode;
};

const UserProvider = ({ children }: UserProviderTypes) => {
  const [thisUser, setThisUser] = useState<DocumentData | null | undefined>(
    null
  );
  const [currentHousehold, setCurrentHousehold] = useState<
    DocumentData | null | undefined
  >(null);
  const [currentBlocks, setCurrentBlocks] = useState<BlockType[]>([]);
  const [userIsSetup, setUserIsSetup] = useState<boolean>(true);
  const [premium, setPremium] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const auth = getAuth();
  const { setWaitForResponse } = useAuth();

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      setWaitForResponse(true);
      if (user) {
        await user.reload();
        //Check User Claims for Admin or Premium
        user.getIdTokenResult().then((idTokenResult) => {
          // console.log(idTokenResult.claims)

          if (idTokenResult.claims.admin) setIsAdmin(true);
          else setIsAdmin(false);
          if (idTokenResult.claims.premium) setPremium(true);
          else {
            axios
              .get(`https://api.revenuecat.com/v1/subscribers/${user.uid}`, {
                headers: {
                  Authorization: `Bearer ${REVENUECAT_API_KEY}`,
                  "Content-Type": "application/json",
                },
              })
              .then((data: any) => {
                if (
                  typeof data.subscriber.entitlements.active["Premium"] !==
                  "undefined"
                ) {
                  setPremium(true);
                } else {
                  setPremium(false);
                }
              })
              .catch((error) => {
                setPremium(false);
              });
          }
        });

        getFirebaseUser(user.uid)
          .then((response) => {
            if (response.user == null) setUserIsSetup(false);
            setThisUser(response.user);
            setCurrentHousehold(response.household || null);
            //We need to get the correct categories for this month here
            setCurrentBlocks(response.user?.blocks);
            setWaitForResponse(false);
          })
          .catch((e) => {
            setUserIsSetup(false);
            console.log("Error with retrieving user. Message: " + e);
          });
      } else {
        // User is signed out
        // ...
      }
    });

    return () => {
      if (thisUser) {
        updateFirebaseBlocks(thisUser.id, currentBlocks).catch((e) => {
          console.log("Error updating categories. Message: " + e);
        });
      }
    };
  }, []);

  return (
    <UserContext.Provider
      value={
        {
          user: [thisUser, setThisUser],
          household: [currentHousehold, setCurrentHousehold],
          blocks: [currentBlocks, setCurrentBlocks],
          userSetup: [userIsSetup, setUserIsSetup],
          premium,
          isAdmin,
        } as any
      }
    >
      {children}
    </UserContext.Provider>
  );
};
export { UserProvider, useUser };
