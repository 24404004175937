import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { Button, Dialog, Typography, InputLabel, MenuItem, Select, TextField, useTheme } from '@mui/material';
import { useUser } from '../../@common/contexts/UserContext';
import { collection, doc, setDoc } from 'firebase/firestore';
import { auth, firestore } from '../../@common/firebase/firebase';
import { useAppContext } from '../../@common/contexts/AppContext';
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import { useTranslation } from 'react-i18next';

const CustomDialog = styled(Dialog)(({ theme }) => ({

  '& .MuiPaper-root':{
    width: "300px",
    borderRadius: "25px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(4)
  }

}));

const CustomTextfield = styled(TextField)(({ theme }) => ({
  margin: theme.spacing(0,0,2,0),
  width: "100%"
}));

type PasswordChangeDialogType = {
  open: boolean,
  setOpen: (open: boolean) => void,
}

const PasswordChangeDialog = ({open, setOpen} : PasswordChangeDialogType) => {
const [oldPassword, setOldPassword] = React.useState<string>('');
const [newPasswordOne, setNewPasswordOne] = React.useState<string>('');
const [newPasswordTwo, setNewPasswordTwo] = React.useState<string>('');
const [errorState, setErrorState] = React.useState<boolean>(false);
const {AddInfoNotification} = useAppContext();
const theme = useTheme();
const {t, i18n} = useTranslation();



	const handleClose = () => {
		setOpen(false);
    setErrorState(false);
    setOldPassword('');
    setNewPasswordOne('');
    setNewPasswordTwo('');
	};

    const handleSubmit = async () => {
      if (newPasswordOne === newPasswordTwo && newPasswordOne.length > 5 && auth.currentUser)
      {
        const credential = EmailAuthProvider.credential(auth.currentUser.email || "", oldPassword);
        const thisUser = auth.currentUser;
    
        reauthenticateWithCredential(thisUser, credential).then(() => {
          // User re-authenticated.
          updatePassword(thisUser, newPasswordOne)
          .then(() => {
            AddInfoNotification(t("Successfully updated password!"));
            handleClose();
          });
        }).catch((error : any) => {
          console.log(error)
    
        });
      }
      else {
        setErrorState(true);
      }
      
    }


  return (
      <CustomDialog
      open={open}
      onClose={handleClose}
      >
        <Typography variant='h5' sx={errorState === true? {mb: 1, textAlign: "center"} : {mb:4, textAlign: "center"}}>{t("Let's make some changes!")}</Typography>
        {errorState === true?
        <Typography color={theme.palette.warning.main} sx={{mb: 2}}>
          {t("Something went wrong. Make sure both passwords are the same and longer than 4 characters.")}
        </Typography>
        :
        <></>
        }
        <CustomTextfield 
          id="old-password-input"
          label={t("Old Password")}
          aria-labelledby={t("Old Password") || "Old Password"}
          aria-lang={i18n.language}
          type="password"
          inputProps={{ maxLength: 30 }}
          variant="outlined"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          onKeyDown={(e : any) => {
            if (e.key === "Enter") handleSubmit();
            if (e.key === "Escape") handleClose();
          }}
        />
        <CustomTextfield 
          id="new-password-input"
          label={t("New Password")}
          aria-labelledby={t("New Password") || "New Password"}
          aria-lang={i18n.language}
          type="password"
          inputProps={{ maxLength: 30 }}
          variant="outlined"
          value={newPasswordOne}
          onChange={(e) => setNewPasswordOne(e.target.value)}
          onKeyDown={(e : any) => {
            if (e.key === "Enter") handleSubmit();
            if (e.key === "Escape") handleClose();
          }}
        />
       <CustomTextfield 
          id="password-confirm-input"
          label={t("Confirm New Password")}
          aria-labelledby={t("Confirm New Password") || "Confirm New Password"}
          aria-lang={i18n.language}
          type="password"
          inputProps={{ maxLength: 30 }}
          variant="outlined"
          value={newPasswordTwo}
          onChange={(e) => setNewPasswordTwo(e.target.value)}
          onKeyDown={(e : any) => {
            if (e.key === "Enter") handleSubmit();
            if (e.key === "Escape") handleClose();
          }}
        />
      <Button variant='contained' onClick={handleSubmit} sx={{mt:2}}>{t("Send")}</Button>

      </CustomDialog>
   
    
  );
};

export default PasswordChangeDialog;
