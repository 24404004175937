import React from "react";
import { Doughnut, getElementAtEvent } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip } from "chart.js";
import { useUser } from "../../@common/contexts/UserContext";
import {
  categorySumForMonth,
  formatCurrency,
  getSumforMonth,
} from "../../main/resources/CalcRessources";
import { useEntry } from "../../@common/contexts/EntryContext";
import { styled } from "@mui/system";
import { Typography, Button, useTheme } from "@mui/material";
import { expandAnimation, months } from "../../@common/theme/Theme";
import CircleIcon from "@mui/icons-material/Circle";
import { makeTimeStamp } from "../../overview/resources/helpers";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useAppContext } from "../../@common/contexts/AppContext";
import Sankey from "./MonthlySankey";
import MonthlyTable from "./MonthlyTable";
import fileDownload from "js-file-download";
import ExportMenu from "./ExportMenu";
import { BalanceCard, IncomeCard } from "../../overview/components/BudgetCard";
import { useTranslation } from "react-i18next";
import AdMonthlySankey from "../../main/components/AdMonthlySankey";
import { BlockType, CategoryType } from "../../main/resources/UserResources";
import useCSVExport from "../resources/ExportRessources";

Chart.register(ArcElement, Tooltip);

const MainInsightsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  flexWrap: "wrap",
}));

const LeftCol = styled("div")(({ theme }) => ({
  width: "60%",
  flexGrow: 0,
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));

const RightCol = styled("div")(({ theme }) => ({
  width: "40%",
  flexShrink: 0,
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));

const MonthlyTableBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(4, 2),
  marginLeft: theme.spacing(3),
  borderRadius: "10px",
  animation: expandAnimation + " 200ms ease-out",

  [theme.breakpoints.down("lg")]: {
    margin: theme.spacing(2, 0),
  },
}));

const ReportContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: 10,
  padding: theme.spacing(4, 2),
}));
const SankeyContainer = styled("div")(({ theme }) => ({
  height: "500px",
  margin: theme.spacing(4, 0),
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(4, 3, 8, 3),
  borderRadius: 10,
  position: "relative",
}));

const ChartContainer = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  minHeight: "400px",
  height: "100%",
}));

const LegendContainer = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexWrap: "wrap",
  width: "100%",
  justifyContent: "space-evenly",
  margin: theme.spacing(3, 0, 1, 0),
}));

const LegendItem = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
}));

const MonthPicker = styled("div")(({ theme }) => ({
  margin: "25px 0",
  width: "fit-content",
  display: "flex",
  alignItems: "center",

  "& .MuiTypography-root": {
    display: "inline",
    margin: 0,
  },

  [theme.breakpoints.down("md")]: {
    margin: "25px auto",
  },
}));

const Label = styled(Typography)(({ theme }) => ({
  position: "absolute",
  textAlign: "center",
  fontSize: "1.2rem",
  lineHeight: "2.5rem",
  animation: expandAnimation + " 300ms ease",

  "& .sum": {
    fontSize: "3rem",
  },

  //Mobile screens
  [theme.breakpoints.down("md")]: {
    fontSize: "1rem",
    "& .sum": {
      fontSize: "2rem",
    },
  },
}));

const StatsRow = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(3),
  marginLeft: "auto",
  marginRight: "auto",
  position: "relative",
  borderRadius: "15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
}));

const MonthlyReportChart = () => {
  const { blocks, user, currentHousehold, premium } = useUser();
  const { allEntries } = useEntry();
  const { month, year, isMobile, color_series } = useAppContext();
  const { t } = useTranslation();
  const [yearOfInterest, setYearOfInterest] = React.useState(year);
  const [monthOfInterest, setMonthOfInterest] = React.useState(month);
  const thisTimeStamp = makeTimeStamp(yearOfInterest, monthOfInterest);
  const chartRef = React.useRef();
  const [labelElement, setLabelElement] = React.useState<JSX.Element>(
    <Label sx={{ position: "relative", margin: "auto" }}>
      Nothing to see here.
    </Label>
  );
  const [dataDetails, setDataDetails] = React.useState<any>(null);
  const [blockOfInterest, setBlockOfInterest] = React.useState<any>(null);
  const [borderWidth, setBorderWidth] = React.useState<number | number[]>(0);
  const monthName = t(months[monthOfInterest]) + " " + yearOfInterest;
  const [offset, setOffset] = React.useState<number | number[]>(0);
  const theme = useTheme();
  const { shadowStyle } = useAppContext();
  const { createMonthlyCSV } = useCSVExport();

  const DoughnutOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "85%",
    layout: { padding: 0 },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    currency: currentHousehold.currency,
  };

  let activeBlocks = [...blocks];
  activeBlocks = activeBlocks.filter((item: BlockType) => {
    if (
      item.status === "deleted" &&
      Number(thisTimeStamp) >= Number(item.deleted)
    )
      return false;
    if (Number(item.created) > Number(thisTimeStamp)) return false;
    return true;
  });

  const getBlockSum = (categories: CategoryType[]) => {
    let s = 0;
    let cats = categories.filter((categ: CategoryType) => {
      if (
        categ.status === "deleted" &&
        Number(thisTimeStamp) >= Number(categ.deleted)
      )
        return false;
      if (Number(categ.created) > Number(thisTimeStamp)) return false;
      return true;
    });

    cats.forEach((element) => {
      s =
        s +
        categorySumForMonth(
          user.id,
          allEntries,
          element.label,
          monthOfInterest,
          yearOfInterest
        );
    });
    return Math.round(Math.max(s, 0) * 100) / 100;
  };

  const spentData = React.useMemo(() => {
    return {
      labels: activeBlocks.map((b: BlockType) => b.name),
      datasets: [
        {
          data: activeBlocks.map((b: BlockType) => getBlockSum(b.categories)),
          backgroundColor: color_series,
          hoverBackgroundColor: color_series,
          borderWidth: borderWidth,
          borderColor: color_series,
          hoverBorderColor: color_series,
          hoverBorderWidth: borderWidth,
          offset: offset,
        },
      ],
    };
  }, [borderWidth, activeBlocks, monthOfInterest]);

  const incomeData = React.useMemo(() => {
    return getSumforMonth(
      user.id,
      "income",
      allEntries,
      monthOfInterest,
      yearOfInterest
    );
  }, [user.id, allEntries, monthOfInterest, yearOfInterest]);

  const nextMonth = () => {
    let m = monthOfInterest + 1;
    let y = yearOfInterest;
    if (m >= 12) {
      m = 0;
      y = y + 1;
    }

    setMonthOfInterest(m);
    setYearOfInterest(y);
  };

  const prevMonth = () => {
    let m = monthOfInterest - 1;
    let y = yearOfInterest;
    if (m < 0) {
      m = 11;
      y = y - 1;
    }

    setMonthOfInterest(m);
    setYearOfInterest(y);
  };

  const isSpentDataEmpty = () => {
    for (let i = 0; i < spentData.datasets[0].data.length; i++) {
      if (spentData.datasets[0].data[i] > 0) return false;
    }
    return true;
  };

  React.useEffect(() => {
    if (dataDetails != null) {
      setLabelElement(
        <Label key={"spent" + dataDetails.label}>
          {dataDetails.label}: <br />
          <span className="sum">
            {formatCurrency(dataDetails.data, currentHousehold.currency)}
          </span>
        </Label>
      );
    } else {
      setLabelElement(
        <Label key={"spent"}>
          {t("Spent")}: <br />
          <span className="sum">
            {formatCurrency(spentSum, currentHousehold.currency)}
          </span>
        </Label>
      );
    }
  }, [dataDetails, currentHousehold.currency, monthOfInterest]);

  const clickCanvas = (event: any) => {
    if (chartRef.current) {
      const el = getElementAtEvent(chartRef.current, event);

      if (el.length > 0) {
        const details = {
          label: activeBlocks[el[0].index].name,
          //@ts-ignore
          data: el[0].element.$context.raw,
        };

        let temp = activeBlocks.map((v: BlockType) => 0);

        if (dataDetails && dataDetails.label === details.label) {
          setDataDetails(null);
          setBlockOfInterest(null);
          setBorderWidth(temp);
          setOffset(temp);
        } else {
          setDataDetails(details);
          setBlockOfInterest(activeBlocks[el[0].index]);
          temp[el[0].index] = 5;
          setBorderWidth(temp);
          let offsetTemp = [...temp];
          offsetTemp[el[0].index] = 15;
          setOffset(offsetTemp);
        }
      }
    }
  };

  const spentSum = spentData.datasets[0].data.reduce((accumulator, value) => {
    return accumulator + value;
  }, 0);

  const startExport = () => {
    createMonthlyCSV(
      user.id,
      allEntries,
      activeBlocks,
      monthOfInterest,
      yearOfInterest
    ).then((res: any) => {
      let title = monthName + "_Report.csv";
      fileDownload(res, title);
    });
  };

  //  let currTotal = 0;
  let balance = incomeData - spentSum;
  //  const isPos = balance >= 0? true : false;

  return (
    <>
      <MonthPicker>
        <Button
          onClick={prevMonth}
          sx={{
            minWidth: "auto",
            width: "fit-content",
            mr: 1,
            color: theme.palette.text.primary,
          }}
          aria-label={t("previous month") || "previous month"}
        >
          <ArrowBackIosNewIcon sx={{ fontSize: 16 }} />
        </Button>
        <Typography variant="h5">{monthName}</Typography>
        <Button
          onClick={nextMonth}
          sx={{
            minWidth: "auto",
            width: "fit-content",
            ml: 1,
            color: theme.palette.text.primary,
          }}
          aria-label={t("next month") || "next month"}
        >
          <ArrowForwardIosIcon sx={{ fontSize: 16 }} />
        </Button>
      </MonthPicker>
      <ExportMenu exportFunction={startExport} />
      <MainInsightsContainer>
        <LeftCol>
          <StatsRow>
            <IncomeCard income={incomeData} />
            <BalanceCard fund={balance} />
          </StatsRow>
          <ReportContainer sx={shadowStyle}>
            <ChartContainer>
              <Doughnut
                //@ts-ignore
                data={spentData}
                options={DoughnutOptions}
                ref={chartRef}
                onClick={clickCanvas}
              />
              {activeBlocks.length <= 0 || isSpentDataEmpty() ? (
                <Label>{t("Nothing to see here.")}</Label>
              ) : (
                labelElement
              )}
            </ChartContainer>
            <LegendContainer>
              {activeBlocks.map((block: BlockType, index: number) => {
                return (
                  <LegendItem key={block.name}>
                    <CircleIcon
                      sx={{
                        mr: 1,
                        ml: 1,
                        color: color_series[index % color_series.length],
                      }}
                    />
                    <Typography sx={{ fontSize: "16px" }}>
                      {block.name}
                    </Typography>
                  </LegendItem>
                );
              })}
            </LegendContainer>
          </ReportContainer>
        </LeftCol>
        <RightCol>
          {blockOfInterest != null ? (
            <MonthlyTableBox sx={shadowStyle}>
              <MonthlyTable
                blockOfInterest={blockOfInterest}
                monthOfInterest={monthOfInterest}
                yearOfInterest={yearOfInterest}
              />
            </MonthlyTableBox>
          ) : (
            <></>
          )}
        </RightCol>
      </MainInsightsContainer>
      {isMobile ? (
        <></>
      ) : (
        <>
          <SankeyContainer sx={shadowStyle}>
            <Typography variant="body2" sx={{ marginLeft: "1rem" }}>
              {t("Flow Diagram")}
            </Typography>
            {premium ? (
              <Sankey
                monthOfInterest={monthOfInterest}
                yearOfInterest={yearOfInterest}
              />
            ) : (
              <AdMonthlySankey />
            )}
          </SankeyContainer>
        </>
      )}
    </>
  );
};

export default MonthlyReportChart;
