import React, { useState } from 'react';
import { styled } from '@mui/system';
import { Button, Typography, Switch, useTheme } from '@mui/material';
import { deleteUser, EmailAuthProvider, reauthenticateWithCredential, signOut, sendEmailVerification  } from "firebase/auth";
import Layout from '../layout';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../@common/contexts/AppContext';
import PremiumGrantDialog from './components/PremiumGrantDialog';



const Content = styled('div')(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  maxWidth: "700px",

  [theme.breakpoints.down("md")]: {
    paddingTop: theme.spacing(2),

  }

   }
 ));

 const SettingsBlock = styled('div')(({ theme }) => ({
  
  backgroundColor: theme.palette.background.paper,
  borderRadius: "10px",
  margin: theme.spacing(0,0,3,0),
   
  //Mobile screens
   [theme.breakpoints.down("md")]: {
    padding: theme.spacing(1.5),

  }
   }
 ));


 const ColOne = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(1), 
  fontSize: "1.1em", 

   }
 ));

 const ColTwo = styled('div')(({ theme }) => ({
  flexWrap: "wrap",
  display: "flex"
   }
 ));

 const CustomButton = styled(Button)(({ theme }) => ({
  height: "50px",
  margin: theme.spacing(2,2,2,0),
   }
 ));

 const SettingsRow = styled('div')(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  //marginBottom: theme.spacing(2),
  padding: theme.spacing(0, 2, 0, 3),
  height: '55px',

  '&:not(:last-child)' : {
    borderBottom: '1px solid' + theme.palette.interactable,

  }



   }
 ));



const AdminContainer = () => {
  const {shadowStyle} = useAppContext();
  const theme = useTheme();
  const {t} = useTranslation();
  const [premiumGrantDialogOpen, setPremiumGrantDialogOpen] = useState(false);

  return (
      <Layout title={"Admin"}>

           <Content>
           <Typography variant='h6' sx={{mb: 1, ml: 0.5, color: theme.palette.text.disabled}}>User</Typography>  
          <SettingsBlock sx={shadowStyle}>  
           <SettingsRow>
        <ColOne>No. User</ColOne> 
        <ColOne>XXX</ColOne>
        </SettingsRow>
        <SettingsRow>
        <ColOne>No. Subscriptions</ColOne> 
        <ColOne>XXX</ColOne>
        </SettingsRow>
        <SettingsRow>
        <ColOne>Special Premium Access</ColOne> 
        <ColOne>XXX</ColOne>
        </SettingsRow>
        <SettingsRow>
        <CustomButton onClick={() => setPremiumGrantDialogOpen(true)}>Grant Premium Access</CustomButton> 
        </SettingsRow>
        </SettingsBlock>
       </Content>
       <PremiumGrantDialog open={premiumGrantDialogOpen} setOpen={setPremiumGrantDialogOpen}/>
      </Layout>
   
   
    
  );
};

export default AdminContainer;
