import { Input, styled, SxProps } from '@mui/material';
import React from 'react'
import { formatCurrency, NumberFormatCustom, removeCurrencyFromString } from '../../main/resources/CalcRessources';
import { TextField, Typography } from '@mui/material';
import { useUser } from '../../@common/contexts/UserContext';


type EditableLabelProps = {
    editState: boolean | null;
    label: string;
    labelSx?: SxProps;
    handleBlur : (newLabel : string, checkLabel? : boolean) => void;
    handleOnClick : () => void;
    handleKey : (e : any) => void;
    type? : string;
    showEditable? : boolean;

  };


  const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
      maxWidth: '120px',
      height: '40px',
    }
  }))

  const Label = styled('div')(({ theme }) => ({
    cursor: "text",

    '&:hover':{
        backgroundColor: theme.palette.interactable,
    },

    '&.number': {
      backgroundColor: theme.palette.interactable,
      borderRadius: 4,
      padding: theme.spacing(0.5,1,0.5,1),
      whiteSpace: 'pre',
      maxWidth: '120px',
    },

    '&.number .MuiTypography-root': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }

  }));


const EditableLabel = ({editState, label, labelSx, handleBlur, handleOnClick, handleKey, type, showEditable} : EditableLabelProps) => {

  const {currentHousehold} = useUser();
  const defaultLabel = String(removeCurrencyFromString(label, currentHousehold.currency));

    if(editState)
    {
      if (type && type === "number")
      {
        return(
          <CustomTextField sx={labelSx} onClick={(e) => {e.stopPropagation()}} defaultValue={defaultLabel === "0"? "" : defaultLabel} autoFocus onBlur={(e) => handleBlur(e.target.value || "0")} onKeyDown={(e) => handleKey(e)}
          InputProps={{
            inputComponent: NumberFormatCustom as any,
          }}
          />
      )
      }
        return(
            <Input sx={labelSx} onClick={(e) => {e.stopPropagation()}} defaultValue={label} autoFocus onBlur={(e) => handleBlur(e.currentTarget.value.trim(), true)} onKeyDown={(e) => handleKey(e)} inputProps={{ maxLength: 25 }}></Input>
        )
    }
    else if (type && type === "number")
    {
      return(
        <Label onClick={(e) => {e.stopPropagation(); handleOnClick();}} className="number"><Typography sx={labelSx}>{formatCurrency(Number(defaultLabel),currentHousehold.currency)}</Typography></Label>
      )
    }
  return (
    <Label onClick={(e) => {e.stopPropagation(); handleOnClick();}} className={showEditable? "number" : undefined}><Typography sx={labelSx}>{label}</Typography></Label>
  )
}

export default EditableLabel;
