import React from "react";
import { styled } from "@mui/system";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import Navigation from "../navigation/Navigation";
import Footer from "../../home/components/Footer";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Page = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(10),
  display: "flex",
  flexDirection: "column",
  alignItems: "space-between",
  backgroundColor: theme.palette.homepage.light,
}));

const Section = styled("div")(({ theme }) => ({
  paddingLeft: theme.spacing(20),
  paddingRight: theme.spacing(20),
  minHeight: "80vh",

  //Small
  [theme.breakpoints.down("lg")]: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
  },
  //Mobile
  [theme.breakpoints.down("md")]: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
}));

const Container = styled("div")(({ theme }) => ({
  width: "80vw",
  maxWidth: "1000px",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  color: theme.palette.homepage.main,
  marginBottom: theme.spacing(20),

  "& .MuiTypography-root": {
    color: "inherit",
  },
}));

const LinkList = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: "flex",
  justifyContent: "flex-start",
}));

const LegalNotice = () => {
  const { t, i18n } = useTranslation();
  const { locale } = useParams();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navigation />
      <Page>
        <Section className="introduction">
          <Container>
            <LinkList>
              <NavLink
                to={`/en/legal`}
                style={{
                  fontFamily: "ApfelGrotezkRegular",
                  textDecoration: "none",
                  fontSize: "14px",
                }}
              >
                EN
              </NavLink>
              <hr style={{ marginInline: 10 }} />
              <NavLink
                to={`/de/legal`}
                style={{
                  fontFamily: "ApfelGrotezkRegular",
                  textDecoration: "none",
                  fontSize: "14px",
                }}
              >
                DE
              </NavLink>
            </LinkList>
            <Typography variant="h2" sx={{ mb: 2 }}>
              {t("Legal Notice", { lng: locale })}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {t("Information in accordance with Section 5 TMG:", {
                lng: locale,
              })}
            </Typography>
            <Typography variant="body2" sx={{ mb: 2, mt: 4 }}>
              {t("Company Name: Vanilla Noir Ltd", { lng: locale })}
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              {t("Legal Form: Ltd", { lng: locale })}
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              {t(
                "We are registered in England & Wales at Companies House, Crown Way, Cardiff, CF14 3UZ under registration number 15244694.",
                {
                  lng: locale,
                }
              )}
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              {t("Managing Director: Madeleine Frister, Maximilian Croissant", {
                lng: locale,
              })}
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              {t("Contact Information:", { lng: locale })}
            </Typography>
            <Typography variant="body2">Vanilla Noir Ltd</Typography>
            <Typography variant="body2">35 Medallion House</Typography>
            <Typography variant="body2">Joseph Terry Grove</Typography>
            <Typography variant="body2">York YO23 1FL</Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              United Kingdom
            </Typography>
            <Typography variant="body2">+44 7747 206413</Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              info@vanilla-noir.com
            </Typography>
            <Typography variant="body2" sx={{ mb: 2, mt: 4 }}>
              {t(
                "Platform of the EU Commission for online dispute resolution: ",
                { lng: locale }
              )}
              <a
                href="https://www.ec.europa.eu/consumers/odr"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.ec.europa.eu/consumers/odr
              </a>
            </Typography>
          </Container>
        </Section>
        <Footer />
      </Page>
    </>
  );
};

export default LegalNotice;
