import {
  collection,
  deleteDoc,
  Timestamp,
  doc,
  FieldValue,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { firestore, functions } from "../../@common/firebase/firebase";
import { deleteObject, ref as storageRef } from "firebase/storage";
import sizeof from "firestore-size";
import { httpsCallable } from "firebase/functions";
import { makeTimeStamp } from "../../overview/resources/helpers";

//Types

export interface CategoryType {
  status: string;
  startingBalance: number;
  secretBalance: number;
  label: string;
  id: string;
  icon: string;
  created: string;
  deleted?: string;
  budget: { [timestamp: string]: number };
}

export interface BlockType {
  status: string;
  name: string;
  created: string;
  deleted?: string;
  categories: CategoryType[];
}

export interface UserType {
  name: string;
  lastModified?: Timestamp | FieldValue;
  id: string;
  household: string;
  budgetList: string[];
  blocks: BlockType[];
  invitation?: InvitationType;
}

export interface InvitationType {
  inviter: string;
  inviterName: string;
  invitee: string;
  householdId: string;
  householdName: string;
}

export interface MemberType {
  id: string;
  name: string;
}

export interface HouseholdType {
  owner: string;
  name: string;
  lastModified?: Timestamp | FieldValue;
  id: string;
  currency: string;
  members: MemberType[];
}

const createFirebaseUser = async (userId: string, name: string) => {
  const newUser: UserType = {
    blocks: [],
    id: userId,
    name: name,
    household: "",
    budgetList: [],
    lastModified: serverTimestamp(),
  };

  await setDoc(doc(firestore, "users", userId), newUser);
  return newUser;
};

const createFirebaseHousehold = async (newHousehold: HouseholdType) => {
  const ref = doc(collection(firestore, "households"));
  let createdHousehold = newHousehold;
  createdHousehold.id = ref.id;
  createdHousehold.lastModified = serverTimestamp();

  await setDoc(ref, createdHousehold);
  return createdHousehold;
};

const updateFirebaseBlocks = async (userId: string, blocks: BlockType[]) => {
  const userref = doc(firestore, "users", userId);
  const newblocks = [...blocks];
  const res = await updateDoc(userref, {
    blocks: newblocks,
    lastModified: serverTimestamp(),
  });
  return res;
};

const updateFirebaseUser = async (user: UserType) => {
  const userref = doc(firestore, "users", user.id);
  let newUser = { ...user };
  newUser.lastModified = serverTimestamp();

  const res = await updateDoc(userref, newUser);
  return res;
};

const deleteFirebaseUser = async (userId: string, householdId: string) => {
  const handleFirebaseDelete = httpsCallable(
    functions,
    "handleInvitationAccept"
  );
  await handleFirebaseDelete({ userId: userId, householdId: householdId });
  return true;
};

const updateFirebaseHousehold = async (household: HouseholdType) => {
  const householdref = doc(firestore, "households", household.id);
  let newHousehold = { ...household };
  newHousehold.lastModified = serverTimestamp();
  const res = await updateDoc(householdref, newHousehold);
  return res;
};

const getFirebaseUser = async (userId: string) => {
  const ref = doc(firestore, "users", userId);
  const user = await getDoc(ref);
  const userData = user.data();
  //  console.log("Userdata:")
  // console.log(userData)
  //   console.log(sizeof(userData))

  let household = null;

  if (userData?.household && userData?.household.length > 0) {
    const householRef = doc(firestore, "households", userData.household);
    const householdDoc = await getDoc(householRef);
    household = householdDoc.data();
  }

  if (household === undefined) household = null;

  return { user: userData, household: household };
};

const cleanUpData = async (
  user: UserType,
  year: number,
  month: number
): Promise<any> => {
  let tempUser = user;
  const cutOffMonth = makeTimeStamp(year - 5, month);
  //We need to clean the data so the app scales better
  //1. We need to remove all category budgets that are older than 60(?) months and add the balance from them to the "secretBalance" in each category
  //2. We also need to permanently delete all categories/blocks that were deleted and have no funds anymore because of Step 1

  //Maybe not this:
  //3. We need to delete all entries that are older than 60 months and add them to the secretBalances. If it's a multi-member expense, we should transform the payee object and remove the user from there

  //First get the old entries
  // const entriesToRemove : EntryType[] = [];

  // for (const entry of tempEntries) {
  //   const entryTimestamp = makeTimeStamp(entry.date.toDate().getFullYear(), entry.date.toDate().getMonth());

  //   if (Number(entryTimestamp) < Number(cutOffMonth) && entry.type !== "payment") {
  //       entriesToRemove.push(entry);
  //   }
  // }
  for (let i = tempUser.budgetList.length - 1; i >= 0; i--) {
    if (Number(tempUser.budgetList[i]) < Number(cutOffMonth)) {
      tempUser.budgetList.splice(i, 1);
    }
  }

  //Then let's go through each category
  let blockToRemove: BlockType[] = [];

  tempUser.blocks.forEach((block: BlockType) => {
    let categoriesToRemove: CategoryType[] = [];

    block.categories.forEach((category: CategoryType) => {
      // 1 - Remove old budgets
      const budgets = category.budget;
      for (const [tStamp, balance] of Object.entries(budgets)) {
        if (Number(tStamp) < Number(cutOffMonth)) {
          let defaultBalance = category.secretBalance || 0;
          category.secretBalance = defaultBalance + Number(balance);
          delete budgets[tStamp];
        }
      }
      //2 - Check if we need to adjust balance because of deleted entries
      // for (const entryToDelete of entriesToRemove){
      //   if (category.label === entryToDelete.category && entryToDelete.payee[user.id]){
      //       let defaultBalance = category.secretBalance || 0;
      //       category.secretBalance = defaultBalance - Number(entryToDelete.payee[user.id]);
      //   }
      // }

      //3 - Permanently delete old categories
      if (
        category.status === "deleted" &&
        Object.keys(category.budget).length <= 0
      ) {
        categoriesToRemove.push(category);
      }
    });

    for (const toRemove of categoriesToRemove) {
      const index = block.categories.indexOf(toRemove);
      if (index !== -1) {
        block.categories.splice(index, 1);
      }
    }
    //3 - Permanently delete old blocks
    if (block.status === "deleted" && block.categories.length <= 0) {
      blockToRemove.push(block);
    }
  });

  for (const toRemove of blockToRemove) {
    const index = tempUser.blocks.indexOf(toRemove);
    if (index !== -1) {
      tempUser.blocks.splice(index, 1);
    }
  }

  // if(entriesToRemove.length < 1){
  //   return{newUser: tempUser, newEntries: tempEntries}
  // }

  // const handleFirebaseDelete = httpsCallable(functions,'cleanUpEntries');
  // await handleFirebaseDelete({userId: user.id, householdId: householdId, entriesToRemove: entriesToRemove})

  //   for (const entry of entriesToRemove) {
  //     const index = tempEntries.indexOf(entry);
  //     //Only delete if it's not a split entry
  //     if (Object.keys(entry.payee).length <= 1 && index !== -1) {
  //       tempEntries.splice(index, 1);
  //     }
  //     //Remove payee if it's a split entry
  //     else if(Object.keys(entry.payee).length > 1 && index !== -1){
  //       delete tempEntries[index].payee[user.id];
  //     }
  //   }
  return tempUser;
};

export {
  createFirebaseUser,
  deleteFirebaseUser,
  getFirebaseUser,
  createFirebaseHousehold,
  updateFirebaseBlocks,
  updateFirebaseUser,
  updateFirebaseHousehold,
  cleanUpData,
};
