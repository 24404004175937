import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import React from 'react'
import { useAppContext } from '../../@common/contexts/AppContext';
import { opacityInAnimation } from '../../@common/theme/Theme';
import { useTranslation } from 'react-i18next';

const WarningContainer = styled('div')(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  position: "relative",
  width: "400px",
  height: "fit-content",
  padding: theme.spacing(2),
  backgroundColor: theme.palette.warning.main,
  borderRadius: "15px",
  animation: opacityInAnimation + " 500ms ease",

  '& .MuiTypography-root': {
    color: theme.palette.common.white,
  },

   //Small screens
   [theme.breakpoints.down("lg")]: {
    '& .MuiTypography-root': {
      fontSize: "13px"
    },
  },
  }));

const WarningBox = () => {
const {isSmallScreen} = useAppContext();
const [isLoaded, setIsLoaded] = React.useState(false);
const {t} = useTranslation();

React.useEffect(() => {
  setTimeout(() => {
    setIsLoaded(true);
  }, 1500)
}, [])


  return (
    <>
    {isSmallScreen === true || isLoaded === false?
      <></>
      :
      <WarningContainer>
      <Typography variant='strong'>{t("Warning")}:</Typography>
      <Typography>{t("Some of your expenses don't fit your categories. Please check your transactions for this month.")}</Typography>
      </WarningContainer>
    }
  </>
  )
}


export default WarningBox;
