import React from 'react'
import { styled } from '@mui/system';
import { Typography, Button, useTheme } from '@mui/material';
import { categorySumForMonth, formatCurrency } from '../../main/resources/CalcRessources';
import { useUser } from '../../@common/contexts/UserContext';
import { useEntry } from '../../@common/contexts/EntryContext';
import { useAppContext } from '../../@common/contexts/AppContext';
import Icon from '../../Icon';
import { expandAnimation } from '../../@common/theme/Theme';
import { makeTimeStamp } from '../../overview/resources/helpers';
import TransactionIcon from '../../transactions/components/TransactionIcon';
import { BlockType, CategoryType } from '../../main/resources/UserResources';

const BlockContainer = styled('div')(({ theme }) => ({
    position: "relative",
   //maxWidth: "400px",
    padding: theme.spacing(0,2),
    [theme.breakpoints.down('lg')] : {
      padding: 0,
    }
 
     }
   ));

   const CustomRow = styled('div')(({ theme }) => ({
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    margin: theme.spacing(1.5, 0),
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 2, 1, 1),
    borderRadius: '7px',
    

 
     }
   ));

type MonthyTableType = {
    blockOfInterest : BlockType;
    monthOfInterest: number;
    yearOfInterest: number;
}

const MonthlyTable = ({blockOfInterest, monthOfInterest, yearOfInterest} : MonthyTableType) => {
    const {user, currentHousehold, blocks} = useUser();
    const {allEntries} = useEntry();
    const {shadowStyle, darkMode} = useAppContext();
    const theme = useTheme();
    const thisTimeStamp = makeTimeStamp(yearOfInterest,monthOfInterest);
    const blockIndex = blocks.indexOf(blockOfInterest);

    const cats = blockOfInterest?.categories.filter((categ : CategoryType) => {
      if (categ.status === "deleted" && Number(thisTimeStamp) >= Number(categ.deleted)) return false;
      if (Number(categ.created) > Number(thisTimeStamp)) return false;
      return true;
    })

    return (
        <>
        {blockOfInterest != null?
               <BlockContainer key={blockOfInterest.name}>
               <Typography variant='body2' sx={{fontSize: "18px", margin: '0 auto 1rem auto', textAlign: 'center'}}>{blockOfInterest.name}</Typography>
               {cats.map((cat : CategoryType) => {
                
                return(
                     <CustomRow key={cat.id + cat.label} sx={darkMode?[{animation: expandAnimation + " 500ms ease"}, shadowStyle, {backgroundColor: theme.palette.interactable}]:[{animation: expandAnimation + " 500ms ease"}, shadowStyle]}>
                     <div style={{display: "flex", alignItems: "center"}}>
                     <TransactionIcon type='expense' categoryLabel={cat.label} blockIndex={blockIndex}/>
                     <Typography sx={{fontSize: "16px", ml: 2}}>{cat.label}</Typography>
                    </div>
                     <Typography sx={{fontSize: "16px"}}>{formatCurrency(categorySumForMonth(user.id, allEntries, cat.label, monthOfInterest, yearOfInterest),currentHousehold.currency)}</Typography>
                     </CustomRow>
                 )
               })
 
               }
             </BlockContainer>
             :
             <></>

        }    
       
        </>
  )
}

export default MonthlyTable