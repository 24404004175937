import React from 'react'
import { MenuItem, ListSubheader } from '@mui/material';
import { BlockType, CategoryType } from '../../main/resources/UserResources';




const renderCategorySelectItem = (block : BlockType, thisTimeStamp : string, setBlockIndex : (i : number) => void, blockI : number) => {
    const items = block.categories.filter((item : CategoryType) => {
        if (item.status === "deleted" && Number(thisTimeStamp) >= Number(item.deleted)) return false;
        if (Number(item.created) > Number(thisTimeStamp)) return false;
     //   if (typeof item.budget[thisTimeStamp] === "undefined") return false;
        return true;  
      })
        .map((c : CategoryType) => {
          return(
             <MenuItem value={c.label} key={c.id} sx={{fontSize: "16px", ml: 1}} onClick={() => setBlockIndex(blockI)}>{c.label}</MenuItem>
     
          )});
  return [
    <ListSubheader sx={{fontSize: "14px", ml: 1, padding: 0, lineHeight: "24px"}}>{block.name}</ListSubheader>, items]

}

export default renderCategorySelectItem;
