import React, { useState } from 'react'
import { styled } from '@mui/system';
import { Typography, Button, Menu, MenuItem, Badge } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useAppContext } from '../../@common/contexts/AppContext';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../@common/contexts/UserContext';
import SubscriptionContainer from '../../main/SubscriptionContainer';

const MenuButton = styled(Button)(({ theme }) => ({
    position: "absolute",
    top: 0,
    right: 0,
   // margin: theme.spacing(2,2,0,0),
    padding: 0,
    minWidth: "auto",
    
    [theme.breakpoints.down("md")]: {
    //  margin: theme.spacing(1,0)
    }
 
     }
   ));


   type ExportMenuType = {
    exportFunction: () => void;
    buttonSX?: any;
   }

const ExportMenu = ({exportFunction, buttonSX} : ExportMenuType) => {
    const {premium} = useUser();
    const [subscriptionDialogOpen, setSubscriptionDialogOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);
    const {shadowStyle} = useAppContext();
    const {t, i18n} = useTranslation();

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
      setAnchorEl(null);
    };
    const handleExport = () => {
      if (premium)
      {
        exportFunction();
      }
       else {
        setSubscriptionDialogOpen(true);
       }
       handleMenuClose();

    }

  return (
    <>
      <MenuButton variant='text'
      style={buttonSX}
      aria-controls={menuOpen ? 'export-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={menuOpen ? 'true' : undefined}
      onClick={handleMenuClick}><MoreVertIcon/></MenuButton>
      <Menu
        id="basic-menu"
        sx={shadowStyle}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': t("Open Export Menu") || "Open Export Menu",
        }}
      >
        <MenuItem onClick={handleExport}>{t("Export CSV")}</MenuItem>
      </Menu>
      <SubscriptionContainer open={subscriptionDialogOpen} setOpen={setSubscriptionDialogOpen}/>
    </>
  )
}

export default ExportMenu