import { CircularProgress, styled } from '@mui/material';
import React from 'react';

const LoadingContainer = styled('div')(({ theme }) => ({
  width:"100vw",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "fixed",
  top: 0,
  backgroundColor: theme.palette.background.default,
  zIndex: 100

}));

function LoadingScreen() {
  return (
    <LoadingContainer>
    <CircularProgress/>
    </LoadingContainer>
  )
}

export default LoadingScreen;
