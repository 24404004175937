import React from 'react';
import { styled } from '@mui/system';
import { Button, Dialog, TextField, Typography } from '@mui/material';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useTranslation } from 'react-i18next';


const StyledDialog = styled(Dialog)(({ theme }) => ({

    '& .MuiPaper-root':{
      borderRadius: "25px",
      maxWidth: "none",
    },

    '& .MuiPaper-root, .MuiPaper-root > div' : {
      margin: theme.spacing(4, 3),
    },

    '& h4' : {
      textAlign: 'center',
      marginBottom: '1.5rem',
    },

    '& h5' : {
      textAlign: 'center',
      marginBottom: '1.5rem',
    },



}));

const StyledContainer = styled("div")(({ theme }) => ({
    margin: theme.spacing(4),
    position:"relative",
    display:"flex",
    flexDirection:"column",

    '& form' : {
      marginBottom: '0.5rem',
    }
  }));

  const CustomTextfield = styled(TextField)(({ theme }) => ({
    margin: theme.spacing(1,0,1,0),
    width: "100%"
  }));


type AuthDialogType = {
  open: boolean,
  setOpen: (open: boolean) => void,
  sendAuth: (email: string, password : string) => Promise<void>,
  buttonText: string,
  addForgotPassword?: boolean,
  header?: string,
  subheader?: string,
  passwordOnly?: boolean,
}

const AuthDialog = ({open, setOpen, sendAuth, buttonText, addForgotPassword, header, subheader, passwordOnly} : AuthDialogType) => {
const [password, setPassword] = React.useState<string>('');
const [email, setEmail] = React.useState<string>('');
const [dialogMode, setDialogMode] = React.useState<string>("auth");
const [hasError, setHasError] = React.useState<boolean>(false);
const {t} = useTranslation();
const auth = getAuth();


	const handleClose = () => {
		setOpen(false);
    setEmail('');
    setPassword('');
    setHasError(false);
    setTimeout(() => {
      setDialogMode("auth");
    }, 500);
	};

  const sendPasswordReset = () => {
    setDialogMode("resetSent");

    sendPasswordResetEmail(auth, email)
    .then(() => {
      // Password reset email sent!
      setDialogMode("resetSent");
    })
    .catch((error) => {
    });
  }

  
  const onButtonPress = async() => {
    sendAuth(email, password)
    .then(() => {
      handleClose();
    })
    .catch((error) => {
      console.log(error)
      setHasError(true);
    }) 
  }

  return (
      <StyledDialog
      open={open}
      onClose={handleClose}
      >
        <StyledContainer>
          {dialogMode === "reset"?
          <>
          <Typography variant='h5'>{t("Let's reset your password.")}</Typography>
          <Typography>{t("Enter your mail and we'll get this sorted.")}</Typography>
          <form>
          <CustomTextfield 
          id="email-input" 
          label={t("Email")}
          autoComplete='off'
          variant="outlined" 
          value={email}
          inputProps={{ maxLength: 40 }}
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={(e : any) => {
            if (e.key === "Enter") sendPasswordReset();
            if (e.key === "Escape") handleClose();
          }}
          />
          </form>
          <Button type="button" color="primary" variant='contained' className="form__custom-button" sx={{mt:2, padding: 1.5}} onClick={sendPasswordReset}>
            {t("Send")}
          </Button>
          </>
          :
          <></>}
         {dialogMode === "resetSent"?
          <>
          <Typography variant='h5'>{t("All good.")}</Typography>
          <Typography sx={{mt: 2, mb: 2}}>{t("We've sent you a link to reset your password. Just follow the instructions in the mail and you're good to go!")}</Typography>
          <Button type="button" color="primary" variant='contained' autoFocus className="form__custom-button" sx={{mt:2, padding: 1.5}} onClick={handleClose}
          onKeyDown={(e : any) => {
            if (e.key === "Enter") handleClose();
            if (e.key === "Escape") handleClose();
          }}>
            {t("Okay!")}
          </Button>
          </>
          :
          <></>}
        {dialogMode === "auth"?
          <>
        <Typography variant='h5'>{header}</Typography>
        <Typography>{subheader}</Typography>
        <form>
        {passwordOnly !== true?
        <CustomTextfield 
        id="email-input" 
        label={t("Email")}
        autoComplete='off'
        variant="outlined" 
        inputProps={{ maxLength: 40 }}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        />
        :
        <></>
        }
        <CustomTextfield
          error={hasError || false}
          helperText={hasError? t("Wrong password") : ""}
          id="password-input"
          label={t("Password")}
          type="password"
          autoComplete="current-password"
          inputProps={{ maxLength: 30 }}
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={(e : any) => {
            if (e.key === "Enter") onButtonPress();
            if (e.key === "Escape") handleClose();
          }}
        />
        </form>
           {addForgotPassword?
          <Typography variant='body1'>{t("Forgot your password?")} <Button sx={{textTransform: "none", lineHeight: "initial", padding: '0 0 3px 3px', textDecoration: 'underline'}} onClick={()=>setDialogMode("reset")}>{t("Let's reset.")}</Button></Typography>
            :
          <></>
          }
          <Button type="button" color="primary" variant='contained' className="form__custom-button" sx={{mt:4, padding: 1.5}} onClick={onButtonPress}>
            {buttonText}
          </Button>
          </>
          :
          <></>
          }
      </StyledContainer>

      </StyledDialog>
   
    
  );
};

export default AuthDialog;
