import React, { useEffect, useState } from "react";
import { Snackbar } from "@mui/material";
import { useUser } from "../../@common/contexts/UserContext";
import InviteSnackbar from "./InviteSnackbar";
import { useAppContext } from "../../@common/contexts/AppContext";
import { InvitationType } from "../resources/UserResources";

const Notification = () => {
  const { infoNotifications, DeleteInfoNotification } = useAppContext();
  const { user, currentHousehold } = useUser();
  const [infoOpen, setInfoOpen] = useState(false);

  const [inviteOpen, setInviteOpen] = useState(false);
  const [invitation, setInvitation] = useState<InvitationType | null>(null);

  const onInfoClose = () => {
    setInfoOpen(false);
    DeleteInfoNotification();
  };

  //Invite Check
  useEffect(() => {
    if (
      user.invitation != null &&
      user.invitation.inviter !== user.id &&
      user.invitation.householdId !== user.household
    ) {
      setInvitation(user.invitation);
      setInviteOpen(true);
    } else {
      setInviteOpen(false);
    }
  }, [user]);

  //Info Notification Check
  useEffect(() => {
    if (infoNotifications.length > 0) {
      setInfoOpen(true);
    }
  }, [infoNotifications]);

  return (
    <>
      <InviteSnackbar
        open={inviteOpen}
        setOpen={setInviteOpen}
        invitation={invitation}
        userId={user.id}
        userName={user.name}
        userHouseholdId={currentHousehold.id}
      />
      <Snackbar
        open={infoOpen}
        onClose={onInfoClose}
        autoHideDuration={3000}
        message={infoNotifications[0]}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
    </>
  );
};

export default Notification;
