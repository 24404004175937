import React from 'react'
import InviteDialog from '../../settings/components/InviteDialog';
import { Button, Typography, styled, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../@common/contexts/UserContext';
import SubscriptionContainer from '../SubscriptionContainer';
import { useAppContext } from '../../@common/contexts/AppContext';

const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: 10,
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%"
   }
 ));

const AdSplit = () => {
  const theme = useTheme();
  const {premium} = useUser();
  const [inviteDialogOpen, setInviteDialogOpen] = React.useState(false);
  const [subDialogOpen, setSubDialogOpen] = React.useState(false);
  const {t} = useTranslation();
  const {shadowStyle} = useAppContext();


  return (
    <>
    <Container sx={shadowStyle}>
    <Typography variant='h4' style={{marginBottom: 20, textAlign: "center"}}>{t("Share Expenses!")}</Typography> 
    <Typography variant='body1' style={{textAlign: "center"}}>{t("Connect with your partner or your roommates and track your shared expenses, while everybody keeps their own budget.")}</Typography> 
    <img src={require('../../assets/images/people.png')} style={{width: 240, marginTop: 24}} 
    //@ts-ignore
    alt={t('group of people using their devices together')}/>
    {premium?
    <Button sx={{marginTop: 4, marginHorizontal: 100}} variant="contained" size='large' onClick={() => setInviteDialogOpen(true)}>{t("Invite New Member")}</Button>
    :
    <Button sx={{marginTop: 4, marginHorizontal: 100}} variant="contained" size='large' onClick={() => setSubDialogOpen(true)}>{t("Get started!")}</Button>
    }
    </Container>
    <InviteDialog open={inviteDialogOpen} setOpen={setInviteDialogOpen}/>
    <SubscriptionContainer open={subDialogOpen} setOpen={setSubDialogOpen}/>
    </>
    )
}

export default AdSplit