import React from 'react';
import Header from '../../home/components/Header';
import { styled } from '@mui/system';
import About from '../../home/components/About';
import Features from '../../home/components/Features';
import Footer from '../../home/components/Footer';
import Contact from '../../home/components/Contact';
import Highlights from '../../home/components/Highlights';



const LandingPage = () => {

  return (
  <div>
  <Header/>
  <About/>
  {/* <Highlights/> */}
  <Contact/>
  <Footer/>
  </div>
)}

export default LandingPage;
