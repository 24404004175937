import React from 'react'
import { styled } from '@mui/system';
import { Button, Dialog, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CategoryBox from './CategoryBox';
import { useTranslation } from 'react-i18next';


const CustomDialog = styled(Dialog)(({ theme }) => ({
  marginTop: "15vh",
  maxHeight: "80vh",

  '& .MuiDialog-container': {
    alignItems: "flex-start",
  },

  '& .MuiPaper-root':{
    maxHeight: "80%",
    minWidth: "350px",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(6,3,3,3),
    backgroundColor: theme.palette.background.paper,

  },

     //Mobile screens
     [theme.breakpoints.down("md")]: {
      marginTop: "1vh",

      '& .MuiPaper-root':{
        maxHeight: "90%",
        minWidth: "300px",
      },  
    }

}));

const CloseButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  margin: theme.spacing(2),
  padding: 0,
  width: "fit-content",
  minWidth: "auto",
  right: 0,
  top: 0
    
  }));


type DialogType = {
  open: boolean,
  setOpen: (open: boolean) => void,
  children?: JSX.Element,
}




const CategoryDialogMobile = ({open, setOpen, children} : DialogType) => {
  const {t} = useTranslation();

    const handleClose = () => {
            setOpen(false);
        };


  return (
    <CustomDialog
    open={open}
    onClose={handleClose}
    >
      <CloseButton onClick={handleClose} aria-labelledby={t('Close Window') || "Close Window"}><CloseIcon sx={{fontSize: "24px"}}/></CloseButton>
      {children}
      </CustomDialog>
  )
}

export default CategoryDialogMobile