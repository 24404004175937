import React, { useEffect, useState } from 'react';
import { styled, SxProps } from '@mui/system';
import { Button, Dialog, FormControl, InputLabel, MenuItem, Select, useTheme } from '@mui/material';
import { useUser } from '../../@common/contexts/UserContext';
import { Theme } from '@mui/material/styles';
import { allCurrencies } from '../../@common/theme/Theme';
import { useTranslation } from 'react-i18next';

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: "16px",
   }
 ));

 type CurrencyPickerTypes = {
  handleChange: (value : string) => void;
  hasTitle: boolean;
  formSx?: SxProps<Theme> | undefined;
 }

const CurrencyPicker = ({handleChange, hasTitle, formSx} : CurrencyPickerTypes) => {
const {currentHousehold} = useUser();
const [curr, setCurr] = useState(currentHousehold?.currency || null);
const theme = useTheme();
const {t} = useTranslation();

    const onChange = (e : any) => {
        if(e.target.value != null)
        {
            setCurr(e.target.value);
            handleChange(e.target.value);
        }
    }

  return (
 <>
        <FormControl fullWidth size='small' sx={formSx}>
        {hasTitle?
        <InputLabel id="currency-select-title" sx={{backgroundColor: theme.palette.background.paper, width: "175px"}}>{t("Your favourite currency")}</InputLabel>
        :
        <></>
        }
        <Select
          labelId="currency-select-input"
          id="currency-select-input"
          value={curr}
          onChange={onChange}
          sx={{fontSize: "16px"}}
        >
          {allCurrencies.map((curr : any) => {
            return(
              <CustomMenuItem key={curr.val} value={curr.val}>{curr.name}</CustomMenuItem>
            )})

          }
        </Select>
      </FormControl>

  
      </>
    
  );
};

export default CurrencyPicker;
