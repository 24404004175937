import React from 'react';
import { styled } from '@mui/system';
import { Button, Dialog, Typography, TextField, useTheme } from '@mui/material';
import { auth } from '../../@common/firebase/firebase';
import { useAppContext } from '../../@common/contexts/AppContext';
import { updateEmail } from 'firebase/auth';
import { useTranslation } from 'react-i18next';

const CustomDialog = styled(Dialog)(({ theme }) => ({

  '& .MuiPaper-root':{
    width: "300px",
    borderRadius: "25px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(4)
  }

}));

const CustomTextfield = styled(TextField)(({ theme }) => ({
  margin: theme.spacing(0,0,2,0),
  width: "100%"
}));

type DialogType = {
  open: boolean,
  setOpen: (open: boolean) => void,
}

const UsernameChangeDialog = ({open, setOpen} : DialogType) => {
const [newEmailOne, setNewEmailOne] = React.useState<string>('');
const [newEmailTwo, setNewEmailTwo] = React.useState<string>('');
const [errorState, setErrorState] = React.useState<boolean>(false);
const {AddInfoNotification} = useAppContext();
const theme = useTheme();
const {t, i18n} = useTranslation();



	const handleClose = () => {
		setOpen(false);
    setErrorState(false);
    setNewEmailOne('');
    setNewEmailTwo('');
	};

    const handleSubmit = async () => {
      if (newEmailOne === newEmailTwo && newEmailOne.length > 1 && auth.currentUser)
      {

        updateEmail(auth.currentUser, newEmailOne).then(() => {
          // Email updated!
          AddInfoNotification(t("Email successfully updated!"))
        }).catch((error : any) => {
          setErrorState(true);
        });
 
    }
  }

  return (
      <CustomDialog
      open={open}
      onClose={handleClose}
      >
        <Typography variant='h5' sx={errorState === true? {mb: 1, textAlign: "center"} : {mb:4, textAlign: "center"}}>{t("Let's make some changes!")}</Typography>
        {errorState === true?
        <Typography color={theme.palette.warning.main} sx={{mb: 2}}>
          {t("Something went wrong. Make sure both emails are the same.")}
        </Typography>
        :
        <></>
        }
        <CustomTextfield 
          id="email-input"
          label={t("New Email")}
          aria-labelledby={t("New Email") || "New Email"}
          aria-lang={i18n.language}
          type="email"
          inputProps={{ maxLength: 30 }}
          variant="outlined"
          value={newEmailOne}
          onChange={(e) => setNewEmailOne(e.target.value)}
          onKeyDown={(e : any) => {
            if (e.key === "Enter") handleSubmit();
            if (e.key === "Escape") handleClose();
          }}
        />
       <CustomTextfield 
          id="email-input"
          label={t("Confirm Email")}
          aria-labelledby={t("Confirm Email") || "Confirm Email"}
          aria-lang={i18n.language}
          type="email"
          inputProps={{ maxLength: 30 }}
          variant="outlined"
          value={newEmailTwo}
          onChange={(e) => setNewEmailTwo(e.target.value)}
          onKeyDown={(e : any) => {
            if (e.key === "Enter") handleSubmit();
            if (e.key === "Escape") handleClose();
          }}
        />
      <Button variant='contained' onClick={handleSubmit} sx={{mt:2}}>{t("Send")}</Button>

      </CustomDialog>
   
    
  );
};

export default UsernameChangeDialog;
