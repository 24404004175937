import { LinearProgress, ListItem, styled, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useUser } from '../../@common/contexts/UserContext';
import EditableLabel from './EditableLabel';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useAppContext } from '../../@common/contexts/AppContext';
import { categorySumForMonth, formatCurrency } from '../../main/resources/CalcRessources';
import { useEntry } from '../../@common/contexts/EntryContext';
import { makeTimeStamp } from '../resources/helpers';
import { useTranslation } from 'react-i18next';
import { CategoryType } from '../../main/resources/UserResources';

const CustomListItem = styled(ListItem)(({ theme }) => ({
 justifyContent: "space-between",
 cursor: "pointer",
 borderRadius: "7px",
 margin: '3px',
 width: 'auto',
 padding: '6px 6px 6px 10px',

 '&:hover': {
  backgroundColor: theme.palette.background.default,
 }

}));

const ColOne = styled('div')(({ theme }) => ({
  display: "flex",
justifyContent:"flex-start",
alignItems: "center",
width: "calc(100% - 135px)",

"& .dragHandle": {
  display: "flex",
  alignItems: "center",
  color: theme.palette.text.primary,
},


'& .label': {
  display: "flex",
  justifyContent:"flex-start",
  width: "200px",

}

}));


const ColTwo = styled('div')(({ theme }) => ({
  display: "flex",
justifyContent:"flex-end",
alignItems: "center",




}));

const NumberLabel = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(0.5,1,0.5,1),
  margin: 0,
}));

const calcPercent = (overviewState : string, mySpent : number, myRemaining : number, category : CategoryType, monthStamp: string) => {
  if (overviewState === "spent") {
    let d = 0;
    if (mySpent > 0) {
      //Both sum and budget are larger than 0 
      if (Number(category.budget[monthStamp]) > 0) d = (mySpent / Number(category.budget[monthStamp])) * 100;
      else d = 100;
    } 
    return Math.min(d, 100);
  }
  if (overviewState === "remaining") {
    let remSum = myRemaining - mySpent;
    let d = 0;
    if (myRemaining > 0) {
      //Both sum and budget are larger than 0 
      if (Number(category.budget[monthStamp]) > 0) d = (remSum / myRemaining) * 100;
      else d = 100;
    } 

    return Math.min(d, 100);
  }
}

export type DraggableListItemProps = {
  category: CategoryType;
  index: number;
  blockIndex: number;
  categoryClicked: (category : CategoryType, blockIndex : number) => void;
  thisTimeStamp: string;
  categoryRemaining: number;
};

const DraggableListItem = ({ category, index, blockIndex, thisTimeStamp, categoryClicked, categoryRemaining }: DraggableListItemProps) => {
  const {user, currentHousehold} = useUser();
  const [editState, setEditState] = React.useState(category.status === "edit");
  const [plannedEditState, setPlannedEditState] = React.useState(false);
  const { updateCategory, updateCategoryFund, blocks, setBlocks } = useUser();
  const { allEntries, renameEntryCategories } = useEntry();
  const {overviewState, month, year, isSmallScreen, isMobile, color_series_progress} = useAppContext();
  const [sum, setSum] = React.useState(0);
  const theme = useTheme();
  const {t} = useTranslation();
  const mySpent = React.useMemo(() => categorySumForMonth(user.id, allEntries, category.label, month, year), [user.id, allEntries, category.label, month, year])
  const myPercent = calcPercent(overviewState, mySpent, (categoryRemaining + category.budget[thisTimeStamp]), category, thisTimeStamp);


React.useEffect(() => {
  if (overviewState === "spent") {
   setSum(mySpent);
  }
  else if (overviewState === "remaining") {
   setSum((categoryRemaining + category.budget[thisTimeStamp] + category.startingBalance) - mySpent);
  }
}, [overviewState, mySpent, categoryRemaining, category.budget[thisTimeStamp]])

  const finishEdit = (newLabel : string, checkLabel? : boolean) => {
    //We cancel the process when no name was added
    if (newLabel === t("Label") && checkLabel) 
    {
    cancelEdit();
    return;
    }
    if (newLabel === category.label || newLabel.length < 1){
      cancelEdit();
      return;
    }
    renameEntryCategories(category.label,newLabel);
    const temp = category;
    temp.label = newLabel;
    temp.status = "okay";
    setEditState(false);
    updateCategory(temp, category, blockIndex);
  }

  const finishPlannedEdit = (newLabel : string, checkLabel? : boolean) => {
    if (newLabel === String(category.budget[thisTimeStamp]))
    {
      setPlannedEditState(false);
      return;
    }
  const cleanText = newLabel.replace(currentHousehold.currency,'');
  const fundNumber = parseFloat(cleanText.replace(/,/g,''));
  updateCategoryFund(fundNumber, category, thisTimeStamp, blockIndex);
  setPlannedEditState(false);
  }

  const cancelEdit = () => {
    if (category.status === "edit"){
      const temp = [...blocks];
      const index = temp[blockIndex].categories.indexOf(category);
      if (index > -1) { // only splice array when item is found
        temp[blockIndex].categories.splice(index, 1); // 2nd parameter means remove one item only
      }
      setBlocks(temp);
      return;
    }
    setEditState(false);
  }

  const cancelPlannedEdit = () => {
    setPlannedEditState(false);
  }

  const handleKeyPresses = (e : any) => {
    if (e.key === "Escape") cancelEdit();
    if (e.key === "Enter") finishEdit(e.target.value);

  }
  const handlePlannedKeyPresses = (e : any) => {
    if (e.key === "Escape") cancelPlannedEdit();
    if (e.key === "Enter") finishPlannedEdit(e.target.value || "0");

  }

  const ProgressBar = styled('div')(({ theme }) => ({
    width: "65%",
    margin: theme.spacing(0,0,0,2),
   
    '& .MuiLinearProgress-root': {
     borderRadius: "15px",
     color: theme.palette.divider,
   
     '& .MuiLinearProgress-bar': {
       color: color_series_progress[blockIndex]
     }
    }
   }));

    return (
    <Draggable draggableId={String(category.label)} index={index}>
      {(provided, snapshot) => (
           <CustomListItem
           ref={provided.innerRef}
           {...provided.draggableProps}
           className={snapshot.isDragging ? 'dragging' : ''}
           onClick={() => categoryClicked(category, blockIndex)}
         >
          <ColOne>
          <div className='label'>
          <div className='dragHandle' {...provided.dragHandleProps}><DragIndicatorIcon sx={{fontSize:"14px", display: isMobile? "none" : "block"}}/></div>
        <EditableLabel editState={editState} label={category.label} handleKey={handleKeyPresses}
        handleBlur={finishEdit}
        handleOnClick={() => { setEditState(true)}}
        labelSx={{
          fontSize: "17px",
          margin: isMobile? 0 : "0 0 0 12px",
        }}
        />
        </div>
        {isSmallScreen === false && overviewState !== "planned"?
        <ProgressBar style={{color: color_series_progress[blockIndex]}}>
          <LinearProgress variant="determinate" value={myPercent} color="inherit"/>
        </ProgressBar>
        :
        <></>
        }
        </ColOne>
        <ColTwo>
        {overviewState === "planned"?
        <>
    
        <EditableLabel editState={plannedEditState} label={String(category.budget[thisTimeStamp])} handleKey={handlePlannedKeyPresses}
        type="number"
        handleBlur={finishPlannedEdit}
        handleOnClick={() => {setPlannedEditState(true)}}
        labelSx={{
          fontSize: "17px",
          margin: 0,
        }}/>

        </>
        :

        <NumberLabel sx={{
          fontSize: "17px",
          color: sum < 0? theme.palette.warning.main : theme.palette.text.primary,
        }}>{formatCurrency(sum,currentHousehold.currency)}</NumberLabel>

        }
        </ColTwo>
         </CustomListItem>
      )}
    </Draggable>
  );
};

export default DraggableListItem;
