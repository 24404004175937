import React from 'react'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { styled } from '@mui/system';
import PaymentIcon from '@mui/icons-material/Payment';
import { useUser } from '../../@common/contexts/UserContext';
import { getCategoryByLabel } from '../resources/helpers';
import Button from '@mui/material/Button';
import CategoryIconPicker from '../../overview/components/categoryIconPicker';
import Icon from '../../Icon';
import { useAppContext } from '../../@common/contexts/AppContext';
import { useTheme } from '@mui/material';

const IconContainer = styled('div')(({ theme }) => ({
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: theme.spacing(2),

    //Small screens
    [theme.breakpoints.down("md")]: {
      width: "40px",
      height: "40px",

    }
  }));

  const IconPickerButton = styled(Button)(({ theme }) => ({
    padding:0, 
    minWidth: "auto", 
    width: "50px",
    height: "50px",
    margin: theme.spacing(0,2,0,0),
    
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },

        //Small screens
        [theme.breakpoints.down("md")]: {
          width: "40px",
          height: "40px",

        }
   
   }));

   type TransactionIconTypes = {
    type : string;
    blockIndex : number | null;
    categoryLabel: string;
    }

const TransactionIcon = ({type, blockIndex, categoryLabel} : TransactionIconTypes) => {

const {user} = useUser();
const {isMobile, color_series, color_series_text} = useAppContext();
const category = getCategoryByLabel(user, categoryLabel);
const [openIconPicker, setOpenIconPicker] = React.useState(false);
const theme = useTheme();
blockIndex = blockIndex ?? 0
  return (
    <>
    {type === "income"?
     <IconContainer sx={{backgroundColor: theme.palette.info.light}}>
      <Icon icon={"Money-in"} size={isMobile? 24 : 30} color={theme.palette.info.main}/>
     </IconContainer>
    :
    <></>
    }
    {type === "expense"?
    <IconContainer sx={{backgroundColor: color_series[blockIndex % color_series.length]}}>
    <Icon icon={category?.icon || "Bag"} size={isMobile? 24 : 30} color={color_series_text[blockIndex % color_series_text.length]}/>
    </IconContainer>
    :
    <></>
    }
    {type === "payment"?
    <IconContainer sx={{backgroundColor: theme.palette.balance.light}}>
      <Icon icon={"Transactions"} size={isMobile? 24 : 30} color={theme.palette.balance.main}/>
    </IconContainer>
    :
    <></>
    } 
    {category?
        <CategoryIconPicker open={openIconPicker} setOpen={setOpenIconPicker} category={category} blockIndex={blockIndex}/>
    :
    <></>
    }
    </>
    )
}

export default TransactionIcon;
