import React, { useState } from 'react';
import { styled } from '@mui/system';
import { Chip, ListItemButton, Typography, Button, useTheme } from '@mui/material';
import { EntryType, firebaseDatetoDay } from '../resources/EntryRessources';
import AddIcon from '@mui/icons-material/Add';
import EntryDialog from '../components/EntryDialog';
import EditDialog from '../components/EditDialog';
import { useEntry } from '../../@common/contexts/EntryContext';
import { formatCurrency } from '../../main/resources/CalcRessources';
import { useUser } from '../../@common/contexts/UserContext';
import { useAppContext } from '../../@common/contexts/AppContext';
import TransactionIcon from '../components/TransactionIcon';
import { hasUserCategory } from '../resources/helpers';
import Layout from '../../layout';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { months } from '../../@common/theme/Theme';
import shopping from '../../assets/images/shopping.png';
import { useTranslation } from 'react-i18next';
import { MemberType } from '../../main/resources/UserResources';

const TransactionBox = styled('div')(({ theme }) => ({
  position: "relative",
  borderRadius: "20px",
  maxWidth: "1200px",

  //Small screens
  [theme.breakpoints.down("lg")]: {
    width: "auto"


}
   }
 ));

 const TransactionItem = styled(ListItemButton)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  position: "relative",
  borderRadius: "10px",
  margin: theme.spacing(1.5),
  transition: "all 0.2s ease",

  '& .col-1' : {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },

  '& .col-2' : {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end"
  },

  '&:hover':{
    opacity: 0.5,
    backgroundColor: theme.palette.background.paper,
  },

     //Small screens
     [theme.breakpoints.down("lg")]: {
      margin: theme.spacing(1.5,0),
      width: "auto"
  
  
    }
   }
 )); 

 const ListContent = styled('div')(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  margin: theme.spacing(0.5,0),
  overflow: "hidden",

  '& .col-1, .info': {
    overflow: "hidden",
  },
 
  '& .merchant':{
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  '& .date, .category':{
    fontSize: "14px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    color: theme.palette.text.disabled,
  },
 
  //Mobile screens
  [theme.breakpoints.down("md")]: {
    margin: theme.spacing(0),

    '& .date, .category':{
      fontSize: "13px",
    },

  }
  
}));

 const DateText = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  margin: theme.spacing(3, 0, 1, 2),
  color: theme.palette.text.disabled,

    //Mobile screens
    [theme.breakpoints.down("md")]: {
      fontSize: "13px",
 
    }
   }
 )); 

 const PriceLabel = styled(Typography)(({ theme }) => ({
  margin: 0,

  '&.income': {
    color: theme.palette.info.main,
    WebkitTextStroke: "0.25px",

    '&::before': {
      content: '"+"'
    }
  },

  '&.second': {
    color: theme.palette.text.disabled,
    fontSize: "14px",

  },

    //Mobile screens
    [theme.breakpoints.down("md")]: {
      
        '&.second': {
          fontSize: "13px",
        },
    }
   }
 )); 

 const NoEntriesBox = styled('div')(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: theme.spacing(2),
  textAlign: 'left',

   //Mobile screens
   [theme.breakpoints.down("md")]: {
    textAlign: 'center',
    }
   }
 ));

 const MonthPicker = styled('div')(({ theme }) => ({
  width: "fit-content",
  display: "flex",
  alignItems: "center",
  padding: '8px',
  
  '& .MuiTypography-root': {
   display: "inline",
   margin: 0,
  
  },
  
  [theme.breakpoints.down("md")]: {
    margin:"auto",
  }
  }
  ));

 const ButtonBox = styled('div')(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between"
   }
 )); 

 const WarningChip = styled(Chip)(({ theme }) => ({
  padding: 0, 
  marginTop: theme.spacing(1),

      //Mobile screens
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
        maxWidth: "150px",
        height: "20px"
   
      }
   }
 )); 

const TransactionsContainer = () => {
  const { user, currentHousehold } = useUser();
  const { allEntries } = useEntry();
  const {budgetAvailable, isMobile} = useAppContext();
  const {t, i18n} = useTranslation();
  const [entryDialogOpen, setEntryDialogOpen] = useState(false)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [toEdit, setToEdit] = useState<any>();
  let showDate = Number.MAX_SAFE_INTEGER;
  const {month, year, prevMonth, nextMonth} = useAppContext();
  const monthName = t(months[month]) + " " + year;
  const theme = useTheme();

  let currentEntries = [...allEntries];
  currentEntries = currentEntries.filter((entry : EntryType) => {
    const thisDate = entry.date.toDate();
    if (thisDate.getFullYear() !== year) return false;
    if (thisDate.getMonth() !== month) return false;
    return true;
  })


const handleAddButton = () => {
  setEntryDialogOpen(true)
}


const handleListButton = (e : any, entry : EntryType) => {

  if (entry.type === "income" && entry.creator !== user.id) return;
  setToEdit(entry);
  setEditDialogOpen(true)
}


  return (
      <Layout title={t("Transactions") || "Transactions"}>
        <TransactionBox>
        {currentEntries.length <= 0?
        <>
        {budgetAvailable === true?
        <>
        {isMobile === false?
        <ButtonBox>
        <MonthPicker>
        <Button onClick={prevMonth} sx={{minWidth: "auto", width: "fit-content", mr: 1, color: theme.palette.text.primary}} aria-label={t("previous month") || "previous month"}><ArrowBackIosNewIcon sx={{ fontSize: 16 }}/></Button><Typography variant='h5'>{monthName}</Typography><Button onClick={nextMonth} sx={{minWidth: "auto", width: "fit-content", ml: 1, color: theme.palette.text.primary}} aria-label={t("next month") || "next month"}><ArrowForwardIosIcon sx={{ fontSize: 16 }}/></Button>
        </MonthPicker>
        <Button aria-labelledby={t("New Transaction") || "New Transaction"} onClick={handleAddButton} variant="contained"><AddIcon sx={{fontSize: "24px", mr: 2, ml: "-5px"}}/>{t("New Transaction")}</Button>
        </ButtonBox>
        :
        <ButtonBox>
        <MonthPicker>
        <Button onClick={prevMonth} sx={{minWidth: "auto", width: "fit-content", mr: 1, color: theme.palette.text.primary}} aria-label={t("previous month") || "previous month"}><ArrowBackIosNewIcon sx={{ fontSize: 16 }}/></Button><Typography variant='h5'>{monthName}</Typography><Button onClick={nextMonth} sx={{minWidth: "auto", width: "fit-content", ml: 1, color: theme.palette.text.primary}} aria-label={t("next month") || "next month"}><ArrowForwardIosIcon sx={{ fontSize: 16 }}/></Button>
        </MonthPicker>
        </ButtonBox>
        }
        <NoEntriesBox>
        <Typography variant='body1' sx={{mt: 6, mb: 1}}>{t("Here could be all your transactions!")}</Typography>
        </NoEntriesBox>
        </>
        :
        <>
        <ButtonBox>
        <MonthPicker>
        <Button onClick={prevMonth} sx={{minWidth: "auto", width: "fit-content", mr: 1, color: theme.palette.text.primary}} aria-label={t("previous month") || "previous month"}><ArrowBackIosNewIcon sx={{ fontSize: 16 }}/></Button><Typography variant='h5'>{monthName}</Typography><Button onClick={nextMonth} sx={{minWidth: "auto", width: "fit-content", ml: 1, color: theme.palette.text.primary}} aria-label={t("next month") || "next month"}><ArrowForwardIosIcon sx={{ fontSize: 16 }}/></Button>
        </MonthPicker>
        </ButtonBox>
        <NoEntriesBox>
        <img src={shopping} alt={t('illustration of a shopping display') || "illustration of a shopping display"} style={{width: 300, marginLeft: 50}}></img>
        <Typography sx={{mt: 1, mb: 1}}>{t("Create a budget to get started!")}</Typography>
        </NoEntriesBox>
        </>
        }
        </>
        : 
        <>
        {(budgetAvailable === true && isMobile === false) || (currentEntries.length > 0 && isMobile === false)?
        <>
        <ButtonBox>
        <MonthPicker>
        <Button onClick={prevMonth} sx={{minWidth: "auto", width: "fit-content", mr: 1, color: theme.palette.text.primary}} aria-label={t("previous month") || "previous month"}><ArrowBackIosNewIcon sx={{ fontSize: 16 }}/></Button><Typography variant='h5'>{monthName}</Typography><Button onClick={nextMonth} sx={{minWidth: "auto", width: "fit-content", ml: 1, color: theme.palette.text.primary}} aria-label={t("next month") || "next month"}><ArrowForwardIosIcon sx={{ fontSize: 16 }}/></Button>
        </MonthPicker>
        <Button aria-labelledby={t("New Transaction") || "New Transaction"} onClick={handleAddButton} variant="contained"><AddIcon sx={{fontSize: "24px", mr: 2, ml: "-5px"}}/>{t("New Transaction")}</Button>
        </ButtonBox>
        </>
        :
        <>
        <ButtonBox>
        <MonthPicker>
        <Button onClick={prevMonth} sx={{minWidth: "auto", width: "fit-content", mr: 1, color: theme.palette.text.primary}} aria-label={t("previous month") || "previous month"}><ArrowBackIosNewIcon sx={{ fontSize: 16 }}/></Button><Typography variant='h5'>{monthName}</Typography><Button onClick={nextMonth} sx={{minWidth: "auto", width: "fit-content", ml: 1, color: theme.palette.text.primary}} aria-label={t("next month") || "next month"}><ArrowForwardIosIcon sx={{ fontSize: 16 }}/></Button>
        </MonthPicker>
        </ButtonBox>
        </>
        }
        </>
        }
        <>
        {currentEntries
          .sort((a : EntryType, b : EntryType) => {
            if (a.date > b.date) return -1;      // sort by date
            if (b.date > a.date) return 1;

            return 0;
          }) 
          .map((entry : EntryType) => {
            let notMine = entry.payee[user.id]? false : entry.type === "payment"? false : true;
            let paymentText = "";
            let paymentGiver = "";
            let paymentReceiver = "";

            if (entry.type === "payment") {
              paymentGiver = currentHousehold.members.find((m : MemberType) => m.id === entry.payer)?.name || "???";
              paymentReceiver = currentHousehold.members.find((m : MemberType) => m.id === Object.keys(entry.payee)[0])?.name || "???";
              paymentText = t("From") + " " + paymentGiver + " " + t("to") + " " + paymentReceiver;
            }

            if (firebaseDatetoDay(entry.date) < showDate)
            {
              showDate = firebaseDatetoDay(entry.date);
              return (
                <React.Fragment key={entry.id}>
                <DateText className='date'>{entry.date.toDate().toLocaleDateString(i18n.language)}</DateText>
        <TransactionItem key={entry.id} onClick={(e : any) => handleListButton(e,entry)}>
                <ListContent>
            <div className='col-1'>
            <TransactionIcon type={entry.type} blockIndex={entry.blockIndex} categoryLabel={entry.category}/>
            <div className='info'>
              <Typography className='merchant' variant='body1'>{entry.type === "payment"? paymentText : entry.merchant}</Typography>
                <Typography className='category'>{entry.type === "income"? "Income" : entry.type === "payment"? "Payment" : entry.category}</Typography>
                {entry.type === "expense" && notMine === false && entry.payee[user.id] > 0 && !hasUserCategory(user, entry.category)?
                <WarningChip label={isMobile? t("Unknown category") : t("Warning: You don't have this category")} color='warning'/>
                :
                <></>
                }
              </div>
            </div>
            <div className='col-2'>
              {notMine?
              <>
              <PriceLabel className={entry.type}>{formatCurrency(0,currentHousehold.currency)}</PriceLabel>
              <PriceLabel className='second'>{t("out of")} {formatCurrency(Number(entry.price), currentHousehold.currency)}</PriceLabel>
              </>
              :
              <>
              {entry.type === "payment"?
              <>
              <PriceLabel className={entry.type}>{formatCurrency(Number(entry.price),currentHousehold.currency)}</PriceLabel>
              </>
              :
              <>
              <PriceLabel className={entry.type}>{formatCurrency(entry.payee[user.id],currentHousehold.currency)}</PriceLabel>
              {
                entry.payee[user.id] !== entry.price?
                <>
                <PriceLabel className='second'>{t("out of")} {formatCurrency(Number(entry.price), currentHousehold.currency)}</PriceLabel>
                </>
                :
                <></>
              }
              </>
              }
              </>
              }
            </div>
          </ListContent>
        </TransactionItem>
                </React.Fragment>
              )
            }

          return (
            
            <TransactionItem key={entry.id} onClick={(e : any) => handleListButton(e,entry)}>
              <ListContent>
          <div className='col-1'>
          <TransactionIcon type={entry.type} blockIndex={entry.blockIndex} categoryLabel={entry.category}/>   
          <div className='info'>
              <Typography className='merchant' variant='body1'>{entry.type === "payment"? paymentText : entry.merchant}</Typography>
                <Typography className='category'>{entry.type === "income"? "Income" : entry.type === "payment"? "Payment" : entry.category}</Typography>
                {entry.type === "expense" && notMine === false && entry.payee[user.id] > 0 && !hasUserCategory(user, entry.category)?
                <WarningChip label={isMobile? t("Unknown category") : t("Warning: You don't have this category")} color='warning'/>
                :
                <></>
                }
              </div>
          </div>
          <div className='col-2'>
          {notMine?
              <>
              <PriceLabel className={entry.type}>{formatCurrency(0,currentHousehold.currency)}</PriceLabel>
              <PriceLabel className='second'>{t("out of")} {formatCurrency(Number(entry.price), currentHousehold.currency)}</PriceLabel>
              </>
              :
              <>
              {entry.type === "payment"?
              <>
              <PriceLabel className={entry.type}>{formatCurrency(Number(entry.price),currentHousehold.currency)}</PriceLabel>
              </>
              :
              <>
              <PriceLabel className={entry.type}>{formatCurrency(entry.payee[user.id],currentHousehold.currency)}</PriceLabel>
              {
                entry.payee[user.id] !== entry.price?
                <>
                <PriceLabel className='second'>{t("out of")} {formatCurrency(Number(entry.price), currentHousehold.currency)}</PriceLabel>
                </>
                :
                <></>
              }
              </>
              }
              </>
              }
          </div>
        </ListContent>
      </TransactionItem>
          )})}
      </>


        </TransactionBox>
    
      <EditDialog open={editDialogOpen} setOpen={setEditDialogOpen} entry={toEdit}/>
     <EntryDialog open={entryDialogOpen} setOpen={setEntryDialogOpen}/>
      </Layout>
   
    
  );
};

export default TransactionsContainer;
