import { monthNumbers, months } from "../../@common/theme/Theme";
import {
  categorySumForMonth,
  getPaymentPerMonth,
  getSharedExpensePerCategory,
  getSumforMonth,
} from "../../main/resources/CalcRessources";
import { makeTimeStamp } from "../../overview/resources/helpers";
import {
  BlockType,
  CategoryType,
  HouseholdType,
  MemberType,
} from "../../main/resources/UserResources";
import { EntryType } from "../../transactions/resources/EntryRessources";
import { useTranslation } from "react-i18next";

const useCSVExport = () => {
  const { t } = useTranslation();

  const createYearlyCSV = async (
    userId: string,
    entries: EntryType[],
    blocks: BlockType[],
    year: number
  ) => {
    let tableArr = [];
    const thisTimeStamp = makeTimeStamp(year, 11);

    let activeBlocks = [...blocks];
    activeBlocks = activeBlocks.filter((item: BlockType) => {
      if (
        item.status === "deleted" &&
        Number(thisTimeStamp) >= Number(item.deleted)
      )
        return false;
      if (Number(item.created) > Number(thisTimeStamp)) return false;
      return true;
    });

    //TitleRow
    let headerArr = [];
    headerArr.push(year);
    months.forEach((m) => headerArr.push(m));
    headerArr.push(t("Sum"));
    headerArr.push(t("Average"));
    tableArr.push(headerArr);
    tableArr.push([t("Expenses")]);

    activeBlocks.forEach((b: BlockType) => {
      //Row for complete block
      let blockRow = [];
      blockRow.push(b.name);
      let currTotal = 0;
      months.forEach((month: string, index: number) => {
        let no = getBlockSum(b.categories, index, year, entries, userId);
        currTotal = currTotal + no;
        blockRow.push(no);
      });
      blockRow.push(currTotal);
      blockRow.push(currTotal / 12);
      tableArr.push(blockRow);

      //Row for each Category within block
      let cats = b.categories.filter((categ: CategoryType) => {
        if (
          categ.status === "deleted" &&
          Number(thisTimeStamp) >= Number(categ.deleted)
        )
          return false;
        if (Number(categ.created) > Number(thisTimeStamp)) return false;
        return true;
      });

      cats.forEach((cat: CategoryType) => {
        let catRow = [];
        catRow.push(cat.label);
        let tot = 0;
        monthNumbers.forEach((month: number) => {
          let val = categorySumForMonth(
            userId,
            entries,
            cat.label,
            month,
            year
          );
          catRow.push(val);
          tot = tot + val;
        });
        catRow.push(tot);
        catRow.push(tot / 12);
        tableArr.push(catRow);
      });

      //Add empty row
      tableArr.push([]);
    });

    tableArr.push([]);
    let incomeArr: any[] = [t("Income")];
    let tot = 0;

    monthNumbers.forEach((month: number) => {
      let val = getSumforMonth(userId, "income", entries, month, year);
      incomeArr.push(val);
      tot = tot + val;
    });
    incomeArr.push(tot);
    incomeArr.push(tot / 12);
    tableArr.push(incomeArr);

    let csvContent = "";

    tableArr.forEach((row) => {
      csvContent += row.join(",") + "\n";
    });
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8," });

    return blob;
  };

  const createMonthlyCSV = async (
    userId: string,
    entries: EntryType[],
    blocks: BlockType[],
    month: number,
    year: number
  ) => {
    let tableArr = [];

    const thisTimeStamp = makeTimeStamp(year, month);
    let activeBlocks = [...blocks];
    activeBlocks = activeBlocks.filter((item: BlockType) => {
      if (
        item.status === "deleted" &&
        Number(thisTimeStamp) >= Number(item.deleted)
      )
        return false;
      if (Number(item.created) > Number(thisTimeStamp)) return false;
      return true;
    });

    //TitleRow
    let headerArr = [];
    headerArr.push(year);
    headerArr.push(months[month]);
    tableArr.push(headerArr);
    tableArr.push([t("Expenses")]);

    activeBlocks.forEach((b: BlockType) => {
      //Row for complete block
      let blockRow = [];
      blockRow.push(b.name + " " + t("Sum"));
      let no = getBlockSum(b.categories, month, year, entries, userId);
      blockRow.push(no);
      tableArr.push(blockRow);

      //Row for each Category within block
      let cats = b.categories.filter((categ: CategoryType) => {
        if (
          categ.status === "deleted" &&
          Number(thisTimeStamp) >= Number(categ.deleted)
        )
          return false;
        if (Number(categ.created) > Number(thisTimeStamp)) return false;
        return true;
      });
      cats.forEach((cat: CategoryType) => {
        let catRow = [];
        catRow.push(cat.label);
        let val = categorySumForMonth(userId, entries, cat.label, month, year);
        catRow.push(val);
        tableArr.push(catRow);
      });

      //Add empty row
      tableArr.push([]);
    });

    tableArr.push([]);
    let incomeArr: any[] = [t("Income")];
    let val = getSumforMonth(userId, "income", entries, month, year);
    incomeArr.push(val);
    tableArr.push(incomeArr);

    let csvContent = "";

    tableArr.forEach((row) => {
      csvContent += row.join(",") + "\n";
    });
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8," });

    return blob;
  };

  const createSplitCSV = async (
    userId: string,
    household: HouseholdType,
    entries: EntryType[],
    blocks: BlockType[],
    month: number,
    year: number
  ) => {
    const monthName = months[month] + " " + year;
    let tableArr = [];

    const thisTimeStamp = makeTimeStamp(year, month);
    let activeBlocks = [...blocks];
    activeBlocks = activeBlocks.filter((item: BlockType) => {
      if (
        item.status === "deleted" &&
        Number(thisTimeStamp) >= Number(item.deleted)
      )
        return false;
      if (Number(item.created) > Number(thisTimeStamp)) return false;
      return true;
    });

    //TitleRow
    let headerArr = [];
    headerArr.push(monthName);
    headerArr.push(t("Overall shared expenses"));
    household.members.forEach((m: MemberType) =>
      headerArr.push(t("Payed by") + " " + m.name)
    );
    tableArr.push(headerArr);

    activeBlocks.forEach((b: BlockType) => {
      //Row for complete block
      let blockRow = [];
      blockRow.push(b.name);
      tableArr.push(blockRow);

      //Row for each Category within block
      let cats = b.categories.filter((categ: CategoryType) => {
        if (
          categ.status === "deleted" &&
          Number(thisTimeStamp) >= Number(categ.deleted)
        )
          return false;
        if (Number(categ.created) > Number(thisTimeStamp)) return false;
        return true;
      });

      cats.forEach((cat: CategoryType) => {
        let catRow = [];
        catRow.push(cat.label);

        let val = getSharedExpensePerCategory(entries, cat.label, month, year);
        catRow.push(val);

        household.members.forEach((member: MemberType) => {
          let payed = getPaymentPerMonth(
            member.id,
            entries,
            cat.label,
            month,
            year
          );
          catRow.push(payed);
        });

        tableArr.push(catRow);
      });

      //Add empty row
      tableArr.push([]);
    });

    let csvContent = "";

    tableArr.forEach((row) => {
      csvContent += row.join(",") + "\n";
    });
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8," });

    return blob;
  };

  const getBlockSum = (
    categories: CategoryType[],
    monthOfInterest: number,
    yearOfInterest: number,
    allEntries: EntryType[],
    userId: string
  ) => {
    let s = 0;
    let thisTimeStamp = makeTimeStamp(yearOfInterest, monthOfInterest);
    let cats = categories.filter((categ: CategoryType) => {
      if (
        categ.status === "deleted" &&
        Number(thisTimeStamp) >= Number(categ.deleted)
      )
        return false;
      if (Number(categ.created) > Number(thisTimeStamp)) return false;
      return true;
    });

    cats.forEach((element) => {
      s =
        s +
        categorySumForMonth(
          userId,
          allEntries,
          element.label,
          monthOfInterest,
          yearOfInterest
        );
    });
    return Math.round(s * 100) / 100;
  };
  return { createYearlyCSV, createMonthlyCSV, createSplitCSV };
};

export default useCSVExport;
