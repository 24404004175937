import React, { useEffect, useState } from "react";
import { Typography } from '@mui/material';
import { useTranslation } from "react-i18next";


const ErrorScreen = () => {
    const {t} = useTranslation();

    return (
      <div>
        <Typography sx={{textAlign: "center", mt: 4}}>{t("Something went wrong. Please reload the page.")}</Typography>
    </div>
    );
  
}

export default ErrorScreen;

