import React from 'react';
import { styled } from '@mui/system';
import { Typography, Fab, Button, useTheme, Dialog } from '@mui/material';
import meditation from '../assets/images/meditation.png';
import happy from '../assets/images/happy.png';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useUser } from '../@common/contexts/UserContext';
import { auth } from '../@common/firebase/firebase';
import axios from 'axios';
import { REVENUECAT_API_KEY } from '../@common/theme/Theme';
import iosStore from '../assets/images/BuyButtons/ios.svg';
import playStore from '../assets/images/BuyButtons/google-play-badge.png';

const StyledDialog = styled(Dialog)(({ theme }) => ({

  '& .MuiPaper-root':{
    borderRadius: "15px",
    maxWidth: "none",
    width: "700px"
  },

  [theme.breakpoints.down("md")]: {
    '& .MuiPaper-root':{
      margin: 12,
      marginTop: theme.spacing(10),
    },
  }
}));

const StyledContainer = styled("div")(({ theme }) => ({
  margin: theme.spacing(4),
  position:"relative",
  display:"flex",
  flexDirection:"column",
  alignItems: "center",

  [theme.breakpoints.down("md")]: {
    margin: theme.spacing(2),
  }
}));

const PremiumBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: 16,
  paddingTop: 24,
  marginTop: 16,
  borderRadius: 24,

  [theme.breakpoints.down("md")]: {
    padding: 8,
    paddingTop: 16
  }
}));

const List = styled("div")(({ theme }) => ({
  display: "flex", 
  flexDirection: "row",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  margin: theme.spacing(0,2)
}));

const ListItem = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  width: "50%",
  paddingBottom: '1em',
}));

const ButtonCont = styled("div")(({ theme }) => ({
  display: "flex", 
  marginTop: 12, 
  alignItems: "center", 
  justifyContent: "center",

  [theme.breakpoints.down("md")]: {
    flexDirection: "column"
  }
}));

type SubscriptionDialogType = {
  open: boolean,
  setOpen: (open: boolean) => void,
}

const SubscriptionContainer = ({open, setOpen} : SubscriptionDialogType) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const {premium, user} = useUser();
  const [vip, setVip] = React.useState(false);
  const [willRenew, setWillRenew] = React.useState(false);
  const [expirationDate, setExpirationDate] = React.useState<string | null>(new Date().toDateString());

	const handleClose = () => {
		setOpen(false);
	};


  const premiumFeatures = [
    t("Shared Expenses"),
    t("More Icons"),
    t("Spending Insights"),
    t("Data Exports"),
    t("Split Insights"),
    t("No Ads"),
    t("Full Web Access"),
    t("Eternal Gratitude")

  ]

    // Revnuecat API key
const API_KEY = 'strp_mcEcYMfDnbXlEWrIgbpJcEvifKF';

  React.useEffect(() => {
    //Check User Claims for Premium
    auth.currentUser?.getIdTokenResult().then((idTokenResult) => {
        if (idTokenResult.claims.premium) setVip(true);     
        else {
          axios.get(`https://api.revenuecat.com/v1/subscribers/${user.id}`, {
            headers: {
              'Authorization': `Bearer ${REVENUECAT_API_KEY}`,
              'Content-Type': 'application/json'
            }
          })
          .then((data : any) => {
            if(typeof data.subscriber.entitlements.active["Premium"] !== "undefined"){
              setWillRenew(data.subscriber.entitlements.active["Premium"].willRenew);
              setExpirationDate(data.subscriber.entitlements.active["Premium"].expirationDate);
            }

          })
          .catch((error) => {
            console.log(error);
          })
        }
        })
    

  }, [])

  if (premium) return (
    <StyledDialog
    open={open}
    onClose={handleClose}
    >
      <StyledContainer>
       
  
        <Typography variant='h4' sx={{textAlign: "center", maxWidth: 400, margin: "auto", mb: 4}}>{t("You are a premium member!")}</Typography>
        <div style={{display: "flex", alignItems: "center"}}>
        <img src={happy} 
        //@ts-ignore
        alt={t('illustration of a happy girl')}
        style={{width: 180, paddingLeft: 32}}></img>
        <div style={{paddingLeft: 32}}>
          <div style={{display: "flex", alignItems: "center"}}>
            <Typography>{t("Plan")}</Typography>
            <Typography>: {vip === true? t("Gifted Subscription") : t("Payed Subscription")}</Typography>
          </div>
          {vip === true?
            <div style={{display: "flex", alignItems: "center"}}>
            <Typography>{t("Subscription Ends")}</Typography>
            <Typography>: {t("Never 😊")}</Typography>
            </div>
            :
            <div style={{display: "flex", alignItems: "center"}}>
            <Typography>{willRenew? t("Next Payment") : t("Subscription Ends")}</Typography>
            <Typography>: {expirationDate}</Typography>
            </div>

          }
          <div style={{display: "flex", alignItems: "center", backgroundColor: theme.palette.info.light, padding: 16, borderRadius: 12, marginTop: 32}}>
            <Typography sx={{color: theme.palette.info.contrastText}}>{t("To change your subscription settings, please visit your mobile app!")}</Typography>
            </div>
        </div>
        </div>
        <PremiumBox>
        <Typography variant='h5' style={{marginBottom: 16, textAlign: "center"}}>{t("Your Premium Features")}</Typography>
        <List>
        {premiumFeatures.map((item : string) => {
          return(
            <ListItem key={item}>
            <CheckCircleIcon/>
            <Typography variant='body1' style={{marginLeft: 8}}>{item}</Typography>
          </ListItem>
          )})

        }
        </List>
        </PremiumBox>
    </StyledContainer>

    </StyledDialog>



   
    
  );


    return (
      <StyledDialog
      open={open}
      onClose={handleClose}
      >
        <StyledContainer>
         
    
          <Typography variant='h4' sx={{textAlign: "center", maxWidth: 400, margin: "auto", mb: 4}}>{t("More flexibility, more insights, more financial peace")}</Typography>
          <img src={meditation} 
          //@ts-ignore
          alt={t('illustration of a meditating girl')}
          style={{width: 250, margin: "auto", marginBottom: 32, paddingLeft: 32}}></img>
          <PremiumBox>
          <Typography variant='h5' style={{marginBottom: 16, textAlign: "center"}}>{t("Premium Features")}</Typography>
          <List>
          {premiumFeatures.map((item : string) => {
            return(
              <ListItem key={item}>
              <CheckCircleIcon/>
              <Typography variant='body1' style={{marginLeft: 8}}>{item}</Typography>
            </ListItem>
            )})

          }
          </List>
          </PremiumBox>
          <Typography variant='h5' style={{marginBottom: 16, marginTop: 24, textAlign: "center"}}>{t("Why a subscription?")}</Typography>
        <Typography variant='body1' style={{marginBottom: 16, textAlign: "center", maxWidth: 600}}>{t("We're a two people team who made this app to be more mindful about our spending. The subscription let's us keep working on our software, pay the servers and app stores that we wouldn't otherwise be able to afford.")}</Typography>
        <div>
          
        </div>
        
        <Typography variant='h3' sx={{mt: 2}}>{t("Get the App to opt in!")}</Typography>
        <ButtonCont >
        <div style={{ height: 75, width: 200, position: "relative" }}>
          <a
              style={{ background: `url(${iosStore}) no-repeat`,
              backgroundSize: 'contain',
              display: 'inline-block',
              overflow: 'hidden',
              textDecoration: 'none',
              height: 55,
              width: '100%',
              margin: 12
              }}
              href={"www.balancey.app"}
              target="_blank"
              rel="noopener noreferrer"
              
      
          >

          </a>
        </div>
        <div style={{ height: 75, width: 200, display: 'inline-block' }}>
          <a
              style={{ background: `url(${playStore}) no-repeat`,
              backgroundSize: 'contain',
              display: 'inline-block',
              overflow: 'hidden',
              textDecoration: 'none',
              height: "100%",
              width: '100%',
              padding: '5px',}}
              href={"https://play.google.com/store/apps/details?id=com.vanillanoir.balancey"}
              target="_blank"
              rel="noopener noreferrer"
              
      
          >

          </a>
        </div>
        </ButtonCont>


      </StyledContainer>

      </StyledDialog>


 
     
      
    );

};

export default SubscriptionContainer;
