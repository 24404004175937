import React, { useRef } from 'react';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import { motion, useSpring, useScroll, useTransform, MotionValue, useMotionValueEvent } from "framer-motion";
import { useTheme } from '@mui/material';
import step1 from "../../assets/images/step1.png";
import step2 from "../../assets/images/step2.png";
import step3 from "../../assets/images/step3.png";
import heroImg from "../../assets/images/girl-meditating.png";
import ParallaxContainer from './ParallaxContainer';
import Features from './Features';





const fadeInUp = { duration: 2, ease: [0.47,.21,0.05,1], delay: 0.4};
const fadeInUp4 = { duration: 2, ease: [0.47,.21,0.05,1], delay: 0.55};


const Section = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(20),
  paddingRight: theme.spacing(20),

  '&.introduction': {
    backgroundColor: theme.palette.homepage.light, 
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: '10rem',
    paddingBottom: '16rem',
  },


  //Small
  [theme.breakpoints.down("lg")]: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),  

    '&.introduction' : {
      height: '100%',
      alignItems: 'flex-start',
      paddingBottom: '7rem',
    }
    },
  //Mobile
  [theme.breakpoints.down("md")]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(10),
    textAlign: 'center',


  },

}));

const IntroContainer = styled('div')(({ theme }) => ({
  width: "80vw",
  color: theme.palette.homepage.main,
  position: 'relative',

  '& img' : {
    position: 'absolute',
    right: '-8vw',
    top: '3rem',
    width: '28vw',
  },

  '& .line-wrapper' : {
    overflow: 'hidden',
    height: '100%'
  },

  '& .line-wrapper > div' : {
    display: 'inline-block',
  },


   //Small
   [theme.breakpoints.down("lg")]: {
    width: "100%",
    display: 'flex',
    flexDirection: 'column',

    '& img': {
      position: 'relative',
      width: '354px',
      //top: '-1rem',
      right: '-1rem',
      alignSelf: 'center',
    }
    
  },

  //Mobile
  [theme.breakpoints.down("md")]: {
   
   '& img' : {
    width: '280px',
    right: '-30px'
  }
  },
}));

const HeroText = styled(Typography)(({ theme }) => ({
  fontFamily: "objectivitymedium",
  letterSpacing: "-0.3rem",
  maxWidth: '59vw',
  fontSize: '3.5vw',
  lineHeight: 1.1,
  color: "inherit",
  margin: theme.spacing(0,50,0,0),

  [theme.breakpoints.down("lg")]: {
    fontSize: '3rem',
    letterSpacing: '-0.25rem',
    margin: theme.spacing(0, 10),
    lineHeight: 1.2,
    maxWidth: '100vw',
    textAlign: 'center',

   },

   [theme.breakpoints.down("md")]: {
    fontSize: '2.2rem',
    letterSpacing: '-0.2rem',
    margin: theme.spacing(0, 5),
   },


}));


const AboutContainer = styled('div')(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  color: theme.palette.homepage.main,
  scrollSnapAlign: 'start',


}));


const AboutHeader = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  margin: theme.spacing(20,20,20,20),
  maxWidth: '850px',
  color: "inherit",

  [theme.breakpoints.down("lg")]: {
    margin: theme.spacing(20, 0, 0, 0),
  },
  

}));






const About = () => {
  const theme = useTheme();


  return (
    <>
    <Section className='introduction'>
        <IntroContainer>
          <div >
            <motion.div initial={{ y: 80, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={fadeInUp} viewport={{ once: true }}>
              <HeroText>
              Understand what you spend for things that you need.
              Have more money for things that bring you happiness.
              </HeroText>
            </motion.div>
          </div>
          {/* <div className='line-wrapper'>
            <motion.div initial={{ y: '70%', opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={fadeInUp2} viewport={{ once: false }}>
              <HeroText>
             things that you need.
              </HeroText>
            </motion.div>
          </div> */}
          {/* <div className='line-wrapper'>
            <motion.div initial={{ y: '70%', opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={fadeInUp3} viewport={{ once: false }}>
              <HeroText>
              Have more money for things that bring you happiness.
              </HeroText>
            </motion.div>
          </div> */}
          {/* <div className='line-wrapper'>
            <motion.div initial={{ y: '70%', opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={fadeInUp4} viewport={{ once: false }}>
              <HeroText>
              that bring you happiness.
              </HeroText>
            </motion.div>
          </div> */}
            <img src={heroImg}></img>
      </IntroContainer>
    </Section>
    <Features/>
      <Section sx={{backgroundColor: theme.palette.common.white}}>
      <AboutContainer>
      <motion.div initial={{ opacity: 0, y: 60 }} whileInView={{ opacity: 1, y:0 }} transition={fadeInUp4} viewport={{ once: true }}>
            <AboutHeader variant='h2'>
            Change the way you handle money in three easy steps
                </AboutHeader>
        </motion.div>
        <ParallaxContainer imgSrc={step1} imgClassName="step1" number='01' title='Create a budget' subtitle='Budget your money for different categories based on your income. Always know how much you have left for everything you buy.'/>
        <ParallaxContainer imgSrc={step2} imgClassName="step2" number='02' title='Track your spending' subtitle='Get in touch with what you spend. Learn your spending habits and where your money goes.'/>
        <ParallaxContainer imgSrc={step3} imgClassName="step3" number='03' title='Change how you view money' subtitle='Give yourself more money for things you enjoy and save money through understanding your habits.'/>
            </AboutContainer>
      </Section>

            </>
  );
};

export default About;
