import { Button, styled } from '@mui/material';
import * as React from 'react';
import { useAppContext } from '../../@common/contexts/AppContext';
import { useTranslation } from 'react-i18next';

const GroupContainer = styled("div")(({ theme }) => ({
  position:"relative",
  display: "flex",
  justifyContent: "space-between"
 
 })); 

const Spotlight = styled("div")(({ theme }) => ({
  position:"absolute",
  width: "100px",
  height: "40px",
  backgroundColor: theme.palette.primary.main,
  zIndex: 1,
  top: 0,
  transition: "all 0.2s ease",
  borderRadius: "25px",
  marginRight: 10,
 }));

 const CustomButton = styled(Button)(({ theme }) => ({
  width: "100px",
  height: "40px",
  zIndex: 3,
  marginRight: 10,

  '&.active': {
      color: theme.palette.primary.contrastText,
  }
 
 }));

   type InsightButtonGroupType = {
    insightState: string;
    setInsightState: (insightState : string) => void;
   }

const InsightsButtonGroup = ({insightState, setInsightState} : InsightButtonGroupType) => {
  const {t} = useTranslation();
  const spotlightRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  const slideSpotlight = (pos : number) => {
      spotlightRef.current.style.left = pos+"px";
  }

  React.useEffect(() => {
    if (insightState === "split")
    {
      slideSpotlight(220);
    }
    else if (insightState === "monthly")
    {
      slideSpotlight(0);
    }
    else if (insightState === "yearly")
    {
      slideSpotlight(110);
    }
  }, [insightState])
 
    

  return (
    <GroupContainer>
        <CustomButton onClick={() => {setInsightState("monthly")}}className={insightState==="monthly"? "active" : ""}>{t("Monthly")}</CustomButton>
        <CustomButton onClick={() => {setInsightState("yearly")}}className={insightState==="yearly"? "active" : ""}>{t("Yearly")}</CustomButton>
        <CustomButton onClick={() => {setInsightState("split")}} className={insightState==="split"? "active" : ""}>{t("Split")}</CustomButton>
        <Spotlight ref={spotlightRef}/>
    </GroupContainer>
  )
}

export default InsightsButtonGroup