import { collection, doc } from "firebase/firestore";
import React, { useState, useContext, createContext, useEffect } from "react";
import {
  EntryType,
  deleteFirebaseEntry,
  getFirebaseEntries,
  getSortedEntries,
  setFirebaseEntries,
  updateFirebaseEntry,
} from "../../transactions/resources/EntryRessources";
import { firestore } from "../firebase/firebase";
import { useAppContext } from "./AppContext";
import { useUser } from "./UserContext";

const EntryContext = createContext(null);

const useEntry = () => {
  const { entryState, monthEntries, entriesLoaded } =
    useContext<any>(EntryContext);
  const [entries, setEntries] = entryState as [
    EntryType[],
    React.Dispatch<React.SetStateAction<EntryType[]>>
  ];
  const { currentHousehold } = useUser();

  const handleAdd = async (values: EntryType[]) => {
    const temp = [...entries];

    for (var i = 0; i < values.length; i++) {
      const ref = doc(
        collection(firestore, "households", currentHousehold.id, "entries")
      );
      values[i].id = ref.id;
      await setFirebaseEntries(values[i], ref).catch((e) => {
        console.log("Error with saving entries. Message: " + e);
      });

      temp.push(values[i]);
    }

    setEntries(getSortedEntries(temp));
  };

  const handleRemove = async (values: EntryType[]) => {
    const temp = [...entries];
    for (var i = 0; i < values.length; i++) {
      const index = temp.indexOf(values[i]);
      if (index > -1) {
        // only splice array when item is found
        temp.splice(index, 1); // 2nd parameter means remove one item only
      }

      await deleteFirebaseEntry(values[i].id, currentHousehold.id).catch(
        (e) => {
          console.log("Error with deleting entry.");
        }
      );
    }

    setEntries(getSortedEntries(temp));
  };

  const handleUpdate = (entryId: string, entry: EntryType) => {
    const temp = [...entries];
    const item = temp.find((x) => x.id === entryId);

    if (item) {
      const index = temp.indexOf(item);
      temp[index] = entry;

      updateFirebaseEntry(entryId, currentHousehold.id, entry).catch((e) => {
        console.log("Error with updating entry.");
      });
      setEntries(getSortedEntries(temp));
    }
  };

  const renameEntryCategories = (oldLabel: string, newLabel: string) => {
    const temp = [...entries];

    //We go backwards through all entries, so newest come first
    for (let i = entries.length - 1; i >= 0; i--) {
      if (entries[i].category === oldLabel) {
        temp[i].category = newLabel;
        updateFirebaseEntry(entries[i].id, currentHousehold.id, temp[i]).catch(
          (e) => {
            console.log("Error with renaming entries. Message: " + e);
          }
        );
      }
    }

    setEntries(getSortedEntries(temp));
  };

  // const updateEntriesAfterCleanup = async(newEntries : EntryType[]) => {
  //   await setEntries(getSortedEntries(newEntries));

  // }

  return {
    allEntries: entries,
    monthEntries: monthEntries,
    addEntry: handleAdd,
    removeEntry: handleRemove,
    updateEntry: handleUpdate,
    renameEntryCategories,
    entriesLoaded,
  };
};

type EntryProviderTypes = {
  children: React.ReactNode;
};

const EntryProvider = ({ children }: EntryProviderTypes) => {
  const [entries, setEntries] = useState<EntryType[]>([]);
  const [monthEntries, setMonthEntries] = useState<EntryType[]>([]);
  const [entriesLoaded, setEntriesLoaded] = useState(false);
  const { currentHousehold, user } = useUser();
  const { month, year } = useAppContext();

  useEffect(() => {
    if (currentHousehold) {
      getFirebaseEntries(currentHousehold.id, user.id)
        .then((response) => {
          setEntries(response);
          setEntriesLoaded(true);
        })
        .catch((e) => {
          console.log("Error with retrieving entries." + e);
        });
    }
  }, [currentHousehold]);

  useEffect(() => {
    if (currentHousehold) {
      let temp: EntryType[] = [];

      //We go backwards through all entries, so newest come first
      for (let i = entries.length - 1; i >= 0; i--) {
        const thisDate = entries[i].date.toDate();
        //Stop looking if the year is smaller than our search year
        if (thisDate.getFullYear() < year) {
          setMonthEntries(temp);
          return;
        }
        //Stop looking if the month is smaller than our search month, assuming we are in the right year
        if (thisDate.getMonth() < month && thisDate.getFullYear() === year) {
          setMonthEntries(temp);
          return;
        }
        //This entry is either in the right month or later. If it's in the right month, we check it
        if (thisDate.getMonth() === month && thisDate.getFullYear() === year) {
          temp.push(entries[i]);
        }
      }
      setMonthEntries(getSortedEntries(temp));
    }
  }, [entries, month, year, currentHousehold]);

  return (
    <EntryContext.Provider
      value={
        {
          entryState: [entries, setEntries],
          monthEntries: monthEntries,
          entriesLoaded: entriesLoaded,
        } as any
      }
    >
      {children}
    </EntryContext.Provider>
  );
};
export { EntryProvider, useEntry };
