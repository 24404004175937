import { Button, styled, useTheme } from '@mui/material';
import * as React from 'react';
import { useUser } from '../../@common/contexts/UserContext';
import ClearIcon from '@mui/icons-material/Clear';
import EditableLabel from './EditableLabel';
import DeleteDialog from '../../@common/components/DeleteDialog';
import { removeDeletedCategory } from '../../transactions/resources/EntryRessources';
import { useEntry } from '../../@common/contexts/EntryContext';
import { useAppContext } from '../../@common/contexts/AppContext';

const TitleBox = styled("div")(({ theme }) => ({
    position:"relative",
    display: "flex",
    alignItems:"center",
    justifyContent:"space-between",
    width: "100%",
    height: "40px",
    color: theme.palette.text.secondary,
    paddingLeft: '3px',

    [theme.breakpoints.down("lg")]: {
      paddingLeft: '8px',    
    },
   
   }));

   const DeleteBoxButton = styled(Button)(({ theme }) => ({
    display:"flex",
    width: "20px",
    height: "20px",
    minWidth:"auto",
    border: "1px solid " + theme.palette.text.secondary,
    color: theme.palette.text.secondary,
    borderRadius: "50%",

    "& .MuiSvgIcon-root": {
        cursor:"pointer",

    }
   
   }));

type BlockTitleProps = {
    index: number;
    thisTimeStamp: string;
  };

const BlockTitle = ({index, thisTimeStamp} : BlockTitleProps) => {
 
    const {blocks, updateAllBlocks, setBlocks} = useUser();
    const {allEntries, updateEntry} = useEntry();
    const {month, year} = useAppContext();
    const [editState, setEditState] = React.useState(false);
    const [blockDeleteDialogOpen, setBlockDialogOpen] = React.useState(false);
    const theme = useTheme();


    const handleKeyPresses = (e : any) => {
      if (e.key === "Escape") cancelEdit();
      if (e.key === "Enter") finishEdit(e.target.value);
  
    }

    const cancelEdit = () => {
      setEditState(false);
    }

    const finishEdit = (newLabel : string) => {
      //Update Block Name here
    const temp = [...blocks];
    if (index > -1) { // only splice array when item is found
      temp[index].status = "okay";
      temp[index].name = newLabel;
    }
    setEditState(false);
    updateAllBlocks(temp);
    setBlocks(temp);
    }

    const deleteThis = () => {
      const temp = [...blocks];
        if (index > -1) { // only splice array when item is found
          //If block is not used in another month - delete it completely
          if (temp[index].created === thisTimeStamp)
          {
            temp.splice(index,1);
          }
          else {
            temp[index].status = "deleted";
            temp[index].deleted = thisTimeStamp;

            for (let i = 0; i<temp[index].categories.length; i++){
              let catLabel = temp[index].categories[i].label;
                //If category is unused  - delete it completely (Only for non-default categories)
              if((temp[index].categories[i].budget && Object.keys(temp[index].categories[i].budget).length <= 0) ||  temp[index].categories[i].created === thisTimeStamp)
              {
                if (!temp[index].categories[i].id.includes("default")) {
                  temp[index].categories.splice(i,1);
                }
                else {
                  temp[index].categories[i].status = "deleted";
                  temp[index].categories[i].deleted = thisTimeStamp;
                }
              }
              else {
                temp[index].categories[i].status = "deleted";
                temp[index].categories[i].deleted = thisTimeStamp;
              }

              removeDeletedCategory(allEntries, month, year, catLabel, updateEntry);
            }
          }
        }

        updateAllBlocks(temp);
        setBlocks(temp);
    }
    
  return (
    <>
    <TitleBox>
    <EditableLabel editState={editState} label={blocks[index].name} handleKey={handleKeyPresses}
        handleBlur={finishEdit}
        handleOnClick={() => {setEditState(true)}}
        labelSx={{
          margin: 0,
          color: theme.palette.text.secondary,
          fontSize: 14,
        }}
        />    
        <DeleteBoxButton onClick={() => setBlockDialogOpen(true)}>
    <ClearIcon sx={{fontSize: "16px"}}/>
    </DeleteBoxButton>
    </TitleBox>
    <DeleteDialog open={blockDeleteDialogOpen} setOpen={setBlockDialogOpen} onSubmit={deleteThis} toDelete={blocks[index].name || ""}/>

    </>
  );
};

export default BlockTitle;
