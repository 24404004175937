import i18n, {
  LanguageDetectorAsyncModule,
  Services,
  InitOptions,
} from "i18next";
import { initReactI18next } from "react-i18next";

const getDeviceLang = () => {
  return navigator.language;
};

const languageDetector: LanguageDetectorAsyncModule = {
  type: "languageDetector",
  // If this is set to true, your detect function receives a callback function that you should call with your language,
  //useful to retrieve your language stored in AsyncStorage for example
  async: true,
  init: (
    _services: Services,
    _detectorOptions: object,
    _i18nextOptions: InitOptions
  ) => {
    /* use services and options */
  },
  detect: (callback: (lng: string) => void) => {
    const lng = window.localStorage.getItem("@balancey_language");
    // Error fetching stored data or no language was stored
    if (!lng) {
      const deviceLang = getDeviceLang();
      callback(deviceLang);
      return;
    }

    callback(lng);
  },
  cacheUserLanguage: (lng: string) => {
    window.localStorage.setItem("@balancey_language", lng);
  },
};

i18n
  .use(languageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    compatibilityJSON: "v3",
    fallbackLng: "en",
    resources: {
      en: {
        translation: {
          //Introduction
          "Let's find your financial balance.":
            "Let's find your financial balance.",
          "My Household": "My Household",
          "What's your name?": "What's your name?",
          "What's your favourite currency?": "What's your favourite currency?",
          "Begin this journey": "Begin this journey",
          "Connect to existing account": "Connect to existing account",
          //Overview
          Overview: "Overview",
          "Overview for": "Overview for",
          Income: "Income",
          "Your budget is balanced!": "Your budget is balanced!",
          "You are": "You are",
          "over budget!": "over budget!",
          "Left to Budget": "Left to Budget",
          "Category Details": "Category Details",
          "Other Categories": "Other Categories",
          "You've reached the limit for new blocks.":
            "You've reached the limit for new blocks.",
          Label: "Label",
          "You've reached the limit for new categories.":
            "You've reached the limit for new categories.",
          "Let's start by creating a budget!":
            "Let's start by creating a budget!",
          "Create Budget": "Create Budget",
          "Add Block": "Add Block",
          "Add Category": "Add Category",
          "Delete Budget": "Delete Budget",
          "your budget for this month": "your budget for this month",
          Created: "Created",
          Planned: "Planned",
          Spent: "Spent",
          Remaining: "Remaining",
          "Carryover from last month": "Carryover from last month",
          "Delete Category": "Delete Category",
          "Initial Balance": "Initial Balance",
          "close category window": "close category window",
          Warning: "Warning",
          "Some of your expenses don't fit your categories. Please check your transactions for this month.":
            "Some of your expenses don't fit your categories. Please check your transactions for this month.",
          "next month": "next month",
          "previous month": "previous month",
          "next year": "next year",
          "previous year": "previous year",
          "edit icon": "edit icon",
          //Transactions
          Transactions: "Transactions",
          "Transactions in ": "Transactions in ",
          "Add Transaction": "Add Transaction",
          "New Transaction": "New Transaction",
          "Edit Transaction": "Edit Transaction",
          "Here could be all your transactions!":
            "Here could be all your transactions!",
          "Something went wrong. Cannot update entries.":
            "Something went wrong. Cannot update entries.",
          "Create a budget to get started!": "Create a budget to get started!",
          "Unknown Category": "Unknown Category",
          "Warning: You don't have this category":
            "Warning: You don't have this category",
          "out of": "out of",
          Back: "Back",
          Expense: "Expense",
          Payment: "Payment",
          Amount: "Amount",
          Merchant: "Merchant",
          Date: "Date",
          Category: "Category",
          "Undo Split": "Undo Split",
          "Payed by": "Payed by",
          "Payed for": "Payed for",
          "Price split for": "Price split for",
          "Split Transaction": "Split Transaction",
          "Automatically create when making new budget":
            "Automatically create when making new budget",
          "Payment from": "Payment from",
          "Payed to": "Payed to",
          Save: "Save",
          Delete: "Delete",
          "this transaction": "this transaction",
          From: "From",
          //Insights
          Insights: "Insights",
          Monthly: "Monthly",
          Yearly: "Yearly",
          Split: "Split",
          "Shared Expenses": "Shared Expenses",
          "No shared expenses this month!": "No shared expenses this month!",
          "Suggested Payments:": "Suggested Payments:",
          pays: "pays",
          to: "to",
          "Actually, you're all set already!":
            "Actually, you're all set already!",
          "and everybody should be happy!": "and everybody should be happy!",
          "Household Balance": "Household Balance",
          "Settle Up": "Settle Up",
          "Nothing to see here.": "Nothing to see here.",
          Balance: "Balance",
          "Export CSV": "Export CSV",
          "Open Export Menu": "Open Export Menu",
          "Flow Diagram": "Flow Diagram",
          Total: "Total",
          Sum: "Sum",
          Saved: "Saved",
          Average: "Average",
          Expenses: "Expenses",
          "Overall shared expenses": "Overall shared expenses",
          //Hints
          "Try to give yourself a budget that ressembles your average monthly spending for this category.":
            "Try to give yourself a budget that ressembles your average monthly spending for this category.",
          "If you want to empty your category fund, you can plan a negative budget for this month.":
            "If you want to take money from your category fund, you can plan a negative budget for that month.",
          "Give yourself a strict budget for the things you have to pay and plan more on things that give you happiness.":
            "Give yourself a strict budget for the things you have to pay and plan more on things that give you happiness.",
          "Try to first eliminate all debts on your roadmap to a peaceful financial life.":
            "Try to first eliminate all debts on your roadmap to a peaceful financial life.",
          "Don't be surprised by emergencies - keep at least $1000 in your emergency fund.":
            "Don't be surprised by emergencies - keep at least $1000 in your emergency fund.",
          "Mindful spending means connecting with what you spend money on. Manually keeping track is an important part of understanding your habits.":
            "Mindful spending means connecting with what you spend money on. Manually keeping track is an important part of understanding your habits.",
          "Every financial situation is different. Don't compare yourself to others. Improve habits you want improved.":
            "Mindful spending means connecting with what you spend money on. Manually keeping track is an important part of understanding your habits.",
          "Do not give yourself strict saving goals. Plan all your income to lead a consistent life.":
            "Do not give yourself strict saving goals. Plan all your income to lead a consistent life.",
          "It's important to give your whole income a purpose. Leftover money can be used to pay off debts, invest, or adjust your funds.":
            "It's important to give your whole income a purpose. Leftover money can be used to pay off debts, invest, or adjust your funds.",
          "If you plan $10 a month for christmas gifts, you will have $120 on christmas with no additional financial burden.":
            "If you plan $10 a month for christmas gifts, you will have $120 on christmas with no additional financial burden.",
          "For some categories it makes sense to adjust your planned budgets at the end of a month to reflect your spending.":
            "For some categories it makes sense to adjust your planned budgets at the end of a month to reflect your spending.",
          "Try to consistently give yourself money for big purchases, even if these are far in the future.":
            "Try to consistently give yourself money for big purchases, even if these are far in the future.",
          //Settings
          Settings: "Settings",
          General: "General",
          Name: "Name",
          Role: "Role",
          Currency: "Currency",
          "Household Members": "Household Members",
          "Invite New Member": "Invite New Member",
          Language: "Language",
          "Dark Mode": "Dark Mode",
          App: "App",
          Version: "Version",
          Copyright: "Copyright",
          Account: "Account",
          Status: "Status",
          Connected: "Connected",
          "Not connected": "Not connected",
          "Change Email": "Change Email",
          "Change Password": "Change Password",
          "Delete Account": "Delete Account",
          "Create New Account": "Create New Account",
          "Connect Existing Account": "Connect Existing Account",
          Confirm: "Confirm",
          "Please enter your password to confirm.":
            "Please enter your password to confirm.",
          "Connect existing account": "Connect existing account",
          "Warning: This will override your local save files with the existing cloud files.":
            "Warning: This will override your local save files with the existing cloud files.",
          "Create a new account": "Create a new account",
          "Create and connect a new account. This will sync your local files to the cloud.":
            "Create and connect a new account. This will sync your local files to the cloud.",
          "Create Account": "Create Account",
          "Invite a user to your household!":
            "Invite a user to your household!",
          "Their email address": "Their email address",
          "Something went wrong. Please try again later.":
            "Something went wrong. Please try again later.",
          "Successfully sent invitation!": "Successfully sent invitation!",
          "Successfully updated password!": "Successfully updated password!",
          "Let's make some changes!": "Let's make some changes!",
          "Something went wrong. Make sure both passwords are the same and longer than 4 characters.":
            "Something went wrong. Make sure both passwords are the same and longer than 4 characters.",
          "Something went wrong. Make sure both emails are the same.":
            "Something went wrong. Make sure both emails are the same.",
          "Log Out": "Log Out",
          "Wrong email or password.": "Wrong email or password.",
          "Something went wrong. Please check your email and password.":
            "Something went wrong. Please check your email and password.",
          "Testing Version": "Beta Test",
          "All features for testing purposes":
            "All features for testing purposes",
          Verified: "Verified",
          "Not verified": "Not verified",
          "Verification email sent.": "Verification email sent.",
          "If you live in a shared household, you can create shared expenses, while every user retains an individual budget. Individual expenses will remain private and only visible to you.":
            "If you live in a shared household, you can create shared expenses, while every user retains an individual budget. Individual expenses will remain private and only visible to you.",
          "Create a free account to be able to use Balancey across your devices and on the web. Some functionalities (like sharing households) are only possible with an account.":
            "Create a free account to be able to use Balancey across your devices and on the web. Some functionalities (like sharing households) are only possible with an account.",
          "Privacy Policy": "Privacy Policy",
          "Terms & Conditions": "Terms & Conditions",
          //SUBSCRIPTION AND AD
          "Share Expenses!": "Share Expenses!",
          "Connect with your partner or your roommates and track your shared expenses, while everybody keeps their own budget.":
            "Connect with your partner or your roommates and track your shared expenses, while everybody keeps their own budget. Of course you can keep tracking private expenses too, which will not be visible for the other members.",
          "Create an account!": "Create an account!",
          "Get started!": "Get started with premium",
          "Get Yearly Insights!": "Get Yearly Insights!",
          "More flexibility, more insights, more financial peace":
            "More flexibility, more insights, more financial peace",
          "Premium Features": "Premium Features",
          Premium: "Premium",
          "Good News!": "Good News!",
          "As a member of the premium test program, you have access to all current premium features with no extra charge. Please be aware that you will lose access to the features described here when the test phase is over. You will never lose access to your budgets or transactions.":
            "As a member of the beta test program, you have access to all current premium features with no extra charge. Please be aware that you will lose access to the features described here when the test phase is over. You will never lose access to your budgets or transactions.",
          "More Icons": "More Icons",
          "Spending Insights": "Spending Insights",
          "Data Exports": "Data Exports",
          "Split Insights": "Split Insights",
          "No Ads": "No Ads",
          "Full Web Access": "Full Web Access",
          "Eternal Gratitude": "Eternal Gratitude",
          "Alright!": "Alright!",
          "Available in Premium": "Available in Premium",
          "Learn more": "Learn more",
          "Try Premium": "Try Premium",
          "No ads, better insights, shared households.":
            "No ads, better insights, shared households.",
          "Free Member": "Free Member",
          "Premium Member": "Premium Member",
          "All Features": "All Features",
          "Basic Feautures": "Basic Features",
          MONTHLY: "MONTHLY",
          ANNUAL: "ANNUAL",
          "/mo": "/mo",
          "billed monthly": "billed monthly",
          "billed annually at": "billed annually at",
          billedyearly2: "",
          "Recurring billing. Cancel anytime.":
            "Recurring billing. Cancel anytime.",
          "Why a subscription?": "Why a subscription?",
          "We're a two people team who made this app to be more mindful about our spending. The subscription let's us keep working on our software, pay the servers and app stores that we wouldn't otherwise be able to afford.":
            "We're a two people team who made this app to be more mindful about our spending. The subscription let's us keep working on our software, pay the servers and app stores that we wouldn't otherwise be able to afford.",
          "Restore Purchases": "Restore Purchases",
          "Start Free Trial": "Start Free Trial",
          "Try 7 days, then": "Try 7 days, then",
          "per year": "per year",
          "You are a premium member!": "You are a premium member!",
          "Your Premium Features": "Your Premium Features",
          Plan: "Plan",
          "Something went wrong. Please manage your subscription on the device you originally purchased it on.":
            "Something went wrong. Please manage your subscription on the device you originally purchased it on.",
          "illustration of a happy girl": "illustration of a happy girl",
          "Gifted Subscription": "Gifted subscription",
          "Payed Subscription": "Payed subscription",
          "Subscription Ends": "Subscription ends",
          "Never 😊": "Never 😊",
          "Next Payment": "Next payment",
          "Subscription Settings": "Subscription settings",
          "To change your subscription settings, please visit your mobile app!":
            "To change your subscription settings, please visit your mobile app!",
          "Get the App to opt in!": "Get the App to opt in!",
          //Icon Select
          "Icon Select": "Icon Select",
          "Close Window": "Close Window",
          Housing: "Housing",
          Travel: "Travel",
          //Auth Dialog
          "Sign In": "Sign In",
          "Welcome back!": "Welcome back!",
          "Let's reset your password.": "Let's reset your password.",
          "Enter your mail and we'll get this sorted.":
            "Enter your mail and we'll get this sorted.",
          Email: "Email",
          Send: "Send",
          "All good.": "All good.",
          "We've sent you a link to reset your password. Just follow the instructions in the mail and you're good to go!":
            "We've sent you a link to reset your password. Just follow the instructions in the mail and you're good to go!",
          "Okay!": "Okay!",
          Password: "Password",
          "Wrong password": "Wrong password",
          "Old Password": "Old Password",
          "New Password": "New Password",
          "Forgot your password?": "Forgot your password?",
          "Let's reset.": "Let's reset.",
          "Confirm New Password": "Confirm New Password",
          "Email successfully updated!": "Email successfully updated!",
          "New Email": "New Email",
          "Confirm New Email": "Confirm New Email",
          "Are you sure?": "Are you sure?",
          "All settings and local user data will be removed.":
            "All settings and local user data will be removed.",
          "No account?": "No account?",
          "Reset app": "Reset app",
          "Log in": "Log in",
          "Success! You can now log into your account with your new password.":
            "Success! You can now log into your account with your new password.",
          "Your request is expired or your password is too weak. Please try again.":
            "Your request is expired or your password is too weak. Please try again.",
          "Enter a new password to reset the password on your account":
            "Enter a new password to reset the password on your account",
          "Reset password": "Reset password",
          //Delete Dialog
          "Are you sure you want to delete": "Are you sure you want to delete",
          "Are you sure you want to delete your account?":
            "Are you sure you want to delete your account?",
          delete2: "?",
          "Yes, I'm sure.": "Reset app",
          Nevermind: "Cancel",
          //Invite Dialog
          "Something went wrong. Invitation could not be accepted.":
            "Something went wrong. Invitation could not be accepted.",
          "You accepted the invitation!": "You accepted the invitation!",
          "Something went wrong.": "Something went wrong.",
          " has invited you to their household ":
            " has invited you to their household ",
          inviteMessage2: ".",
          Join: "Join",
          Decline: "Decline",
          //THEME: Months
          January: "January",
          February: "February",
          March: "March",
          April: "April",
          May: "May",
          June: "June",
          July: "July",
          August: "August",
          September: "September",
          October: "October",
          November: "November",
          December: "December",
          //THEME: Blocks/Categories
          "Fixed Expenses": "Fixed Expenses",
          Living: "Living",
          Fun: "Fun",
          Other: "Other",
          Savings: "Savings",
          Rent: "Rent",
          Utilities: "Utilities",
          Internet: "Internet",
          Phone: "Phone",
          Subscription: "Subscriptions",
          Groceries: "Groceries",
          Household: "Household",
          "Health & Cosmetics": "Health & Cosmetics",
          Clothing: "Clothing",
          Transport: "Transport",
          "Eating Out": "Eating Out",
          Entertainment: "Entertainment",
          Vacation: "Vacation",
          "Unexpected Expenses": "Unexpected Expenses",
          Gifts: "Gifts",
          Purchases: "Purchases",
          "Emergency Fund": "Emergency Fund",
          "Savings Fund": "Savings Fund",
          //IMG ALT TEXTE
          "illustration of a shopping display":
            "illustration of a shopping display",
          "girl filling a piggy bank": "girl filling a piggy bank",
          "group of people using their devices together":
            "group of people using their devices together",
          "illustration of a meditating girl":
            "illustration of a meditating girl",
          "illustration of an open gift box":
            "illustration of an open gift box",
          "image of a chart": "image of a chart",
          //Privacy
          PrivacyPolicyHeader: "Privacy Policy",
          "Last updated:": "Last updated:",
          "Vanilla Noir Ltd (“us”, “we”, or “our”) operates the Balancey mobile and web application (the “App”). We are committed to ensuring that your personal information is protected and never misused. This policy informs you about what personal information we collect, why we collect it, how we use it, and what data protection rights you have.":
            "Vanilla Noir Ltd (“us”, “we”, or “our”) operates the Balancey mobile and web application (the “App”). We are committed to ensuring that your personal information is protected and never misused. This policy informs you about what personal information we collect, why we collect it, how we use it, and what data protection rights you have.",
          "Information Collection and Use": "Information Collection and Use",
          "We collect several types of information from and about users of our App, including:":
            "We collect several types of information from and about users of our App, including:",
          "Personal Information: You can use the App without an account, but if you choose to create one, we will collect personal information such as your email address, and other information you provide when you register. This information is only used to authenticate you as a user.":
            "Personal Information: You can use the App without an account, but if you choose to create one, we will collect personal information such as your email address, and other information you provide when you register. This information is only used to authenticate you as a user.",
          "Performance Data: We may collect anonymized data to improve our services. This information may include the nature of your device, the pages of our App that you visit, the time and date of your visit, the time spent on those pages, and other statistics (such as taps, clicks, scrolling information, etc.). These data are fully anonymized and aggregated and thus not linked to any particular user. The application may also collect crashes and performance reports so we can improve the stability and performance of our app.":
            "Performance Data: We may collect anonymized data to improve our services. This information may include the nature of your device, the pages of our App that you visit, the time and date of your visit, the time spent on those pages, and other statistics (such as taps, clicks, scrolling information, etc.). These data are fully anonymized and aggregated and thus not linked to any particular user. The application may also collect crashes and performance reports so we can improve the stability and performance of our App.",
          "Financial Data: Your spending and budgeting data is stored locally, but you can choose to connect an account to synchronize it with our Firebase Database (Google Inc.), where it will be stored separately from your personal data or device data. We store your data to provide the opportunity for you to use it on any device through the App. We do not use this data in any way other than as a backup for your personal use. We separate personal data from app usage data to protect your privacy.":
            "Financial Data: Your spending and budgeting data is stored locally, but you can choose to connect an account to synchronize it with our Firebase Database (Google Inc.), where it will be stored separately from your personal data or device data. We store your data to provide the opportunity for you to use it on any device through the App. We do not use this data in any way other than as a backup for your personal use. We separate personal data from app usage data to protect your privacy.",
          "Log and Usage Data: We automatically collect certain information when you visit, use, or navigate the App. This information does not reveal your identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our App, and other technical information. This information is primarily needed to maintain the security and operation of our App, and for our internal analytics and reporting purposes.":
            "Log and Usage Data: We automatically collect certain information when you visit, use, or navigate the App. This information does not reveal your identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our App, and other technical information. This information is primarily needed to maintain the security and operation of our App, and for our internal analytics and reporting purposes.",
          "Sharing of Information": "Sharing of Information",
          "We may share non-personal information we collect with third-party service providers that perform services on our behalf, such as hosting services, analytics providers, and crash analytics providers. We also need to share some personal data with the following third party solution to make sure the App is working as intended:":
            "We may share non-personal information we collect with third-party service providers that perform services on our behalf, such as hosting services, analytics providers, and crash analytics providers. We also need to share some personal data with the following third party solution to make sure the App is working as intended:",
          "Firebase Authentication (Google Inc.):  This service helps us to provide you a secure connection to our systems and identify you as the owner of your data.":
            "Firebase Authentication (Google Inc.):  This service helps us to provide you a secure connection to our systems and identify you as the owner of your data.",
          "Firebase Firestore (Google Inc.): This service provides cloud functionalities to securely store your usage data and make it available for you across all versions of our App.":
            "Firebase Firestore (Google Inc.): This service provides cloud functionalities to securely store your usage data and make it available for you across all versions of our App.",
          "These third-party service providers are only granted access to your personal information, as we deem necessary, and they are contractually restricted in the ways they may use your information.":
            "These third-party service providers are only granted access to your personal information, as we deem necessary, and they are contractually restricted in the ways they may use your information.",
          'If you decide to make use of our household sharing functionality, only expenses explicitly marked by you as "shared expenses" will be shared between the members of your shared household. Your budget will never be shared with other users, even if they share a household with you.':
            'If you decide to make use of our household sharing functionality, only expenses explicitly marked by you as "shared expenses" will be shared between the members of your shared household. Your budget will never be shared with other users, even if they share a household with you.',
          "We may share some of your information with third parties as required by law or to protect our legal rights. We do not sell or rent your personal information to third parties.":
            "We may share some of your information with third parties as required by law or to protect our legal rights. We do not sell or rent your personal information to third parties.",
          "Data Retention": "Data Retention",
          "We retain personal information for as long as necessary to provide our services, fulfill the purposes outlined in this Privacy Policy, or as required by law.":
            "We retain personal information for as long as necessary to provide our services, fulfill the purposes outlined in this Privacy Policy, or as required by law.",
          "Legal Basis for Processing Data": "Legal Basis for Processing Data",
          "We process personal data based on various legal grounds, including:":
            "We process personal data based on various legal grounds, including:",
          "Consent: When you provide explicit consent for specific processing activities.":
            "Consent: When you provide explicit consent for specific processing activities.",
          "Contractual Necessity: To fulfill contractual obligations with you.":
            "Contractual Necessity: To fulfill contractual obligations with you.",
          "Legal Obligations: To comply with legal or regulatory obligations.":
            "Legal Obligations: To comply with legal or regulatory obligations.",
          "Legitimate Interests: Pursuant to legitimate interests, balancing your rights and freedoms.":
            "Legitimate Interests: Pursuant to legitimate interests, balancing your rights and freedoms.",
          "Your Choices": "Your Choices",
          "You may choose not to provide us with certain information, but this may limit your ability to use certain features of the App.":
            "You may choose not to provide us with certain information, but this may limit your ability to use certain features of the App.",
          "You may also choose to opt-out of certain types of communications from us, such as promotional emails or push notifications. You may opt-out by following the unsubscribe instructions provided in the communication or by contacting us at info@vanilla-noir.com.":
            "You may also choose to opt-out of certain types of communications from us, such as promotional emails or push notifications. You may opt-out by following the unsubscribe instructions provided in the communication or by contacting us at info@vanilla-noir.com.",
          "You Rights": "Your Rights",
          "In certain regions like the EEA, UK, and Canada, you hold certain rights in accordance with relevant data protection laws. These may include the ability to (i) request access to and receive a copy of your personal information, (ii) request corrections or deletion of your data, (iii) control the processing of your personal information in certain situations, and (iv) where applicable, request data portability. Under specific circumstances, you may also have the right to contest the processing of your personal data. Should you wish to exercise these rights, please contact us using the provided details in the “Contact Us” section below.":
            "In certain regions like the EEA, UK, and Canada, you hold certain rights in accordance with relevant data protection laws. These may include the ability to (i) request access to and receive a copy of your personal information, (ii) request corrections or deletion of your data, (iii) control the processing of your personal information in certain situations, and (iv) where applicable, request data portability. Under specific circumstances, you may also have the right to contest the processing of your personal data. Should you wish to exercise these rights, please contact us using the provided details in the “Contact Us” section below.",
          Security: "Security",
          "We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee the security of your information.":
            "We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee the security of your information.",
          "Changes to this Privacy Policy": "Changes to this Privacy Policy",
          "We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.":
            "We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.",
          "Contact Us": "Contact Us",
          "If you have any questions or concerns about this Privacy Policy or our practices, please contact us at info@vanilla-noir.com.":
            "If you have any questions or concerns about this Privacy Policy or our practices, please contact us at info@vanilla-noir.com.",
          //Terms
          "Terms and Conditions": "Terms and Conditions",
          "The following terms and conditions are standard clauses about software products. We still advise you to carefully read these before using the app.":
            "The following terms and conditions are standard clauses about software products. We still advise you to carefully read these before using the app.",
          "By using Balancey (herein referred to as the “Software”), you agree to the terms and conditions set forth in this End-User License Agreement (“EULA”). This agreement is between you and Vanilla Noir Ltd (herein referred to as the “Owner”, “we”, “us”, or “our”), and not Apple, Inc. or any other party involved in the distribution of the Software. The Owner, not Apple or any other party involved in the distribution of the Software, is solely responsible for the Software and the content thereof. It is essential that you read and understand the terms contained in this EULA before installing or using the Software.":
            "By using Balancey (herein referred to as the “Software”), you agree to the terms and conditions set forth in this End-User License Agreement (“EULA”). This agreement is between you and Vanilla Noir Ltd (herein referred to as the “Owner”, “we”, “us”, or “our”), and not Apple, Inc. or any other party involved in the distribution of the Software. The Owner, not Apple or any other party involved in the distribution of the Software, is solely responsible for the Software and the content thereof. It is essential that you read and understand the terms contained in this EULA before installing or using the Software.",
          "You agree to comply with all applicable third party terms of agreement when using the Software. You agree that Apple, Inc. and its subsidiaries are third party beneficiaries of this License Agreement and that, upon your acceptance of the terms and conditions of this License Agreement, Apple, Inc. will have the right (and will be deemed to have accepted the right) to enforce the License Agreement against you as a third party beneficiary thereof.":
            "You agree to comply with all applicable third party terms of agreement when using the Software. You agree that Apple, Inc. and its subsidiaries are third party beneficiaries of this License Agreement and that, upon your acceptance of the terms and conditions of this License Agreement, Apple, Inc. will have the right (and will be deemed to have accepted the right) to enforce the License Agreement against you as a third party beneficiary thereof.",
          "1. LICENSE": "1. LICENSE",
          "The Owner grants you a non-exclusive, non-transferable, non-sublicensable, limited right and license to use one copy of the Software for your personal, non-commercial use on a single device that you own or control. The rights granted herein are subject to your compliance with this EULA. The Software is being licensed to you, and you hereby acknowledge that no title or ownership in the Software is being transferred or assigned, and this EULA is not to be construed as a sale of any rights in the Software.":
            "The Owner grants you a non-exclusive, non-transferable, non-sublicensable, limited right and license to use one copy of the Software for your personal, non-commercial use on a single device that you own or control. The rights granted herein are subject to your compliance with this EULA. The Software is being licensed to you, and you hereby acknowledge that no title or ownership in the Software is being transferred or assigned, and this EULA is not to be construed as a sale of any rights in the Software.",
          "2. RESTRICTIONS OF USE": "2. RESTRICTIONS OF USE",
          "Unless the Owner has authorized you to distribute the Software, you shall not make or distribute copies of the Software or transfer the Software from one device to another. You shall not decompile, reverse engineer, disassemble, include in other software, translate the Software, or use the Software for any commercial purposes. You shall not modify, alter, change or otherwise make any modification to the Software or create derivative works based upon the Software. You shall not rent, lease, resell, sub-license, assign, distribute or otherwise transfer the Software or this license. Any attempt to do so shall be void and of no effect.":
            "Unless the Owner has authorized you to distribute the Software, you shall not make or distribute copies of the Software or transfer the Software from one device to another. You shall not decompile, reverse engineer, disassemble, include in other software, translate the Software, or use the Software for any commercial purposes. You shall not modify, alter, change or otherwise make any modification to the Software or create derivative works based upon the Software. You shall not rent, lease, resell, sub-license, assign, distribute or otherwise transfer the Software or this license. Any attempt to do so shall be void and of no effect.",
          "3. COPYRIGHT": "3. COPYRIGHT",
          "You acknowledge that no title to the intellectual property in the Software is transferred to you. You further acknowledge that title and full ownership rights to the Software will remain the exclusive property of the Owner, and you will not acquire any rights to the Software. You shall not remove or obscure the Owner's copyright, trademark or other proprietary notices from any of the materials contained in this package or downloaded together with the Software.":
            "You acknowledge that no title to the intellectual property in the Software is transferred to you. You further acknowledge that title and full ownership rights to the Software will remain the exclusive property of the Owner, and you will not acquire any rights to the Software. You shall not remove or obscure the Owner's copyright, trademark or other proprietary notices from any of the materials contained in this package or downloaded together with the Software.",
          "In the event of any third party claim that the Software or your possession and use of that Software infringes that third party’s intellectual property rights, the Owner, not Apple, Inc. or other parties involved in the distribution of the Software, reserves the right to investigate, defend, settle, and discharge of any such intellectual property infringement claim.":
            "In the event of any third party claim that the Software or your possession and use of that Software infringes that third party’s intellectual property rights, the Owner, not Apple, Inc. or other parties involved in the distribution of the Software, reserves the right to investigate, defend, settle, and discharge of any such intellectual property infringement claim.",
          "4. DISCLAIMER OF WARRANTY": "4. DISCLAIMER OF WARRANTY",
          "The Software is provided “AS IS”, without warranty of any kind. The Owner disclaims and make no express or implied warranties and specifically disclaim the warranties of merchantability, fitness for a particular purpose and non-infringement of third-party rights, both for itself and on behalf of Apple, Inc. and each of their respective licensors and affiliates and all other parties involved in the distribution of the Software. The entire risk as to the quality and performance of the Software is with you. We do not warrant that the functions contained in the Software will meet your requirements or that the operation of the Software will be error-free.":
            "The Software is provided “AS IS”, without warranty of any kind. The Owner disclaims and make no express or implied warranties and specifically disclaim the warranties of merchantability, fitness for a particular purpose and non-infringement of third-party rights, both for itself and on behalf of Apple, Inc. and each of their respective licensors and affiliates and all other parties involved in the distribution of the Software. The entire risk as to the quality and performance of the Software is with you. We do not warrant that the functions contained in the Software will meet your requirements or that the operation of the Software will be error-free.",
          "5. LIMITATION OF LIABILITY": "5. LIMITATION OF LIABILITY",
          "In no event will the Owner, Apple, Inc. and their respective affiliates, or any other parties involved in the distribution of the Software be liable for special, incidental or consequential damages resulting from possession, access, use or malfunction of the Software, including but not limited to damages to property, loss of goodwill, computer or mobile device malfunction and, to the extent permitted by law, damages for personal injuries, property damage, lost profits or punitive damages from any causes of action arising out of or related to this EULA or the Software, whether arising in tort (including negligence), contract, strict liability or otherwise and whether or not the Owner has been advised of the possibility of such damages.":
            "In no event will the Owner, Apple, Inc. and their respective affiliates, or any other parties involved in the distribution of the Software be liable for special, incidental or consequential damages resulting from possession, access, use or malfunction of the Software, including but not limited to damages to property, loss of goodwill, computer or mobile device malfunction and, to the extent permitted by law, damages for personal injuries, property damage, lost profits or punitive damages from any causes of action arising out of or related to this EULA or the Software, whether arising in tort (including negligence), contract, strict liability or otherwise and whether or not the Owner has been advised of the possibility of such damages.",
          "Because some states/countries do not allow certain limitations of liability, this limitation of liability shall apply to the fullest extent permitted by law in the applicable jurisdiction. This limitation of liability shall not be applied solely to the extent that any specific provision of this limitation of liability is prohibited by any federal, state, or municipal law, which cannot be pre-empted. This EULA gives you specific legal rights, and you may have other rights that vary from jurisdiction to jurisdiction. In no event shall the Owner's liability for all damages (except as required by applicable law) exceed the actual price paid by you for use of the Software.":
            "Because some states/countries do not allow certain limitations of liability, this limitation of liability shall apply to the fullest extent permitted by law in the applicable jurisdiction. This limitation of liability shall not be applied solely to the extent that any specific provision of this limitation of liability is prohibited by any federal, state, or municipal law, which cannot be pre-empted. This EULA gives you specific legal rights, and you may have other rights that vary from jurisdiction to jurisdiction. In no event shall the Owner's liability for all damages (except as required by applicable law) exceed the actual price paid by you for use of the Software.",
          "6. PRIVACY POLICY": "6. PRIVACY POLICY",
          "Our Privacy Policy can be found on our website. We advise you to read it in its entirety.":
            "Our Privacy Policy can be found on our website. We advise you to read it in its entirety.",
          "7. INDEMNIFICATION": "7. INDEMNIFICATION",
          "By using the Software according to the terms of the EULA, you agree to indemnify, defend, and hold the Owner harmless from any and all damages, losses, and expenses directly or indirectly arising from your acts and omissions or your breach of this EULA.":
            "By using the Software according to the terms of the EULA, you agree to indemnify, defend, and hold the Owner harmless from any and all damages, losses, and expenses directly or indirectly arising from your acts and omissions or your breach of this EULA.",
          "8. UPDATES AND MODIFICATIONS": "8. UPDATES AND MODIFICATIONS",
          "The Owner may periodically release updates or modifications to the Software, which may include adding, modifying, or removing features or functionality. You may be required to accept and install these updates or modifications. You acknowledge and agree that Apple, Inc. has no obligation whatsoever to furnish any maintenance and support services with respect to the Software.":
            "The Owner may periodically release updates or modifications to the Software, which may include adding, modifying, or removing features or functionality. You may be required to accept and install these updates or modifications. You acknowledge and agree that Apple, Inc. has no obligation whatsoever to furnish any maintenance and support services with respect to the Software.",
          "9. FINANCIAL DISCLAIMER": "9. FINANCIAL DISCLAIMER",
          "The information provided by this app is for educational and informational purposes only and is not intended to be a substitute for professional financial advice. The app is designed to help you track your expenses and manage your budget, but it is important to remember that individual financial situations may vary. The app owner and its affiliates are not responsible for any financial decisions made based on information obtained from the app. Before making any financial decisions, it is recommended that you consult with a qualified financial advisor.":
            "The information provided by this app is for educational and informational purposes only and is not intended to be a substitute for professional financial advice. The app is designed to help you track your expenses and manage your budget, but it is important to remember that individual financial situations may vary. The app owner and its affiliates are not responsible for any financial decisions made based on information obtained from the app. Before making any financial decisions, it is recommended that you consult with a qualified financial advisor.",
          "10. SUBSCRIPTION TERMS AND PURCHASES":
            "10. SUBSCRIPTION TERMS AND PURCHASES",
          "There are various ways to access the Software, including the Free Version, Free Trial, and Paid Subscription. You can only access the Subscription Program while your subscription is active and in good standing. You can purchase a subscription to Balancey within the app, subject to the policies of the respective Application Marketplace Partners. Please note that purchases made through the Apple iTunes Store or our iPhone application are final, and refunds are not provided. Similarly, if you purchase a subscription through the Google Play store, the sale is final, and refunds are not provided. Balancey offers monthly and yearly subscription options, with a month defined as 30 calendar days and a year as 365 calendar days. Your monthly subscription will be automatically renewed each month until canceled, while your yearly subscription is paid for by a one-time upfront payment with automatic annual renewals.":
            "There are various ways to access the Software, including the Free Version, Free Trial, and Paid Subscription. You can only access the Subscription Program while your subscription is active and in good standing. You can purchase a subscription to Balancey within the app, subject to the policies of the respective Application Marketplace Partners. Please note that purchases made through the Apple iTunes Store or our iPhone application are final, and refunds are not provided. Similarly, if you purchase a subscription through the Google Play store, the sale is final, and refunds are not provided. Balancey offers monthly and yearly subscription options, with a month defined as 30 calendar days and a year as 365 calendar days. Your monthly subscription will be automatically renewed each month until canceled, while your yearly subscription is paid for by a one-time upfront payment with automatic annual renewals.",
          "11. CONTENT": "11. CONTENT",
          "During the Free Trial Period and refund period provided by our Application Marketplace Partners, users can evaluate the Content of our Product to determine if it meets their needs. If a user is dissatisfied with the Product during this period, they can cancel the Trial without incurring any fees. If a user continues using the Product without canceling the Trial, they will be responsible for purchasing the Product, and the Content will not be a reason for compensation. Please note that the scope of the Content might be specific to the platform or device on which the Software is being used. Users can cancel their Trial anytime by following the instructions provided in the Application Marketplace Partner platform.":
            "During the Free Trial Period and refund period provided by our Application Marketplace Partners, users can evaluate the Content of our Product to determine if it meets their needs. If a user is dissatisfied with the Product during this period, they can cancel the Trial without incurring any fees. If a user continues using the Product without canceling the Trial, they will be responsible for purchasing the Product, and the Content will not be a reason for compensation. Please note that the scope of the Content might be specific to the platform or device on which the Software is being used. Users can cancel their Trial anytime by following the instructions provided in the Application Marketplace Partner platform.",
          "12. LEGAL COMPLIANCE": "12. LEGAL COMPLIANCE",
          "You will comply with all applicable laws in connection with your use of the Software. You acknowledge that the Software may be subject to U.S. or foreign export and import laws or regulations. You will comply with all applicable import, export and re-export laws and regulations of the United States or foreign agencies or authorities. You represent and warrant that (i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.":
            "You will comply with all applicable laws in connection with your use of the Software. You acknowledge that the Software may be subject to U.S. or foreign export and import laws or regulations. You will comply with all applicable import, export and re-export laws and regulations of the United States or foreign agencies or authorities. You represent and warrant that (i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.",
          "13. CHANGES TO TERMS": "13. CHANGES TO TERMS",
          "We reserve the right to modify or replace these Terms at any time.":
            "We reserve the right to modify or replace these Terms at any time.",
          "14. CONTACT INFORMATION": "14. CONTACT INFORMATION",
          "Should you have any questions, complaints or claims with respect to the Software, please contact the Owner at:":
            "Should you have any questions, complaints or claims with respect to the Software, please contact the Owner at:",
          "Vanilla Noir Ltd, and not Apple, Inc. or any other parties involved in the distribution of the Software, is responsible for addressing claims relating to the Software and your possession and/or use of that Software.":
            "Vanilla Noir Ltd, and not Apple, Inc. or any other parties involved in the distribution of the Software, is responsible for addressing claims relating to the Software and your possession and/or use of that Software.",
          //Legal
          "Legal Notice": "Legal Notice",
          "Information in accordance with Section 5 TMG:":
            "Information in accordance with Section 5 TMG:",
          "Company Name: Vanilla Noir Ltd": "Company Name: Vanilla Noir Ltd",
          "Legal Form: Ltd": "Legal Form: Ltd",
          "We are registered in England & Wales at Companies House, Crown Way, Cardiff, CF14 3UZ under registration number 15244694.":
            "We are registered in England & Wales at Companies House, Crown Way, Cardiff, CF14 3UZ under registration number 15244694.",
          "Managing Director: Madeleine Frister, Maximilian Croissant":
            "Managing Director: Madeleine Frister, Maximilian Croissant",
          "Contact Information:": "Contact Information:",
          "Platform of the EU Commission for online dispute resolution: ":
            "Platform of the EU Commission for online dispute resolution: ",
          //FAQ
          "Frequently Asked Questions": "Frequently Asked Questions",
          //User Management
          "Email verified successfully. You can now sign in with your email and password.":
            "Email verified successfully. You can now sign in with your email and password.",
          "The verification code has expired. Please request a new verification email.":
            "The verification code has expired. Please request a new verification email.",
          "The verification code is invalid. Please check the code and try again.":
            "The verification code is invalid. Please try again.",
          "The user account associated with this email has been disabled.":
            "The user account associated with this email has been disabled.",
          "No user found corresponding to the verification code. The account may have been deleted.":
            "No user found corresponding to the verification code. The account may have been deleted.",
          "An unknown error occurred during email verification. Please try again.":
            "An unknown error occurred during email verification. Please try again.",
          "Something went wrong. Please reload the page.":
            "Something went wrong. Please reload the page.",
        },
      },
      de: {
        translation: {
          //Introduction
          "Let's find your financial balance.":
            "Deine finanzielle Balance ist zum Greifen nah.",
          "My Household": "Mein Haushalt",
          "What's your name?": "Was ist dein Name?",
          "What's your favourite currency?": "Was ist deine Lieblingswährung?",
          "Begin this journey": "Los gehts!",
          "Connect to existing account": "Existierendes Konto verbinden",
          //Overview
          Overview: "Übersicht",
          "Overview for": "Übersicht für",
          Income: "Einkommen",
          "Your budget is balanced!": "Dein Budget ist ausgeglichen!",
          "You are": "",
          "over budget!": "über deinem Budget!",
          "Left to Budget": "Noch offen",
          "Category Details": "Kategorie-Details",
          "Other Categories": "Andere Kategorien",
          "You've reached the limit for new blocks.":
            "Du hast das Limit für neue Blöcke erreicht.",
          Label: "Label",
          "You've reached the limit for new categories.":
            "Du hast das Limit für neue Kategorien erreicht.",
          "Let's start by creating a budget!":
            "Lass uns damit beginnen, ein Budget zu erstellen!",
          "Create Budget": "Budget erstellen",
          "Add Block": "Block hinzufügen",
          "Add Category": "Kategorie hinzufügen",
          "Delete Budget": "Budget löschen",
          "your budget for this month": "Dein Budget für diesen Monat",
          Created: "Erstellt",
          Planned: "Geplant",
          Spent: "Ausgegeben",
          Remaining: "Verbleibend",
          "Carryover from last month": "Übrig vom letzten Monat",
          "Delete Category": "Kategorie löschen",
          "Initial Balance": "Anfangsbilanz",
          "close category window": "Kategorie-Überblick schließen",
          "next month": "nächster Monat",
          "previous month": "vorheriger Monat",
          "next year": "nächstes Jahr",
          "previous year": "vorherhiges Jahr",
          "edit icon": "Icon bearbeiten",
          //Transactions
          Transactions: "Transaktionen",
          "Transactions in ": "Transaktionen in ",
          "Add Transaction": "Transaktion hinzufügen",
          "New Transaction": "Neue Transaktion",
          "Edit Transaction": "Transaktion bearbeiten",
          "Here could be all your transactions!":
            "Hier könnten all deine Transaktionen stehen!",
          "Something went wrong. Cannot update entries.":
            "Etwas ist schief gelaufen. Einträge können nicht aktualisiert werden.",
          "Create a budget to get started!":
            "Erstelle ein Budget, um loszulegen!",
          "Unknown Category": "Unbekannte Kategorie",
          "Warning: You don't have this category":
            "Warnung: Du hast diese Kategorie nicht",
          "out of": "von",
          Back: "Zurück",
          Expense: "Ausgaben",
          Payment: "Zahlung",
          Amount: "Betrag",
          Merchant: "Titel",
          Date: "Datum",
          Category: "Kategorie",
          "Undo Split": "Split rückgängig machen",
          "Payed by": "Bezahlt von",
          "Payed for": "Bezahlt für",
          "Price split for": "Preis aufteilen für",
          "Split Transaction": "Transaktion splitten",
          "Automatically create when making new budget":
            "Automatisch erstellen wenn du ein neues Budget machst",
          "Payment from": "Zahlung von",
          "Payed to": "Bezahlt an",
          Save: "Speichern",
          Delete: "Löschen",
          "this transaction": "diese Transaktion",
          From: "Von",
          //Insights
          Insights: "Statistiken",
          Monthly: "Monatlich",
          Yearly: "Jährlich",
          Split: "Split",
          "Shared Expenses": "Geteilte Ausgaben",
          "No shared expenses this month!":
            "Diesen Monat keine geteilten Ausgaben!",
          "Suggested Payments:": "Vorgeschlagene Zahlungen:",
          pays: "bezahlt",
          to: "an",
          "Actually, you're all set already!": "Alles ausgeglichen!",
          "and everybody should be happy!": "und alle sollten glücklich sein!",
          "Household Balance": "Haushaltsbilanz",
          "Settle Up": "Ausgleichen",
          "Nothing to see here.": "Hier gibt es nichts zu sehen.",
          Balance: "Balance",
          "Export CSV": "CSV exportieren",
          "Open Export Menu": "Export Menü öffnen",
          "Flow Diagram": "Flussdiagramm",
          Total: "Summe",
          Sum: "Summe",
          Saved: "Gespart",
          Average: "Durchschnitt",
          Expenses: "Ausgaben",
          "Overall shared expenses": "Übersicht geteilte Ausgaben",
          //Hints
          "Try to give yourself a budget that ressembles your average monthly spending for this category.":
            "Versuche, dir ein Budget zu geben, das deinem durchschnittlichen monatlichen Ausgaben für diese Kategorie entspricht.",
          "If you want to empty your category fund, you can plan a negative budget for this month.":
            "Wenn du Geld aus deinen Kategoriefonds nehmen möchtest, kannst du dir für den jeweiligen Monat ein negatives Budget einplanen.",
          "Give yourself a strict budget for the things you have to pay and plan more on things that give you happiness.":
            "Gib dir ein striktes Budget für die Dinge, die du bezahlen musst, und plane dir mehr Geld ein für Dinge, die dich glücklich machen.",
          "Try to first eliminate all debts on your roadmap to a peaceful financial life.":
            "Versuche zuerst, alle Schulden auszugleichen um ein friedliches finanzielles Leben zu führen.",
          "Don't be surprised by emergencies - keep at least $1000 in your emergency fund.":
            "Lass dich von Notfällen nicht überraschen - halte mindestens $1000 in deinem Notfallfond bereit.",
          "Mindful spending means connecting with what you spend money on. Manually keeping track is an important part of understanding your habits.":
            "Achtsames Ausgeben bedeutet, eine Verbindung zu dem eigenen Ausgebeverhalten aufzubauen. Manuelle Eingaben sind wichtig, um die eigenen Gewohnheiten zu verstehen.",
          "Every financial situation is different. Don't compare yourself to others. Improve habits you want improved.":
            "Jede finanzielle Situation ist anders. Vergleiche dich nicht mit anderen. Versuche die Gewohnheiten zu verbessern, die du verbessern möchtest.",
          "Do not give yourself strict saving goals. Plan all your income to lead a consistent life.":
            "Setz dir keine strikten Sparziele. Versuche lieber dein gesamtes Einkommen so zu planen, dass du ein konsistentes Leben führst.",
          "It's important to give your whole income a purpose. Leftover money can be used to pay off debts, invest, or adjust your funds.":
            "Es ist wichtig, deinem gesamten Einkommen einen Zweck zu geben. Übrig gebliebenes Geld kannst du nutzen, um Schulden abzuzahlen, zu investieren oder deine Fonds anzupassen.",
          "If you plan $10 a month for christmas gifts, you will have $120 on christmas with no additional financial burden.":
            "Wenn du $10 pro Monat für Weihnachtsgeschenke einplanst, hast du zu Weihnachten $120 ohne zusätzliche finanzielle Belastung.",
          "For some categories it makes sense to adjust your planned budgets at the end of a month to reflect your spending.":
            "Manchmal macht es Sinn, deine geplanten Budgets am Ende eines Monats anzupassen, um deine Ausgaben widerzuspiegeln.",
          "Try to consistently give yourself money for big purchases, even if these are far in the future.":
            "Versuche, dir konsequent Geld für große Einkäufe zu geben, auch wenn die weit in der Zukunft liegen.",
          //Settings
          Settings: "Einstellungen",
          General: "Allgemein",
          Name: "Name",
          Role: "Rolle",
          Currency: "Währung",
          "Household Members": "Mitglieder im Haushalt",
          "Invite New Member": "Neues Mitglied einladen",
          Language: "Sprache",
          "Dark Mode": "Dark Mode",
          App: "Anwendung",
          Version: "Version",
          Copyright: "Copyright",
          Account: "Konto",
          Status: "Status",
          Connected: "Verbunden",
          "Not connected": "Nicht verbunden",
          "Change Email": "E-Mail ändern",
          "Change Password": "Passwort ändern",
          "Delete Account": "Konto löschen",
          "Create New Account": "Neues Konto erstellen",
          "Connect Existing Account": "Bestehendes Konto verbinden",
          Confirm: "Bestätigen",
          "Please enter your password to confirm.":
            "Bitte geben Sie Ihr Passwort zur Bestätigung ein.",
          "Connect existing account": "Bestehendes Konto verbinden",
          "Warning: This will override your local save files with the existing cloud files.":
            "Warnung: Deine lokalen Speicherdateien werden mit den vorhandenen Cloud-Dateien überschrieben.",
          "Create a new account": "Ein neues Konto erstellen",
          "Create and connect a new account. This will sync your local files to the cloud.":
            "Erstelle ein neues Konto! Dadurch werden deine lokalen Dateien mit der Cloud synchronisiert.",
          "Create Account": "Konto erstellen",
          "Invite a user to your household!":
            "Lade einen Nutzer zu deinem Haushalt ein!",
          "Their email address": "Ihre E-Mail-Adresse",
          "Something went wrong. Please try again later.":
            "Etwas ist schiefgelaufen. Bitte versuche es später erneut.",
          "Successfully sent invitation!": "Einladung erfolgreich gesendet!",
          "Successfully updated password!":
            "Passwort erfolgreich aktualisiert!",
          "Let's make some changes!": "Zeit für Veränderungen!",
          "Something went wrong. Make sure both passwords are the same and longer than 4 characters.":
            "Etwas ist schiefgelaufen. Stelle sicher, dass beide Passwörter identisch sind und länger als 4 Zeichen sind.",
          "Something went wrong. Make sure both emails are the same.":
            "Etwas ist schiefgelaufen. Stelle sicher, dass beide E-Mail-Adressen identisch sind.",
          "Log Out": "Ausloggen",
          "Wrong email or password.": "Falsche E-mail oder falsches Passwort.",
          "Something went wrong. Please check your email and password.":
            "Etwas ist schiefgelaufen. Bitte überprüfe deine Email und dein Passwort.",
          "Testing Version": "Beta-Test",
          "All features for testing purposes": "Alle Features zum testen",
          Verified: "Verifiziert",
          "Not verified": "Nicht verifiziert",
          "Send Verification Mail": "Verifizierungs-Email senden",
          "Verification email sent.": "Verifizierungs-Email gesendet.",
          "If you live in a shared household, you can create shared expenses, while every user retains an individual budget. Individual expenses will remain private and only visible to you.":
            "Wenn du in einem gemeinsamen Haushalt lebst, kannst du geteilte Ausgaben erstellen, während jeder Nutzer ein eigenes Budget behält. Individuelle Ausgaben bleiben privat und nur für dich sichtbar.",
          "Create a free account to be able to use Balancey across your devices and on the web. Some functionalities (like sharing households) are only possible with an account.":
            "Erstelle ein kostenloses Konto, um Balancey auf all deinen Geräten und im Internet zu nutzen. Einige Funktionen (wie das Teilen von Haushalten) sind nur mit einem Konto möglich.",
          "Privacy Policy": "Privatsphäre",
          "Terms & Conditions": "Nutzungsbedingungen",
          //SUBSCRIPTION AND AD
          "Share Expenses!": "Teile deine Haushaltsausgaben!",
          "Connect with your partner or your roommates and track your shared expenses, while everybody keeps their own budget.":
            "Verbinde dich mit deinem Partner oder deinen Mitbewohnern und trackt eure gemeinsamen Ausgaben, wobei jeder ein eigenes Budget behält. Natürlich kann jeder auch weiterhin private Ausgaben tracken, die für andere geheim bleiben.",
          "Create an account!": "Create an account!",
          "Get started!": "Premium starten",
          "Get Yearly Insights!": "Hol dir deine jährlichen Statistiken!",
          "More flexibility, more insights, more financial peace":
            "Mehr Flexibilität, bessere Statistiken, finanzieller Frieden",
          "Premium Features": "Premium-Funktionen",
          Premium: "Premium",
          "Good News!": "Gute Neuigkeiten!",
          "As a member of the premium test program, you have access to all current premium features with no extra charge. Please be aware that you will lose access to the features described here when the test phase is over. You will never lose access to your budgets or transactions.":
            "Als Mitglied des Beta-Testprogramms hast du Zugang zu allen aktuellen Premium-Funktionen ohne zusätzliche Kosten. Bitte beachte, dass du den Zugang zu den hier beschriebenen Funktionen verlieren kannst, wenn die Testphase abgeschlossen ist. Du wirst niemals den Zugang zu deinen Budgets oder Transaktionen verlieren.",
          "More Icons": "Mehr Symbole",
          "Spending Insights": "Mehr Statistiken",
          "Data Exports": "Datenexporte",
          "Split Insights": "Split-Details",
          "No Ads": "Keine Werbung",
          "Full Web Access": "Voller Webzugriff",
          "Eternal Gratitude": "Ewige Dankbarkeit",
          "Alright!": "Alles klar!",
          "Available in Premium": "In Premium verfügbar",
          "Learn more": "Mehr erfahren",
          "Try Premium": "Probier Premium",
          "No ads, better insights, shared households.":
            "Keine Werbung, bessere Statistiken, geteilte Haushalte.",
          "Free Member": "Kostenloses Mitglied",
          "Premium Member": "Premium Mitglied",
          "All Features": "Alle Features",
          "Basic Feautures": "Basic Features",
          MONTHLY: "MONATLICH",
          ANNUAL: "JÄHRLICH",
          "/mo": "/mo",
          "billed monthly": "monatlich abgebucht",
          "billed annually at": "abgebucht als",
          billedyearly2: "pro Jahr",
          "Recurring billing. Cancel anytime.":
            "Wiederkehrende Abrechnung. Jederzeit kündbar.",
          "Why a subscription?": "Wieso ein Abo?",
          "We're a two people team who made this app to be more mindful about our spending. The subscription let's us keep working on our software, pay the servers and app stores that we wouldn't otherwise be able to afford.":
            "Wir sind zwei Entwickler, die diese App in unserer Freizeit gemacht haben, damit wir achtsamer mit unserem Geld umgehen können. Das Abonnement ermöglicht es uns weiter an der Software zu arbeiten, sie zu teilen und die Server und App-Stores zu bezahlen, die wir uns sonst nicht leisten könnten.",
          "Restore Purchases": "Käufe wiederherstellen",
          "Start Free Trial": "Testversion starten",
          "Try 7 days, then": "7 Tage testen, dann",
          "per year": "im Jahr",
          "You are a premium member!": "Du bist ein Premium-Member!",
          "Your Premium Features": "Deine Premium-Features",
          Plan: "Plan",
          "Something went wrong. Please manage your subscription on the device you originally purchased it on.":
            "Etwas ist schiefgelaufen. Versuche dein Abonnement auf dem Gerät zu verwalten, auf dem du es abgeschlossen hast.",
          "illustration of a happy girl":
            "Illustration eines fröhlichen Mädchens",
          "Gifted Subscription": "Geschenktes Abonnement",
          "Payed Subscription": "Bezahltes Abonnement",
          "Subscription Ends": "Abo endet",
          "Never 😊": "Nie 😊",
          "Next Payment": "Nächste Abbuchung",
          "Subscription Settings": "Abonnement verwalten",
          "To change your subscription settings, please visit your mobile app!":
            "Bitte besuche deine Mobile-App, um deine Abonnement-Einstellungen zu verwalten!",
          "Get the App to opt in!": "Hol dir die App und leg los!",
          //Icon Select
          "Icon Select": "Icon auswählen",
          "Close Window": "Fenster schließen",
          Housing: "Wohnen",
          Travel: "Reisen",
          //Auth Dialog
          "Sign In": "Anmelden",
          "Welcome back!": "Willkommen zurück!",
          "Let's reset your password.": "Passwort zurücksetzen",
          "Enter your mail and we'll get this sorted.":
            "Gib deine E-Mail-Adresse ein und wir kümmern uns darum.",
          Email: "E-Mail",
          Send: "Senden",
          "All good.": "Alles gut.",
          "We've sent you a link to reset your password. Just follow the instructions in the mail and you're good to go!":
            "Wir haben dir einen Link zum Zurücksetzen deines Passworts geschickt. Folge einfach den Anweisungen in der E-Mail um ein neues Passwort zu setzen.",
          "Okay!": "Okay!",
          Password: "Passwort",
          "Old Password": "Altes Passwort",
          "New Password": "Neues Passwort",
          "Wrong password": "Falsches Passwort",
          "Forgot your password?": "Passwort vergessen?",
          "Let's reset.": "Zurücksetzen.",
          "Confirm New Password": "Neues Passwort bestätigen",
          "Email successfully updated!":
            "E-Mail-Adresse erfolgreich aktualisiert!",
          "New Email": "Neue E-Mail-Adresse",
          "Confirm New Email": "Neue E-Mail-Adresse bestätigen",
          "Are you sure?": "Bist du sicher?",
          "All settings and local user data will be removed.":
            "Alle Einstellungen und lokalen Nutzerdaten werden dadurch gelöscht.",
          "No account?": "Kein Nutzerkonto?",
          "Reset app": "App zurücksetzen",
          "Log in": "Einloggen",
          "Success! You can now log into your account with your new password.":
            "Passwort erfolgreich zurückgesetzt! Du kannst dich jetzt mit deinem neuen Passwort einloggen.",
          "Your request is expired or your password is too weak. Please try again.":
            "Deine Anfrage ist abgelaufen oder dein Passwort ist zu schwach. Bitte versuche es erneut.",
          "Enter a new password to reset the password on your account":
            "Gib ein neues Passwort ein, um das Passwort für deinen Account zurückzusetzen.",
          "Reset password": "Passwort zurücksetzen",

          //Delete Dialog
          "Are you sure you want to delete": "Bist du sicher, dass du ",
          "Are you sure you want to delete your account?":
            "Bist du sicher, dass du deinen Account löschen möchtest?",
          delete2: "löschen möchtest?",
          "Yes, I'm sure.": "Ja, ich bin sicher.",
          Nevermind: "Lieber doch nicht",
          //Invite Dialog
          "Something went wrong. Invitation could not be accepted.":
            "Etwas ist schiefgelaufen. Einladung konnte nicht akzeptiert werden.",
          "You accepted the invitation!": "Du hast die Einladung angenommen!",
          "Something went wrong.": "Etwas ist schiefgelaufen.",
          " has invited you to their household ": " hat dich zu dem Haushalt ",
          inviteMessage2: " eingeladen.",
          Join: "Annehmen",
          Decline: "Ablehnen",
          //THEME: Months
          January: "Januar",
          February: "Februar",
          March: "März",
          April: "April",
          May: "Mai",
          June: "Juni",
          July: "Juli",
          August: "August",
          September: "September",
          October: "Oktober",
          November: "November",
          December: "Dezember",
          //THEME: Blocks/Categories
          "Fixed Expenses": "Feste Ausgaben",
          Living: "Leben",
          Fun: "Spaß",
          Other: "Sonstiges",
          Savings: "Ersparnisse",
          Rent: "Miete",
          Utilities: "Nebenkosten",
          Internet: "Internet",
          Phone: "Telefon",
          Subscription: "Abonnements",
          Groceries: "Lebensmittel",
          Household: "Haushalt",
          "Health & Cosmetics": "Gesundheit & Kosmetik",
          Clothing: "Kleidung",
          Transport: "Transport",
          "Eating Out": "Essen gehen",
          Entertainment: "Unterhaltung",
          Vacation: "Urlaub",
          "Unexpected Expenses": "Unvorhergesehene Ausgaben",
          Gifts: "Geschenke",
          Purchases: "Käufe",
          "Emergency Fund": "Notfallfonds",
          "Savings Fund": "Sparfonds",
          //IMG ALT TEXTS
          "illustration of a shopping display":
            "Illustration eines Shopping Displays",
          "girl filling a piggy bank": "Mädchen füllt eine Spardose",
          "group of people using their devices together":
            "Gruppe von Personen, die ihre Geräte gemeinsam nutzen",
          "illustration of a meditating girl":
            "Illustration eines meditierenden Mädchens",
          "illustration of an open gift box":
            "Illustration eines offenen Geschenks",
          "image of a chart": "Bild eines Graphen",

          //Privacy
          PrivacyPolicyHeader: "Datenschutzvereinbarung",
          "Last updated:": "Zuletzt aktualisiert:",
          "Vanilla Noir Ltd (“us”, “we”, or “our”) operates the Balancey mobile and web application (the “App”). We are committed to ensuring that your personal information is protected and never misused. This policy informs you about what personal information we collect, why we collect it, how we use it, and what data protection rights you have.":
            "Vanilla Noir („uns“, „wir“ oder „unser“) betreibt die mobile und Webanwendung Balancey (die „App“). Wir setzen uns dafür ein, sicherzustellen, dass Ihre persönlichen Informationen geschützt und niemals missbraucht werden. Diese Richtlinie informiert Sie darüber, welche personenbezogenen Informationen wir sammeln, warum wir sie sammeln, wie wir sie verwenden und welche Datenschutzrechte Sie haben.",
          "Information Collection and Use": "Informationssammlung und -nutzung",
          "We collect several types of information from and about users of our App, including:":
            "Wir sammeln mehrere Arten von Informationen von und über Benutzer unserer App, einschließlich:",
          "Personal Information: You can use the App without an account, but if you choose to create one, we will collect personal information such as your email address, and other information you provide when you register. This information is only used to authenticate you as a user.":
            "Persönliche Informationen: Sie können die App ohne ein Konto verwenden, aber wenn Sie sich dafür entscheiden, eines zu erstellen, sammeln wir persönliche Informationen wie Ihre E-Mail-Adresse und andere Informationen, die Sie bei der Registrierung angeben. Diese Informationen werden nur zur Authentifizierung Ihrer Identität als Benutzer verwendet.",
          "Performance Data: We may collect anonymized data to improve our services. This information may include the nature of your device, the pages of our App that you visit, the time and date of your visit, the time spent on those pages, and other statistics (such as taps, clicks, scrolling information, etc.). These data are fully anonymized and aggregated and thus not linked to any particular user. The application may also collect crashes and performance reports so we can improve the stability and performance of our app.":
            "Leistungsdaten: Wir können anonymisierte Daten sammeln, um unsere Dienste zu verbessern. Diese Informationen können die Art Ihres Geräts, die Seiten unserer App, die Sie besuchen, das Datum und die Uhrzeit Ihres Besuchs, die auf diesen Seiten verbrachte Zeit und andere Statistiken (wie Berührungen, Klicks, Scroll-Informationen usw.) umfassen. Diese Daten sind vollständig anonymisiert und aggregiert und somit nicht mit einem bestimmten Benutzer verknüpft. Die Anwendung kann auch Absturz- und Leistungsberichte sammeln, damit wir die Stabilität und Leistung unserer App verbessern können.",
          "Financial Data: Your spending and budgeting data is stored locally, but you can choose to connect an account to synchronize it with our Firebase Database (Google Inc.), where it will be stored separately from your personal data or device data. We store your data to provide the opportunity for you to use it on any device through the App. We do not use this data in any way other than as a backup for your personal use. We separate personal data from app usage data to protect your privacy.":
            "Finanzielle Daten: Ihre Ausgaben- und Budgetdaten werden lokal gespeichert, aber Sie können sich entscheiden, ein Konto zu verbinden, um es mit unserer Firebase-Datenbank (Google Inc.) zu synchronisieren, wo es separat von Ihren persönlichen Daten oder Gerätedaten gespeichert wird. Wir speichern Ihre Daten, um Ihnen die Möglichkeit zu geben, sie auf jedem Gerät über die App zu verwenden. Wir verwenden diese Daten nicht in anderer Weise als als Sicherung für Ihren persönlichen Gebrauch. Wir trennen persönliche Daten von App-Nutzungsdaten, um Ihre Privatsphäre zu schützen.",
          "Log and Usage Data: We automatically collect certain information when you visit, use, or navigate the App. This information does not reveal your identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our App, and other technical information. This information is primarily needed to maintain the security and operation of our App, and for our internal analytics and reporting purposes.":
            "Log- und Nutzungsdaten: Wir erfassen automatisch bestimmte Informationen, wenn Sie die App besuchen, nutzen oder darin navigieren. Diese Informationen geben keinen Aufschluss über Ihre Identität (wie z. B. Ihren Namen oder Ihre Kontaktdaten), können aber Geräte- und Nutzungsinformationen enthalten, wie z. B. Ihre IP-Adresse, Browser- und Geräteeigenschaften, Betriebssystem, Spracheinstellungen, verweisende URLs, Gerätename, Land, Standort, Informationen darüber, wie und wann Sie unsere App nutzen, und andere technische Informationen. Diese Informationen werden in erster Linie benötigt, um die Sicherheit und den Betrieb unserer App aufrechtzuerhalten sowie für unsere internen Analyse- und Berichtszwecke.",
          "Sharing of Information": "Weitergabe von Informationen",
          "We may share non-personal information we collect with third-party service providers that perform services on our behalf, such as hosting services, analytics providers, and crash analytics providers. We also need to share some personal data with the following third party solution to make sure the App is working as intended:":
            "Wir können nicht-personenbezogene Informationen, die wir sammeln, mit Drittanbieter-Dienstleistern teilen, die Dienste in unserem Auftrag erbringen, wie Hosting-Dienste, Analyse-Anbieter und Absturz-Analyse-Anbieter. Wir müssen auch einige persönliche Daten mit der folgenden Drittanbieter-Lösung teilen, um sicherzustellen, dass die App wie beabsichtigt funktioniert:",
          "Firebase Authentication (Google Inc.):  This service helps us to provide you a secure connection to our systems and identify you as the owner of your data.":
            "Firebase-Authentifizierung (Google Inc.): Dieser Dienst hilft uns, Ihnen eine sichere Verbindung zu unseren Systemen bereitzustellen und Sie als Eigentümer Ihrer Daten zu identifizieren.",
          "Firebase Firestore (Google Inc.): This service provides cloud functionalities to securely store your usage data and make it available for you across all versions of our App.":
            "Firebase Firestore (Google Inc.): Dieser Dienst bietet Cloud-Funktionen, um Ihre Nutzungsdaten sicher zu speichern und sie für Sie in allen Versionen unserer App verfügbar zu machen.",
          "These third-party service providers are only granted access to your personal information, as we deem necessary, and they are contractually restricted in the ways they may use your information.":
            "Diese Drittanbieter-Dienstleister erhalten nur Zugriff auf Ihre persönlichen Informationen, wie wir es für notwendig erachten, und sie sind vertraglich in den Möglichkeiten eingeschränkt, wie sie Ihre Informationen verwenden dürfen.",
          'If you decide to make use of our household sharing functionality, only expenses explicitly marked by you as "shared expenses" will be shared between the members of your shared household. Your budget will never be shared with other users, even if they share a household with you.':
            "Wenn Sie sich dafür entscheiden, unsere Funktionalität zur Haushaltsfreigabe zu nutzen, werden nur von Ihnen explizit als „gemeinsame Ausgaben“ markierte Ausgaben zwischen den Mitgliedern Ihres gemeinsamen Haushalts geteilt. Ihr Budget wird niemals mit anderen Benutzern geteilt, auch wenn sie einen Haushalt mit Ihnen teilen.",
          "We may share some of your information with third parties as required by law or to protect our legal rights. We do not sell or rent your personal information to third parties.":
            "Wir können einige Ihrer Informationen mit Dritten teilen, wie gesetzlich vorgeschrieben oder um unsere rechtlichen Rechte zu schützen. Wir verkaufen oder vermieten Ihre persönlichen Informationen nicht an Dritte.",
          "Data Retention": "Speicherung Ihrer Daten",
          "We retain personal information for as long as necessary to provide our services, fulfill the purposes outlined in this Privacy Policy, or as required by law.":
            "Wir speichern personenbezogene Informationen so lange, wie es zur Bereitstellung unserer Dienste erforderlich ist, um die in dieser Datenschutzrichtlinie festgelegten Zwecke zu erfüllen oder wie es gesetzlich vorgeschrieben ist.",
          "Legal Basis for Processing Data":
            "Rechtsgrundlage für die Datenverarbeitung",
          "We process personal data based on various legal grounds, including:":
            "Wir verarbeiten personenbezogene Daten auf der Grundlage verschiedener Rechtsgrundlagen, darunter:",
          "Consent: When you provide explicit consent for specific processing activities.":
            "Zustimmung: Wenn Sie Ihre ausdrückliche Zustimmung zu bestimmten Verarbeitungsaktivitäten geben.",
          "Contractual Necessity: To fulfill contractual obligations with you.":
            "Vertragliche Notwendigkeit: Um vertragliche Verpflichtungen mit Ihnen zu erfüllen.",
          "Legal Obligations: To comply with legal or regulatory obligations.":
            "Rechtliche Verpflichtungen: Zur Erfüllung gesetzlicher oder behördlicher Verpflichtungen.",
          "Legitimate Interests: Pursuant to legitimate interests, balancing your rights and freedoms.":
            "Legitime Interessen: Aufgrund von berechtigten Interessen, die Ihre Rechte und Freiheiten abwägen.",
          "Your Choices": "Ihre Wahlmöglichkeiten",
          "You may choose not to provide us with certain information, but this may limit your ability to use certain features of the App.":
            "Sie können sich entscheiden, uns bestimmte Informationen nicht zur Verfügung zu stellen, aber dies kann Ihre Fähigkeit zur Nutzung bestimmter Funktionen der App einschränken.",
          "You may also choose to opt-out of certain types of communications from us, such as promotional emails or push notifications. You may opt-out by following the unsubscribe instructions provided in the communication or by contacting us at info@vanilla-noir.com.":
            "Sie können sich auch dafür entscheiden, bestimmte Arten von Mitteilungen von uns abzulehnen, wie z. B. Werbe-E-Mails oder Push-Benachrichtigungen. Sie können sich abmelden, indem Sie den in der Mitteilung angegebenen Abmeldeanweisungen folgen oder uns unter info@vanilla-noir.com kontaktieren.",
          "Your Rights": "Ihre Rechte",
          "In certain regions like the EEA, UK, and Canada, you hold certain rights in accordance with relevant data protection laws. These may include the ability to (i) request access to and receive a copy of your personal information, (ii) request corrections or deletion of your data, (iii) control the processing of your personal information in certain situations, and (iv) where applicable, request data portability. Under specific circumstances, you may also have the right to contest the processing of your personal data. Should you wish to exercise these rights, please contact us using the provided details in the “Contact Us” section below.":
            "In bestimmten Regionen wie dem EWR, dem Vereinigten Königreich und Kanada haben Sie gemäß den einschlägigen Datenschutzgesetzen bestimmte Rechte. Dazu gehört u. a. die Möglichkeit, (i) Zugang zu Ihren personenbezogenen Daten zu verlangen und eine Kopie davon zu erhalten, (ii) Korrekturen oder die Löschung Ihrer Daten zu verlangen, (iii) die Verarbeitung Ihrer personenbezogenen Daten in bestimmten Situationen zu kontrollieren und (iv) gegebenenfalls Datenübertragbarkeit zu verlangen. Unter bestimmten Umständen können Sie auch das Recht haben, die Verarbeitung Ihrer personenbezogenen Daten anzufechten. Wenn Sie diese Rechte ausüben möchten, wenden Sie sich bitte an uns, indem Sie die Angaben im Abschnitt “Kontakt” weiter unten verwenden.",
          Security: "Sicherheit",
          "We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee the security of your information.":
            "Wir ergreifen angemessene Maßnahmen, um Ihre persönlichen Informationen vor unbefugtem Zugriff, Verwendung oder Offenlegung zu schützen. Keine Übertragungsmethode über das Internet oder elektronische Speicherung ist jedoch vollständig sicher, und wir können die Sicherheit Ihrer Informationen nicht garantieren.",
          "Changes to this Privacy Policy":
            "Änderungen dieser Datenschutzrichtlinie",
          "We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.":
            "Wir können diese Datenschutzrichtlinie von Zeit zu Zeit aktualisieren. Wir werden Sie über etwaige Änderungen informieren, indem wir die neue Datenschutzrichtlinie auf dieser Seite veröffentlichen.",
          "Contact Us": "Kontaktieren Sie uns",
          "If you have any questions or concerns about this Privacy Policy or our practices, please contact us at info@vanilla-noir.com.":
            "Wenn Sie Fragen oder Bedenken bezüglich dieser Datenschutzrichtlinie oder unserer Praktiken haben, kontaktieren Sie uns bitte unter info@vanilla-noir.com.",
          //Terms
          "Terms and Conditions": "Allgemeine Geschäftsbedingungen",
          "The following terms and conditions are standard clauses about software products. We still advise you to carefully read these before using the app.":
            "Die folgenden Allgemeinen Geschäftsbedingungen sind Standardklauseln für Softwareprodukte. Wir empfehlen Ihnen dringend, diese vor der Nutzung der App sorgfältig zu lesen.",
          "By using Balancey (herein referred to as the “Software”), you agree to the terms and conditions set forth in this End-User License Agreement (“EULA”). This agreement is between you and Vanilla Noir Ltd (herein referred to as the “Owner”, “we”, “us”, or “our”), and not Apple, Inc. or any other party involved in the distribution of the Software. The Owner, not Apple or any other party involved in the distribution of the Software, is solely responsible for the Software and the content thereof. It is essential that you read and understand the terms contained in this EULA before installing or using the Software.":
            "Durch die Nutzung von Balancey (im Folgenden als “Software” bezeichnet) erklären Sie sich mit den in diesem Endbenutzer-Lizenzvertrag (“EULA”) dargelegten Bedingungen und Konditionen einverstanden. Diese Vereinbarung gilt zwischen Ihnen und Vanilla Noir Ltd (im Folgenden als “Eigentümer”, “wir”, “uns” oder “unser” bezeichnet), und nicht Apple, Inc. oder einer anderen Partei, die an der Verteilung der Software beteiligt ist. Der Eigentümer und nicht Apple oder eine andere Partei, die an der Verteilung der Software beteiligt ist, ist allein für die Software und deren Inhalt verantwortlich. Es ist unerlässlich, dass Sie die in dieser EULA enthaltenen Bedingungen lesen und verstehen, bevor Sie die Software installieren oder verwenden.",
          "You agree to comply with all applicable third party terms of agreement when using the Software. You agree that Apple, Inc. and its subsidiaries are third party beneficiaries of this License Agreement and that, upon your acceptance of the terms and conditions of this License Agreement, Apple, Inc. will have the right (and will be deemed to have accepted the right) to enforce the License Agreement against you as a third party beneficiary thereof.":
            "Sie erklären sich damit einverstanden, bei der Nutzung der Software alle geltenden Vertragsbedingungen Dritter einzuhalten. Sie erklären sich damit einverstanden, dass Apple, Inc. und seine Tochtergesellschaften Drittbegünstigte dieser Lizenzvereinbarung sind und dass Apple, Inc. nach Ihrer Zustimmung zu den Bestimmungen und Bedingungen dieser Lizenzvereinbarung das Recht hat (und es wird davon ausgegangen, dass es das Recht akzeptiert hat), die Lizenzvereinbarung gegen Sie als Drittbegünstigten durchzusetzen.",
          "1. LICENSE": "1. LIZENZ",
          "The Owner grants you a non-exclusive, non-transferable, non-sublicensable, limited right and license to use one copy of the Software for your personal, non-commercial use on a single device that you own or control. The rights granted herein are subject to your compliance with this EULA. The Software is being licensed to you, and you hereby acknowledge that no title or ownership in the Software is being transferred or assigned, and this EULA is not to be construed as a sale of any rights in the Software.":
            "Der Eigentümer gewährt Ihnen ein nicht-exklusives, nicht übertragbares, nicht unterlizenzierbares, eingeschränktes Recht und eine Lizenz zur Verwendung einer Kopie der Software für Ihre persönliche, nicht-kommerzielle Nutzung auf einem einzelnen Gerät, welches Sie besitzen oder kontrollieren. Die hierin gewährten Rechte unterliegen Ihrer Einhaltung dieser EULA. Die Software wird Ihnen lizenziert, und Sie erkennen hiermit an, dass kein Titel oder Eigentumsrecht an der Software übertragen oder zugewiesen wird und diese EULA nicht als Verkauf von Rechten an der Software ausgelegt werden darf.",
          "2. RESTRICTIONS OF USE": "2. NUTZUNGSBESCHRÄNKUNGEN",
          "Unless the Owner has authorized you to distribute the Software, you shall not make or distribute copies of the Software or transfer the Software from one device to another. You shall not decompile, reverse engineer, disassemble, include in other software, translate the Software, or use the Software for any commercial purposes. You shall not modify, alter, change or otherwise make any modification to the Software or create derivative works based upon the Software. You shall not rent, lease, resell, sub-license, assign, distribute or otherwise transfer the Software or this license. Any attempt to do so shall be void and of no effect.":
            "Sofern der Eigentümer Sie nicht autorisiert hat, die Software zu verteilen, dürfen Sie keine Kopien der Software erstellen oder verteilen oder die Software von einem Gerät auf ein anderes übertragen. Sie dürfen die Software weder dekompilieren, rückentwickeln, zerlegen, in andere Software einbeziehen, die Software übersetzen noch für kommerzielle Zwecke verwenden. Sie dürfen die Software nicht modifizieren, ändern oder anderweitig modifizieren oder darauf basierende Derivate erstellen. Sie dürfen die Software nicht vermieten, verleasen, weiterverkaufen, unterlizenzieren, übertragen, verteilen oder anderweitig übertragen. Ein solcher Versuch ist nichtig und wirkungslos.",
          "3. COPYRIGHT": "3. URHEBERRECHT",
          "You acknowledge that no title to the intellectual property in the Software is transferred to you. You further acknowledge that title and full ownership rights to the Software will remain the exclusive property of the Owner, and you will not acquire any rights to the Software. You shall not remove or obscure the Owner's copyright, trademark or other proprietary notices from any of the materials contained in this package or downloaded together with the Software.":
            "Sie bestätigen, dass kein Titel am geistigen Eigentum der Software auf Sie übertragen wird. Sie bestätigen weiterhin, dass der Titel und sämtliche Eigentumsrechte an der Software im ausschließlichen Besitz des Eigentümers bleiben und Sie keinerlei Rechte an der Software erwerben werden. Sie dürfen weder das Urheberrecht, Markenzeichen noch andere Eigentumsangaben des Eigentümers von den Materialien in diesem Paket oder von der gemeinsamen Nutzung mit der Software entfernen oder verbergen.",
          "In the event of any third party claim that the Software or your possession and use of that Software infringes that third party’s intellectual property rights, the Owner, not Apple, Inc. or other parties involved in the distribution of the Software, reserves the right to investigate, defend, settle, and discharge of any such intellectual property infringement claim.":
            "Für den Fall, dass ein Dritter behauptet, dass die Software oder Ihr Besitz und Ihre Nutzung der Software die geistigen Eigentumsrechte dieses Dritten verletzen, behält sich der Eigentümer, nicht Apple, Inc. oder andere am Vertrieb der Software beteiligte Parteien, das Recht vor, einen solchen Anspruch auf Verletzung des geistigen Eigentums zu untersuchen, zu verteidigen, beizulegen und zu begleichen.",
          "4. DISCLAIMER OF WARRANTY": "4. HAFTUNGSAUSSCHLUSS",
          "The Software is provided “AS IS”, without warranty of any kind. The Owner disclaims and make no express or implied warranties and specifically disclaim the warranties of merchantability, fitness for a particular purpose and non-infringement of third-party rights, both for itself and on behalf of Apple, Inc. and each of their respective licensors and affiliates and all other parties involved in the distribution of the Software. The entire risk as to the quality and performance of the Software is with you. We do not warrant that the functions contained in the Software will meet your requirements or that the operation of the Software will be error-free.":
            "Die Software wird “WIE SIE IST”, ohne jegliche Garantie, zur Verfügung gestellt. Der Eigentümer lehnt jegliche ausdrückliche oder stillschweigende Gewährleistung ab und schließt insbesondere die Gewährleistung der Marktgängigkeit, der Eignung für einen bestimmten Zweck und der Nichtverletzung von Rechten Dritter aus, sowohl für sich selbst als auch im Namen von Apple, Inc. und allen ihren jeweiligen Lizenzgebern und verbundenen Unternehmen sowie allen anderen an der Verbreitung der Software beteiligten Parteien. Das gesamte Risiko in Bezug auf die Qualität und Leistung der Software liegt bei Ihnen. Wir garantieren nicht, dass die in der Software enthaltenen Funktionen Ihren Anforderungen entsprechen oder dass der Betrieb der Software fehlerfrei ist.",
          "5. LIMITATION OF LIABILITY": "5. HAFTUNGSBESCHRÄNKUNG",
          "In no event will the Owner, Apple, Inc. and their respective affiliates, or any other parties involved in the distribution of the Software be liable for special, incidental or consequential damages resulting from possession, access, use or malfunction of the Software, including but not limited to damages to property, loss of goodwill, computer or mobile device malfunction and, to the extent permitted by law, damages for personal injuries, property damage, lost profits or punitive damages from any causes of action arising out of or related to this EULA or the Software, whether arising in tort (including negligence), contract, strict liability or otherwise and whether or not the Owner has been advised of the possibility of such damages.":
            "In keinem Fall sind der Eigentümer, Apple, Inc. und ihre jeweiligen Tochtergesellschaften oder andere an der Verteilung der Software beteiligte Parteien für besondere, zufällige oder Folgeschäden haftbar gemacht werden, die sich aus dem Besitz, dem Zugriff, der Verwendung oder der Fehlfunktion der Software ergeben, einschließlich, aber nicht beschränkt auf Schäden an Eigentum, Verlust von Firmenwert, Fehlfunktionen von Computern oder mobilen Geräten und, soweit gesetzlich zulässig, für Personen- und Sachschäden, entgangenen Gewinn oder Strafschadensersatz aus jeglichen Klagegründen, die sich aus dieser EULA oder der Software ergeben oder damit in Zusammenhang stehen, unabhängig davon, ob sie aus unerlaubter Handlung (einschließlich Fahrlässigkeit), Vertrag, Gefährdungshaftung oder anderweitig entstanden sind und unabhängig davon, ob der Eigentümer auf die Möglichkeit solcher Schäden hingewiesen wurde oder nicht.",
          "Because some states/countries do not allow certain limitations of liability, this limitation of liability shall apply to the fullest extent permitted by law in the applicable jurisdiction. This limitation of liability shall not be applied solely to the extent that any specific provision of this limitation of liability is prohibited by any federal, state, or municipal law, which cannot be pre-empted. This EULA gives you specific legal rights, and you may have other rights that vary from jurisdiction to jurisdiction. In no event shall the Owner's liability for all damages (except as required by applicable law) exceed the actual price paid by you for use of the Software.":
            "Da einige Staaten/Länder bestimmte Haftungsbeschränkungen nicht zulassen, gilt diese Haftungsbeschränkung im maximal zulässigen Umfang des geltenden Rechts in der entsprechenden Gerichtsbarkeit. Diese Haftungsbeschränkung gilt nicht allein in dem Umfang, in dem eine bestimmte Bestimmung dieser Haftungsbeschränkung durch Bundes-, Landes- oder Kommunalrecht verboten ist, das nicht umgangen werden kann. Dieser EULA gewährt Ihnen bestimmte gesetzliche Rechte, und Sie können andere Rechte haben, die je nach Gerichtsbarkeit unterschiedlich sind. In keinem Fall haftet der Eigentümer für alle Schäden (außer wie gesetzlich vorgeschrieben), die den tatsächlich von Ihnen für die Nutzung der Software gezahlten Preis übersteigen.",
          "6. PRIVACY POLICY": "6. DATENSCHUTZERKLÄRUNG",
          "Our Privacy Policy can be found on our website. We advise you to read it in its entirety.":
            "Unsere Datenschutzerklärung finden Sie auf unserer Website. Wir empfehlen Ihnen, sie vollständig durchzulesen.",
          "7. INDEMNIFICATION": "7. FREISTELLUNG",
          "By using the Software according to the terms of the EULA, you agree to indemnify, defend, and hold the Owner harmless from any and all damages, losses, and expenses directly or indirectly arising from your acts and omissions or your breach of this EULA.":
            "Durch die Nutzung der Software gemäß den Bestimmungen des EULA erklären Sie sich damit einverstanden, den Eigentümer von sämtlichen Schäden, Verlusten und Ausgaben freizustellen, die direkt oder indirekt aus Ihren Handlungen und Unterlassungen oder Ihrem Verstoß gegen diesen EULA resultieren.",
          "8. UPDATES AND MODIFICATIONS": "8. UPDATES UND ÄNDERUNGEN",
          "The Owner may periodically release updates or modifications to the Software, which may include adding, modifying, or removing features or functionality. You may be required to accept and install these updates or modifications. You acknowledge and agree that Apple, Inc. has no obligation whatsoever to furnish any maintenance and support services with respect to the Software.":
            "Der Eigentümer kann in regelmäßigen Abständen Updates oder Änderungen an der Software veröffentlichen, die das Hinzufügen, Ändern oder Entfernen von Funktionen oder Funktionalitäten beinhalten können. Sie können verpflichtet sein, diese Updates oder Änderungen zu akzeptieren und zu installieren. Sie erkennen an und erklären sich damit einverstanden, dass Apple, Inc. in keiner Weise verpflichtet ist, Wartungs- und Unterstützungsleistungen in Bezug auf die Software zu erbringen.",
          "9. FINANCIAL DISCLAIMER": "9. FINANZIELLE HAFTUNGSAUSSCHLUSS",
          "The information provided by this app is for educational and informational purposes only and is not intended to be a substitute for professional financial advice. The app is designed to help you track your expenses and manage your budget, but it is important to remember that individual financial situations may vary. The app owner and its affiliates are not responsible for any financial decisions made based on information obtained from the app. Before making any financial decisions, it is recommended that you consult with a qualified financial advisor.":
            "Die von dieser App bereitgestellten Informationen dienen ausschließlich Bildungs- und Informationszwecken und sollen nicht als Ersatz für professionelle Finanzberatung dienen. Die App soll Ihnen dabei helfen, Ihre Ausgaben zu verfolgen und Ihr Budget zu verwalten, aber es ist wichtig zu bedenken, dass individuelle finanzielle Situationen variieren können. Der App-Eigentümer und seine Partnerunternehmen übernehmen keine Verantwortung für finanzielle Entscheidungen, die aufgrund von Informationen aus der App getroffen werden. Bevor Sie finanzielle Entscheidungen treffen, wird empfohlen, sich an einen qualifizierten Finanzberater zu wenden.",
          "10. SUBSCRIPTION TERMS AND PURCHASES":
            "10. ABONNEMENTBEDINGUNGEN UND KÄUFE",
          "There are various ways to access the Software, including the Free Version, Free Trial, and Paid Subscription. You can only access the Subscription Program while your subscription is active and in good standing. You can purchase a subscription to Balancey within the app, subject to the policies of the respective Application Marketplace Partners. Please note that purchases made through the Apple iTunes Store or our iPhone application are final, and refunds are not provided. Similarly, if you purchase a subscription through the Google Play store, the sale is final, and refunds are not provided. Balancey offers monthly and yearly subscription options, with a month defined as 30 calendar days and a year as 365 calendar days. Your monthly subscription will be automatically renewed each month until canceled, while your yearly subscription is paid for by a one-time upfront payment with automatic annual renewals.":
            "Es gibt verschiedene Möglichkeiten, auf die Software zuzugreifen, einschließlich der Free Version, des Free Trial und des Paid Subscription. Sie können nur auf das Subscription-Programm zugreifen, solange Ihr Abonnement aktiv und in gutem Zustand ist. Sie können ein Abonnement für Balancey innerhalb der App kaufen, unterliegen jedoch den Richtlinien der jeweiligen Application Marketplace-Partner. Bitte beachten Sie, dass Käufe über den Apple iTunes Store oder unsere iPhone-App endgültig sind und keine Rückerstattungen erfolgen. Ebenso gilt, wenn Sie ein Abonnement über den Google Play Store kaufen, der Kauf als endgültig und Rückerstattungen werden nicht gewährt. Balancey bietet monatliche und jährliche Abonnementoptionen an, wobei ein Monat als 30 Kalendertage und ein Jahr als 365 Kalendertage definiert sind. Ihr monatliches Abonnement wird automatisch jeden Monat erneuert, bis es gekündigt wird, während Ihr jährliches Abonnement durch eine einmalige Vorauszahlung mit automatischer jährlicher Verlängerung bezahlt wird.",
          "11. CONTENT": "11. INHALT",
          "During the Free Trial Period and refund period provided by our Application Marketplace Partners, users can evaluate the Content of our Product to determine if it meets their needs. If a user is dissatisfied with the Product during this period, they can cancel the Trial without incurring any fees. If a user continues using the Product without canceling the Trial, they will be responsible for purchasing the Product, and the Content will not be a reason for compensation. Please note that the scope of the Content might be specific to the platform or device on which the Software is being used. Users can cancel their Trial anytime by following the instructions provided in the Application Marketplace Partner platform.":
            "Während der Free Trial- und Rückerstattungsfrist, die von unseren Application Marketplace-Partnern bereitgestellt wird, können Benutzer den Inhalt unseres Produkts bewerten, um festzustellen, ob er ihren Anforderungen entspricht. Wenn ein Benutzer während dieses Zeitraums mit dem Produkt unzufrieden ist, kann er den Test ohne Gebühren abbrechen. Wenn ein Benutzer das Produkt ohne Abbruch des Tests weiterhin nutzt, ist er verpflichtet, das Produkt zu kaufen, und der Inhalt ist kein Grund für eine Entschädigung. Bitte beachten Sie, dass der Umfang des Inhalts möglicherweise auf die Plattform oder das Gerät beschränkt ist, auf dem die Software verwendet wird. Benutzer können ihren Test jederzeit abbrechen, indem sie den Anweisungen auf der Plattform des Application Marketplace-Partners folgen.",
          "12. LEGAL COMPLIANCE": "12. EINHALTUNG VON RECHTSVORSCHRIFTEN",
          "You will comply with all applicable laws in connection with your use of the Software. You acknowledge that the Software may be subject to U.S. or foreign export and import laws or regulations. You will comply with all applicable import, export and re-export laws and regulations of the United States or foreign agencies or authorities. You represent and warrant that (i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.":
            "Sie werden alle anwendbaren Gesetze in Verbindung mit Ihrer Nutzung der Software einhalten. Sie erkennen an, dass die Software möglicherweise US-amerikanischen oder ausländischen Export- und Importgesetzen oder -vorschriften unterliegt. Sie werden alle anwendbaren Import-, Export- und Reexportgesetze und -vorschriften der Vereinigten Staaten oder ausländischer Agenturen oder Behörden einhalten. Sie sichern zu und gewährleisten, dass (i) Sie sich nicht in einem Land befinden, das einem Embargo der US-Regierung unterliegt oder das von der US-Regierung als “Staatlicher Förderer des Terrorismus” eingestuft wurde, und (ii) Sie nicht auf einer Liste der US-Regierung mit verbotenen oder eingeschränkten Parteien stehen.",
          "13. CHANGES TO TERMS": "13. ÄNDERUNGEN DER BEDINGUNGEN",
          "We reserve the right to modify or replace these Terms at any time.":
            "Wir behalten uns das Recht vor, diese Bedingungen jederzeit zu ändern oder zu ersetzen.",
          "14. CONTACT INFORMATION": "14. KONTAKTINFORMATIONEN",
          "Should you have any questions, complaints or claims with respect to the Software, please contact the Owner at:":
            "Sollten Sie Fragen, Beschwerden oder Ansprüche in Bezug auf die Software haben, wenden Sie sich bitte an den Eigentümer unter folgender Adresse:",
          "Vanilla Noir Ltd, and not Apple, Inc. or any other parties involved in the distribution of the Software, is responsible for addressing claims relating to the Software and your possession and/or use of that Software.":
            "Vanilla Noir Ltd, und nicht Apple, Inc. oder andere am Vertrieb der Software beteiligte Parteien, ist verantwortlich für die Behandlung von Ansprüchen im Zusammenhang mit der Software und Ihrem Besitz und/oder Ihrer Verwendung dieser Software.",
          //Impressum
          "Legal Notice": "Impressum",
          "Information in accordance with Section 5 TMG:":
            "Informationen gemäß § 5 TMG:",
          "Company Name: Vanilla Noir Ltd": "Firmenname: Vanilla Noir Ltd",
          "Legal Form: Ltd": "Rechtsform: Ltd",
          "We are registered in England & Wales at Companies House, Crown Way, Cardiff, CF14 3UZ under registration number 15244694.":
            "Wir sind in England und Wales im Companies House, Crown Way, Cardiff, CF14 3UZ unter der Registrierungsnummer 15244694 eingetragen.",
          "Managing Director: Madeleine Frister, Maximilian Croissant":
            "Geschäftsführer: Madeleine Frister, Maximilian Croissant",
          "Contact Information:": "Kontaktinformationen:",
          "Platform of the EU Commission for online dispute resolution: ":
            "Plattform der EU-Kommission zur Online-Streitbeilegung: ",
          //FAQ
          "Frequently Asked Questions": "Häufig gestellte Fragen",
          //User Management
          "Email verified successfully. You can now sign in with your email and password.":
            "Email erfolgreich verifiziert. Du kannst dich jetzt mit deiner Email-Adresse und deinem Passwort anmelden.",
          "The verification code has expired. Please request a new verification email.":
            "Der Verifizierungscode ist abgelaufen. Bitte fordere eine neue Verifizierungs-Email an.",
          "The verification code is invalid. Please check the code and try again.":
            "Der Verifizierungscode ist ungültig. Bitte versuche es erneut.",
          "The user account associated with this email has been disabled.":
            "Das mit dieser Email-Adresse verknüpfte Nutzerkonto wurde deaktiviert.",
          "No user found corresponding to the verification code. The account may have been deleted.":
            "Es wurde kein Nutzer gefunden, der dem Verifizierungscode entspricht. Das Konto wurde möglicherweise gelöscht.",
          "An unknown error occurred during email verification. Please try again.":
            "Bei der E-Mail-Verifizierung ist ein unbekannter Fehler aufgetreten. Bitte versuche es erneut.",
          "Something went wrong. Please reload the page.":
            "Etwas ist schiefgelaufen. Bitte lade die Seite neu.",
        },
      },
    },
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
    },
  });
