import { Button, Container, styled, Typography, useTheme } from "@mui/material";
import React, { useState, useRef } from "react";
import logo from "../../assets/images/logo.svg";
import { NavLink } from 'react-router-dom';


   
   
   const LogoBox = styled('div')(({ theme }) => ({
    padding: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.palette.homepage.light,
  
    '& img' : {
      width: '110px',
    },
  
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 2, 2, 3)
    }
  
     
   
   }));

const Navigation = () => {

const theme = useTheme();


  return (
    <div>
    <NavLink to="/">
      <LogoBox>
      <img src={logo} alt="balancey logo"></img>
      </LogoBox>
    </NavLink>
    </div>
  )
}

export default Navigation