import React, { useEffect } from 'react'
import { styled } from '@mui/system';
import { Typography, Button, Table, TableRow, TableHead, TableBody, TableCell, Menu, MenuItem, useTheme } from '@mui/material';
import { useAppContext } from '../../@common/contexts/AppContext';
import { useTranslation } from 'react-i18next';
import SubscriptionContainer from '../SubscriptionContainer';


  const Container = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    padding: theme.spacing(4),
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
     }
   ));


const AdYearly = () => {
    const theme = useTheme();
    const {shadowStyle} = useAppContext();
    const {t} = useTranslation();
    const [subDialogOpen, setSubDialogOpen] = React.useState(false);

   

  return (
    <>
    <Container sx={shadowStyle}>
    <Typography variant='h4' style={{marginBottom: 20, textAlign: "center"}}>{t("Get Yearly Insights!")}</Typography>
    <img src={require('../../assets/images/charts.png')} style={{width: 320, marginTop: 12}}
    //@ts-ignore
    alt={t("image of a chart")}/>
    <Button variant='contained' size="large" onClick={() => setSubDialogOpen(true)} sx={{marginTop: 4}}>{t("Get started!")}</Button>
    </Container>
    <SubscriptionContainer open={subDialogOpen} setOpen={setSubDialogOpen}/>
    </>
  )
}

export default AdYearly