import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { Button, Dialog, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';




const StyledDialog = styled(Dialog)(({ theme }) => ({

    '& .MuiPaper-root':{
      borderRadius: "25px",
      maxWidth: "none",
      width: "400px"
    }
}));

const StyledContainer = styled("div")(({ theme }) => ({
    margin: theme.spacing(4),
    position:"relative",
    display:"flex",
    flexDirection:"column",
  }));




type DeleteDialogType = {
  open: boolean,
  setOpen: (open: boolean) => void,
  onSubmit: () => void,
  toDelete: string,
}

const DeleteDialog = ({open, setOpen, onSubmit, toDelete} : DeleteDialogType) => {
  const {t} = useTranslation();


	const handleClose = () => {
		setOpen(false);
	};



    

  return (
      <StyledDialog
      open={open}
      onClose={handleClose}
      >
        <StyledContainer>
         
    
          <Typography variant='h5' sx={{textAlign: "center", mb:2}}>{t("Are you sure you want to delete")} {toDelete} {t("delete2")}</Typography>
          <Button type="button" color="primary" variant='contained' className="form__custom-button" sx={{mt:2, ml:6, mr:6}} onClick={() => {onSubmit(); handleClose();}}>
            {t("Delete")}
          </Button>
          <Button type="button" color="primary" variant='outlined' className="form__custom-button" sx={{mt:2, ml:6, mr:6}} onClick={handleClose}>
            {t("Nevermind")}
          </Button>

       
      </StyledContainer>

      </StyledDialog>
   
    
  );
};

export default DeleteDialog;
