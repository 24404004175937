import React, { useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
} from "chart.js";
import { useUser } from "../../@common/contexts/UserContext";
import {
  categorySumForMonth,
  formatCurrency,
  getSumforYear,
} from "../../main/resources/CalcRessources";
import { useEntry } from "../../@common/contexts/EntryContext";
import { styled } from "@mui/system";
import {
  Typography,
  Button,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  useTheme,
} from "@mui/material";
import { monthNumbers, months } from "../../@common/theme/Theme";
import CircleIcon from "@mui/icons-material/Circle";
import { makeTimeStamp } from "../../overview/resources/helpers";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { externalLineTooltipHandler } from "../resources/GraphPlugins";
import fileDownload from "js-file-download";
import ExportMenu from "./ExportMenu";
import YearlySankey from "./YearlySankey";
import { BalanceCard, IncomeCard } from "../../overview/components/BudgetCard";
import { useAppContext } from "../../@common/contexts/AppContext";
import { useTranslation } from "react-i18next";
import { BlockType, CategoryType } from "../../main/resources/UserResources";
import useCSVExport from "../resources/ExportRessources";

Chart.register(Tooltip, CategoryScale, LinearScale, PointElement, LineElement);

const ReportContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: 10,
  padding: theme.spacing(4),
}));
const SankeyContainer = styled("div")(({ theme }) => ({
  height: "500px",
  margin: theme.spacing(4, 0),
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(4, 3, 8, 3),
  borderRadius: 10,
}));

const ChartContainer = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  minHeight: "400px",
  height: "100%",
}));

const LegendContainer = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexWrap: "wrap",
  width: "100%",
  justifyContent: "space-evenly",
  margin: theme.spacing(3, 0, 1, 0),
}));

const TableContainer = styled("div")(({ theme }) => ({
  position: "relative",
  width: "100%",
}));

const LegendItem = styled(Button)(({ theme }) => ({
  position: "relative",
  display: "flex",
}));

const CustomTable = styled(Table)(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.palette.background.paper,
  borderRadius: "15px",
  margin: theme.spacing(4, 0),
  width: "100%",
}));

const CustomRow = styled(TableRow)(({ theme }) => ({
  border: "1px solid",
  borderColor: theme.palette.divider,
}));

const CustomCell = styled(TableCell)(({ theme }) => ({
  fontSize: "14px",
  padding: theme.spacing(0),
  maxWidth: "150px",
  border: "none",

  "&.firstCell": {
    overflow: "hidden",
    whiteSpace: "nowrap",
    padding: theme.spacing(1, 2),
  },

  [theme.breakpoints.up("md")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "1.1rem",
  },
}));

const YearPicker = styled("div")(({ theme }) => ({
  margin: "25px 0",
  width: "fit-content",
  display: "flex",
  alignItems: "center",

  "& .MuiTypography-root": {
    display: "inline",
    margin: 0,
  },
}));

const StatsRow = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  position: "relative",
  borderRadius: "10px",
  display: "flex",
  justifyContent: "space-between",
  width: "60%",
}));

type LineChartProps = {};

const YearlyReportChart = () => {
  const { blocks, user, currentHousehold } = useUser();
  const { allEntries } = useEntry();
  const { color_series, year } = useAppContext();
  const [yearOfInterest, setYearOfInterest] = React.useState(year);
  const thisTimeStamp = makeTimeStamp(yearOfInterest, 11);
  const [blockOfInterest, setBlockOfInterest] = React.useState<any>(null);
  const [monthlyExpense, setMonthlyExpense] = React.useState<number[]>([]);
  const [numberOfMonths, setNumberOfMonths] = React.useState<number>(1);
  const theme = useTheme();
  const { shadowStyle } = useAppContext();
  const { createYearlyCSV } = useCSVExport();
  const { t } = useTranslation();

  const monthNames = months.map((mon: string) => t(mon));

  const LineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: { padding: 0 },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: externalLineTooltipHandler,
      },
    },
  };

  let activeBlocks = [...blocks];
  activeBlocks = activeBlocks.filter((item: BlockType) => {
    if (
      item.status === "deleted" &&
      Number(thisTimeStamp) >= Number(item.deleted)
    )
      return false;
    if (Number(item.created) > Number(thisTimeStamp)) return false;
    return true;
  });

  const getBlockSum = (categories: CategoryType[], monthOfInterest: number) => {
    let s = 0;
    let cats = categories.filter((categ: CategoryType) => {
      if (
        categ.status === "deleted" &&
        Number(thisTimeStamp) >= Number(categ.deleted)
      )
        return false;
      if (Number(categ.created) > Number(thisTimeStamp)) return false;
      return true;
    });

    let purchasesMade = 0;

    cats.forEach((element) => {
      let newSum = categorySumForMonth(
        user.id,
        allEntries,
        element.label,
        monthOfInterest,
        yearOfInterest
      );
      if (newSum > 0) purchasesMade++;
      s = s + newSum;
    });

    if (purchasesMade > 0 && numberOfMonths < monthOfInterest + 1)
      setNumberOfMonths(monthOfInterest + 1);

    return Math.round(s * 100) / 100;
  };

  const spentDatasets = activeBlocks.map((block: BlockType, index: number) => {
    return {
      label: block.name,
      data: monthNumbers.map((m: number) => getBlockSum(block.categories, m)),
      backgroundColor: color_series[index],
      borderColor: color_series[index],
      tension: 0.1,
    };
  });

  const spentData: any = {
    labels: monthNames,
    datasets: spentDatasets,
  };

  const incomeData = React.useMemo(() => {
    return getSumforYear(user.id, "income", allEntries, yearOfInterest);
  }, [user.id, allEntries, yearOfInterest]);

  const getSum = (catIndex: number) => {
    let ind = catIndex * 12;
    let s = 0;
    for (let i = 0; i < 12; i++) {
      s = s + monthlyExpense[ind + i];
    }
    return s;
  };

  useEffect(() => {
    if (blockOfInterest) {
      let temp: any[] = [];

      blockOfInterest.categories.forEach((cat: CategoryType) => {
        monthNumbers.forEach((month: number) => {
          let val = categorySumForMonth(
            user.id,
            allEntries,
            cat.label,
            month,
            yearOfInterest
          );
          temp.push(val);
        });
      });
      setMonthlyExpense(temp);
    }
  }, [yearOfInterest, blockOfInterest, allEntries, user.id]);

  //This is the count for the months the app was used to calculate average
  useEffect(() => {
    if (spentData && spentData.datasets.length > 0) {
      let count = 0;
      for (let i = 0; i < monthNumbers.length; i++) {
        if (spentData.datasets[0].data[i] > 0) count++;
      }
      setNumberOfMonths(Math.max(count, 1));
    }
  }, [spentData]);

  const selectBlockOfInterest = (block: BlockType) => {
    let temp = { ...block };
    let cats = block.categories.filter((categ: CategoryType) => {
      if (
        categ.status === "deleted" &&
        Number(thisTimeStamp) >= Number(categ.deleted)
      )
        return false;
      if (Number(categ.created) > Number(thisTimeStamp)) return false;
      return true;
    });
    temp.categories = cats;
    if (blockOfInterest && blockOfInterest.name === block.name)
      setBlockOfInterest(null);
    else setBlockOfInterest(temp);
  };

  const spentSum = () => {
    let s = 0;
    spentDatasets.forEach((dataset) => {
      dataset.data.forEach((data) => {
        s = s + data;
      });
    });

    return Math.round(s * 100) / 100;
  };

  const startExport = () => {
    createYearlyCSV(user.id, allEntries, activeBlocks, yearOfInterest).then(
      (res: any) => {
        let title = yearOfInterest + "_Report.csv";
        fileDownload(res, title);
      }
    );
  };

  let currTotal = 0;
  let balance = incomeData - spentSum();
  const isPos = balance >= 0 ? true : false;

  return (
    <>
      <YearPicker>
        <Button
          onClick={() => setYearOfInterest(yearOfInterest - 1)}
          sx={{
            minWidth: "auto",
            width: "fit-content",
            mr: 1,
            color: theme.palette.text.primary,
          }}
          aria-label={t("previous year") || "previous year"}
        >
          <ArrowBackIosNewIcon sx={{ fontSize: 16 }} />
        </Button>
        <Typography variant="h5">{yearOfInterest}</Typography>
        <Button
          onClick={() => setYearOfInterest(yearOfInterest + 1)}
          sx={{
            minWidth: "auto",
            width: "fit-content",
            ml: 1,
            color: theme.palette.text.primary,
          }}
          aria-label={t("next year") || "next year"}
        >
          <ArrowForwardIosIcon sx={{ fontSize: 16 }} />
        </Button>
      </YearPicker>
      <ExportMenu exportFunction={startExport} />
      <StatsRow>
        <IncomeCard income={incomeData} />
        <BalanceCard fund={balance} />
      </StatsRow>
      <ReportContainer sx={shadowStyle}>
        <ChartContainer>
          <Line data={spentData} options={LineOptions} />
        </ChartContainer>
        <LegendContainer>
          {activeBlocks.map((block: BlockType, index: number) => {
            return (
              <LegendItem
                key={block.name}
                onClick={() => selectBlockOfInterest(block)}
                variant="outlined"
              >
                <CircleIcon
                  sx={{
                    mr: 1,
                    ml: 1,
                    color: color_series[index % color_series.length],
                  }}
                />
                <Typography sx={{ fontSize: "16px" }}>{block.name}</Typography>
              </LegendItem>
            );
          })}
        </LegendContainer>
        {blockOfInterest !== null ? (
          <TableContainer>
            <CustomTable aria-label="Yearly expenses by category">
              <TableHead
                sx={{
                  "& .MuiTableCell-root": { fontWeight: 700 },
                  border: "1px solid",
                  borderColor: theme.palette.divider,
                  backgroundColor: theme.palette.interactable,
                }}
              >
                <CustomRow>
                  <CustomCell className="firstCell">
                    {blockOfInterest.name}
                  </CustomCell>
                  {monthNames.map((month: string) => {
                    return (
                      <CustomCell key={month}>
                        {month.substring(0, 3)}
                      </CustomCell>
                    );
                  })}
                  <CustomCell>{t("Sum")}</CustomCell>
                  <CustomCell>Ø</CustomCell>
                </CustomRow>
              </TableHead>
              <TableBody>
                {blockOfInterest.categories.map(
                  (category: CategoryType, catIndex: number) => {
                    let thisSum = getSum(catIndex);
                    let thisAvg = thisSum / numberOfMonths;

                    return (
                      <CustomRow key={category.label}>
                        <CustomCell className="firstCell">
                          {category.label}
                        </CustomCell>
                        {monthNames.map((month: string, index: number) => {
                          return (
                            <CustomCell key={month + "-" + category.label}>
                              {formatCurrency(
                                monthlyExpense[catIndex * 12 + index],
                                currentHousehold.currency
                              )}
                            </CustomCell>
                          );
                        })}
                        <CustomCell>
                          {formatCurrency(thisSum, currentHousehold.currency)}
                        </CustomCell>
                        <CustomCell>
                          {formatCurrency(thisAvg, currentHousehold.currency)}
                        </CustomCell>
                      </CustomRow>
                    );
                  }
                )}
                <CustomRow
                  sx={{
                    "& .MuiTableCell-root": { fontWeight: 700 },
                    backgroundColor: theme.palette.interactable,
                  }}
                >
                  <CustomCell className="firstCell">{t("Total")}</CustomCell>
                  {monthNames.map((month: string, index: number) => {
                    let no = getBlockSum(blockOfInterest.categories, index);
                    currTotal = currTotal + no;
                    return (
                      <CustomCell key={month + "-total"}>
                        {formatCurrency(no, currentHousehold.currency)}
                      </CustomCell>
                    );
                  })}
                  <CustomCell>
                    {formatCurrency(currTotal, currentHousehold.currency)}
                  </CustomCell>
                  <CustomCell>
                    {formatCurrency(
                      currTotal / numberOfMonths,
                      currentHousehold.currency
                    )}
                  </CustomCell>
                </CustomRow>
              </TableBody>
            </CustomTable>
          </TableContainer>
        ) : (
          <></>
        )}
      </ReportContainer>
      <SankeyContainer sx={shadowStyle}>
        <Typography variant="body2" sx={{ marginLeft: "1rem" }}>
          {t("Flow Diagram")}
        </Typography>
        <YearlySankey yearOfInterest={yearOfInterest} />
      </SankeyContainer>
    </>
  );
};

export default YearlyReportChart;
