import React from "react";
import { styled } from "@mui/system";
import { Typography, Button, useTheme } from "@mui/material";
import { useAppContext } from "../../@common/contexts/AppContext";
import { useEntry } from "../../@common/contexts/EntryContext";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { months } from "../../@common/theme/Theme";
import { useUser } from "../../@common/contexts/UserContext";
import { makeTimeStamp } from "../../overview/resources/helpers";
import {
  formatCurrency,
  getPaymentPerMonth,
  getSharedExpensePerCategory,
} from "../../main/resources/CalcRessources";
import fileDownload from "js-file-download";
import ExportMenu from "./ExportMenu";
import TransactionIcon from "../../transactions/components/TransactionIcon";
import { useTranslation } from "react-i18next";
import {
  BlockType,
  CategoryType,
  MemberType,
} from "../../main/resources/UserResources";
import useCSVExport from "../resources/ExportRessources";

interface SharedExpensesCategoryType extends CategoryType {
  sharedExpense: number;
}

interface SharedExpensesOutput {
  name: string;
  categories: SharedExpensesCategoryType[];
}

const ReportContainer = styled("div")(({ theme }) => ({
  margin: theme.spacing(4, 0, 0, 0),
  flexBasis: "60%",

  [theme.breakpoints.down("lg")]: {
    flexBasis: "100%",
  },
}));

const ReportPaper = styled("div")(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: 10,
  padding: theme.spacing(3),
}));

const DetailsContainer = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  boxSizing: "border-box",
  width: "100%",
  minHeight: "400px",
  height: "100%",

  [theme.breakpoints.down("md")]: {
    margin: theme.spacing(2, 0),
  },
}));

const MonthPicker = styled("div")(({ theme }) => ({
  margin: "15px auto 15px auto",
  width: "fit-content",
  display: "flex",
  alignItems: "center",

  "& .MuiTypography-root": {
    display: "inline",
    margin: 0,
    fontSize: "1.3rem",
  },

  [theme.breakpoints.down("md")]: {
    margin: "auto",
  },
}));

const BlockContainer = styled("div")(({ theme }) => ({
  position: "relative",
  width: "100%",
}));

const CustomRow = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  margin: theme.spacing(0, 0, 2, 0),
  backgroundColor: theme.palette.background.paper,
  borderRadius: "10px",

  "& .MuiTypography-root": {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const NameBox = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(2),
}));

const SplitDetails = () => {
  const { blocks, user, currentHousehold } = useUser();
  const { allEntries } = useEntry();
  const { t } = useTranslation();
  const { month, year, shadowStyle, darkMode } = useAppContext();
  const [yearOfInterest, setYearOfInterest] = React.useState(year);
  const [monthOfInterest, setMonthOfInterest] = React.useState(month);
  const monthName = t(months[monthOfInterest]) + " " + yearOfInterest;
  const thisTimeStamp = makeTimeStamp(yearOfInterest, monthOfInterest);
  const { createSplitCSV } = useCSVExport();
  const theme = useTheme();

  let activeBlocks = [...blocks];
  activeBlocks = activeBlocks.filter((item: BlockType) => {
    if (
      item.status === "deleted" &&
      Number(thisTimeStamp) >= Number(item.deleted)
    )
      return false;
    if (Number(item.created) > Number(thisTimeStamp)) return false;
    return true;
  });

  const sharedExpenses = React.useMemo(() => {
    let output: SharedExpensesOutput[] = [];
    activeBlocks.forEach((block: BlockType) => {
      let outputCats: SharedExpensesCategoryType[] = [];
      let cats = block.categories.filter((categ: CategoryType) => {
        if (
          categ.status === "deleted" &&
          Number(thisTimeStamp) >= Number(categ.deleted)
        )
          return false;
        if (Number(categ.created) > Number(thisTimeStamp)) return false;
        return true;
      });
      cats.forEach((thisCat: CategoryType) => {
        let shared = getSharedExpensePerCategory(
          allEntries,
          thisCat.label,
          monthOfInterest,
          yearOfInterest
        );
        if (shared > 0) {
          let newCat = { ...thisCat } as SharedExpensesCategoryType;
          newCat.sharedExpense = shared;
          outputCats.push(newCat);
        }
      });

      if (outputCats.length > 0) {
        let temp = {
          name: block.name,
          categories: outputCats,
        };
        output.push(temp);
      }
    });
    return output;
  }, [activeBlocks, allEntries, monthOfInterest, yearOfInterest]);

  const nextMonth = () => {
    let m = monthOfInterest + 1;
    let y = yearOfInterest;
    if (m >= 12) {
      m = 0;
      y = y + 1;
    }

    setMonthOfInterest(m);
    setYearOfInterest(y);
  };

  const prevMonth = () => {
    let m = monthOfInterest - 1;
    let y = yearOfInterest;
    if (m < 0) {
      m = 11;
      y = y - 1;
    }

    setMonthOfInterest(m);
    setYearOfInterest(y);
  };

  const startExport = () => {
    createSplitCSV(
      user.id,
      currentHousehold,
      allEntries,
      activeBlocks,
      monthOfInterest,
      yearOfInterest
    ).then((res: any) => {
      let title = monthName + "_SharedExpenses_Report.csv";
      fileDownload(res, title);
    });
  };

  return (
    <>
      <ExportMenu exportFunction={startExport} />
      <ReportContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{ color: theme.palette.text.disabled, ml: "10px", mb: 1 }}
          >
            {t("Shared Expenses")}
          </Typography>
        </div>
        <ReportPaper>
          <MonthPicker>
            <Button
              onClick={prevMonth}
              sx={{
                minWidth: "auto",
                width: "fit-content",
                mr: 1,
                color: theme.palette.text.primary,
              }}
              aria-label={t("previous month") || "previous month"}
            >
              <ArrowBackIosNewIcon sx={{ fontSize: 15 }} />
            </Button>
            <Typography variant="h5">{monthName}</Typography>
            <Button
              onClick={nextMonth}
              sx={{
                minWidth: "auto",
                width: "fit-content",
                ml: 1,
                color: theme.palette.text.primary,
              }}
              aria-label={t("next month") || "next month"}
            >
              <ArrowForwardIosIcon sx={{ fontSize: 15 }} />
            </Button>
          </MonthPicker>
          <DetailsContainer>
            {sharedExpenses.length <= 0 ? (
              <CustomRow sx={{ marginTop: 2 }}>
                <Typography sx={{ fontSize: "16px" }}>
                  {t("No shared expenses this month!")}
                </Typography>
              </CustomRow>
            ) : (
              sharedExpenses.map(
                (block: SharedExpensesOutput, index: number) => {
                  return (
                    <BlockContainer key={block.name}>
                      <Typography
                        variant="h6"
                        sx={{
                          mb: 1,
                          mt: 1,
                          fontSize: "14px",
                          color: theme.palette.text.disabled,
                        }}
                      >
                        {block.name}
                      </Typography>
                      {block.categories.map((cat: CategoryType) => {
                        const blockIndex = blocks.indexOf(activeBlocks[index]);

                        return (
                          <CustomRow
                            key={cat.id + cat.label + monthOfInterest}
                            sx={
                              darkMode
                                ? [
                                    shadowStyle,
                                    {
                                      borderColor: theme.palette.interactable,
                                      borderWidth: 1,
                                      borderStyle: "solid",
                                    },
                                  ]
                                : shadowStyle
                            }
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                borderBottomStyle: "solid",
                                borderBottomWidth: 1,
                                borderBottomColor: theme.palette.interactable,
                                padding: theme.spacing(2, 3, 2, 2),
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  overflow: "hidden",
                                }}
                              >
                                <TransactionIcon
                                  type="expense"
                                  categoryLabel={cat.label}
                                  blockIndex={blockIndex}
                                />
                                <Typography variant="body2">
                                  {cat.label}
                                </Typography>
                              </div>
                              <Typography variant="body1" sx={{ ml: 2 }}>
                                {formatCurrency(
                                  getSharedExpensePerCategory(
                                    allEntries,
                                    cat.label,
                                    monthOfInterest,
                                    yearOfInterest
                                  ),
                                  currentHousehold.currency
                                )}
                              </Typography>
                            </div>
                            <NameBox>
                              {currentHousehold.members.map(
                                (member: MemberType) => {
                                  return (
                                    <div
                                      key={member.id}
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        padding: theme.spacing(1),
                                      }}
                                    >
                                      <Typography
                                        variant="body1"
                                        sx={{ fontSize: "16px" }}
                                      >
                                        {member.name}
                                      </Typography>
                                      <Typography
                                        variant="body1"
                                        sx={{ fontSize: "16px" }}
                                      >
                                        {formatCurrency(
                                          getPaymentPerMonth(
                                            member.id,
                                            allEntries,
                                            cat.label,
                                            monthOfInterest,
                                            yearOfInterest
                                          ),
                                          currentHousehold.currency
                                        )}
                                      </Typography>
                                    </div>
                                  );
                                }
                              )}
                            </NameBox>
                          </CustomRow>
                        );
                      })}
                    </BlockContainer>
                  );
                }
              )
            )}
          </DetailsContainer>
        </ReportPaper>
      </ReportContainer>
    </>
  );
};

export default SplitDetails;
