import React, { useState } from "react";
import { styled } from "@mui/system";
import { Button, Typography, Switch, useTheme } from "@mui/material";
import CurrencyPicker from "../components/CurrencyPicker";
import InviteDialog from "../components/InviteDialog";
import {
  deleteUser,
  EmailAuthProvider,
  reauthenticateWithCredential,
  signOut,
  sendEmailVerification,
} from "firebase/auth";
import { useUser } from "../../@common/contexts/UserContext";
import {
  MemberType,
  deleteFirebaseUser,
} from "../../main/resources/UserResources";
import AuthDialog from "../../@common/auth/AuthDialog";
import { auth } from "../../@common/firebase/firebase";
import useAuth from "../../@common/hooks/useAuth";
import EditableLabel from "../../overview/components/EditableLabel";
import { useAppContext } from "../../@common/contexts/AppContext";
import Layout from "../../layout";
import PasswordChangeDialog from "../components/PasswordChangeDialog";
import LanguagePicker from "../components/LanguagePicker";
import UsernameChangeDialog from "../components/UsernameChangeDialog";
import LoadingModal from "../../@common/components/LoadingModal";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import pie from "../../assets/images/pie.png";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SubscriptionContainer from "../../main/SubscriptionContainer";

const Content = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  maxWidth: "700px",

  [theme.breakpoints.down("md")]: {
    paddingTop: theme.spacing(2),
  },
}));

const SettingsBlock = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: "10px",
  margin: theme.spacing(0, 0, 3, 0),
}));

const ColOne = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(1),
  fontSize: "1.1em",
}));

const ColTwo = styled("div")(({ theme }) => ({
  flexWrap: "wrap",
  display: "flex",
}));

const CustomButton = styled(Button)(({ theme }) => ({
  height: "50px",
  width: "fit-content",
  margin: theme.spacing(0, 2, 2, 0),

  "&:hover": {
    backgroundColor: "transparent",
    textDecoration: "underline",
  },
}));

const SettingsRow = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  //marginBottom: theme.spacing(2),
  padding: theme.spacing(0, 2, 0, 3),
  minHeight: "55px",

  "&:not(:last-child)": {
    borderBottom: "1px solid" + theme.palette.interactable,
  },
}));

const ImageContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.info.light,
  display: "flex",
  borderRadius: 100,
  alignItems: "center",
  justifyContent: "center",
  marginRight: 16,
  width: 80,
  height: 80,
}));

const AuthButtons = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  marginLeft: "-5px",
  padding: theme.spacing(1, 3),
}));

const MemberContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(0.75, 2),
  margin: theme.spacing(1, 0, 1, 2),
  backgroundColor: theme.palette.info.light,
  color: theme.palette.info.contrastText,
  borderRadius: "25px",
  minWidth: "75px",
  display: "flex",
  justifyContent: "center",
  fontSize: "13px",
}));

const SettingsContainer = () => {
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const [subscriptionDialogOpen, setSubscriptionDialogOpen] = useState(false);
  const [emailChangeDialogOpen, setEmailChangeDialogOpen] = useState(false);
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [reauthenticateOpen, setReauthenticateOpen] = useState(false);
  const { user, currentHousehold, updateUser, updateHousehold, premium } =
    useUser();
  const { darkMode, setDarkMode, shadowStyle, AddInfoNotification } =
    useAppContext();
  const { setWaitForResponse } = useAuth();
  const [editStateHousehold, setEditStateHousehold] = React.useState(false);
  const [editStateName, setEditStateName] = React.useState(false);
  const [loadingModalOpen, setLoadingModalOpen] = React.useState(false);
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const languageCode = i18n.language.split("-")[0];

  const OpenInviteDialog = () => {
    setInviteDialogOpen(true);
  };

  const LogOut = () => {
    setLoadingModalOpen(true);
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
        setLoadingModalOpen(false);
      });
  };

  const DeleteAccount = async (email: string, password: string) => {
    if (auth.currentUser && password.length > 0) {
      setLoadingModalOpen(true);
      setWaitForResponse(true);
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email || "",
        password
      );
      const thisUser = auth.currentUser;

      reauthenticateWithCredential(thisUser, credential)
        .then(() => {
          // User re-authenticated.
          deleteFirebaseUser(user.id, currentHousehold.id).then(() => {
            deleteUser(thisUser)
              .then(() => {
                setWaitForResponse(false);
              })
              .catch((error) => {
                AddInfoNotification(t("Something went wrong."));
                setLoadingModalOpen(false);
              });
          });
        })
        .catch((error: any) => {
          AddInfoNotification(t("Something went wrong."));
          setLoadingModalOpen(false);
        });
    }
  };

  const sendVerificationMail = () => {
    setLoadingModalOpen(true);
    if (auth.currentUser) {
      sendEmailVerification(auth.currentUser)
        .then(() => {
          AddInfoNotification("Verification email sent.");
          setLoadingModalOpen(false);
        })
        .catch(() => {
          AddInfoNotification("Something went wrong. Please try again later.");
          setLoadingModalOpen(false);
        });
    }
  };

  const handleKeyPresses = (e: any, type: string) => {
    if (e.key === "Escape") cancelEdit();
    if (e.key === "Enter") finishEdit(e.target.value, type);
  };

  const cancelEdit = () => {
    setEditStateHousehold(false);
    setEditStateName(false);
  };

  const finishEdit = (newLabel: string, type: string) => {
    if (type === "name" && newLabel.length > 0) {
      //Update Profile Name here
      let temp = { ...user };
      temp.name = newLabel;
      updateUser(temp);

      let tempHousehold = { ...currentHousehold };
      tempHousehold.members.forEach((m: MemberType) => {
        if (m.id === user.id) m.name = newLabel;
      });
      updateHousehold(tempHousehold);
    }
    if (type === "household" && newLabel.length > 0) {
      //Update Profile Household here
      let temp = { ...currentHousehold };
      temp.name = newLabel;
      updateHousehold(temp);
    }

    setEditStateHousehold(false);
    setEditStateName(false);
  };

  return (
    <Layout title={t("Settings") || "Settings"}>
      <Content>
        <SettingsBlock
          sx={{ marginBottom: 5 }}
          onClick={() => setSubscriptionDialogOpen(true)}
        >
          <SettingsRow sx={{ marginBottom: 0, padding: 2, height: "auto" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <ImageContainer>
                <img
                  src={pie}
                  //@ts-ignore
                  alt={t("illustration of an open gift box")}
                  style={{ width: "100%", objectFit: "contain" }}
                ></img>
              </ImageContainer>
              <div>
                <Typography variant="h5">
                  {premium ? t("Premium Member") : t("Free Member")}
                </Typography>
                <Typography variant="body1">
                  {premium ? t("All Features") : t("Basic Features")}
                </Typography>
              </div>
            </div>
            <ChevronRightIcon
              sx={{ color: theme.palette.primary.main, fontSize: 42 }}
            />
          </SettingsRow>
        </SettingsBlock>
        <Typography
          variant="h6"
          sx={{ mb: 1, ml: 0.5, color: theme.palette.text.disabled }}
        >
          {t("General")}
        </Typography>
        <SettingsBlock sx={shadowStyle}>
          <SettingsRow>
            <ColOne>{t("Name")}</ColOne>
            <EditableLabel
              editState={editStateName}
              label={user.name}
              handleKey={(e) => handleKeyPresses(e, "name")}
              handleBlur={(e) => finishEdit(e, "name")}
              handleOnClick={() => {
                setEditStateName(true);
              }}
              labelSx={{
                margin: 0,
              }}
              showEditable={true}
            />
          </SettingsRow>
          <SettingsRow sx={{ mb: 0 }}>
            <ColOne>{t("Currency")}</ColOne>
            <CurrencyPicker
              handleChange={(value: string) => {
                let newHousehold = { ...currentHousehold };
                newHousehold.currency = value;
                updateHousehold(newHousehold);
              }}
              hasTitle={false}
              formSx={{ width: "200px" }}
            />
          </SettingsRow>
        </SettingsBlock>
        <Typography
          variant="h6"
          sx={{ mb: 1, mt: 2, ml: 0.5, color: theme.palette.text.disabled }}
        >
          {t("Household")}
        </Typography>
        <SettingsBlock sx={shadowStyle}>
          <SettingsRow>
            <ColOne>{t("Name")}</ColOne>
            <EditableLabel
              editState={editStateHousehold}
              label={currentHousehold.name}
              handleKey={(e) => handleKeyPresses(e, "household")}
              handleBlur={(e) => finishEdit(e, "household")}
              handleOnClick={() => {
                setEditStateHousehold(true);
              }}
              labelSx={{
                margin: 0,
              }}
              showEditable={true}
            />
          </SettingsRow>
          <SettingsRow>
            <ColOne>{t("Household Members")}</ColOne>
            <ColTwo sx={{ justifyContent: "flex-end" }}>
              {currentHousehold.members.map(
                (member: MemberType, index: number) => {
                  return (
                    <MemberContainer key={member.id}>
                      <Typography sx={{ color: "inherit" }}>
                        {member.name}
                      </Typography>
                    </MemberContainer>
                  );
                }
              )}
            </ColTwo>
          </SettingsRow>
        </SettingsBlock>
        <CustomButton
          variant="outlined"
          onClick={
            premium ? OpenInviteDialog : () => setSubscriptionDialogOpen(true)
          }
          sx={{
            mb: 1,
            mr: 0,
            width: "200px",
            height: "40px",
            alignSelf: "flex-end",
          }}
        >
          {t("Invite New Member")}
        </CustomButton>
        <Typography
          variant="h6"
          sx={{ mb: 1, mt: 2, ml: 0.5, color: theme.palette.text.disabled }}
        >
          {t("App")}
        </Typography>
        <SettingsBlock sx={shadowStyle}>
          <SettingsRow>
            <ColOne>{t("Language")}</ColOne>
            <LanguagePicker />
          </SettingsRow>
          <SettingsRow>
            <ColOne>{t("Dark Mode")}</ColOne>
            <Switch
              value={darkMode}
              checked={darkMode}
              onChange={() => {
                const jsonValue = JSON.stringify(!darkMode);
                localStorage.setItem("@balancey_darkMode", jsonValue);
                setDarkMode(!darkMode);
              }}
            />
          </SettingsRow>
          <SettingsRow>
            <ColOne>{t("Version")}</ColOne>
            <ColOne>1.0.6</ColOne>
          </SettingsRow>
        </SettingsBlock>
        <Typography
          variant="h6"
          sx={{ mb: 1, mt: 2, ml: 0.5, color: theme.palette.text.disabled }}
        >
          {t("Account")}
        </Typography>
        <SettingsBlock sx={shadowStyle}>
          <SettingsRow>
            <ColOne>{t("Email")}</ColOne>
            <ColOne>{auth.currentUser?.email}</ColOne>
          </SettingsRow>
          <SettingsRow>
            <ColOne>{t("Status")}</ColOne>
            {auth.currentUser?.emailVerified ? (
              <ColOne sx={{ color: theme.palette.success.main }}>
                {t("Verified")}
              </ColOne>
            ) : (
              <ColOne sx={{ color: theme.palette.error.main }}>
                {t("Not verified")}
              </ColOne>
            )}
          </SettingsRow>
          <AuthButtons>
            {auth.currentUser?.emailVerified ? (
              <></>
            ) : (
              <CustomButton
                variant="text"
                onClick={sendVerificationMail}
                sx={{
                  justifyContent: "flex-start",
                  margin: 0,
                  height: 30,
                  textDecoration: "underline",
                }}
              >
                {t("Send Verification Mail")}
              </CustomButton>
            )}
            <CustomButton
              variant="text"
              onClick={() => setEmailChangeDialogOpen(true)}
              sx={{
                justifyContent: "flex-start",
                margin: 0,
                height: 30,
                textDecoration: "underline",
              }}
            >
              {t("Change Email")}
            </CustomButton>
            <CustomButton
              variant="text"
              onClick={() => setPasswordDialogOpen(true)}
              sx={{
                justifyContent: "flex-start",
                margin: 0,
                height: 30,
                textDecoration: "underline",
              }}
            >
              {t("Change Password")}
            </CustomButton>
            <CustomButton
              variant="text"
              onClick={LogOut}
              sx={{
                justifyContent: "flex-start",
                marginBottom: 0,
                height: 30,
                textDecoration: "underline",
              }}
            >
              {t("Log Out")}
            </CustomButton>
            <CustomButton
              variant="text"
              onClick={() => setReauthenticateOpen(true)}
              sx={{
                justifyContent: "flex-start",
                margin: 0,
                height: 30,
                textDecoration: "underline",
              }}
            >
              {t("Delete Account")}
            </CustomButton>
          </AuthButtons>
        </SettingsBlock>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <NavLink
            to={`/${languageCode}/privacy`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              fontFamily: "ApfelGrotezkRegular",
              textDecoration: "none",
              color: theme.palette.text.primary,
              fontSize: "14px",
            }}
          >
            {t("Privacy Policy")}
          </NavLink>
          <NavLink
            to={`/${languageCode}/terms`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              fontFamily: "ApfelGrotezkRegular",
              textDecoration: "none",
              fontSize: "14px",
              color: theme.palette.text.primary,
            }}
          >
            {t("Terms & Conditions")}
          </NavLink>
        </div>
      </Content>

      <InviteDialog open={inviteDialogOpen} setOpen={setInviteDialogOpen} />
      <UsernameChangeDialog
        open={emailChangeDialogOpen}
        setOpen={setEmailChangeDialogOpen}
      />
      <PasswordChangeDialog
        open={passwordDialogOpen}
        setOpen={setPasswordDialogOpen}
      />
      <AuthDialog
        open={reauthenticateOpen}
        setOpen={setReauthenticateOpen}
        sendAuth={DeleteAccount}
        buttonText={t("Confirm") || "Confirm"}
        //@ts-ignore
        header={t("Are you sure you want to delete your account?")}
        //@ts-ignore
        subheader={t("Please enter your password to confirm.")}
        passwordOnly
      />
      <SubscriptionContainer
        open={subscriptionDialogOpen}
        setOpen={setSubscriptionDialogOpen}
      />
      <LoadingModal open={loadingModalOpen} />
    </Layout>
  );
};

export default SettingsContainer;
