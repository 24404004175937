import React from 'react'
import { Doughnut, getDatasetAtEvent, getElementAtEvent } from 'react-chartjs-2'
import {Chart, ArcElement, Tooltip} from 'chart.js'
import { useUser } from '../../@common/contexts/UserContext';
import { categorySumForMonth, formatCurrency } from '../../main/resources/CalcRessources';
import { useEntry } from '../../@common/contexts/EntryContext';
import { useAppContext } from '../../@common/contexts/AppContext';
import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import { expandAnimation } from '../../@common/theme/Theme';
import CircleIcon from '@mui/icons-material/Circle';
import { externalTooltipHandler } from '../resources/GraphPlugins';
import { useTranslation } from 'react-i18next';
import { BlockType, CategoryType } from '../../main/resources/UserResources';

Chart.register(ArcElement, Tooltip);

  const StatsFlex = styled('div')(({ theme }) => ({
    position: "relative",
    display: "flex",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(4),
    //height: "22vw",
    maxHeight: '450px',
    minHeight: '396px',
    
    //Small
    [theme.breakpoints.down("lg")]: {
     flexDirection: "column",
     height: "fit-content",
     minHeight: "22px",

    
    },
    
     }
   ));

 

  const DoughnutContainer = styled('div')(({ theme }) => ({
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "70%",
    minHeight: "300px",
    

      //Small
      [theme.breakpoints.down("lg")]: {
        width: "100%",
       
       },

     }
   ));

   const LegendContainer = styled('div')(({ theme }) => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    margin: theme.spacing(3,0,1,2),

      //Small
      [theme.breakpoints.down("lg")]: {
        flexDirection: "row",
        flexWrap: "wrap",
        margin: theme.spacing(3,0,2,0)
       
       },
 
     }
   ));

   const LegendItem = styled('div')(({ theme }) => ({
    position: "relative",
    display: "flex",
    margin: theme.spacing(1,0),
    color: theme.palette.primary.main,

     }
   ));

  const Label = styled(Typography)(({ theme }) => ({
    position: "absolute",
    textAlign: "center",
    lineHeight: "2.2rem",
    color: theme.palette.text.primary,
    animation: expandAnimation + " 300ms ease",

    '& .sum': {
      fontSize: "2.8rem"
    },

  //Large
  [theme.breakpoints.down("xl")]: {
    lineHeight: "1.8rem",
    '& .sum': {
      fontSize: "2rem"
    },
   },
  
  //Small
  [theme.breakpoints.down("lg")]: {
    lineHeight: "1.5rem",
    '& .sum': {
      fontSize: "1.8rem"
    },
   },

  
  
  }));

  type DoughnutChartProps = {
    thisTimeStamp : string;
    budgetAvaiable : boolean;
    setSpending: (spending: number) => void;
    remainingObject: any;

  };

const DoughnutChart = ({thisTimeStamp, budgetAvaiable, setSpending, remainingObject} : DoughnutChartProps) => {
    const {blocks, user, currentHousehold} = useUser();
    const { allEntries } = useEntry();
    const {overviewState, month, year, color_series} = useAppContext();
    const {t} = useTranslation();
    const chartRef = React.useRef();
    const [labelElement, setLabelElement] = React.useState<JSX.Element>(<Label sx={{position: "relative", margin: "auto"}}>{t("Nothing to see here.")}</Label>)
    const [dataDetails, setDataDetails] = React.useState<any>(null);
    const [borderWidth, setBorderWidth] = React.useState<number | number[]>(0);
    const [offset, setOffset] = React.useState<number | number[]>(0);
    const {shadowStyle} = useAppContext();

    const DoughnutOptions : any = {
      responsive: true,
      maintainAspectRatio: false,
      cutout: "85%",
      layout: { padding: 0},
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          external:  externalTooltipHandler,
        },
      },
      currency: currentHousehold.currency
    };

    let activeBlocks = [...blocks];
    activeBlocks = activeBlocks.filter((item : BlockType) => {
        if (item.status === "deleted" && Number(thisTimeStamp) >= Number(item.deleted)) return false;
        if (Number(item.created) > Number(thisTimeStamp)) return false;
        return true;
      })

    const getBlockSum = (categories : CategoryType[]) => {
        let s = 0;
        let cats = categories.filter((categ : CategoryType) => {
          if (categ.status === "deleted" && Number(thisTimeStamp) >= Number(categ.deleted)) return false;
          if (Number(categ.created) > Number(thisTimeStamp)) return false;
          return true;
        })

        cats.forEach(element => {
            s = s + categorySumForMonth(user.id, allEntries, element.label, month, year);
            
        });
        return Math.round(Math.max(s,0) * 100) / 100;
    }

    const getBlockRemaining = (categories : CategoryType[]) => {
        let s = 0;
        let cats = categories.filter((categ : CategoryType) => {
          if (categ.status === "deleted" && Number(thisTimeStamp) >= Number(categ.deleted)) return false;
          if (Number(categ.created) > Number(thisTimeStamp)) return false;
          return true;
        }) 
        cats.forEach(element => {
          s = s + ((remainingObject[element.label] + element.budget[thisTimeStamp] + element.startingBalance) - categorySumForMonth(user.id, allEntries, element.label, month, year));            
        });
        return Math.round(Math.max(s,0) * 100) / 100;
    }

    const getBlockPlanned = (categories : CategoryType[]) => {
        let s = 0;
        let cats = categories.filter((categ : CategoryType) => {
          if (categ.status === "deleted" && Number(thisTimeStamp) >= Number(categ.deleted)) return false;
          if (Number(categ.created) > Number(thisTimeStamp)) return false;
          return true;
        })

        cats.forEach(element => {
            s = s + element.budget[thisTimeStamp];
            
        });
        return Math.round(Math.max(s,0) * 100) / 100;;
    }    
    
    const spentData = React.useMemo(() => {return{
        labels: activeBlocks.map((b : BlockType) => b.name),
        datasets: [{
          data: activeBlocks.map((b : BlockType) => getBlockSum(b.categories)),
          backgroundColor: color_series,
          hoverBackgroundColor: color_series,
          borderWidth: borderWidth,
          borderColor: color_series,
          hoverBorderColor: color_series,
          hoverBorderWidth: borderWidth,
          offset: offset,
          borderRadius: 0,
        },
    ],
    };}, [borderWidth, activeBlocks])

    const remainingData = React.useMemo(() => {return{
        labels: activeBlocks.map((b : BlockType) => b.name),
        datasets: [{
          data: activeBlocks.map((b : BlockType) => getBlockRemaining(b.categories)),
          backgroundColor: color_series,
          hoverBackgroundColor: color_series,
          borderWidth: borderWidth,
          borderColor: color_series.map((c : string) => c + "10"),
          hoverBorderColor: color_series.map((c : string) => c + "10"),
          hoverBorderWidth: borderWidth,
          offset: offset,

        },
      ],
      };}, [borderWidth, activeBlocks])

    const plannedData = React.useMemo(() => {return{
        labels: activeBlocks.map((b : BlockType) => b.name),
        datasets: [{
          data: activeBlocks.map((b : BlockType) => getBlockPlanned(b.categories)),
          backgroundColor: color_series,
          hoverBackgroundColor: color_series,
          borderWidth: borderWidth,
          borderColor: color_series,
          hoverBorderColor: color_series,
          hoverBorderWidth: borderWidth,
          offset: offset,

        },
      ],
      };}, [borderWidth, activeBlocks])

    const remainingSum = remainingData.datasets[0].data.reduce((accumulator, value) => {
      return accumulator + value;
    }, 0);

    const spentSum = spentData.datasets[0].data.reduce((accumulator, value) => {
      return accumulator + value;
    }, 0);

    const plannedSum = plannedData.datasets[0].data.reduce((accumulator, value) => {
      return accumulator + value;
    }, 0);

    React.useEffect(() => {
    let rounded = Math.round(spentSum * 100) / 100;
      setSpending(rounded);
    }, [spentSum, setSpending, month, year])
    
    React.useEffect(() => {
      if (budgetAvaiable === false){
        setLabelElement(<Label sx={{position: "relative", margin: "auto"}}>{t("Nothing to see here.")}</Label>);
      }
      else {
        if (overviewState === "spent"){
          if (dataDetails != null) {
            setLabelElement(<Label key={"spent"+dataDetails.label}>{dataDetails.label}: <br/><span className='sum'>{formatCurrency(dataDetails.data, currentHousehold.currency)}</span></Label>);
          }
          else {
            setLabelElement(<Label key={"spent"}>{t("Spent")}: <br/><span className='sum'>{formatCurrency(spentSum, currentHousehold.currency)}</span></Label>);
          }
        }
        else if (overviewState === "remaining"){
          if (dataDetails != null) {
            setLabelElement(<Label key={"remainingCat"+dataDetails.label}>{dataDetails.label}: <br/><span className='sum'>{formatCurrency(dataDetails.data, currentHousehold.currency)}</span></Label>);
          }
          else {
            setLabelElement(<Label key={"remaining"}>{t("Remaining")}: <br/><span className='sum'>{formatCurrency(remainingSum, currentHousehold.currency)}</span></Label>);
          }
        }
        else if (overviewState === "planned"){
          if (dataDetails != null) {
            setLabelElement(<Label key={"plannedCat"+dataDetails.label}>{dataDetails.label}: <br/><span className='sum'>{formatCurrency(dataDetails.data, currentHousehold.currency)}</span></Label>);
          }
          else {
            setLabelElement(<Label key={"planned"}>{t("Planned")}: <br/><span className='sum'>{formatCurrency(plannedSum, currentHousehold.currency)}</span></Label>);
          }
        }
      }
      }, [budgetAvaiable, overviewState, currentHousehold.currency, plannedSum, spentSum, remainingSum, dataDetails])

      React.useEffect(() => {
        setDataDetails(null)
        setBorderWidth(0)
        setOffset(0);
        }, [overviewState])

    const clickCanvas = (event : any) => {
      if(chartRef.current) {

        const el = getElementAtEvent(chartRef.current, event);
        

        if (el.length > 0)
        {
          const details = {
            label: activeBlocks[el[0].index].name,
            //@ts-ignore
            data: el[0].element.$context.raw,
          }
      
        let temp = activeBlocks.map((v : BlockType) => 0);

        if (dataDetails && dataDetails.label === details.label)
        {
          setDataDetails(null)
          setBorderWidth(temp);
          setOffset(temp);

        }
        else {
          setDataDetails(details);
          temp[el[0].index] = 5;
          setBorderWidth(temp);
          let offsetTemp = [...temp]
          offsetTemp[el[0].index] = 15;
          setOffset(offsetTemp);
        }
        }
      }
      };


    if (budgetAvaiable === false){
      return(
        <StatsFlex>
        {labelElement}
        </StatsFlex>
      )
    }

    if (overviewState === "spent"){
        return (
          <>
          <StatsFlex sx={ shadowStyle }>
            <DoughnutContainer>
            <Doughnut 
            //@ts-ignore
            data={spentData} options={DoughnutOptions} ref={chartRef} onClick={clickCanvas}/>
            {labelElement}
            </DoughnutContainer>
            <LegendContainer>
              {spentData.labels.map((label : string, index : number) => {
                return(
                  <LegendItem key={label}>
                  <CircleIcon sx={{mr: 1, ml:1, color: color_series[(index % color_series.length)]}}/>
                  <Typography sx={{fontSize: "16px"}}>{label}</Typography>
                </LegendItem>
                )
              })

              }
            </LegendContainer>
          </StatsFlex>
          </>

          )
    }
    else if (overviewState === "planned"){
        return (
        <>
        <StatsFlex sx={ shadowStyle }>
          <DoughnutContainer>
          <Doughnut 
              //@ts-ignore
          data={plannedData} options={DoughnutOptions} ref={chartRef} onClick={clickCanvas}/>
          {labelElement}
          </DoughnutContainer>
          <LegendContainer>
              {plannedData.labels.map((label : string, index : number) => {
                return(
                  <LegendItem key={label}>
                  <CircleIcon sx={{mr: 1, ml: 1, color: color_series[(index % color_series.length)]}}/>
                  <Typography sx={{fontSize: "16px"}}>{label}</Typography>
                </LegendItem>
                )
              })

              }
            </LegendContainer>
        </StatsFlex>
        </>          )
    }
  return (
    <>
    <StatsFlex sx={ shadowStyle }>
    <DoughnutContainer >
        <Doughnut 
            //@ts-ignore
        data={remainingData} options={DoughnutOptions} ref={chartRef} onClick={clickCanvas}/>
        {labelElement}
        </DoughnutContainer>
        <LegendContainer>
              {remainingData.labels.map((label : string, index : number) => {
                return(
                  <LegendItem key={label}>
                  <CircleIcon sx={{mr: 1, ml: 1, color: color_series[(index % color_series.length)]}}/>
                  <Typography sx={{fontSize: "16px"}}>{label}</Typography>
                </LegendItem>
                )
              })

              }
          </LegendContainer>
    </StatsFlex>



    </>
  )
}

export default DoughnutChart