import { Button, Typography, useTheme } from "@mui/material";
import { Chart, LinearScale } from "chart.js";
import { SankeyController, Flow } from "chartjs-chart-sankey";
import React from 'react'
import { useAppContext } from "../../@common/contexts/AppContext";
import { useEntry } from "../../@common/contexts/EntryContext";
import { useUser } from "../../@common/contexts/UserContext";
import { categorySumForMonth, getSumforMonth } from "../resources/CalcRessources";
import { makeTimeStamp } from "../../overview/resources/helpers";
import { externalLineTooltipHandler, externalSankeyTooltipHandler } from "../../insights/resources/GraphPlugins";
import { useTranslation } from "react-i18next";
import { defaultBlocks, defaultCategories } from "../../@common/theme/Theme";
import SubscriptionContainer from "../SubscriptionContainer";


function AdMonthlySankey() {
    Chart.register(LinearScale, SankeyController, Flow);
    const {color_series} = useAppContext();
    const {currentHousehold} = useUser();
    const theme = useTheme();
    const {t} = useTranslation();
    const [subscriptionOpen, setSubscriptionOpen] = React.useState(false);

    const createDataSet = React.useMemo(() => {
      const dataArray = [];
      let colorObj = {};
      colorObj[t("Spent")] = theme.palette.error.light;
      colorObj[t("Income")] = theme.palette.info.light;
      colorObj[t("Saved")] = theme.palette.success.light;

      const blockExpenses = [1300, 1100, 700, 900, 400]

      for (let i = 0; i < defaultBlocks.length; i++){
        colorObj[t(defaultBlocks[i])] = color_series[i];

        let tempObject = {from: t("Spent"), to: t(defaultBlocks[i]), flow: blockExpenses[i]};
        dataArray.push(tempObject);
      }

       const FixedExpenses = [800, 200, 100, 100, 100]
       defaultCategories[0].forEach((element, elementIndex) => {
         colorObj[t(element)] = color_series[0];
         let catObject = {from: t(defaultBlocks[0]), to: t(element), flow: FixedExpenses[elementIndex]};
         dataArray.push(catObject);
       })
       const Living = [300, 200, 100, 300, 200]
       defaultCategories[1].forEach((element, elementIndex) => {
         colorObj[t(element)] = color_series[1];
         let catObject = {from: t(defaultBlocks[1]), to: t(element), flow: Living[elementIndex]};
         dataArray.push(catObject);
       })
       const Fun = [100, 100, 500]
       defaultCategories[2].forEach((element, elementIndex) => {
         colorObj[t(element)] = color_series[2];
         let catObject = {from: t(defaultBlocks[2]), to: t(element), flow: Fun[elementIndex]};
         dataArray.push(catObject);
       })
       const Other = [200, 200, 500]
       defaultCategories[3].forEach((element, elementIndex) => {
         colorObj[t(element)] = color_series[3];
         let catObject = {from: t(defaultBlocks[3]), to: t(element), flow: Other[elementIndex]};
         dataArray.push(catObject);
       })
       const Savings = [200, 200]
       defaultCategories[4].forEach((element, elementIndex) => {
         colorObj[t(element)] = color_series[4];
         let catObject = {from: t(defaultBlocks[4]), to: t(element), flow: Savings[elementIndex]};
         dataArray.push(catObject);
       })

        let incomeObject = {from: t("Income"), to: t("Spent"), flow: 4400};
        dataArray.push(incomeObject);
        let savedObject = {from: t("Income"), to: t("Saved"), flow: 1500};
        dataArray.push(savedObject);


      return {data:dataArray, color:colorObj};
    }, [])



    React.useEffect(() => {
        var ctx = document.getElementById("chart").getContext("2d");
        const dataObject = createDataSet;
        const colors = dataObject.color;
   
    
        function getColor(name) {
          return colors[name] || color_series[0];
        }

        const myChart = new Chart(ctx, {
            type: "sankey",
            data: {
              datasets: [
                {
                  data: dataObject.data,
                  colorFrom: c => getColor(c.dataset.data[c.dataIndex].from),
                  colorTo: c => getColor(c.dataset.data[c.dataIndex].to),
                  borderWidth: 0,
                  color: theme.palette.text.primary,
                }
              ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                currency: currentHousehold.currency,
                layout: {padding: 16},
                plugins: {
                    legend: {
                      display: false,
                    },
                    tooltip: {
                      enabled: false,
                      external:  externalSankeyTooltipHandler,
                    },
                  },

            }
          });

          return() => {
            myChart.destroy();
          }
    
    }, [])
    
  return (
    <>
    <div style={{height: "100%"}}>
      <div style={{position: "absolute", top: "45%", right: "43%",  zIndex: 10, display: "flex", flexDirection: "column"}}>
    <Typography variant='body1' style={{textAlign: "center"}}>{t("Available in Premium")}</Typography>
    <Button variant='contained' size="large" style={{marginTop: "1rem"}} onClick={() => setSubscriptionOpen(true)}>{t("Learn more")}</Button>
    </div>
    <div style={{height: "100%", width: "100%", opacity: 0.2}}>
  <canvas id="chart"></canvas>
    </div>
    </div>
    <SubscriptionContainer open={subscriptionOpen} setOpen={setSubscriptionOpen}/>
    </>

  )
}

export default AdMonthlySankey