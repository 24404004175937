import { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase/firebase';


function useAuth() {
  const [user, loading, error] = useAuthState(auth);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [waitforResponse, setWaitForResponse] = useState(false);


  useEffect(() => {
      if (loading || waitforResponse) {
        setIsLoading(true);
        return;
      }
      setIsLoading(false);
      if (user) setIsAuthenticated(true);
      else setIsAuthenticated(false);
    }, [user, loading, waitforResponse]);


  return {
    isAuthenticated,
    isLoading,
    setWaitForResponse,
  };
}
export default useAuth;

