import React from 'react'
import iosStore from '../../assets/images/BuyButtons/ios.svg';
import playStore from '../../assets/images/BuyButtons/google-play-badge.png';
import { Button, Container, styled, Typography, useTheme } from "@mui/material";

const ButtonContainer = styled('div')(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    position: "static",
    bottom: 0,
    transform: 'scale(1.01)',

    '& > div' : {
      display: "flex",
      alignItems: "flex-end",
    },

    [theme.breakpoints.down(2200)]: {
      transform: 'scale(0.8)',
    },


    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      transform: 'scale(0.7)',
    },
  
  
  }));

const StoreButtons = () => {
  return (
    <ButtonContainer>
      <div style={{ height: 80, width: 250, padding: 3}}>
          <a
              style={{ background: `url(${iosStore}) no-repeat`,
              backgroundSize: 'contain',
              backgroundPosition: 'bottom',
              display: 'inline-block',
              overflow: 'hidden',
              textDecoration: 'none',
              height: 75,
              width: '100%',
              }}
              href={"https://apps.apple.com/us/app/balancey/id6447350986"}
              target="_blank"
              rel="noopener noreferrer"
              
      
          >

          </a>
        </div>
        <div style={{ height: 80, width: 250, padding: 3}}>
          <a
              style={{ background: `url(${playStore}) no-repeat`,
              backgroundSize: 'contain',
              backgroundPosition: 'bottom',
              display: 'inline-block',
              overflow: 'hidden',
              textDecoration: 'none',
              height: 75,
              width: '100%',
              }}
              href={"https://play.google.com/store/apps/details?id=com.vanillanoir.balancey"}
              target="_blank"
              rel="noopener noreferrer"
              
      
          >

          </a>
        </div>
    </ButtonContainer>
  )
}

export default StoreButtons