import { UserType } from "../../main/resources/UserResources";



const hasUserCategory = (user : UserType, category : string) => {

for (let i = 0; i<user.blocks?.length; i++){
    for (let j = 0; j<user.blocks[i].categories.length; j++){
        if (category === user.blocks[i].categories[j].label) return true;
    }
}

return false;
}

const getCategoryByLabel = (user : UserType, label : string) => {
    for (let i = 0; i<user.blocks?.length; i++){
        for (let j = user.blocks[i].categories.length-1; j>=0; j--){
            if (label === user.blocks[i].categories[j].label) return user.blocks[i].categories[j];
        }
    }
    
    return null;
    }



export {hasUserCategory, getCategoryByLabel}