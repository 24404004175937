import NumberFormat, { InputAttributes } from 'react-number-format';
import React from 'react'
import { makeTimeStamp } from '../../overview/resources/helpers';
import { EntryType } from '../../transactions/resources/EntryRessources';
import { BlockType, CategoryType, HouseholdType, MemberType } from './UserResources';


function getSumforMonth(userId : string, type : string, entries : EntryType[], month : number, year : number) : number {
  let s = 0;
    //We go backwards through all entries, so newest come first
    for (let i = entries.length-1; i >= 0; i--){
      const thisDate = entries[i].date.toDate();
      //Stop looking if the year is smaller than our search year
      if (thisDate.getFullYear() < year) return(Math.round(s * 100) / 100);
      //Stop looking if the month is smaller than our search month, assuming we are in the right year
      if (thisDate.getMonth() < month && thisDate.getFullYear() === year) return(Math.round(s * 100) / 100);
      //This entry is either in the right month or later. If it's in the right month, we check it
      if (thisDate.getMonth() === month && thisDate.getFullYear() === year && entries[i].type === type)
      {
        s = s + (Number(entries[i].payee[userId]) || 0);
      }

    }

  let rounded = Math.round(s * 100) / 100;
  return(rounded);
}

function getSumforYear(userId : string, type : string, entries : EntryType[], year : number) : number {
  let s = 0;
    //We go backwards through all entries, so newest come first
    for (let i = entries.length-1; i >= 0; i--){
      const thisDate = entries[i].date.toDate();
      //Stop looking if the year is smaller than our search year
      if (thisDate.getFullYear() < year) return(Math.round(s * 100) / 100);
      //This entry is either in the right year or later. If it's in the right year, we check it
      if (thisDate.getFullYear() === year && entries[i].type === type)
      {
        s = s + (Number(entries[i].payee[userId]) || 0);
      }

    }

  let rounded = Math.round(s * 100) / 100;
  return(rounded);
}

const getFund = (userId : string, entries : EntryType[], blocks : BlockType[], month : number, year : number) => {
  const monthStamp = makeTimeStamp(year,month);
    let income = 0;
    let planned = 0;
    let s = 0;
    let allDeleted = true;

    if (blocks != null){
      blocks.forEach((block : BlockType) => {
        if (block.categories != null && block.categories.length > 0){
          block.categories.forEach((element : CategoryType) => {
            if (element.status === "okay")  {
              allDeleted = false;
            }
          })
        }
     
        if (!allDeleted)
      {
        block.categories.forEach((element : CategoryType) => {
          if (typeof element.budget[monthStamp] !== 'undefined' && element.status !== "deleted"){
            planned = planned + Number(element.budget[monthStamp]);
          }
        })
      }
        })
    }
  
    //  setBudgetAvailable(budgetAv);
      s = income - planned;

      if (entries != null){
        //We go backwards through all entries, so newest come first
        for (let i = entries.length-1; i >= 0; i--){
          //Only include income
          if (entries[i].type === "income"){
          const thisDate = entries[i].date.toDate();
          //Stop looking if the year is smaller than our search year
          if (thisDate.getFullYear() < year) return(Math.round(s * 100) / 100);
          //Stop looking if the month is smaller than our search month, assuming we are in the right year
          if (thisDate.getMonth() < month && thisDate.getFullYear() === year) return(Math.round(s * 100) / 100);
          //This entry is either in the right month or later. If it's in the right month, we check it
          if (thisDate.getMonth() === month && thisDate.getFullYear() === year)
          {
            income = income + (Number(entries[i].payee[userId]) || 0);
            s = income - planned;
          }
        }
        }
        }
    
     return(Math.round(s * 100) / 100);
}


const categorySumForMonth = (userId : string, entries : EntryType[], category : string, month : number, year : number) => {
  let s = 0;

    //We go backwards through all entries, so newest come first
    for (let i = entries.length-1; i >= 0; i--){
      const thisDate = entries[i].date.toDate();
      //Stop looking if the year is smaller than our search year
      if (thisDate.getFullYear() < year) return(Math.round(s * 100) / 100);
      //Stop looking if the month is smaller than our search month, assuming we are in the right year
      if (thisDate.getMonth() < month && thisDate.getFullYear() === year) return(Math.round(s * 100) / 100);
      //This entry is either in the right month or later. If it's in the right month, we check it
      if (thisDate.getMonth() === month && thisDate.getFullYear() === year && entries[i].category === category && entries[i].type === "expense")
      {
        s = s + (Number(entries[i].payee[userId]) || 0);
      }

    }

    let rounded = Math.round(s * 100) / 100;
    return(rounded);
}

const categorySumForYear = (userId : string, entries : EntryType[], category : string, year : number) => {
  let s = 0;

    //We go backwards through all entries, so newest come first
    for (let i = entries.length-1; i >= 0; i--){
      const thisDate = entries[i].date.toDate();
      //Stop looking if the year is smaller than our search year
      if (thisDate.getFullYear() < year) return(Math.round(s * 100) / 100);
      //This entry is either in the right year or later. If it's in the right year, we check it
      if (thisDate.getFullYear() === year && entries[i].category === category && entries[i].type === "expense")
      {
        s = s + (Number(entries[i].payee[userId]) || 0);
      }

    }

  return(Math.round(s * 100) / 100);
}


const getThisMonthRemaining = (userId : string, entries : EntryType[], blocks : BlockType[], month : number, year : number) => {
let returnObj : any = {};
const thisTimeStamp = makeTimeStamp(year,month);


//We add all categories for this month to the return Object
blocks.forEach((block : BlockType) => {
  if (block.categories != null && block.categories.length > 0){
    let cats = block.categories.filter((categ : CategoryType) => {
      if (categ.status === "deleted" && Number(thisTimeStamp) >= Number(categ.deleted)) return false;
      if (Number(categ.created) > Number(thisTimeStamp)) return false;
      return true;
    })

    cats.forEach((cat : CategoryType) => {
      returnObj[cat.label] = 0;
      //We add each prior budget into this object
      const budgetArray = Object.values(cat.budget);
      budgetArray.forEach((element : any, index) => {
        if (Number(Object.keys(cat.budget)[index]) < Number(thisTimeStamp))
        {
          returnObj[cat.label] += element;
        }
      })
    //  let starting = cat.startingBalance || 0;
      let secret = cat.secretBalance || 0;

      returnObj[cat.label] = Math.round((returnObj[cat.label] + secret) * 100) / 100;


    })
  }
  })
   //We go forwards through all entries, so oldest come first
   //TO DO: Maybe we could cache an object that shows for each category -> each month -> expense and only it based on lastmodified
   //Should make calculations much easier
   for (let i = 0; i < entries.length; i++){
    const elementStamp = Number(makeTimeStamp(entries[i].date.toDate().getFullYear(), entries[i].date.toDate().getMonth()));
    //Stop looking if entries are too new
    if(elementStamp >= Number(thisTimeStamp)) return(returnObj);

    //Entry is okay - subtract it from the right category 
    let expense = Number(entries[i].payee[userId]) || 0;
    let defaultValue =  returnObj[entries[i].category] || 0;
    returnObj[entries[i].category] = defaultValue - expense;
    returnObj[entries[i].category] = Math.round(returnObj[entries[i].category] * 100) / 100;
  }

  return(returnObj);
}

const calculateBalance = (currentHousehold : HouseholdType, entries : EntryType[]) => {
    let members : any = {};
    currentHousehold.members.forEach((m : MemberType) => {
      members[m.id] = 0;
    })


    entries.forEach((entry : EntryType) => {
         //See if the balance changes
          if ((entry.type === "expense" && Object.keys(entry.payee).length > 1) || entry.type === "payment"){
            //Set payer balance
            if (members[entry.payer] != null) {
              let payed = 0;
              Object.values(entry.payee).forEach((value : any) => {
                payed = payed + Math.round(Number(value) * 100) / 100;
              })
              members[entry.payer] = members[entry.payer] + payed;
            }
            //Set payee balance
            Object.keys(entry.payee).forEach((key : string) => {
              if (members[key] != null){
                members[key] = members[key] - Math.round(Number(entry.payee[key]) * 100) / 100;
               members[key] = Math.round(members[key] * 100) / 100;
              } 
            })
    }
    })

    return members;
}

const getSharedExpensePerCategory = (entries : EntryType[], category: string, month : number, year : number) => {
  let s = 0;

  //We go backwards through all entries, so newest come first
  for (let i = entries.length-1; i >= 0; i--){
    //Only check payments and expenses
    if (entries[i].type === "expense" && Object.keys(entries[i].payee).length > 1){
    const thisDate = entries[i].date.toDate();
    //Stop looking if the year is smaller than our search year
    if (thisDate.getFullYear() < year) return(Math.round(s * 100) / 100);
    //Stop looking if the month is smaller than our search month, assuming we are in the right year
    if (thisDate.getMonth() < month && thisDate.getFullYear() === year) return(Math.round(s * 100) / 100);
    //This entry is either in the right month or later. If it's in the right month, we check it
    if (thisDate.getMonth() === month && thisDate.getFullYear() === year && entries[i].category === category)
    {
      s = s + (Number(entries[i].price) || 0);
    }

  }}

  let rounded = Math.round(s * 100) / 100;
  return(rounded);
  
}

const getPaymentPerMonth = (memberId : string, entries : EntryType[], category: string, month : number, year : number) => {
  let s = 0;

  //We go backwards through all entries, so newest come first
  for (let i = entries.length-1; i >= 0; i--){
    //Only check payments and expenses
    if (entries[i].type === "expense" && Object.keys(entries[i].payee).length > 1){
    const thisDate = entries[i].date.toDate();
    //Stop looking if the year is smaller than our search year
    if (thisDate.getFullYear() < year) return(Math.round(s * 100) / 100);
    //Stop looking if the month is smaller than our search month, assuming we are in the right year
    if (thisDate.getMonth() < month && thisDate.getFullYear() === year) return(Math.round(s * 100) / 100);
    //This entry is either in the right month or later. If it's in the right month, we check it
    if (thisDate.getMonth() === month && thisDate.getFullYear() === year && entries[i].category === category && entries[i].payer === memberId)
    {
      s = s + (Number(entries[i].price) || 0);
    }

  }}

  let rounded = Math.round(s * 100) / 100;
  return(rounded);
  
}

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    allowNegative: boolean;
  }

const NumberFormatCustom = React.forwardRef<
  NumberFormat<InputAttributes>,
  CustomProps
>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value:  values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      decimalScale={2}
      fixedDecimalScale={true}

    />
  );
});

const formatCurrency = (value : number, currency : string, hasPlus?: boolean) => {
  let rounded = Math.round(value * 100) / 100;
  let sym = hasPlus && rounded >= 0? "+":"";
  if (rounded >= 10000000) {
    rounded = 9999999;
    sym = "> "
  }
  else if (rounded <= -10000000) {
    rounded = 9999999;
    sym = "< -"
  }
  else if (rounded < 0) {
    rounded = Math.abs(rounded)
    sym="-"
  }
  
  return (
    <NumberFormat
      value={rounded.toFixed(2)}
      style={{whiteSpace: "nowrap"}}
      displayType={'text'}
      thousandSeparator={true}
      renderText={formattedValue => <><span className='currencyFont'>{sym}{getCurrencyPrefix(currency)}</span>{formattedValue}<span className='currencyFont'>{getCurrencySuffix(currency)}</span></>}
    />
  );
}

const formatCurrencyAsString = (value : number, currency : string) => {
  let rounded = Math.round(value * 100) / 100;
  let sym = "";
  if (rounded >= 10000000) {
    rounded = 9999999;
    sym = ">"
  }
  return String(sym + getCurrencyPrefix(currency) + rounded.toFixed(2) + getCurrencySuffix(currency));
}

const removeCurrencyFromString = (label : string, currency : string) => {
  let ret = label;
  if(label && label.length > 0 && label.indexOf(currency) > -1) ret = label.replace(currency,'');
return ret;
}

const getCurrencyPrefix = (currency : string) => {
const prefixChars : any = {
  ARS: "$",
  AUD: "$",
  BRL: "R$",
  GBP: "£",
  CAD: "$",
  CLP: "$",
  CNY: "¥",
  COP: "$",
  DKK: "kr ",
  HKD: "HK$",
  HUF: "Ft",
  INR: "₹",
  JPY: "¥",
  KRW: "₩",
  MYR: "RM",
  MXN: "$",
  NZD: "$",
  NOK: "kr",
  PHP: "₱",
  SGD: "$",
  ZAR: "R",
  CHF: "fr.",
  USD: "$",
};

let ind = Object.keys(prefixChars).indexOf(currency);

if (ind !== -1)
{
  return prefixChars[currency];
}
return "";
}



const getCurrencySuffix = (currency : string) => {
  const prefixChars : any= {
    CZK: "Kč",
    EUR: "€",
    HUF: "Ft",
    MAD: "د.م.",
    PLN: "zł",
    RUB: "₽",
    SAR: "﷼",
    SEK: "kr",
    THB: "฿",
    TRY: "₺",
    VND: "₫",
  };
  
  let ind = Object.keys(prefixChars).indexOf(currency);
  
  if (ind !== -1)
  {
    return prefixChars[currency];
  }
  return "";
  }


  const getStandardDate = (now : Date) => {
    return new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds()));
  }



export { getSumforMonth, getSumforYear, getFund, categorySumForMonth, categorySumForYear, getThisMonthRemaining, NumberFormatCustom, formatCurrency, formatCurrencyAsString, removeCurrencyFromString, calculateBalance, getPaymentPerMonth, getSharedExpensePerCategory, getStandardDate }