import React, { useCallback, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/en-gb";
import "dayjs/locale/en";
import "dayjs/locale/de";
import { styled } from "@mui/system";
import {
  Button,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEntry } from "../../@common/contexts/EntryContext";
import { useUser } from "../../@common/contexts/UserContext";
import {
  NumberFormatCustom,
  getStandardDate,
} from "../../main/resources/CalcRessources";
import { useAppContext } from "../../@common/contexts/AppContext";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Timestamp } from "firebase/firestore";
import CloseIcon from "@mui/icons-material/Close";
import { makeTimeStamp } from "../../overview/resources/helpers";
import renderCategorySelectItem from "./CategorySelectItem";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DeleteDialog from "../../@common/components/DeleteDialog";
import { useTranslation } from "react-i18next";
import { EntryType } from "../resources/EntryRessources";
import { BlockType, MemberType } from "../../main/resources/UserResources";

const CustomDialog = styled(Dialog)(({ theme }) => ({
  marginTop: "15vh",

  "& .MuiDialog-container": {
    alignItems: "flex-start",
  },

  "& .MuiPaper-root": {
    maxHeight: "80%",
    minWidth: "350px",
    borderRadius: "10px",
    padding: theme.spacing(2, 4),
  },

  "& .MuiInputBase-root": {
    borderRadius: "7px",
  },

  //Mobile screens
  [theme.breakpoints.down("md")]: {
    marginTop: "1vh",

    "& .MuiPaper-root": {
      maxHeight: "90%",
      minWidth: "300px",
      padding: theme.spacing(0, 3),
    },
  },
}));

const CloseButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  margin: theme.spacing(2),
  padding: 0,
  width: "fit-content",
  minWidth: "auto",
  right: 0,
  top: 0,
}));

const SecretButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  margin: theme.spacing(2),
  padding: 0,
  width: "fit-content",
  minWidth: "auto",
  left: 0,
  top: 0,
}));

const PayeeBox = styled("div")(({ theme }) => ({
  position: "relative",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  margin: theme.spacing(1.5, 0),

  "& .MuiTypography-root": {
    fontSize: "18px",
  },
}));

const ButtonFlexBox = styled("div")(({ theme }) => ({
  display: "flex",
  marginTop: "1rem",

  "& > button": {
    flexBasis: "50%",
  },
}));

type EditDialogType = {
  open: boolean;
  setOpen: (open: boolean) => void;
  entry: EntryType;
};

const EditDialog = ({ open, setOpen, entry }: EditDialogType) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const { updateEntry, removeEntry } = useEntry();
  const { blocks, user, currentHousehold, premium } = useUser();
  const [price, setPrice] = React.useState<string>("");
  const [merchant, setMerchant] = React.useState<string>("");
  const [category, setCategory] = React.useState<string>("");
  const [blockIndex, setBlockIndex] = React.useState<number | null>(null);
  const [payer, setPayer] = React.useState<string>(user.id);
  const otherMember =
    currentHousehold.members.find(
      (member: MemberType) => member.id !== user.id
    ) || null;
  const [paymentReceiver, setPaymentReceiver] = React.useState<string>(
    otherMember?.id || ""
  );
  const [payee, setPayee] = React.useState<any>({});
  const [isRecurring, setIsRecurring] = React.useState<boolean>(false);
  const [dateValue, setDateValue] = React.useState<Dayjs | null>(dayjs());
  const { month, year } = useAppContext();
  const thisTimeStamp = makeTimeStamp(year, month);
  const [entryType, setEntryType] = useState("expense");
  const [splitEntry, setSplitEntry] = useState(false);
  const [splitVal, setsplitVal] = React.useState<string[]>([]);
  const [active, setActive] = React.useState<boolean>(false);
  const { t, i18n } = useTranslation();

  const pickerLang = i18n.language;

  useEffect(() => {
    if (entry != null && open === true) {
      setPrice(entry.price);
      setMerchant(entry.merchant);
      setCategory(entry.category);
      setBlockIndex(entry.blockIndex);
      setPayer(entry.payer);
      setPayee(entry.payee);
      setDateValue(dayjs(entry.date.toDate()));
      setIsRecurring(entry.recurring);
      setEntryType(entry.type);

      if (entry.type === "payment")
        setPaymentReceiver(Object.keys(entry.payee)[0]);

      if (Object.keys(entry.payee).length > 1) {
        setSplitEntry(true);
      }

      if (
        currentHousehold.members.length > 1 &&
        Object.keys(entry.payee).length > 1
      ) {
        console.log(entry.payee);
        let tempVal: string[] = [];

        currentHousehold.members.forEach((member: MemberType) => {
          if (entry.payee[member.id] != null) {
            tempVal.push(String(entry.payee[member.id]));
          } else {
            tempVal.push("0");
          }
        });
        setSplitEntry(true);
        setsplitVal(tempVal);
      } else {
        let temp: string[] = [];
        currentHousehold.members.forEach((member: MemberType) => {
          const val = "0";
          temp = [...temp, val];
        });
        setsplitVal(temp);
      }
      setTimeout(() => {
        setActive(true);
      }, 100);
    }
  }, [open, entry, user.id, currentHousehold.members]);

  const handleClose = () => {
    setPrice("");
    setMerchant("");
    setCategory("");
    setDateValue(dayjs());
    setBlockIndex(null);
    setPayer(user.id);
    setPayee({});
    setSplitEntry(false);
    setIsRecurring(false);
    setOpen(false);
    setEntryType("expense");
    setsplitVal([]);
    setActive(false);
  };

  const handleDelete = () => {
    removeEntry([entry]);
    handleClose();
  };

  const EditEntry = () => {
    let payeeOb = { [user.id]: price };
    let sec = true;

    if (entryType === "expense") {
      if (splitEntry === true) {
        sec = false;
        payeeOb = payee;
      }
    } else if (entryType === "payment") {
      sec = false;
      payeeOb = { [paymentReceiver]: price };
    }

    const now = new Date();

    let temp = { ...entry };
    temp.category = category;
    temp.blockIndex = blockIndex;
    temp.date = Timestamp.fromDate(getStandardDate(dateValue?.toDate() || now));
    temp.merchant = merchant;
    temp.payee = payeeOb;
    temp.payer = payer;
    temp.price = price;
    temp.recurring = isRecurring;
    temp.secret = sec;

    updateEntry(temp.id, temp);
    handleClose();
  };

  //Split Pricechange
  useEffect(() => {
    if (splitEntry && active) {
      let temp = [...splitVal];
      let payeeTemp = { ...payee };

      currentHousehold.members.forEach((member: MemberType, index: number) => {
        const unroundedVal = Number(price) / currentHousehold.members.length;
        const val = Math.ceil(Number(unroundedVal) * 100) / 100;
        temp[index] = String(val);
        payeeTemp[member.id] = val;
      });
      setPayee(payeeTemp);
      setsplitVal(temp);
    }
  }, [currentHousehold, price, splitEntry]);

  const handleSplitChange = async (event: any, index: number) => {
    let temp = [...splitVal];
    let payeeTemp = { ...payee };
    let prev = Number(temp[index]);
    //Rounding the input
    let val = Number(event.target.value);

    //Return when nothing changes
    if (val === prev) return;

    //Input hit the ceiling (price)
    if (val >= Number(price)) {
      //Input is now the price
      temp[index] = price;
      //Everything else is 0
      for (let i = 0; i < temp.length; i++) {
        if (i !== index) temp[i] = "0";
      }
    }

    //Input is valid
    else {
      temp[index] = event.target.value;

      //We need to allocate the open balance
      let otherPay =
        Number(temp.reduce((acc, curr) => String(Number(acc) + Number(curr)))) -
        val;
      let allo = Number(price) - otherPay;
      let openBalance = allo - val;
      //Person to allocate to are either the next person in line or the first, if we're at the end
      let personToChange = index + 1;
      if (personToChange >= currentHousehold.members.length) personToChange = 0;
      let safety = 0;

      //Balance is positive -> next person just pays more
      if (openBalance >= 0) {
        let allocated = Number(temp[personToChange]) + openBalance;
        temp[personToChange] = String(
          Math.round(Number(allocated) * 100) / 100
        );
      }
      //Balance is negative -> next person pays less. Cannot pay less than 0, so we need to allocate to the next person
      else {
        while (openBalance < 0 && safety <= 100) {
          let allocated = Number(temp[personToChange]) + openBalance;

          if (allocated < 0) {
            openBalance = allocated;
            allocated = 0;
          } else {
            openBalance = 0;
          }

          temp[personToChange] = String(
            Math.round(Number(allocated) * 100) / 100
          );
          personToChange = personToChange + 1;
          if (personToChange >= currentHousehold.members.length)
            personToChange = 0;
          safety = safety + 1;
        }
      }
    }

    currentHousehold.members.forEach((member: MemberType, index: number) => {
      payeeTemp[member.id] = temp[index];
    });
    setPayee(payeeTemp);
    setsplitVal(temp);
  };

  return (
    <CustomDialog open={open} onClose={handleClose}>
      <Typography
        variant="h5"
        sx={{ textAlign: "center", textTransform: "capitalize", mt: 2, mb: 3 }}
      >
        {t("Edit Transaction")}
      </Typography>
      <CloseButton onClick={handleClose}>
        <CloseIcon />
      </CloseButton>
      {entryType !== "payment" ? (
        <TextField
          id="merchant-field"
          label={t("Merchant")}
          aria-labelledby={t("Merchant") || "Merchant"}
          lang={pickerLang}
          variant="outlined"
          value={merchant}
          inputProps={{ maxLength: 25 }}
          onChange={(e) => setMerchant(e.target.value)}
          sx={{ mt: 1.5, mb: 1.5 }}
        />
      ) : (
        <></>
      )}
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={pickerLang}
      >
        <DatePicker
          label={t("Date")}
          aria-labelledby={t("Date") || "Date"}
          value={dateValue}
          onChange={(newValue) => {
            setDateValue(newValue);
          }}
          renderInput={(params) => (
            <TextField sx={{ mt: 1.5, mb: 1.5 }} {...params} />
          )}
        />
      </LocalizationProvider>
      <TextField
        aria-labelledby={t("Amount") || "Amount"}
        lang={pickerLang}
        label={t("Amount")}
        value={price}
        onChange={(e) => {
          setPrice(e.target.value);
        }}
        name="numberformat"
        id="formatted-numberformat-input"
        InputProps={{
          inputProps: { allowNegative: false },
          inputComponent: NumberFormatCustom as any,
        }}
        variant="outlined"
        sx={{ mt: 1.5, mb: 1.5 }}
      />
      {entry &&
      entry.type === "expense" &&
      user.blocks[0] &&
      user.blocks[0].categories ? (
        <>
          <FormControl fullWidth sx={{ mt: 1.5, mb: 1.5 }}>
            <InputLabel htmlFor="category-select">{t("Category")}</InputLabel>
            <Select
              labelId="category-select-label"
              id="category-select"
              value={category}
              label={t("Category")}
              aria-labelledby={t("Category") || "Category"}
              lang={pickerLang}
              onChange={(e) => setCategory(e.target.value)}
            >
              {blocks.map((block: BlockType) => {
                const blockI = blocks.indexOf(block);
                return renderCategorySelectItem(
                  block,
                  thisTimeStamp,
                  setBlockIndex,
                  blockI
                );
              })}
            </Select>
          </FormControl>
          {splitEntry ? (
            <>
              <Button
                variant="outlined"
                onClick={() => setSplitEntry(false)}
                sx={{
                  mt: 2,
                  mb: 2,
                  ml: "auto",
                  mr: "auto",
                  width: "200px",
                  height: "36px",
                }}
              >
                {t("Undo Split")}
              </Button>
              <FormControl fullWidth sx={{ mt: 1.5, mb: 1.5 }}>
                <InputLabel id="payer-select-label">{t("Payed by")}</InputLabel>
                <Select
                  labelId="payer-select-label"
                  id="payer-select"
                  value={payer}
                  label={t("Payed by")}
                  aria-labelledby={t("Payed by") || "Payed by"}
                  lang={pickerLang}
                  onChange={(e) => setPayer(e.target.value)}
                >
                  {currentHousehold.members.map((member: MemberType) => {
                    return (
                      <MenuItem value={member.id} key={member.id}>
                        {member.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Typography sx={{ mt: 1.5, mb: 0 }}>{t("Payed for")}</Typography>
              {currentHousehold.members.map(
                (member: MemberType, index: number) => {
                  return (
                    <PayeeBox key={member.id} sx={{ mt: 1.5, mb: 1.5 }}>
                      <Typography>{member.name}</Typography>
                      <TextField
                        value={splitVal[index]}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleSplitChange(e, index)
                        }
                        sx={{ width: "200px" }}
                        aria-labelledby={t("Payed for") || "Payed for"}
                        lang={pickerLang}
                        InputProps={{
                          inputComponent: NumberFormatCustom as any,
                        }}
                      />
                    </PayeeBox>
                  );
                }
              )}
            </>
          ) : currentHousehold.members.length > 1 && premium ? (
            <>
              <Button
                variant="outlined"
                onClick={() => setSplitEntry(true)}
                sx={{
                  mt: 2,
                  mb: 3,
                  ml: "auto",
                  mr: "auto",
                  width: "200px",
                  height: "36px",
                }}
              >
                {t("Split Transaction")}
              </Button>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
      {entryType === "income" ? (
        <CustomFormControlLabel
          control={
            <Checkbox
              checked={isRecurring}
              onChange={() => setIsRecurring(!isRecurring)}
            />
          }
          label={t("Automatically create when making new budget")}
        />
      ) : (
        <></>
      )}
      {entryType === "payment" ? (
        <>
          <FormControl fullWidth sx={{ mt: 1.5, mb: 1.5 }}>
            <InputLabel id="payed-by-label">{t("Payment from")}</InputLabel>
            <Select
              labelId="payed-by-label"
              id="payed-by-select"
              value={payer}
              label={t("Payment made by")}
              aria-labelledby={t("Payment made by") || "Payment made by"}
              lang={pickerLang}
              onChange={(e) => {
                setPayer(e.target.value);
                let otherM = currentHousehold.members.find(
                  (member: MemberType) => member.id !== e.target.value
                );
                if (otherM) {
                  setPaymentReceiver(otherM.id);
                  setPayee({ [otherM.id]: price });
                }
              }}
            >
              {currentHousehold.members.map((member: MemberType) => {
                return (
                  <MenuItem value={member.id} key={member.id}>
                    {member.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mt: 1.5, mb: 1.5 }}>
            <InputLabel id="payed-zo-label">{t("Payed to")}</InputLabel>
            <Select
              labelId="payed-to-label"
              id="payed-zo-select"
              label={t("Payment to")}
              aria-labelledby={t("Payment to") || "Payment to"}
              lang={pickerLang}
              value={paymentReceiver}
              onChange={(e) => {
                setPayee({ [e.target.value]: price });
                setPaymentReceiver(e.target.value);
              }}
            >
              {currentHousehold.members.map((member: MemberType) => {
                if (member.id !== payer)
                  return (
                    <MenuItem value={member.id} key={member.id}>
                      {member.name}
                    </MenuItem>
                  );
              })}
            </Select>
          </FormControl>
        </>
      ) : (
        <></>
      )}
      <ButtonFlexBox>
        <Button
          onClick={EditEntry}
          sx={{ mt: 1, mr: 1, height: "50px" }}
          variant="contained"
        >
          {t("Save")}
        </Button>
        <Button
          variant="outlined"
          onClick={() => setDeleteDialogOpen(true)}
          sx={{ mt: 1, mb: 3, ml: 1, height: "50px" }}
        >
          {t("Delete")}
        </Button>
        <DeleteDialog
          open={deleteDialogOpen}
          setOpen={setDeleteDialogOpen}
          onSubmit={handleDelete}
          toDelete={t("this transaction")}
        />
      </ButtonFlexBox>
    </CustomDialog>
  );
};

export default EditDialog;
