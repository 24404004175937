import React from "react";
import { Doughnut, getElementAtEvent } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip } from "chart.js";
import { useUser } from "../../@common/contexts/UserContext";
import {
  categorySumForMonth,
  formatCurrency,
  getSumforYear,
} from "../../main/resources/CalcRessources";
import { useEntry } from "../../@common/contexts/EntryContext";
import { styled } from "@mui/system";
import { Typography, Button, useTheme } from "@mui/material";
import { expandAnimation, monthNumbers } from "../../@common/theme/Theme";
import CircleIcon from "@mui/icons-material/Circle";
import { makeTimeStamp } from "../../overview/resources/helpers";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import YearlyTable from "./YearlyTable";
import fileDownload from "js-file-download";
import ExportMenu from "./ExportMenu";
import { BalanceCard, IncomeCard } from "../../overview/components/BudgetCard";
import { useAppContext } from "../../@common/contexts/AppContext";
import { useTranslation } from "react-i18next";
import { BlockType, CategoryType } from "../../main/resources/UserResources";
import useCSVExport from "../resources/ExportRessources";

Chart.register(ArcElement, Tooltip);

const ReportContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: 10,
  padding: theme.spacing(4, 2),
}));

const ChartContainer = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  minHeight: "400px",
  height: "100%",
}));

const LegendContainer = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexWrap: "wrap",
  width: "100%",
  justifyContent: "space-evenly",
  margin: theme.spacing(3, 0, 1, 0),
}));

const LegendItem = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
}));

const YearPicker = styled("div")(({ theme }) => ({
  margin: "25px auto",
  width: "fit-content",
  display: "flex",
  alignItems: "center",

  "& .MuiTypography-root": {
    display: "inline",
    margin: 0,
  },
}));

const Label = styled(Typography)(({ theme }) => ({
  position: "absolute",
  textAlign: "center",
  fontSize: "1rem",
  lineHeight: "2.5rem",
  animation: expandAnimation + " 300ms ease",

  "& .sum": {
    fontSize: "2rem",
  },
}));

const StatsRow = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  marginLeft: "auto",
  marginRight: "auto",
  position: "relative",
  borderRadius: "15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
}));

const MobileDoughnutReport = () => {
  const { blocks, user, currentHousehold } = useUser();
  const { allEntries } = useEntry();
  const { color_series, shadowStyle } = useAppContext();
  const [yearOfInterest, setYearOfInterest] = React.useState(
    new Date().getFullYear()
  );
  const thisTimeStamp = makeTimeStamp(yearOfInterest, 11);
  const chartRef = React.useRef();
  const [labelElement, setLabelElement] = React.useState<JSX.Element>(
    <Label sx={{ position: "relative", margin: "auto" }}>
      Nothing to see here.
    </Label>
  );
  const [dataDetails, setDataDetails] = React.useState<any>(null);
  const [blockOfInterest, setBlockOfInterest] = React.useState<any>(null);
  const [borderWidth, setBorderWidth] = React.useState<number | number[]>(0);
  const [offset, setOffset] = React.useState<number | number[]>(0);
  const { createYearlyCSV } = useCSVExport();
  const theme = useTheme();
  const { t } = useTranslation();

  const DoughnutOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "85%",
    layout: { padding: 0 },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    currency: currentHousehold.currency,
  };

  let activeBlocks = [...blocks];
  activeBlocks = activeBlocks.filter((item: BlockType) => {
    if (
      item.status === "deleted" &&
      Number(thisTimeStamp) >= Number(item.deleted)
    )
      return false;
    if (Number(item.created) > Number(thisTimeStamp)) return false;
    return true;
  });

  const getBlockSum = (categories: CategoryType[]) => {
    let s = 0;
    let cats = categories.filter((categ: CategoryType) => {
      if (
        categ.status === "deleted" &&
        Number(thisTimeStamp) >= Number(categ.deleted)
      )
        return false;
      if (Number(categ.created) > Number(thisTimeStamp)) return false;
      return true;
    });
    monthNumbers.forEach((month) => {
      cats.forEach((element) => {
        s =
          s +
          categorySumForMonth(
            user.id,
            allEntries,
            element.label,
            month,
            yearOfInterest
          );
      });
    });

    return Math.round(s * 100) / 100;
  };

  const spentData = React.useMemo(() => {
    return {
      labels: activeBlocks.map((b: BlockType) => b.name),
      datasets: [
        {
          data: activeBlocks.map((b: BlockType) => getBlockSum(b.categories)),
          backgroundColor: color_series,
          hoverBackgroundColor: color_series,
          borderWidth: borderWidth,
          borderColor: color_series,
          hoverBorderColor: color_series,
          hoverBorderWidth: borderWidth,
          offset: offset,
        },
      ],
    };
  }, [borderWidth, activeBlocks]);

  const incomeData = React.useMemo(() => {
    return getSumforYear(user.id, "income", allEntries, yearOfInterest);
  }, [user.id, allEntries, yearOfInterest]);

  const isSpentDataEmpty = () => {
    for (let i = 0; i < spentData.datasets[0].data.length; i++) {
      if (spentData.datasets[0].data[i] > 0) return false;
    }
    return true;
  };

  React.useEffect(() => {
    if (dataDetails != null) {
      setLabelElement(
        <Label key={"spent" + dataDetails.label}>
          {dataDetails.label}: <br />
          <span className="sum">
            {formatCurrency(dataDetails.data, currentHousehold.currency)}
          </span>
        </Label>
      );
    } else {
      setLabelElement(
        <Label key={"spent"}>
          {t("Spent")}:<br />
          <span className="sum">
            {formatCurrency(spentSum, currentHousehold.currency)}
          </span>
        </Label>
      );
    }
  }, [dataDetails, currentHousehold.currency]);

  const clickCanvas = (event: any) => {
    if (chartRef.current) {
      const el = getElementAtEvent(chartRef.current, event);

      if (el.length > 0) {
        const details = {
          label: activeBlocks[el[0].index].name,
          //@ts-ignore
          data: el[0].element.$context.raw,
        };

        let temp = activeBlocks.map((v: BlockType) => 0);

        if (dataDetails && dataDetails.label === details.label) {
          setDataDetails(null);
          setBlockOfInterest(null);
          setBorderWidth(temp);
          setOffset(temp);
        } else {
          setDataDetails(details);
          setBlockOfInterest(activeBlocks[el[0].index]);
          temp[el[0].index] = 5;
          setBorderWidth(temp);
          let offsetTemp = [...temp];
          offsetTemp[el[0].index] = 15;
          setOffset(offsetTemp);
        }
      }
    }
  };

  const spentSum = spentData.datasets[0].data.reduce((accumulator, value) => {
    return accumulator + value;
  }, 0);

  const startExport = () => {
    createYearlyCSV(user.id, allEntries, activeBlocks, yearOfInterest).then(
      (res) => {
        let title = yearOfInterest + "_Report.csv";
        fileDownload(res, title);
      }
    );
  };

  let balance = incomeData - spentSum;

  return (
    <>
      <YearPicker>
        <Button onClick={() => setYearOfInterest(yearOfInterest - 1)}>
          <ArrowBackIosNewIcon
            sx={{ fontSize: 20, color: theme.palette.text.primary }}
          />
        </Button>
        <Typography variant="h5">{yearOfInterest}</Typography>
        <Button onClick={() => setYearOfInterest(yearOfInterest + 1)}>
          <ArrowForwardIosIcon
            sx={{ fontSize: 20, color: theme.palette.text.primary }}
          />
        </Button>
      </YearPicker>
      <ExportMenu exportFunction={startExport} />
      <StatsRow>
        <IncomeCard income={incomeData} />
        <BalanceCard fund={balance} />
      </StatsRow>
      <ReportContainer sx={shadowStyle}>
        <ChartContainer>
          <Doughnut
            //@ts-ignore
            data={spentData}
            options={DoughnutOptions}
            ref={chartRef}
            onClick={clickCanvas}
          />
          {activeBlocks.length <= 0 || isSpentDataEmpty() ? (
            <Label>{t("Nothing to see here.")}</Label>
          ) : (
            labelElement
          )}
        </ChartContainer>
        <LegendContainer>
          {activeBlocks.map((block: BlockType, index: number) => {
            return (
              <LegendItem key={block.name}>
                <CircleIcon
                  sx={{
                    mr: 1,
                    ml: 1,
                    color: color_series[index % color_series.length],
                  }}
                />
                <Typography sx={{ fontSize: "16px" }}>{block.name}</Typography>
              </LegendItem>
            );
          })}
        </LegendContainer>
        <YearlyTable
          blockOfInterest={blockOfInterest}
          yearOfInterest={yearOfInterest}
        />
      </ReportContainer>
    </>
  );
};

export default MobileDoughnutReport;
