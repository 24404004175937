import React from 'react'
import { styled } from '@mui/system';
import { Typography, Button, useTheme } from '@mui/material';
import { useUser } from '../../@common/contexts/UserContext';
import { calculateBalance, formatCurrency } from '../../main/resources/CalcRessources';
import { useEntry } from '../../@common/contexts/EntryContext';
import SettleUpDialog from '../components/SettleUpDialog';
import SplitDetails from '../components/SplitDetails';
import AdSplit from '../../main/components/AdSplit';
import { useTranslation } from 'react-i18next';
import { MemberType } from '../../main/resources/UserResources';

const Container = styled('div')(({ theme }) => ({
 display: 'flex',
 justifyContent: 'space-between',
 flexWrap: 'wrap',
  })); 


const BalanceBox = styled('div')(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  margin: theme.spacing(4,0,0,0),
  flexBasis: '38%',

  [theme.breakpoints.down("md")]: {
    margin: theme.spacing(2,0,0,0),
    paddingTop: theme.spacing(2),
    flexBasis: '100%'

  }
    
     }
   )); 

   const Content = styled('div')(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    boxSizing: "border-box",
    borderRadius: 10,
   
    '& .button': {
      marginLeft: theme.spacing(3)
    },


    [theme.breakpoints.down("md")]: {
      width: "100%",
      flexDirection: "column",


      '& .button': {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(0),

      }
    }

     }
   )); 

   const FlexBox = styled('div')(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: 0,

     }
   )); 

   const FlexItem = styled('div')(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(2),
    justifyContent: "space-between",


    '&:not(:last-child)' : {
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      borderBottomColor: theme.palette.interactable,
    },

     }
   )); 

const SplitContainer = () => {
    const [settleUpDialogOpen, setSettleUpDialogOpen] = React.useState(false);
    const {currentHousehold, user} = useUser();
    const {allEntries} = useEntry();
    const theme = useTheme();
    const {t} = useTranslation();

    
    
    const balances = React.useMemo(() => calculateBalance(currentHousehold, allEntries), [currentHousehold, allEntries])


  return (
    <>
    {currentHousehold.members.length > 1?
    <Container>
      <BalanceBox>
        <Typography variant='h6' sx={{mb: 1, color: theme.palette.text.disabled, ml: '10px'}}>{t("Household Balance")}</Typography> 
        <Content>
          <FlexBox>
          {currentHousehold.members.map((member : MemberType) => {
            return(
              <FlexItem key={member.id}>
                <Typography variant='body1' className='memberName'>{member.name}</Typography>
                <Typography variant='body1' className = 'memberBalance'>{formatCurrency(balances[member.id], currentHousehold.currency)}</Typography>
              </FlexItem>
            )
          })
      
          }
          </FlexBox>
        </Content>
        <Button className='button' sx={{width: "150px", mt: 2, alignSelf: 'flex-end'}} variant="outlined" onClick={() => setSettleUpDialogOpen(true)}>{t("Settle Up")}</Button>
      </BalanceBox>
      <SplitDetails/>
      <SettleUpDialog open={settleUpDialogOpen} setOpen={setSettleUpDialogOpen} balances={balances}/>
    </Container>
    :
    <AdSplit/>
    }
    </>
  )
}

export default SplitContainer