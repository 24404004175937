import * as React from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import { deleteField, doc, updateDoc } from "firebase/firestore";
import { firestore, functions } from "../../@common/firebase/firebase";
import { useUser } from "../../@common/contexts/UserContext";
import { httpsCallable } from "firebase/functions";
import { useAppContext } from "../../@common/contexts/AppContext";
import LoadingModal from "../../@common/components/LoadingModal";
import { useTranslation } from "react-i18next";

type SnackbarType = {
  open: boolean;
  setOpen: (open: boolean) => void;
  invitation: any | null;
  userId: string;
  userName: string;
  userHouseholdId: string;
};

export default function InviteSnackbar({
  open,
  setOpen,
  invitation,
  userId,
  userName,
  userHouseholdId,
}: SnackbarType) {
  const { user, currentHousehold, setUserandHousehold, premium } = useUser();
  const { AddInfoNotification } = useAppContext();
  const [loadingModalOpen, setLoadingModalOpen] = React.useState(false);
  const { t } = useTranslation();

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  React.useEffect(() => {
    if (open && !premium) {
      setLoadingModalOpen(true);
      removeInvite().then(() => {
        let temp = user;
        delete temp.invitation;
        setUserandHousehold(temp, currentHousehold);
        invitation = null;
        setLoadingModalOpen(false);
        setOpen(false);
      });
    }
  }, [open, premium]);

  const handleAccept = async () => {
    setLoadingModalOpen(true);

    //Close Snackbar
    setOpen(false);
    const oldHouseholdId = userHouseholdId;

    //First delete invitation
    await removeInvite();

    //Household already joined - do nothing else
    if (invitation.data.householdId === oldHouseholdId) {
      setLoadingModalOpen(false);
      return;
    }

    //Call cloud function
    try {
      const handleInvitationAccept = httpsCallable<any, any>(
        functions,
        "handleInvitationAccept"
      );
      const acceptInvite = await handleInvitationAccept({
        newHouseholdId: invitation.data.householdId,
        oldHousehold: currentHousehold,
        user: user,
        invitationId: invitation.id,
      });
      if (acceptInvite.data.newHousehold == null) {
        setLoadingModalOpen(false);
        AddInfoNotification(
          t("Something went wrong. Invitation could not be accepted.")
        );
      }
      //All well -> we can update household and user
      else {
        setLoadingModalOpen(false);
        let newUser = { ...user };
        newUser.household = acceptInvite.data.newHousehold.id;
        newUser.invitation = undefined;
        setUserandHousehold(newUser, acceptInvite.data.newHousehold);
        AddInfoNotification(t("You accepted the invitation!"));
      }
    } catch {
      setLoadingModalOpen(false);
      AddInfoNotification(
        t("Something went wrong. Invitation could not be accepted.")
      );
    }
  };

  const handleDecline = async () => {
    setLoadingModalOpen(true);

    //First delete invitation
    await removeInvite();

    //Call cloud function
    const handleInvitationDeny = httpsCallable(
      functions,
      "handleInvitationDeny"
    );
    await handleInvitationDeny({ invitationId: invitation.id }).catch(
      (error: any) => {
        console.log("Invitation Deny Error");
      }
    );
    setLoadingModalOpen(false);
    setOpen(false);
  };

  const removeInvite = async () => {
    const ref = doc(firestore, "users", userId);
    await updateDoc(ref, {
      invitation: deleteField(),
    });
  };

  let message = t("Something went wrong.") || "Something went wrong.";

  if (invitation != null) {
    message =
      invitation.data.inviterName +
      t(" has invited you to their household ") +
      invitation.data.householdName +
      t("inviteMessage2");
  }

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleAccept}>
        {t("Join")}
      </Button>
      <Button color="secondary" size="small" onClick={handleDecline}>
        {t("Decline")}
      </Button>
    </React.Fragment>
  );

  return (
    <>
      <Snackbar
        open={open}
        onClose={handleClose}
        message={message}
        action={action}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
      <LoadingModal open={loadingModalOpen} />
    </>
  );
}
