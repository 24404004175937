import { ThemeProvider } from "@mui/system";
import { Chart } from "chart.js";
import React, { useState, useContext, createContext, useEffect } from "react";
import { DarkTheme, LightTheme } from "../theme/Theme";

const AppContext = createContext(null);

const useAppContext = () => {
  const {
    overview,
    month,
    year,
    budget,
    infoNot,
    isMobile,
    isSmallScreen,
    themeMode,
    shadowStyle,
    color_series,
    color_series_text,
    color_series_progress,
  } = useContext<any>(AppContext);
  const [overviewState, setOverviewState] = overview;
  const [currentMonth, setCurrentMonth] = month;
  const [currentYear, setCurrentYear] = year;
  const [budgetAvailable, setBudgetAvailable] = budget;
  const [infoNotifications, setInfoNotifications] = infoNot;
  const [darkMode, setDarkMode] = themeMode;

  const nextMonth = () => {
    let m = currentMonth + 1;
    let y = currentYear;
    if (m >= 12) {
      m = 0;
      y = y + 1;
    }

    setCurrentMonth(m);
    setCurrentYear(y);
  };

  const prevMonth = () => {
    const cutOff = new Date().getFullYear() - 5;
    let m = currentMonth - 1;
    let y = currentYear;
    if (m < 0 && y > cutOff) {
      m = 11;
      y = y - 1;
    } else if (m < 0) m = 0;

    setCurrentMonth(m);
    setCurrentYear(y);
  };

  const AddInfoNotification = (info: string) => {
    const temp = [...infoNotifications];
    temp.push(info);
    setInfoNotifications(temp);
  };

  const DeleteInfoNotification = () => {
    const temp = [...infoNotifications];

    if (temp.length > 0) temp.splice(0, 1);
    setInfoNotifications(temp);
  };

  return {
    overviewState: overviewState,
    month: currentMonth,
    year: currentYear,
    budgetAvailable: budgetAvailable,
    setBudgetAvailable: setBudgetAvailable,
    isMobile: isMobile,
    isSmallScreen: isSmallScreen,
    setOverviewState: setOverviewState,
    darkMode,
    setDarkMode,
    shadowStyle,
    color_series,
    color_series_text,
    color_series_progress,
    nextMonth: nextMonth,
    prevMonth: prevMonth,
    infoNotifications: infoNotifications,
    AddInfoNotification: AddInfoNotification,
    DeleteInfoNotification: DeleteInfoNotification,
  };
};

type AppProviderTypes = {
  children: React.ReactNode;
};

const AppProvider = ({ children }: AppProviderTypes) => {
  const defaultValue = "planned";
  const [darkMode, setDarkMode] = useState(false);
  const [overviewState, setOverviewState] = useState(
    localStorage.getItem("MONEY.OverviewState") || defaultValue
  );
  const d = new Date();
  const [currentMonth, setCurrentMonth] = useState<number>(d.getMonth());
  const [currentYear, setCurrentYear] = useState<number>(d.getFullYear());
  const [budgetAvailable, setBudgetAvailable] = useState<boolean>(false);
  const [infoNotifications, setInfoNotifications] = useState<string[]>([]);
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const shadowStyle = darkMode
    ? {
        boxShadow: "none",
      }
    : {
        boxShadow: "0 4px 15px #F0F0F0",
      };

  const color_series = darkMode
    ? [
        "#FFC5A5",
        "#DEF0B2",
        "#FFD1EC",
        "#FFEEAC",
        "#A6D5EE",
        "#FFADAD",
        "#D1B7EB",
        "#AFD3A9",
        "#C0F0E4",
        "#C1B3B3",
      ]
    : [
        "#FFD2B9",
        "#E7F0D1",
        "#F0D9E7",
        "#FFF3C9",
        "#DEEDF5",
        "#FFC2C2",
        "#E0D3EE",
        "#C4E1C4",
        "#D3EAE4",
        "#D9CFCF",
      ];
  const color_series_text = darkMode
    ? [
        "#975631",
        "#6C803D",
        "#8C4872",
        "#7A6B31",
        "#316785",
        "#983B3B",
        "#442668",
        "#407045",
        "#356969",
        "#585151",
      ]
    : [
        "#D17E50",
        "#81905F",
        "#8E677F",
        "#8A8264",
        "#5E7D8D",
        "#9A5E5E",
        "#6A5C7B",
        "#67896B",
        "#5C8080",
        "#7D7474",
      ];
  const color_series_progress = darkMode
    ? [
        "#FFC5A5",
        "#DEF0B2",
        "#FFD1EC",
        "#FFEEAC",
        "#A6D5EE",
        "#FFADAD",
        "#D1B7EB",
        "#AFD3A9",
        "#C0F0E4",
        "#C1B3B3",
      ]
    : [
        "#FFBD99",
        "#B9E05B",
        "#F4B6DC",
        "#FDD956",
        "#A7D7F0",
        "#FFB1B1",
        "#DBBAFF",
        "#90D990",
        "#8ED7C4",
        "#D8BDBD",
      ];

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth < 768);
    setIsSmallScreen(window.innerWidth < 1300);
  };

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);
    Chart.defaults.font.family = "ApfelGrotezkRegular";
    Chart.defaults.font.size = 14;
    const dark = localStorage.getItem("@balancey_darkMode");
    if (dark) setDarkMode(JSON.parse(dark));

    //  Chart.defaults.color="#ffffff";

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <AppContext.Provider
      value={
        {
          overview: [overviewState, setOverviewState],
          month: [currentMonth, setCurrentMonth],
          year: [currentYear, setCurrentYear],
          budget: [budgetAvailable, setBudgetAvailable],
          infoNot: [infoNotifications, setInfoNotifications],
          themeMode: [darkMode, setDarkMode],
          isMobile,
          isSmallScreen,
          shadowStyle,
          color_series,
          color_series_text,
          color_series_progress,
        } as any
      }
    >
      <ThemeProvider theme={darkMode ? DarkTheme : LightTheme}>
        {children}
      </ThemeProvider>
    </AppContext.Provider>
  );
};
export { AppProvider, useAppContext };
