import React from 'react'
import { styled } from '@mui/system';
import { Typography } from '@mui/material';

import YearlyReportChart from '../components/YearlyReportChart';
import { useAppContext } from '../../@common/contexts/AppContext';
import MobileDoughnutReport from '../components/MobileDoughnutReport';
import InsightsButtonGroup from '../components/InsightsButtonGroup';
import SplitContainer from './SplitContainer';
import MonthlyReportChart from '../components/MonthlyReportChart';
import Layout from '../../layout';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../@common/contexts/UserContext';
import AdYearly from '../../main/components/AdYearly';



  const ButtonContainer = styled("div")(({ theme }) => ({
    position: "relative",
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    margin: theme.spacing(0),

    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      marginTop: '1rem',
    },
  
     }
   ));
  
   const InsightsBox = styled("div")(({ theme }) => ({
    position: "relative",
    maxWidth: "1200px",
    height: "fit-content",
    margin: theme.spacing(0),
    marginTop: '1em',
  
     }
   ));

const InsightsContainer = () => {
  const{premium} = useUser();
const {isSmallScreen} = useAppContext();
const [insightsState, setInsightsState] = React.useState("monthly");
const {t} = useTranslation();



  return (
    <Layout title={t('Insights') || "Insights"}>
    <ButtonContainer>
    <InsightsButtonGroup insightState={insightsState} setInsightState={setInsightsState}/>
    </ButtonContainer>
    {insightsState === "split"? 
    <InsightsBox>
      <SplitContainer/>
    </InsightsBox>
    :
    <></>
    }
    {insightsState === "monthly"? 
    <>
    <InsightsBox>
    <MonthlyReportChart/>
    </InsightsBox>
    </>
    :
    <></>
    }
    {insightsState === "yearly"? 
    premium?
     isSmallScreen === true?
     <InsightsBox>
      <MobileDoughnutReport/>
      </InsightsBox>
      :
      <InsightsBox>
      <YearlyReportChart/>
      </InsightsBox>
    :
    <AdYearly/>
    :
    <></>
    }
    
   
    </Layout>
  )
}

export default InsightsContainer;
