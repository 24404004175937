import React from 'react';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import { motion } from "framer-motion";
import { useAppContext } from '../../@common/contexts/AppContext';
import Carousel from 'react-material-ui-carousel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useTheme } from '@mui/material';
import img7 from '../../assets/images/couple.png';
import img8 from '../../assets/images/insights.png';
import img1 from '../../assets/images/couch.png';
import img2 from '../../assets/images/girl-bread.png';
import img3 from '../../assets/images/people-small.png';
import img4 from '../../assets/images/cloud.png';
import img5 from '../../assets/images/pencilcup.png';
import img6 from '../../assets/images/charts.png';


const snappy = { duration: 0.5, ease: "easeInOut" };
const fadeInUp = { duration: 1, ease: [0.47,.21,0.05,1], delay: 0.4};
const feature1 = { duration: 1, ease: [0.47,.21,0.05,1], delay: 0.8};
const feature2 = { duration: 1, ease: [0.47,.21,0.05,1], delay: 1};
const feature3 = { duration: 1, ease: [0.47,.21,0.05,1], delay: 1.2};



const Section = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(20),
  paddingRight: theme.spacing(20),


  //Small
  [theme.breakpoints.down(1400)]: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10), 
   },
  //Mobile
  [theme.breakpoints.down("md")]: {
    padding: 0,
  },

}));


const FeaturesContainer = styled('div')(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  scrollSnapAlign: 'start',
  paddingTop: '16rem',
  marginBottom: theme.spacing(10),

  [theme.breakpoints.down("lg")]: {
    paddingTop: '8rem',
    marginBottom: theme.spacing(5),
   },

}));

const FeaturesHeader = styled(Typography)(({ theme }) => ({

  textAlign: "center",
  margin: theme.spacing(0,0,10,0),

  [theme.breakpoints.down(1200)]: {
    marginBottom: theme.spacing(3),
 
     },
 

}));

const FlexRow = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  width: "100%",
  maxWidth: '1480px',
  minWidth: '1200px',
  //marginBottom: theme.spacing(3),

  '& > div' : {
    flexBasis: '33%',
    margin: theme.spacing(3),
  },

       //Small
       [theme.breakpoints.down("lg")]: {
       // flexDirection: 'column',

      },
      //Mobile
      [theme.breakpoints.down("md")]: {
     //   fontSize: "1.5rem",
  
      },
  

}));

const FeatureBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: "column",
  justifyContent: "start",
  //alignItems: "flex-start",
  padding: theme.spacing(2, 8, 8, 8),
  backgroundColor: theme.palette.common.white,
  borderRadius: "20px",
  boxShadow: "0 12px 60px #F1EEF1",
  color: theme.palette.homepage.main,
  height: "430px",
  transition: '0.6s cubic-bezier(.55,.3,.47,.98)',

  '& .MuiTypography-root':{
    color: "inherit"
  },

  '& img' : {
    //width: '100%',
    maxHeight: '200px',
    objectFit: 'contain',
    flexBasis: '30%',
    //marginTop: '-8rem',
    flexGrow: 0,
    position: 'relative',
    marginBottom: '2rem',
  },

  '& h4' : {
    flexBasis: '10%',
    marginBottom: '1rem',
  },

  '& p' : {
    fontSize: '1.2rem',
    flexBasis: '60%',
  },

  '& .line-wrapper' : {
    overflow: 'hidden',
    height: '100%'
  },

  '& .line-wrapper > div' : {
    display: 'inline-block',
  },

  [theme.breakpoints.up("md")]: {
    '&:hover' : {
      scale: '1.02',
      boxShadow: "0 12px 60px #EFE8EF",
    },
   },

   [theme.breakpoints.down(1700)]: {
    //padding: theme.spacing(8),

    '& p' : {
      fontSize: '1.1rem',
    },

   },

       [theme.breakpoints.down(1400)]: {
        //width: "90%",
        padding: theme.spacing(2, 5, 5, 5),

       },


       //Mobile Carousel
      [theme.breakpoints.down(1200)] : {
        //minWidth: '300px',
        padding: theme.spacing(2, 8, 8, 8),

        '& img' : {
          //maxHeight: '220px',
        }
      },


       [theme.breakpoints.down("md")]: {
        height: 'fit-content',
        //width: '80vw',
        padding: theme.spacing(5),
        minHeight: 'auto',

        '& p' : {
          fontSize: '1.1rem',
          marginTop: '1rem',
        },

        '& h4' : {
          marginBottom: 0,
        },

        '& img' : {
          maxHeight: '185px'
        }
       },

}));


const CustomCarousel = styled(Carousel)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(15,0),
  minHeight: '500px',


  [theme.breakpoints.down('lg')] : {
    padding: theme.spacing(3, 0),
    '& svg' : {
      width: '0.7em',
    },

    '& button' : {
      margin: '0 1px',
    }
  }

}));

const CarouselBox = styled('div')(({ theme }) => ({
  width: "70%",
  margin: "auto",
  alignItems: 'center',
  

  //Small
  [theme.breakpoints.down(1200)]: {
   width: '52vw',
    //margin: 'auto',

    },
    //Mobile
    [theme.breakpoints.down("md")]: {
      width: "90vw",


    },

}));

const Features = () => {
  const [isSmall, setIsSmall] = React.useState(false);
  const theme = useTheme();

  const handleWindowSizeChange = () => {
    setIsSmall(window.innerWidth <= 1200);
}

  React.useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener('resize', handleWindowSizeChange);
  
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [])


  return (
    <>
    <Section sx={{backgroundColor: theme.palette.common.white}}>
      <FeaturesContainer>
        <div className='line-wrapper'>
      <motion.div initial={{ opacity: 0, y: 60 }} whileInView={{ opacity: 1, y:0 }} transition={fadeInUp} viewport={{ once: true }}>
            <FeaturesHeader variant='h2' color={theme.palette.homepage.dark}>
            Features
            </FeaturesHeader>
        </motion.div>
        </div>
        {isSmall?
      <>
 <CustomCarousel 
        NextIcon={<ArrowForwardIosIcon/>}
        PrevIcon={<ArrowBackIosIcon/>}
        next={(next, active) => {}}
        prev={(prev, active) => {}}
        autoPlay={false}
        animation={'slide'}
        fullHeightHover={false}
        navButtonsAlwaysVisible={false}
        navButtonsProps={{
            style: {background: "transparent", color: theme.palette.primary.main},
        }}
        indicatorContainerProps={{ 
          style: { marginTop: '30px'}
        }}
        activeIndicatorIconButtonProps={{
            style: {}
        }}
        >
          <CarouselBox>
          <FeatureBox>
            <img src={img1} alt='illustration of a armchair and money' id='feature-img1'></img>
                <Typography variant="h4" mb={4}>Easy Budgeting</Typography>
                <Typography>
                Create budgets for different categories and see how much you have left to spend each month.
                </Typography>
            </FeatureBox>
          </CarouselBox>
          <CarouselBox>
          <FeatureBox>
            <img src={img6} alt='illustration of financial charts' id='feature-img2'></img>
                <Typography variant="h4" mb={4}>Track Expenses</Typography>
                <Typography>
                Easily log your income and transactions and keep track of where your money goes.
                </Typography>
            </FeatureBox>
            </CarouselBox>
            <CarouselBox>
            <FeatureBox>
            <img src={img7} alt='illustration of a happy couple' id='feature-img3'></img>
                <Typography variant="h4" mb={4}>Split Transactions</Typography>
                <Typography>
                Share expenses with your spouse or roommates, while everybody keeps their budget. Stay on top of who owes what with split insights.
                </Typography>
            </FeatureBox>
            </CarouselBox>
            <CarouselBox>
            <FeatureBox>
            <img src={img4} alt='illustration of a cloud behind a laptop' id='feature-img1'></img>
                <Typography variant="h4" mb={4}>Cloud Syncing</Typography>
                <Typography>
                Sync your data between devices for a seamless budgeting on-the-go and use the app on Android, iOS, and web!
                </Typography>
            </FeatureBox>
            </CarouselBox>
            <CarouselBox>
            <FeatureBox>
            <img src={img5} alt='illustration of drawing utensils' id='feature-img2'></img>
                <Typography variant="h4" mb={4}>Light & Dark Mode</Typography>
                <Typography>
                Personalise your app experience with your favourite theme.
                </Typography>
            </FeatureBox>
            </CarouselBox>
            <CarouselBox>
            <FeatureBox>
            <img src={img8} alt='illustration of graphs, bars and credit card' id='feature-img3'></img>
                <Typography variant="h4" mb={4}>Personalized Insights</Typography>
                <Typography>
                Receive tailored insights into your monthly and yearly spending patterns.
                </Typography>
            </FeatureBox>
            </CarouselBox>
      </CustomCarousel>
        </>
        :
        <>
        <FlexRow>
          <motion.div initial={{ opacity: 0, y: 100 }} whileInView={{ opacity: 1, y: 0 }} transition={feature1} viewport={{ once: true }}>
            <FeatureBox>
            <img src={img1} alt='illustration of a armchair and money' id='feature-img1'></img>
                <Typography variant="h4" mb={4}>Easy Budgeting</Typography>
                <Typography>
                Create budgets for different categories and see how much you have left to spend each month.
                </Typography>
            </FeatureBox>
          </motion.div>
          <motion.div initial={{ opacity: 0, y: 100 }} whileInView={{ opacity: 1, y: 0 }} transition={feature2} viewport={{ once: true }}>
            <FeatureBox>
            <img src={img6} alt='illustration of financial charts' id='feature-img2'></img>
                <Typography variant="h4" mb={4}>Track Expenses</Typography>
                <Typography>
                Easily log your income and transactions and keep track of where your money goes.
                </Typography>
            </FeatureBox>
          </motion.div>
          <motion.div initial={{ opacity: 0, y: 100 }} whileInView={{ opacity: 1, y: 0 }} transition={feature3} viewport={{ once: true }}>
            <FeatureBox>
            <img src={img7} alt='illustration of a happy couple' id='feature-img3'></img>
                <Typography variant="h4" mb={4}>Split Transactions</Typography>
                <Typography>
                Share expenses with your spouse or roommates, while everybody keeps their budget. Stay on top of who owes what with split insights.
                </Typography>
            </FeatureBox>
          </motion.div>
  </FlexRow>
          <FlexRow>
          <motion.div initial={{ opacity: 0, y: 100 }} whileInView={{ opacity: 1, y: 0 }} transition={feature1} viewport={{ once: true }}>
            <FeatureBox>
            <img src={img4} alt='illustration of a cloud behind a laptop' id='feature-img1'></img>
                <Typography variant="h4" mb={4}>Cloud Syncing</Typography>
                <Typography>
                Sync your data between devices for a seamless budgeting on-the-go and use the app on Android, iOS, and web!
                </Typography>
            </FeatureBox>
          </motion.div>
          <motion.div initial={{ opacity: 0, y: 100 }} whileInView={{ opacity: 1, y: 0 }} transition={feature2} viewport={{ once: true }}>
            <FeatureBox>
            <img src={img5} alt='illustration of drawing utensils' id='feature-img2'></img>
                <Typography variant="h4" mb={4}>Light & Dark Mode</Typography>
                <Typography>
                Personalise your app experience with your favourite theme.
                </Typography>
            </FeatureBox>
          </motion.div>
          <motion.div initial={{ opacity: 0, y: 100 }} whileInView={{ opacity: 1, y: 0 }} transition={feature3} viewport={{ once: true }}>
            <FeatureBox>
            <img src={img8} alt='illustration of graphs, bars and credit card' id='feature-img3'></img>
                <Typography variant="h4" mb={4}>Personalized Insights</Typography>
                <Typography>
                Receive tailored insights into your monthly and yearly spending patterns.
                </Typography>
            </FeatureBox>
          </motion.div>
  </FlexRow>
  </>
      }
     
      </FeaturesContainer>      
    </Section>
</>
  );
};

export default Features;
