import React from 'react';
import { styled } from '@mui/system';
import TransactionsContainer from '../transactions/containers/TransactionsContainer';
import { Drawer, Typography, Fab, Button, useTheme } from '@mui/material';
import OverviewContainer from '../overview/containers/OverviewContainer';
import { TabsUnstyled, TabsListUnstyled, TabPanelUnstyled, TabUnstyled } from '@mui/base';
import SettingsContainer from '../settings/containers/SettingsContainer';
import Notification from './components/Notification';
import { useUser } from '../@common/contexts/UserContext';
import { auth } from '../@common/firebase/firebase';
import InsightsContainer from '../insights/containers/InsightsContainer';
import { useAppContext } from '../@common/contexts/AppContext';
import EntryDialog from '../transactions/components/EntryDialog';
import AddIcon from '@mui/icons-material/Add';
import { signOut } from 'firebase/auth';
import Icon from '../Icon';
import logo from "../assets/images/logo.svg";
import logoWhite from "../assets/images/logoWhite.svg";
import { useEntry } from '../@common/contexts/EntryContext';
import LoadingScreen from '../@common/pages/LoadingScreen';
import { useTranslation } from 'react-i18next';
import AdminContainer from '../admin/AdminContainer';

const AppContainerStyle = styled(TabsUnstyled)(({ theme }) => ({
  display: "flex",
  backgroundColor: theme.palette.background.default,

  [theme.breakpoints.down("lg")]: {
   flexDirection: "column"
  }
}));

const CustomDrawer = styled(Drawer)(({ theme }) => ({
  width: "250px",
  zIndex: 1,

  '& .MuiPaper-root' : {
    width: "250px",
    backgroundColor: theme.palette.background.paper,
    border: "none",
  },
//Small screens
  [theme.breakpoints.down("lg")]: {
    width: "auto",
    height: "0px",

    '& .MuiPaper-root' : {
      width: "auto",
      height: "80px",
      justifyContent: "center",

    },

  },
  //Mobile screens
  [theme.breakpoints.down("md")]: {
    '& .MuiPaper-root' : {
      height: "50px",
    },
    }
}));


const CustomTab = styled(TabUnstyled)(({ theme }) => ({
  //width: "90%",
  height: "60px",
  padding: "30px",
  margin: theme.spacing(1, 3),
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  backgroundColor: "transparent",
  border:"none",
  cursor:"pointer",
  WebkitTapHighlightColor: "transparent",
  borderRadius: "50px",
  color: theme.palette.text.primary,

  '&.Mui-selected': {
    backgroundColor: theme.palette.interactable,
  },

  '& .MuiTypography-root': {
    marginLeft: theme.spacing(2),
  },

  '& svg': {
    overflow: "unset"
  },

  //Small screens
  [theme.breakpoints.down("lg")]: {
   flexDirection: "column",
   width: "100%",
   height: "100%",
   margin: 0,
   padding: theme.spacing(1.5,2),
   borderRadius: 0,
   justifyContent: "space-between",

   '& .MuiTypography-root': {
    fontSize: "1.1rem",
    marginLeft: theme.spacing(0),
  },

  },
    //Mobile screens
    [theme.breakpoints.down("md")]: {
      padding:0,
      justifyContent: "center",

     }

  
}));




const TabsList = styled(TabsListUnstyled)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

    //Small screens
    [theme.breakpoints.down("lg")]: {
      flexDirection: "row",
      height: "100%",
   
     },
    //Mobile screens
    [theme.breakpoints.down("md")]: {
     '& .MuiTypography-root':{
      display: "none"
     }
    
    }

}));

const Pages = styled('div')(({ theme }) => ({
  width: "100%"
}));


const LogoBox = styled('div')(({ theme }) => ({
  padding: theme.spacing(5, 7),
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "left",

  '& img' : {
    width: '125px',
  }

}));


const AppContainer = () => {
  const {isMobile, isSmallScreen, shadowStyle, darkMode} = useAppContext();
  const [entryDialogOpen, setEntryDialogOpen] = React.useState(false);
  const {isAdmin} = useUser();
  const [allLoaded, setAllLoaded] = React.useState(false);
  const { entriesLoaded} = useEntry();
  const theme = useTheme();
  const {t} = useTranslation();

  const LogOut = () => {
    signOut(auth).then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
  }

  const OnTabChange = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  React.useEffect(() => {
    if(entriesLoaded) setAllLoaded(true);
  },[entriesLoaded])

  if (!allLoaded) return(<LoadingScreen/>)

    return (
         <AppContainerStyle defaultValue={0}>
         <CustomDrawer
         variant='permanent'
         anchor={isSmallScreen? "bottom" : "left"}
         open={true}
         sx={shadowStyle}
         >
      {isSmallScreen === false &&
    <LogoBox>
      {darkMode === true?
      <img src={logoWhite} alt="balancey logo"></img>
      :
      <img src={logo} alt="balancey logo" style={{fill: "#fff"}}></img>
      }
    </LogoBox>
    }
  <TabsList>
  <CustomTab onChange={OnTabChange}>
  <Icon icon='Menu-filled' size={23} color={theme.palette.text.primary}/>
    <Typography variant='body1'>{t("Overview")}</Typography>
  </CustomTab>
  <CustomTab onChange={OnTabChange}>
  <Icon icon='Transactions-filled' size={23} color={theme.palette.text.primary}/>
  <Typography>{t("Transactions")}</Typography>
  </CustomTab>
  <CustomTab onChange={OnTabChange}>
  <Icon icon='Piechart-filled' size={23} color={theme.palette.text.primary}/>
  <Typography>{t("Insights")}</Typography>
  </CustomTab>
  <CustomTab onChange={OnTabChange}>
  <Icon icon='Settings-filled' size={23} color={theme.palette.text.primary}/>
  <Typography>{t("Settings")}</Typography>
  </CustomTab>
  {isAdmin &&
    <CustomTab onChange={OnTabChange}>
    <Icon icon='Logo' size={23} color={theme.palette.text.primary}/>
    <Typography>Admin</Typography>
    </CustomTab>
  }
  </TabsList>
  {isSmallScreen === false?
  <>
  <div style={{position: "absolute", bottom: 12, display: "flex", flexDirection: "column", width: "100%"}}>
  <Button onClick={LogOut} sx={{width:"fit-content", margin: '1rem auto', padding: '10px 20px'}}>
  <Icon icon='Logout' size={18} color={theme.palette.text.primary}/>
    <Typography sx={{ml: 1}}>{t("Log Out")}</Typography>
    </Button>
  <Typography sx={{color: theme.palette.text.secondary, fontSize: "12px", textAlign: "center", width: "100%"}}>&copy; {new Date().getFullYear()} Vanilla Noir</Typography>
  </div>
  </>
  :
  <></>
  }
  </CustomDrawer>
  <Pages>
  <TabPanelUnstyled value={0}>
  <OverviewContainer/>
  </TabPanelUnstyled>
  <TabPanelUnstyled value={1}>
  <TransactionsContainer/>
  </TabPanelUnstyled>
  <TabPanelUnstyled value={2}>
  <InsightsContainer/>
  </TabPanelUnstyled>
  <TabPanelUnstyled value={3}>
  <SettingsContainer/>
  </TabPanelUnstyled>
  {isAdmin?
  <TabPanelUnstyled value={4}>
  <AdminContainer/>
  </TabPanelUnstyled>
  :
  <></>
  }

  </Pages>
  <Notification/>
  {isMobile === true?
        <>
        <Fab color="primary" aria-label="add transaction" sx={{position: "fixed", right:20, bottom: 70}} onClick={() => setEntryDialogOpen(true)}>
          <AddIcon />
        </Fab>
        <EntryDialog open={entryDialogOpen} setOpen={setEntryDialogOpen}/>
        </>
        :
        <></>
  }
  </AppContainerStyle>


 
     
      
    );

};

export default AppContainer;
