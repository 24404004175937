import React, { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Routes, Route, Navigate } from "react-router-dom";
import AuthForm from "../../home/components/Header";
import ErrorScreen from "../auth/ErrorScreen";
import { useUser } from "../contexts/UserContext";
import { auth } from "../firebase/firebase";
import useAuth from "../hooks/useAuth";
//import ProtectedRoute from './ProtectedRoute';
//import HomePage from '../pages/HomePage';
//import NotFound from '../pages/NotFound';
import AppPage from "../pages/AppPage";
import LandingPage from "../pages/LandingPage";
import LoadingScreen from "../pages/LoadingScreen";
import Privacy from "../pages/Privacy";
import TermsConditions from "../pages/TermsConditions";
import LegalNotice from "../pages/LegalNotice";
import UserManagement from "../../home/user_management/UserManagementContainer";
import FAQ from "../pages/FAQ";

function AppRouter() {
  const { isAuthenticated, isLoading } = useAuth();
  const { user, userIsSetup } = useUser();

  return (
    <>
      {isLoading === true ||
      (user === null && userIsSetup === true && isAuthenticated) ? (
        <LoadingScreen />
      ) : (
        <Routes>
          <Route
            path="/"
            element={
              isAuthenticated && isLoading === false && userIsSetup === true ? (
                <Navigate replace to="/app" />
              ) : isAuthenticated &&
                isLoading === false &&
                userIsSetup === false ? (
                <Navigate to="/introduction" />
              ) : (
                <LandingPage />
              )
            }
          />
          <Route path="/:locale/privacy" element={<Privacy />} />
          <Route path="/:locale/terms" element={<TermsConditions />} />
          <Route path="/:locale/legal" element={<LegalNotice />} />
          <Route path="/:locale/faq" element={<FAQ />} />
          <Route path="/usermgmt" element={<UserManagement />} />
          <Route
            path="/app"
            element={
              isAuthenticated && isLoading === false && userIsSetup === true ? (
                <AppPage />
              ) : isAuthenticated &&
                isLoading === false &&
                userIsSetup === false ? (
                <Navigate replace to="/introduction" />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/introduction"
            element={
              isAuthenticated &&
              isLoading === false &&
              userIsSetup === false ? (
                //Introduction was replaced with ErrorScreen as account creation is not yet possible on the web version
                <ErrorScreen />
              ) : isAuthenticated &&
                isLoading === false &&
                userIsSetup === true ? (
                <Navigate replace to="/app" />
              ) : (
                <Navigate to="/" />
              )
            }
          />
        </Routes>
      )}
    </>
  );
}

export default AppRouter;
