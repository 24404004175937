import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { Button, Dialog, Typography, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { httpsCallable } from "firebase/functions";
import { useAppContext } from '../../@common/contexts/AppContext';
import { useTranslation } from 'react-i18next';
import { functions } from '../../@common/firebase/firebase';

const CustomDialog = styled(Dialog)(({ theme }) => ({

  '& .MuiPaper-root':{
    width: "300px",
    height: "250px",
    borderRadius: "25px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(4)
  }

}));

type DialogType = {
  open: boolean,
  setOpen: (open: boolean) => void,
}

const PremiumGrantDialog = ({open, setOpen} : DialogType) => {
const [inviteEmail, setInviteEmail] = React.useState<string>('');
const {AddInfoNotification} = useAppContext();
const {t, i18n} = useTranslation();


	const handleClose = () => {
		setOpen(false);
	};

    const handleInvite = async () => {
    //Call cloud function
    const handleInvitationAccept = httpsCallable(functions,'handlePremiumRole');
    handleInvitationAccept({email: inviteEmail, willbePremium: true})
    .then((response : any) => {
      AddInfoNotification("Success");

    })
    .catch((error : any) => {
      console.log("Invitation Accept Error")
      AddInfoNotification(t("Something went wrong. Invitation could not be accepted."));
    })
      
      
      setInviteEmail("");
        handleClose();
    }

  return (
      <CustomDialog
      open={open}
      onClose={handleClose}
      >
        <Typography variant='h4' sx={{textAlign: "center"}}>Grant Premium</Typography>
        <TextField 
        id="invite-email" 
        label="Their email address"
        aria-labelledby={t("Their email address") || "Their email address"}
        variant="standard" 
        value={inviteEmail}
        onChange={(e) => setInviteEmail(e.target.value)}
        />

      <Button variant='contained' onClick={handleInvite} sx={{mt:2}}>Send</Button>

      </CustomDialog>
   
    
  );
};

export default PremiumGrantDialog;
