import React, { useEffect, useState } from 'react';
import { styled, SxProps } from '@mui/system';
import { Button, Dialog, FormControl, InputLabel, MenuItem, Select, useTheme } from '@mui/material';
import { useUser } from '../../@common/contexts/UserContext';
import { Theme } from '@mui/material/styles';
import { DarkTheme, LightTheme, allCurrencies, allLanguages } from '../../@common/theme/Theme';
import i18next from 'i18next';

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: "16px",
   }
 ));

 type CurrencyPickerTypes = {
  forceLightTheme?: boolean;
 }

const LanguagePicker = ({forceLightTheme} : CurrencyPickerTypes) => {
  const defaultLang = allLanguages.find(value => value.val === i18next.language);
  const [lang, setLang] = useState(defaultLang?.val || "en");
  const theme = useTheme();

    const onChange = (e : any) => {
        if(e.target.value != null)
        {
            setLang(e.target.value);
            i18next.changeLanguage(e.target.value);

        }
    }

  return (
 <>
        <FormControl fullWidth size='small' sx={{width: "200px"}}>
        <Select
          labelId="currency-select-input"
          id="currency-select-input"
          value={lang}
          onChange={onChange}
          sx={{fontSize: "16px", color: forceLightTheme? LightTheme.palette.text.primary : theme.palette.text.primary}}
        >
          {allLanguages.map((lang : any) => {
            return(
              <CustomMenuItem key={lang.val} value={lang.val}>{lang.name}</CustomMenuItem>
            )})

          }
        </Select>
      </FormControl>

  
      </>
    
  );
};

export default LanguagePicker;
