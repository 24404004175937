import React from 'react'
import { Typography, styled, useTheme } from '@mui/material';
import Icon from '../../Icon';
import { formatCurrency } from '../../main/resources/CalcRessources';
import { useUser } from '../../@common/contexts/UserContext';
import { useAppContext } from '../../@common/contexts/AppContext';
import { useTranslation } from 'react-i18next';


const MoneyBoxBox = styled('div')(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: theme.spacing(3, 4),
    width: "50%",
    maxWidth: "450px",
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
    height: "70px",
    backgroundColor: theme.palette.background.paper,

    '&:not(:last-child)': {
      marginRight: '15px'
    },
  
    '& .moneyBox-header': {
      color: theme.palette.text.secondary,
      lineHeight: 1.5,
    },
  
        //Mobile screens
        [theme.breakpoints.down("lg")]: {
          flexDirection: "column",
          justifyContent: "flex-start",
          minHeight: "110px",
          height: 'fit-content',
          padding: theme.spacing(1.5, 3),
        }
    }
  ));
  
  const MoneyLabel = styled('div')(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  
    '& .moneyText':{
      fontSize: "2.2rem",
      textOverflow: "ellipsis",
      overflow: "hidden",
      lineHeight: 1,
      whiteSpace: 'pre',
    },
    '& .budgetText':{
        fontSize: "1.3rem",
        lineHeight: "1.1",
      },
  
    //Small
    [theme.breakpoints.down("lg")]: {
      '& .moneyText':{
        fontSize: "2rem",
      },
      '& .budgetText':{
        fontSize: "1.2rem",
      },  
    
    },
    
    //Mobile
    [theme.breakpoints.down("lg")]: {
      textAlign: "center",
      marginTop: theme.spacing(0.5),

    '& .moneyText':{
      fontSize: "1.5rem",
      maxWidth: '150px',
    },    
    '& .budgetText':{
        fontSize: "1rem",
        marginTop: theme.spacing(1),

      },    
  }
   
     }
   ));
  
   const IconBox = styled('div')(({ theme }) => ({
    marginRight: theme.spacing(2.5),
    padding: theme.spacing(2),
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  
    '&.income': {
      backgroundColor: theme.palette.info.light,
    },
    '&.success': {
        backgroundColor: theme.palette.success.light,
      },
      '&.error': {
        backgroundColor: theme.palette.error.light,
      },
      '&.warning': {
        backgroundColor: theme.palette.warning.light,
      },
      '&.balance': {
        backgroundColor: theme.palette.balance.light,
      },
  
      //Mobile
      [theme.breakpoints.down("lg")]: {
        margin: 0,
        padding: theme.spacing(1.5),
    }
  
   
     }
   ));

type IncomeCardType = {
    income: number;
}

export const IncomeCard = ({income} : IncomeCardType) => {
    const {currentHousehold} = useUser();
    const theme = useTheme();
    const {shadowStyle, isSmallScreen} = useAppContext();
    const {t} = useTranslation();

  return (

    <MoneyBoxBox sx={shadowStyle}>
    <IconBox className='income'>
      <Icon icon="Money-in" size={isSmallScreen? 30 : 40} color={theme.palette.info.main}/>
    </IconBox>
    <MoneyLabel>
    <Typography variant='h6' className='moneyBox-header'>{t("Income")}</Typography>
    <Typography className='moneyText'>{formatCurrency(income,currentHousehold.currency)}</Typography>
    </MoneyLabel>
    </MoneyBoxBox>

  
  )
}

type FundCardType = {
    fund: number;
}

export const BudgetCard = ({fund} : FundCardType) => {
    const {currentHousehold} = useUser();
    const theme = useTheme();
    const {shadowStyle, isSmallScreen} = useAppContext();
    const {t} = useTranslation();



  return (
    <>
        {fund === 0?
        <MoneyBoxBox sx={shadowStyle}>
        <IconBox className='success'>
        <Icon icon="Logo" size={isSmallScreen? 30 : 40} color={theme.palette.success.main}/>
        </IconBox>
        <MoneyLabel>
        <Typography className='budgetText'>{t("Your budget is balanced!")}</Typography>
        </MoneyLabel>
        </MoneyBoxBox>
        :
        <></>  
        }
        {fund < 0?
        <MoneyBoxBox sx={shadowStyle}>
        <IconBox className='error'>
        <Icon icon="Alert" size={isSmallScreen? 30 : 40} color={theme.palette.error.main}/>
        </IconBox>
        <MoneyLabel>
        <Typography className='budgetText'>{t("You are")} {formatCurrency(Math.abs(fund), currentHousehold.currency)} {t("over budget!")}</Typography>
        </MoneyLabel>
        </MoneyBoxBox>
        :
        <></>  
        }
          {fund > 0?
        <MoneyBoxBox sx={shadowStyle}>
        <IconBox className='warning'>
        <Icon icon="Piechart" size={isSmallScreen? 30 : 40} color={theme.palette.warning.main}/>
        </IconBox>
        <MoneyLabel>
        <Typography variant='h6' className='moneyBox-header'>{t("Left to Budget")}</Typography>
        <Typography className='moneyText'>{formatCurrency(fund,currentHousehold.currency)}</Typography>
        </MoneyLabel>
        </MoneyBoxBox>
        :
        <></>  
        }
    </>
  
  )
}

export const BalanceCard = ({fund} : FundCardType) => {
  const {currentHousehold} = useUser();
  const theme = useTheme();
  const {shadowStyle, isSmallScreen} = useAppContext();
  const isPos = fund >= 0? true : false;
  const {t} = useTranslation();


return (
  <>
      <MoneyBoxBox sx={shadowStyle}>
      <IconBox className='balance'>
      <Icon icon="Bargraph" size={isSmallScreen? 30 : 40} color={theme.palette.balance.main}/>
      </IconBox>
      <MoneyLabel>
      <Typography variant='h6' className='moneyBox-header'>{t("Balance")}</Typography>
        <Typography className='moneyText' sx={isPos?{color: theme.palette.success.textStyle}:{color: theme.palette.error.textStyle}}>{formatCurrency(fund,currentHousehold.currency, true)}</Typography>
      </MoneyLabel>
      </MoneyBoxBox>

  </>

)
}



