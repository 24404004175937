import { motion, MotionValue, useMotionValue, useScroll, useTransform } from 'framer-motion';
import React, { ReactNode, useRef } from 'react'
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import { Height } from '@mui/icons-material';


// const stepTransition1 = { duration: 0.8, ease: "easeInOut"};
// const stepTransition2 = { duration: 0.8, ease: "easeInOut", delay: 0.2};
// const stepTransition3 = { duration: 0.8, ease: "easeInOut", delay: 0.2};
const scaleUp = { duration: 1, ease: "easeOut"};

const ListBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    width: "100%",
    scrollSnapAlign: 'center',

    '& h3' : {
      fontSize: '2.8rem',
    },

    ' & .step-text' : {
      fontSize: '1.4rem',
    },

    '& p, h3' :{
      textAlign: "left",
      margin: theme.spacing(0,0,2,0),
    },
    '& .col': {
      width: "50%",
  
    },

    [theme.breakpoints.down(1400)]: { 
      '& h3' : {
        fontSize: '2.5rem',
      }
    },
  
       //Small
       [theme.breakpoints.down("lg")]: {
        flexDirection: 'column',
        justifyContent: 'center',
        margin: theme.spacing(10,0,0,0),

        '& p' : {
          margin: 0,
          textAlign: 'center',
        },

        '& .step-text' : {
          fontSize: '1.4rem',
        },

        '& h3' : {
          textAlign: 'center',
          fontSize: '2rem',
        },
  
  
        '& .col': {
          width: "100%",
          transform: 'translateY(0) !important',
        },

        '& #col-1' : {
          order: 2,
          marginBottom: '4rem',
        }

  
      },
  }));
  
  const StepCounter = styled(Typography)(({ theme }) => ({
    fontSize: '2.5rem',
    transform: 'translate(-4rem, 2.5rem)',
    margin: 0,
    opacity: 0.2,

    [theme.breakpoints.down("lg")]: {
      fontSize: '2rem',
      transform: 'translate(0,0)',
    },



  }));
  
  const StepContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    textAlign: "left",
    paddingLeft: '10rem',
    maxWidth: '450px',
  

    [theme.breakpoints.down("lg")]: {
      padding: 0,
      alignItems: 'center',
      maxWidth: '100%',

      '& p' : {
        textAlign: 'center',
      }
    }
  
  }));
  
  const ImageContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    textAlign: "left",
    backgroundColor: theme.palette.homepage.light,
    position:"relative",
    left:-160,
    height: "100vh",
    width: "50vw",
  
    '& > div' : {
      margin: 'auto',
      //position: 'sticky',
      top: '23vh',
    },
  
  
    '& img': {
      maxWidth: '36vw',
      margin: 'auto',
    },
  
    '& .step1': {
      width: '32vw',
    },
  
    '& .step2': {
      width: '25vw',
      transform: 'translateX(0.5vw)',
    },
  
    '& .step3': {
      marginRight: 0,
    },
    
  
    //Small
       [theme.breakpoints.down("lg")]: {
        right: 0,
        padding: theme.spacing(10,0),
        height: 'auto',
        width: "auto",
        marginTop: theme.spacing(5),
        left: 0,

        '& img' : {
          maxWidth: '600px',
          width: '68vw',
        },

        '& .step1, .step3' : {
          width: '68vw',
        },

        '& .step2' : {
          width: '64vw',
          transform: 'translateX(4.5vw)',
        }
      },

      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(8,0),

        '& img' : {
          maxWidth: '500px',
          width: '80vw',
        },
      },
  }));

type Props = {
    imgSrc : string;
    imgClassName : string;
    number?: string;
    title?: string;
    subtitle?: string;
  };



  function useParallax(value: MotionValue<number>, distance: number) {
    return useTransform(value, [0, 1], [-distance, distance]);
  }
  

const ParallaxContainer = ({imgSrc, imgClassName, number, title, subtitle} : Props) => {

    const ref = useRef(null);
    const { scrollYProgress } = useScroll({target: ref});
    const y = useParallax(scrollYProgress, 200);




  return (
    <ListBox>
    <div className='col'  id='col-1'>
        <ImageContainer>
            <motion.div initial = {{ scale: 0.7, opacity: 0}} whileInView={{ scale: 1, opacity: 1 }} exit={{ scale: 0, opacity: 0}} transition={scaleUp} >
              <img ref={ref} src={imgSrc} className={imgClassName}></img>
              </motion.div>
        </ImageContainer>
      </div>
      <motion.div className='col' id='col-2' style={{ y }}>
          <StepContainer>
                <StepCounter variant='body2'>
                  {number}
                </StepCounter>
                    <Typography variant='h3' style={{color: "inherit"}}>
                    {title}
                    </Typography>
                  <Typography className = "step-text" variant='body2' style={{color: "inherit", opacity: 0.5, lineHeight: 1.8}}>
                  {subtitle}
                  </Typography>
          </StepContainer>
      </motion.div>
    </ListBox>
  )
}

export default ParallaxContainer