import * as React from 'react';
import DraggableListItem from './DraggableListItem';
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder
} from 'react-beautiful-dnd';
import { CategoryType } from '../../main/resources/UserResources';

export type DraggableListProps = {
  categories: CategoryType[];
  blockIndex : number;
  thisTimeStamp : string;
  onDragEnd: OnDragEndResponder;
  categoryClicked: (category: CategoryType, blockIndex: number) => void;
  remainingObj: any;
};

const DraggableList = React.memo(({ categories, blockIndex, thisTimeStamp, onDragEnd, categoryClicked, remainingObj }: DraggableListProps) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {categories && categories.length > 0? 
            categories.filter((item) => {
              if (item.status === "deleted" && item.deleted && Number(thisTimeStamp >= item.deleted)) return false;
              if (Number(item.created) > Number(thisTimeStamp)) return false;
              if (typeof item.budget[thisTimeStamp] === "undefined") return false;
              return true;
            })
            .map((item, index) => {
              return (
              <DraggableListItem blockIndex={blockIndex} category={item} index={index} key={item.id + "-" + index} categoryClicked={categoryClicked} thisTimeStamp={thisTimeStamp} categoryRemaining={remainingObj[item.label]}/>
            )})
          :
          <></>
          }
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
});

export default DraggableList;
