import { Button, Container, styled, Typography, useTheme } from "@mui/material";
import React, { useState, useRef } from "react";
import { auth } from "../../@common/firebase/firebase";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import AuthDialog from "../../@common/auth/AuthDialog";
import useAuth from "../../@common/hooks/useAuth";
import Wave from "../../assets/images/waves.svg"
import { motion, useScroll, useSpring, useTransform } from "framer-motion";
import { opacityInAnimation, expandAnimation } from "../../@common/theme/Theme";
import logo from "../../assets/images/logo.svg";
import heroImg from "../../assets/images/hero-img.png"
import StoreButtons from "./StoreButtons";

const WelcomePageContainer = styled('div')(({ theme }) => ({
  width:"100%",
  height: "120vh",
  margin: "auto",
  backgroundImage: `url(${heroImg})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  //scrollSnapAlign: 'center',


}));

const ContentContainer = styled('div')(({ theme }) => ({
  height: "80vh",
  color: theme.palette.homepage.main,
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",

  '& .MuiTypography-root': {
    color: "inherit",
    lineHeight: '1.24',
  },

  [theme.breakpoints.down("lg")]: {
    height: "71vh",
  },

     //Mobile screens
     [theme.breakpoints.down("md")]: {
      width: "auto",
      height: "100vh",
    },

  '& .line-wrapper' : {
    overflow: 'hidden',
    textAlign: 'center'
  },

  '& .line-wrapper > div' : {
    display: 'inline-block',
  },

  '& .storebutton-container' : {
    marginTop: theme.spacing(8),
  }

}));




const Title = styled(Typography)(({ theme }) => ({
fontSize: "6vw",
zIndex: 1,
textAlign: "center",

[theme.breakpoints.down(1800)]: {
  fontSize: "8vw",
},

[theme.breakpoints.down("lg")]: {
  fontSize: "9vw",
},

[theme.breakpoints.down("md")]: {
  fontSize: "3.7rem",
},


}));

const LoginButton = styled(Button)(({ theme }) => ({
 width: "120px",
 padding: '15px',
 fontSize: 20,
 backgroundColor: '#30043411',

 '&:hover' : {
  backgroundColor: '#30043433',
 },

 [theme.breakpoints.down("md")] : {
  fontSize: 16,
  width: '100px',
  padding: '12px'
 }
 }));



const LogoBox = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  '& img' : {
    width: '135px',
  },

  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(2, 2, 2, 3)
  }


}));


const titleTransition1 = { duration: 2, ease: [0.47,.21,0.05,1], delay: 0.3};
const titleTransition2 = { duration: 2, ease: [0.47,.21,0.05,1], delay: 0.4};
const fadeInUp = { duration: 2, ease: [0.47,.21,0.05,1], delay: 0.4};




const Header = () => {
const [signinDialogOpen, setSigninDialogOpen] = useState(false);
const [signupDialogOpen, setSignupDialogOpen] = useState(false);
const {setWaitForResponse} = useAuth();
const theme = useTheme();




  const SignIn = async(email : string, password : string) =>{
    setWaitForResponse(true);
    await signInWithEmailAndPassword(auth, email, password)
    .then(() => {
      setWaitForResponse(false);
      return Promise.resolve();

    })
    .catch((error) => {
        setWaitForResponse(false);
        return Promise.reject(error);
    })

  }

  const SignUp = async(email : string, password : string) =>{
    setWaitForResponse(true);
    createUserWithEmailAndPassword(auth, email, password)
    .then(() => {
      setWaitForResponse(false);
    })
    .catch((error) => {
        Promise.reject();
    })

  }
  

    return (
      <>
      <motion.div >
      <WelcomePageContainer>
        <LogoBox>
        {/* <Icon icon='Logo' size={35} color={theme.palette.homepage.main}/>
        <Typography variant='logo' sx={{ml: 1, fontSize: "1.5em"}} color={theme.palette.homepage.main}>Balancey</Typography> */}
          <img src={logo} alt="balancey logo"></img>
          <LoginButton variant="text" onClick={() => setSigninDialogOpen(true)} color="homepage">Log In</LoginButton>
        </LogoBox>
      
        <ContentContainer>
          <div className="line-wrapper">
            <motion.div initial={{ opacity: 0, y: 120 }} animate={{ opacity: 1, y: 0 }} transition={titleTransition1}>
              <Title variant="h1">Mindful spending,</Title>
            </motion.div>
          </div>
          <div className="line-wrapper">
            <motion.div initial={{ opacity: 0, y: 120 }} animate={{ opacity: 1, y: 0 }} transition={titleTransition2}>
              <Title variant="h1">made simple.</Title>
            </motion.div>
          </div>
            <motion.div className="storebutton-container" initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={fadeInUp} viewport={{ once: true }}>
              <StoreButtons/>
            </motion.div>
              {/* <Button variant="outlined" onClick={() => setSignupDialogOpen(true)}>Create User</Button> */}
            <AuthDialog open={signupDialogOpen} setOpen={setSignupDialogOpen} sendAuth={SignUp} buttonText="Create Account" header="Hi there!" subheader="Just pop in your details and let's get this started."/>
            <AuthDialog open={signinDialogOpen} setOpen={setSigninDialogOpen} sendAuth={SignIn} buttonText="Sign In" addForgotPassword header="Welcome back!"/>
        </ContentContainer>

      </WelcomePageContainer>
      </motion.div>

      </>
    );
  
}

export default Header;

