import React from 'react'
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import Navigation from '../navigation/Navigation';
import Footer from '../../home/components/Footer';
import LanguagePicker from '../../settings/components/LanguagePicker';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';

const Page = styled('div')(({ theme }) => ({
    paddingTop: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
    backgroundColor: theme.palette.homepage.light,

  
  }));

const Section = styled('div')(({ theme }) => ({
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
    minHeight: "80vh",
  
  
    //Small
    [theme.breakpoints.down("lg")]: {
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),  },
    //Mobile
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  
  }));
  
  const Container = styled('div')(({ theme }) => ({
    width: "80vw",
    maxWidth: '1000px',
    margin: 'auto',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: theme.palette.homepage.main,
    marginBottom: theme.spacing(20),

  
  
   '& .MuiTypography-root':{
    color: "inherit",
   },
  

  }));

  const LinkList = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-start"

  }));
  
  

const Privacy = () => {
  const {t, i18n} = useTranslation();
  const {locale} = useParams();

    React.useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
    <>
            <Navigation/>
            <Page>
            <Section className='introduction'>
            <Container>
              <LinkList>
              <NavLink to={`/en/privacy`} style={{fontFamily: 'ApfelGrotezkRegular', textDecoration: "none", fontSize: '14px'}}>EN</NavLink>
              <hr style={{marginInline: 10}}/>
              <NavLink to={`/de/privacy`} style={{fontFamily: 'ApfelGrotezkRegular', textDecoration: "none", fontSize: '14px'}}>DE</NavLink>
              </LinkList>
              <Typography variant='h2' sx={{mb: 2}}>{t("PrivacyPolicyHeader", { lng: locale })}</Typography>
              <Typography variant="body1" sx={{mb: 2}}>{t("Last updated:", { lng: locale })} 15.01.24</Typography>
              <Typography variant='body1'>{t("Vanilla Noir Ltd (“us”, “we”, or “our”) operates the Balancey mobile and web application (the “App”). We are committed to ensuring that your personal information is protected and never misused. This policy informs you about what personal information we collect, why we collect it, how we use it, and what data protection rights you have.", { lng: locale })}</Typography>
              <Typography variant='h5' sx={{mb: 2, mt: 4}}>{t("Information Collection and Use", { lng: locale })}</Typography>
              <Typography variant='body1'>{t("We collect several types of information from and about users of our App, including:", { lng: locale })}</Typography>
              <ul>
                <li><Typography variant='body1'>{t("Personal Information: You can use the App without an account, but if you choose to create one, we will collect personal information such as your email address, and other information you provide when you register. This information is only used to authenticate you as a user.", { lng: locale })}</Typography></li>
                <li><Typography variant='body1'>{t("Performance Data: We may collect anonymized data to improve our services. This information may include the nature of your device, the pages of our App that you visit, the time and date of your visit, the time spent on those pages, and other statistics (such as taps, clicks, scrolling information, etc.). These data are fully anonymized and aggregated and thus not linked to any particular user. The application may also collect crashes and performance reports so we can improve the stability and performance of our app.", { lng: locale })}</Typography></li>
                <li><Typography variant='body1'>{t("Financial Data: Your spending and budgeting data is stored locally, but you can choose to connect an account to synchronize it with our Firebase Database (Google Inc.), where it will be stored separately from your personal data or device data. We store your data to provide the opportunity for you to use it on any device through the App. We do not use this data in any way other than as a backup for your personal use. We separate personal data from app usage data to protect your privacy.", { lng: locale })}</Typography></li>
                <li><Typography variant='body1'>{t("Log and Usage Data: We automatically collect certain information when you visit, use, or navigate the App. This information does not reveal your identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our App, and other technical information. This information is primarily needed to maintain the security and operation of our App, and for our internal analytics and reporting purposes.", { lng: locale })}</Typography></li>
              </ul>
              <Typography variant='h5' sx={{mb: 2, mt: 4}}>{t("Sharing of Information", { lng: locale })}</Typography>
              <Typography variant='body1'>{t("We may share non-personal information we collect with third-party service providers that perform services on our behalf, such as hosting services, analytics providers, and crash analytics providers. We also need to share some personal data with the following third party solution to make sure the App is working as intended:", { lng: locale })}</Typography>
              <ul>
                <li><Typography variant='body1'>{t("Firebase Authentication (Google Inc.):  This service helps us to provide you a secure connection to our systems and identify you as the owner of your data.", { lng: locale })}</Typography></li>
                <li><Typography variant='body1'>{t("Firebase Firestore (Google Inc.): This service provides cloud functionalities to securely store your usage data and make it available for you across all versions of our App.", { lng: locale })}</Typography></li>
              </ul>
              <Typography variant='body1'>{t("These third-party service providers are only granted access to your personal information, as we deem necessary, and they are contractually restricted in the ways they may use your information.", { lng: locale })}</Typography>
              <Typography variant='body1'>{t("If you decide to make use of our household sharing functionality, only expenses explicitly marked by you as \"shared expenses\" will be shared between the members of your shared household. Your budget will never be shared with other users, even if they share a household with you.", { lng: locale })}</Typography>
              <Typography variant='body1'>{t("We may share some of your information with third parties as required by law or to protect our legal rights. We do not sell or rent your personal information to third parties.", { lng: locale })}</Typography>
              <Typography variant='h5' sx={{mb: 2, mt: 4}}>{t("Data Retention", { lng: locale })}</Typography>
              <Typography variant='body1'>{t("We retain personal information for as long as necessary to provide our services, fulfill the purposes outlined in this Privacy Policy, or as required by law.", { lng: locale })}</Typography>
              <Typography variant='h5' sx={{mb: 2, mt: 4}}>{t("Legal Basis for Processing Data", { lng: locale })}</Typography>
              <Typography variant='body1'>{t("We process personal data based on various legal grounds, including:", { lng: locale })}</Typography>
              <ul>
                <li><Typography variant='body1'>{t("Consent: When you provide explicit consent for specific processing activities.", { lng: locale })}</Typography></li>
                <li><Typography variant='body1'>{t("Contractual Necessity: To fulfill contractual obligations with you.", { lng: locale })}</Typography></li>
                <li><Typography variant='body1'>{t("Legal Obligations: To comply with legal or regulatory obligations.", { lng: locale })}</Typography></li>
                <li><Typography variant='body1'>{t("Legitimate Interests: Pursuant to legitimate interests, balancing your rights and freedoms.", { lng: locale })}</Typography></li>
              </ul>
              <Typography variant='h5' sx={{mb: 2, mt: 4}}>{t("Your Choices", { lng: locale })}</Typography>
              <Typography variant='body1'>{t("You may choose not to provide us with certain information, but this may limit your ability to use certain features of the App.", { lng: locale })}</Typography>
              <Typography variant='body1'>{t("You may also choose to opt-out of certain types of communications from us, such as promotional emails or push notifications. You may opt-out by following the unsubscribe instructions provided in the communication or by contacting us at info@vanilla-noir.com.", { lng: locale })}</Typography>
              <Typography variant='h5' sx={{mb: 2, mt: 4}}>{t("Your Rights", { lng: locale })}</Typography>
              <Typography variant='body1'>{t("In certain regions like the EEA, UK, and Canada, you hold certain rights in accordance with relevant data protection laws. These may include the ability to (i) request access to and receive a copy of your personal information, (ii) request corrections or deletion of your data, (iii) control the processing of your personal information in certain situations, and (iv) where applicable, request data portability. Under specific circumstances, you may also have the right to contest the processing of your personal data. Should you wish to exercise these rights, please contact us using the provided details in the “Contact Us” section below.", { lng: locale })}</Typography>
              <Typography variant='h5' sx={{mb: 2, mt: 4}}>{t("Security", { lng: locale })}</Typography>
              <Typography variant='body1'>{t("We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee the security of your information.", { lng: locale })}</Typography>
              <Typography variant='h5' sx={{mb: 2, mt: 4}}>{t("Changes to this Privacy Policy", { lng: locale })}</Typography>
              <Typography variant='body1'>{t("We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.", { lng: locale })}</Typography>
              <Typography variant='h5' sx={{mb: 2, mt: 4}}>{t("Contact Us", { lng: locale })}</Typography>
              <Typography variant='body1'>{t("If you have any questions or concerns about this Privacy Policy or our practices, please contact us at info@vanilla-noir.com.", { lng: locale })}</Typography>

      </Container>
    </Section>
    <Footer/>


    </Page>
    </>
    
  )
}

export default Privacy