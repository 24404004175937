import React, { useEffect, useState, useMemo } from "react";
import { styled } from "@mui/system";
import {
  Button,
  Typography,
  ListItemButton,
  Card,
  useTheme,
} from "@mui/material";
import { useUser } from "../../@common/contexts/UserContext";
import {
  categorySumForMonth,
  formatCurrency,
  getFund,
  getSumforMonth,
  getThisMonthRemaining,
} from "../../main/resources/CalcRessources";
import { useEntry } from "../../@common/contexts/EntryContext";
import DraggableList from "../components/DraggableList";
import { DropResult } from "react-beautiful-dnd";
import { makeTimeStamp, reorder } from "../resources/helpers";
import { useAppContext } from "../../@common/contexts/AppContext";
import {
  defaultBlocks,
  defaultCategories,
  expandAnimation,
  months,
} from "../../@common/theme/Theme";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  EntryType,
  createRecurringIncome,
  deleteRecurringIncome,
  removeDeletedCategory,
} from "../../transactions/resources/EntryRessources";
import BlockTitle from "../components/BlockTitle";
import DoughnutChart from "../components/DoughnutChart";
import AddIcon from "@mui/icons-material/Add";
import EditDialog from "../../transactions/components/EditDialog";
import CloseIcon from "@mui/icons-material/Close";
import DeleteDialog from "../../@common/components/DeleteDialog";
import CategoryBox from "../components/CategoryBox";
import WarningBox from "../components/WarningBox";
import EntryDialog from "../../transactions/components/EntryDialog";
import CategoryDialogMobile from "../components/CategoryDialogMobile";
import { IncomeCard, BudgetCard } from "../components/BudgetCard";
import OverviewButtonGroup from "../components/OverviewButtonGroup";
import Layout from "../../layout";
import {
  BlockType,
  CategoryType,
  cleanUpData,
} from "../../main/resources/UserResources";
import piggybank from "../../assets/images/piggybank.png";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import LoadingModal from "../../@common/components/LoadingModal";

const TableHead = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const MonthBox = styled("div")(({ theme }) => ({
  margin: theme.spacing(0, 0, 4, 0),
  padding: theme.spacing(0),
  display: "flex",
  justifyContent: "space-between",
  // backgroundColor: theme.palette.common.white,

  //Mobile
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    margin: theme.spacing(0, 0, 2, 0),
  },
}));

const MoneyBox = styled("div")(({ theme }) => ({
  margin: theme.spacing(0, 0, 3, 0),
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const MainContainer = styled("div")(({ theme }) => ({
  display: "flex",

  //Small screens
  [theme.breakpoints.down("lg")]: {
    margin: "auto",
  },
}));

const LeftContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "55%",

  //Mobile
  [theme.breakpoints.down("lg")]: {
    width: "60%",
  },

  //Mobile
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const BudgetBox = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  minHeight: "270px",
  backgroundColor: theme.palette.background.paper,

  "& .category-block": {
    marginBottom: "1rem",
  },

  //Mobile
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(3, 1),
  },
}));

const StatsBox = styled("div")(({ theme }) => ({
  margin: theme.spacing(0, 0, 0, 3),
  width: "45%",
  display: "flex",
  flexDirection: "column",

  //Small
  [theme.breakpoints.down("lg")]: {
    width: "calc(40% - 24px)",
  },
}));

const CategoryDetailsContainer = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.palette.background.paper,
  margin: theme.spacing(3, 0),
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  animation: expandAnimation + " 200ms ease-out",
}));

const MonthPicker = styled("div")(({ theme }) => ({
  width: "fit-content",
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1),
  borderRadius: "50px",
  color: theme.palette.text.primary,

  "& .MuiTypography-root": {
    display: "inline",
    margin: 0,
    //fontSize: "2rem",
  },
}));

const ButtonContainer = styled("div")(({ theme }) => ({
  position: "relative",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  margin: theme.spacing(0, 0, 2, 0),
}));

const BlockBody = styled("div")(({ theme }) => ({
  position: "relative",
  width: "100%",
  borderRadius: theme.shape.borderRadius,
  border: "1px solid " + theme.palette.interactable,
}));

const CreateBudgetBox = styled("div")(({ theme }) => ({
  position: "relative",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  color: theme.palette.text.primary,
}));

const DeleteBudgetContainer = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  justifyContent: "flex-end",
}));

const OverviewContainer = () => {
  const { t } = useTranslation();
  const {
    user,
    currentHousehold,
    blocks,
    setBlocks,
    updateAllBlocks,
    updateUser,
  } = useUser();
  const { allEntries, entriesLoaded, addEntry, removeEntry, updateEntry } =
    useEntry();
  const {
    month,
    year,
    nextMonth,
    prevMonth,
    budgetAvailable,
    setBudgetAvailable,
    isMobile,
    shadowStyle,
    AddInfoNotification,
  } = useAppContext();
  const [isPast, setIsPast] = useState(false);
  const [categoryOverview, setCategoryOverview] = useState<any>(null);
  const monthName = t(months[month]) + " " + year;
  const [thisTimeStamp, setThisTimeStamp] = useState(
    makeTimeStamp(year, month)
  );
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [entryDialogOpen, setEntryDialogOpen] = useState(false);
  const [budgetDeleteDialogOpen, setBudgetDeleteDialogOpen] =
    React.useState(false);
  const [categoryDeleteDialogOpen, setCategoryDeleteDialogOpen] =
    useState(false);
  const [spending, setSpending] = useState(0);
  const [categoryMobileViewOpen, setCategoryMobileViewOpen] = useState(false);
  const [loadingModalOpen, setLoadingModalOpen] = React.useState(false);
  const theme = useTheme();

  const [toEdit, setToEdit] = useState<any>();
  const myIncome = React.useMemo(() => {
    if (entriesLoaded === true)
      return getSumforMonth(user.id, "income", allEntries, month, year);
    return 0;
  }, [user.id, allEntries, month, year]);
  const myFund = React.useMemo(() => {
    if (entriesLoaded === true)
      return getFund(user.id, allEntries, blocks, month, year);
    return 0;
  }, [user.id, blocks, allEntries, month, year]);
  const myExpense = useMemo(() => {
    return getSumforMonth(user.id, "expense", allEntries, month, year);
  }, [user.id, allEntries, month, year]);
  const currentRemainingObject = useMemo(() => {
    return getThisMonthRemaining(user.id, allEntries, blocks, month, year);
  }, [user.id, allEntries, month, year]);

  //Update Timestamp
  useEffect(() => {
    setThisTimeStamp(makeTimeStamp(year, month));
  }, [month, year]);

  //Check if budget exists
  useEffect(() => {
    let budgetAv = false;

    if (blocks != null) {
      blocks.forEach((block: BlockType) => {
        if (block.categories != null && block.categories.length > 0) {
          block.categories.forEach((element: CategoryType) => {
            if (
              typeof element.budget[thisTimeStamp] !== "undefined" &&
              element.status !== "deleted"
            ) {
              budgetAv = true;
              return;
            }
          });
        }
      });
    }

    setBudgetAvailable(budgetAv);
  }, [blocks, setBudgetAvailable, thisTimeStamp]);

  const onDragEnd = (
    blockIndex: number,
    { destination, source }: DropResult
  ) => {
    // dropped outside the list
    if (!destination) return;
    let newBlocks = [...blocks];
    const blockCats = newBlocks[blockIndex].categories;

    const activeCategories = blockCats.filter((item: CategoryType) => {
      if (
        item.status === "deleted" &&
        item.deleted &&
        Number(thisTimeStamp >= item.deleted)
      )
        return false;
      if (Number(item.created) > Number(thisTimeStamp)) return false;
      if (typeof item.budget[thisTimeStamp] === "undefined") return false;
      return true;
    });
    const toMove = activeCategories[source.index];
    const moveTo = activeCategories[destination.index];
    const correctDestinationIndex =
      newBlocks[blockIndex].categories.indexOf(moveTo);
    const correctSourceIndex = newBlocks[blockIndex].categories.indexOf(toMove);

    newBlocks[blockIndex].categories = reorder(
      blocks[blockIndex].categories,
      correctSourceIndex,
      correctDestinationIndex
    );

    setBlocks(newBlocks);
    updateAllBlocks(newBlocks);
  };

  const onBlockAdd = () => {
    if (blocks.length < 10) {
      const temp = [...blocks];
      const newBlock = {
        name: t("Other Categories"),
        status: "okay",
        created: thisTimeStamp,
        categories: [],
      };
      temp.push(newBlock);
      setBlocks(temp);
    } else {
      AddInfoNotification(t("You've reached the limit for new blocks."));
    }
  };

  const onCategoryAdd = (blockIndex: number) => {
    if (blocks[blockIndex].categories.length < 20) {
      const temp = [...blocks];
      const monthStamp = makeTimeStamp(year, month);

      let tempBudget: any = {
        [monthStamp]: 0,
      };

      for (let i = 0; i < user.budgetList.length; i++) {
        if (user.budgetList[i] > monthStamp) tempBudget[user.budgetList[i]] = 0;
      }

      const newCategory = {
        id: String(Date.now()),
        icon: "Bag",
        label: t("Label"),
        budget: tempBudget,
        startingBalance: 0,
        secretBalance: 0,
        created: monthStamp,
        status: "edit",
      };
      temp[blockIndex].categories.push(newCategory);
      setBlocks(temp);
    } else {
      AddInfoNotification(t("You've reached the limit for new categories."));
    }
  };

  const onCategoryDelete = () => {
    if (categoryOverview !== null) {
      const temp = [...blocks];
      const index = temp[categoryOverview.blockIndex].categories.findIndex(
        (cat: CategoryType) => cat.id === categoryOverview.category.id
      );

      if (index > -1) {
        const catLabel =
          temp[categoryOverview.blockIndex].categories[index].label;
        // only splice array when item is found
        //If category is unused  - delete it completely (Only for non-default categories)
        if (
          (temp[categoryOverview.blockIndex].categories[index].budget &&
            Object.keys(
              temp[categoryOverview.blockIndex].categories[index].budget
            ).length <= 0) ||
          temp[categoryOverview.blockIndex].categories[index].created ===
            thisTimeStamp
        ) {
          if (
            !temp[categoryOverview.blockIndex].categories[index].id.includes(
              "default"
            )
          ) {
            temp[categoryOverview.blockIndex].categories.splice(index, 1);
          } else {
            temp[categoryOverview.blockIndex].categories[index].status =
              "deleted";
            temp[categoryOverview.blockIndex].categories[index].deleted =
              thisTimeStamp;
          }
        } else {
          temp[categoryOverview.blockIndex].categories[index].status =
            "deleted";
          temp[categoryOverview.blockIndex].categories[index].deleted =
            thisTimeStamp;
        }
        removeDeletedCategory(allEntries, month, year, catLabel, updateEntry);
      }
      updateAllBlocks(temp);
      setBlocks(temp);
      setCategoryOverview(null);
    }
  };

  const onCreateBudget = async () => {
    setLoadingModalOpen(true);
    const response = await cleanUpData(user, year, month);

    if (response) {
      let temp = [...response.blocks];

      //Go through all categories to check which are deleted;
      let allDeleted = true;
      let categorynumber = 0;
      temp.forEach((block: BlockType) => {
        if (block.categories != null) {
          block.categories.forEach((element: CategoryType) => {
            categorynumber = categorynumber + 1;
            if (element.status === "okay") allDeleted = false;
          });
        }
      });

      if (categorynumber < 1 || allDeleted) {
        //No categories exist - default ones are generated
        const generatedBlocks = defaultBlocks;
        const generatedCategories = defaultCategories;
        // let counter = 1;
        for (let i = 0; i < generatedBlocks.length; i++) {
          //Going through all default Blocks and generating block objects
          const thisBlock: {
            name: string;
            status: string;
            created: string;
            categories: CategoryType[];
          } = {
            name: t(generatedBlocks[i]),
            status: "okay",
            created: thisTimeStamp,
            categories: [],
          };
          //Going through all default categories for the current block and generating category objects
          for (let j = 0; j < generatedCategories[i].length; j++) {
            const newCategory = {
              id: "default" + generatedCategories[i][j],
              icon: generatedCategories[i][j].split(" ")[0],
              label: t(generatedCategories[i][j]),
              budget: {
                [thisTimeStamp]: 0,
              },
              startingBalance: 0,
              secretBalance: 0,
              created: thisTimeStamp,
              status: "okay",
            };
            //Adding the category to the block object
            thisBlock.categories.push(newCategory);
          }
          //Putting the finished block into temp
          temp.push(thisBlock);
        }
      } else {
        //Categories exist and new budgets are made based on newest
        temp.forEach((block: BlockType) => {
          block.categories.forEach((element: CategoryType) => {
            //This is when creating a past budget. The "created" gets moved to the past for default categories.
            if (
              Number(element.created) > Number(thisTimeStamp) &&
              element.id.includes("default")
            ) {
              element.created = thisTimeStamp;
              block.created = thisTimeStamp;

              const budgetArray = Object.values(element.budget);
              element.budget[thisTimeStamp] =
                budgetArray[budgetArray.length - 1];
              if (element.budget[thisTimeStamp] === undefined)
                element.budget[thisTimeStamp] = 0;
            } else if (element.status !== "deleted") {
              const budgetArray = Object.values(element.budget);
              element.budget[thisTimeStamp] =
                budgetArray[budgetArray.length - 1] || 0;
            }
          });
        });
      }
      response.budgetList = [...response.budgetList, thisTimeStamp];

      createRecurringIncome(allEntries, month, year, addEntry);
      updateUser(response);

      setBlocks(temp);
      setLoadingModalOpen(false);
    } else {
      AddInfoNotification(t("Something went wrong."));
    }
  };

  const onDeleteBudget = () => {
    const currUser = { ...user };
    const temp = [...currUser.blocks];
    temp.forEach((block: BlockType) => {
      block.categories.forEach((element: CategoryType) => {
        if (typeof element.budget[thisTimeStamp] !== "undefined") {
          delete element.budget[thisTimeStamp];
        }
      });
    });

    const budindex = currUser.budgetList.indexOf(thisTimeStamp);
    if (budindex !== -1) currUser.budgetList.splice(budindex, 1);

    //If there were recurring incomes automatically made, delete them
    deleteRecurringIncome(allEntries, month, year, removeEntry);
    setCategoryOverview(null);
    updateUser(currUser);

    setBlocks(temp);
  };

  const onCategoryClicked = (category: CategoryType, blockIndex: number) => {
    if (categoryOverview === null || categoryOverview.category !== category) {
      setCategoryOverview({ category: category, blockIndex: blockIndex });
    }
    if (isMobile === true) {
      setCategoryMobileViewOpen(true);
    }
  };

  const handleEntryClick = (entry: EntryType) => {
    setToEdit(entry);
    setEditDialogOpen(true);
  };

  useEffect(() => {
    setCategoryOverview(null);
    const date = new Date();
    if (
      Number(year) < date.getFullYear() ||
      (date.getFullYear() === Number(year) && Number(month) < date.getMonth())
    ) {
      setIsPast(true);
      return;
    }
    setIsPast(false);
  }, [month, year]);

  return (
    <Layout title={t("Overview for") + " " + user.name || "Overview"}>
      <MonthBox>
        <MonthPicker>
          <Button
            onClick={prevMonth}
            sx={{
              minWidth: "auto",
              width: "fit-content",
              mr: 1,
              color: theme.palette.text.primary,
            }}
            aria-label={t("previous month") || "previous month"}
          >
            <ArrowBackIosNewIcon sx={{ fontSize: 16 }} />
          </Button>
          <Typography variant="h5">{monthName}</Typography>
          <Button
            onClick={nextMonth}
            sx={{
              minWidth: "auto",
              width: "fit-content",
              ml: 1,
              color: theme.palette.text.primary,
            }}
            aria-label={t("previous month") || "previous month"}
          >
            <ArrowForwardIosIcon sx={{ fontSize: 16 }} />
          </Button>
        </MonthPicker>
        {myExpense !== spending && !isPast && entriesLoaded === true ? (
          <WarningBox />
        ) : (
          <></>
        )}
        {isMobile === true ? (
          <></>
        ) : (
          <Button
            aria-label={t("Add Transaction") || "Add Transaction"}
            onClick={() => setEntryDialogOpen(true)}
            variant="contained"
          >
            <AddIcon sx={{ fontSize: "24px", mr: 2, ml: "-5px" }} />
            {t("New Transaction")}
          </Button>
        )}
      </MonthBox>
      <MainContainer>
        <LeftContainer>
          <MoneyBox>
            <IncomeCard income={myIncome} />
            <BudgetCard fund={myFund} />
          </MoneyBox>
          <BudgetBox sx={shadowStyle}>
            {budgetAvailable ? (
              <>
                <ButtonContainer>
                  <OverviewButtonGroup />
                </ButtonContainer>
                {blocks && blocks.length > 0 ? (
                  blocks
                    .filter((item: BlockType) => {
                      if (
                        item.status === "deleted" &&
                        Number(thisTimeStamp) >= Number(item.deleted)
                      )
                        return false;
                      if (Number(item.created) > Number(thisTimeStamp))
                        return false;
                      return true;
                    })
                    .map((block: BlockType) => {
                      const blockIndex = blocks.indexOf(block);
                      return (
                        <div key={blockIndex} className="category-block">
                          <TableHead>
                            <BlockTitle
                              index={blockIndex}
                              thisTimeStamp={thisTimeStamp}
                            />
                          </TableHead>
                          <BlockBody>
                            <DraggableList
                              categories={block.categories}
                              blockIndex={blockIndex}
                              onDragEnd={(dropresult: DropResult) => {
                                onDragEnd(blockIndex, dropresult);
                              }}
                              thisTimeStamp={thisTimeStamp}
                              categoryClicked={onCategoryClicked}
                              remainingObj={currentRemainingObject}
                            />
                            <Button
                              onClick={() => onCategoryAdd(blockIndex)}
                              sx={{
                                color: theme.palette.text.secondary,
                                fontSize: 14,
                                ml: 1,
                                mt: 0.5,
                                mb: 2,
                                pt: 2,
                                pb: 2,
                                height: "20px",
                              }}
                            >
                              <AddIcon sx={{ fontSize: 14, mr: 1 }} />
                              {t("Add Category")}
                            </Button>
                          </BlockBody>
                        </div>
                      );
                    })
                ) : (
                  <></>
                )}
                <Button
                  onClick={onBlockAdd}
                  sx={{
                    color: theme.palette.text.secondary,
                    fontSize: { xs: 14 },
                    pt: 2,
                    pb: 2,
                    height: "20px",
                  }}
                >
                  <AddIcon sx={{ fontSize: 14, mr: 1 }} />
                  {t("Add Block")}
                </Button>
                <DeleteBudgetContainer>
                  <Button
                    variant="outlined"
                    onClick={() => setBudgetDeleteDialogOpen(true)}
                    sx={{
                      position: "relative",
                      right: 0,
                      height: "30px",
                      fontSize: "14px",
                    }}
                  >
                    {t("Delete Budget")}
                  </Button>
                </DeleteBudgetContainer>
              </>
            ) : (
              <CreateBudgetBox>
                <img
                  src={piggybank}
                  //@ts-ignore
                  alt={t("girl filling a piggy bank")}
                  style={{ width: 150 }}
                ></img>
                <Typography>
                  {t("You haven't created a budget yet.")}
                </Typography>
                <Button
                  onClick={onCreateBudget}
                  sx={{ mt: 2 }}
                  variant="contained"
                  size="large"
                >
                  {t("Create Budget")}
                </Button>
              </CreateBudgetBox>
            )}
          </BudgetBox>
        </LeftContainer>
        {isMobile === true ? (
          <></>
        ) : (
          <StatsBox>
            <DoughnutChart
              thisTimeStamp={thisTimeStamp}
              budgetAvaiable={budgetAvailable}
              setSpending={setSpending}
              remainingObject={currentRemainingObject}
            />
            {categoryOverview === null ? (
              <></>
            ) : (
              <>
                <CategoryDetailsContainer sx={shadowStyle}>
                  <Button
                    onClick={() => setCategoryOverview(null)}
                    sx={{
                      padding: 0,
                      minWidth: "auto",
                      mb: 1,
                      position: "absolute",
                      right: "8px",
                      top: "8px",
                    }}
                    aria-labelledby={
                      t("close category window") || "close category window"
                    }
                  >
                    <CloseIcon sx={{ fontSize: "20px" }} />
                  </Button>
                  <CategoryBox
                    category={categoryOverview.category}
                    thisTimeStamp={thisTimeStamp}
                    blockIndex={categoryOverview.blockIndex}
                    sumForMonth={categorySumForMonth(
                      user.id,
                      allEntries,
                      categoryOverview.category.label,
                      month,
                      year
                    )}
                    remainingForMonth={
                      currentRemainingObject[categoryOverview.category.label]
                    }
                    handleEntryClick={handleEntryClick}
                    setCategoryDeleteDialogOpen={setCategoryDeleteDialogOpen}
                  />
                </CategoryDetailsContainer>
              </>
            )}
          </StatsBox>
        )}
        <EditDialog
          open={editDialogOpen}
          setOpen={setEditDialogOpen}
          entry={toEdit}
        />
        <EntryDialog open={entryDialogOpen} setOpen={setEntryDialogOpen} />
        <DeleteDialog
          open={categoryDeleteDialogOpen}
          setOpen={setCategoryDeleteDialogOpen}
          onSubmit={onCategoryDelete}
          toDelete={categoryOverview?.category?.label || ""}
        />
        <CategoryDialogMobile
          open={categoryMobileViewOpen}
          setOpen={setCategoryMobileViewOpen}
        >
          {categoryOverview === null ? (
            <></>
          ) : (
            <>
              <CategoryBox
                category={categoryOverview.category}
                thisTimeStamp={thisTimeStamp}
                blockIndex={categoryOverview.blockIndex}
                sumForMonth={categorySumForMonth(
                  user.id,
                  allEntries,
                  categoryOverview.category.label,
                  month,
                  year
                )}
                remainingForMonth={
                  currentRemainingObject[categoryOverview.category.label]
                }
                handleEntryClick={handleEntryClick}
                setCategoryDeleteDialogOpen={setCategoryDeleteDialogOpen}
              />
            </>
          )}
        </CategoryDialogMobile>
        <DeleteDialog
          open={budgetDeleteDialogOpen}
          setOpen={setBudgetDeleteDialogOpen}
          onSubmit={onDeleteBudget}
          toDelete={"your budget for this month"}
        />
      </MainContainer>
      <LoadingModal open={loadingModalOpen} />
    </Layout>
  );
};

export default OverviewContainer;
