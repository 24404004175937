import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { Button, Dialog, Typography, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useUser } from '../../@common/contexts/UserContext';
import { collection, doc, setDoc } from 'firebase/firestore';
import { firestore } from '../../@common/firebase/firebase';
import { useAppContext } from '../../@common/contexts/AppContext';
import { useTranslation } from 'react-i18next';

const CustomDialog = styled(Dialog)(({ theme }) => ({

  '& .MuiPaper-root':{
    width: "300px",
    height: "250px",
    borderRadius: "25px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(4)
  }

}));

type InviteDialogType = {
  open: boolean,
  setOpen: (open: boolean) => void,
}

const InviteDialog = ({open, setOpen} : InviteDialogType) => {
const [inviteEmail, setInviteEmail] = React.useState<string>('');
const {user, currentHousehold} = useUser();
const {AddInfoNotification} = useAppContext();
const {t, i18n} = useTranslation();


	const handleClose = () => {
		setOpen(false);
	};

    const handleInvite = async () => {

      const re = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
      if (!inviteEmail || !re.test(inviteEmail)){
        setInviteEmail("");
        handleClose();
        AddInfoNotification(t("Something went wrong. Please try again later."))
        return;
      }

        const inviteObject = {
          inviter: user.id,
          inviterName: user.name,
          invitee: inviteEmail,
          householdId: currentHousehold.id,
          householdName: currentHousehold.name,
        }
        const ref = doc(collection(firestore, "invitations"));
  
       await setDoc(ref,inviteObject);
        
      AddInfoNotification(t("Successfully sent invitation!"));
      setInviteEmail("");
      handleClose();
    }

  return (
      <CustomDialog
      open={open}
      onClose={handleClose}
      >
        <Typography variant='h4' sx={{textAlign: "center"}}>{t("Invite a user to your household!")}</Typography>
        <TextField 
        id="invite-email" 
        label={t("Their email address")}
        aria-labelledby={t("Their email address") || "Their email address"}
        variant="standard" 
        value={inviteEmail}
        onChange={(e) => setInviteEmail(e.target.value)}
        />

      <Button variant='contained' onClick={handleInvite} sx={{mt:2}}>{t("Send")}</Button>

      </CustomDialog>
   
    
  );
};

export default InviteDialog;
