import { initializeApp } from "firebase/app";
import {
  CACHE_SIZE_UNLIMITED,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAUNAWwSo1Kas1q9QpORuO8kprQH2_kq_Q",
  authDomain: "money-app-9912a.firebaseapp.com",
  projectId: "money-app-9912a",
  storageBucket: "money-app-9912a.appspot.com",
  messagingSenderId: "814823363412",
  appId: "1:814823363412:web:6610df6e946d3936a06ec1",
  measurementId: "G-J05BEBKTCE",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firestore = initializeFirestore(app, {
  localCache: persistentLocalCache(
    /*settings*/ {
      cacheSizeBytes: CACHE_SIZE_UNLIMITED,
      tabManager: persistentMultipleTabManager(),
    }
  ),
});
export const auth = getAuth(app);
export const functions = getFunctions(app);
