import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { Button, Dialog, Typography } from '@mui/material';
import { useUser } from '../../@common/contexts/UserContext';
import { formatCurrency } from '../../main/resources/CalcRessources';
import { useTranslation } from 'react-i18next';
import { MemberType } from '../../main/resources/UserResources';

const CustomDialog = styled(Dialog)(({ theme }) => ({

  '& .MuiPaper-root':{
    borderRadius: "25px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(4)
  }

}));

type SettleUpDialogType = {
  open: boolean,
  setOpen: (open: boolean) => void,
  balances : any

}

const SettleUpDialog = ({open, setOpen, balances} : SettleUpDialogType) => {
  const {currentHousehold} = useUser();
  const [payer, setPayer] = useState<any[]>([]);
  const [payee, setPayee] = useState<any[]>([]);
  const {t} = useTranslation();

	const handleClose = () => {

		setOpen(false);
	};


  useEffect(() => {
    if (balances && currentHousehold.members){
      let tempBalances = {...balances};
      let payers : any[] = [];
      let payees : any[] = [];
      
      currentHousehold.members.forEach((member : MemberType) => {
      
        if (tempBalances[member.id] === 0) {
          delete tempBalances[member.id];
        }
        else if (tempBalances[member.id] > 0){
          payees.push({[member.name]: tempBalances[member.id]});
          }
        else {
          payers.push({[member.name]: tempBalances[member.id]});
        }      
      });


      let l = payees.length;
  
      for (let i = 0; i < l; i++)
      {
        let sum = Number(Object.values(payers[i])[0]) + Number(Object.values(payees[i])[0]);
        sum = Math.round(sum * 100) / 100;
        //Payer still wants more money, so another payer object gets pushed
        if (sum < 0){
          payers[i] = {[String(Object.keys(payers[i])[0])]: Number(Object.values(payees[i])[0])};
          payers.push({[String(Object.keys(payers[i])[0])]: sum})
        }
        //Payee owes more money, so another payee object gets pushed
        else if (sum > 0){
          payees[i] = {[String(Object.keys(payees[i])[0])]: Number(Object.values(payers[i])[0])};
          payees.push({[String(Object.keys(payees[i])[0])]: sum})
        }
      }
  
      setPayee(payees);
      setPayer(payers);
    }

  }, [balances, currentHousehold.members])
  
  


  return (
      <CustomDialog
      open={open}
      onClose={handleClose}
      >
       <Typography variant='h5' sx={{mb: 3}}>{t("Suggested Payments:")}</Typography>
       {payee.map((val : any, index : number) => {
        return(
          <Typography key={index}><>{String(Object.keys(payer[index])[0])} {t("pays")} {formatCurrency(Math.abs(Number(Object.values(payer[index])[0])), currentHousehold.currency)} {t("to")} {String(Object.keys(payee[index])[0])}</></Typography>
        )})

       }
       {payee.length <= 0?
       <Typography>{t("Actually, you're all set already!")}</Typography>
        :        
        <Typography>{t("and everybody should be happy!")}</Typography>

      }

 
    <Button
  variant="contained"
  component="label"
  sx={{mb: 0, mt: 3, width: "50%", ml: "auto", mr: "auto"}}
  onClick={handleClose}
>
 {t("Okay!")}
</Button>
</CustomDialog>
   
    
  );
};

export default SettleUpDialog;
