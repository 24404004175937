import { Button, styled, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { useUser } from '../../@common/contexts/UserContext';
import { formatCurrency } from '../../main/resources/CalcRessources';
import { timeStampToDate } from '../resources/helpers';
import EditableLabel from './EditableLabel';
import CategoryIconPicker from './categoryIconPicker';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useEntry } from '../../@common/contexts/EntryContext';
import ListItemButton from '@mui/material/ListItemButton';
import Icon from '../../Icon';
import { useAppContext } from '../../@common/contexts/AppContext';
import { useTranslation } from 'react-i18next';
import { CategoryType } from '../../main/resources/UserResources';
import { EntryType } from '../../transactions/resources/EntryRessources';


const TopBox = styled("div")(({ theme }) => ({
  position:"relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0,2),

 
 }));

 const IconPickerButton = styled(Button)(({ theme }) => ({
  padding:0, 
  minWidth: "auto", 
  width: "60px", 
  height: "60px",
  margin: theme.spacing(0,2,0,0),
  
  '&:hover': {
    backgroundColor: theme.palette.interactable,
  },

  '&:hover path':{
    fill: theme.palette.primary.main + "!important",
  }
 
 }));

const LabelBox = styled("div")(({ theme }) => ({
    position:"relative",
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: theme.spacing(0,0, 2, 0)
   
   }));

  const StartingBalanceBox = styled("div")(({ theme }) => ({
  position:"relative",
  display: "flex",
  alignItems: "center",
  width: "100%",
  padding: theme.spacing(1.5,0)
  
  }));

   const SecondRow = styled("div")(({ theme }) => ({
    position:"relative",
    display: "flex",
    alignItems: "center",
    width: "100%",
    borderTop: "1px solid " + theme.palette.divider, 
    padding: theme.spacing(2,0)   
   }));

   const MoneyCol = styled("div")(({ theme }) => ({
    position:"relative",
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(0,7,0,0),
    
      //Small screens
  [theme.breakpoints.down("lg")]: {
    margin: theme.spacing(0,2,0,0),

    '& .MuiTypography-root': {
      fontSize: "14px"
    }
  }
   }));

   const TransactionsContainer = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
   
   }));

   const EntryContainer = styled(ListItemButton)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "7px",
    padding: 0,
  
    '& .MuiTypography-root': {
      margin: theme.spacing(1,2),
      fontSize: "14px",
    }
  
     }
   ));

   const CarryOverContainer = styled('div')(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "7px",
    padding: 0,
  
    '& .MuiTypography-root': {
      margin: theme.spacing(1,2),
      fontSize: "14px",
    }
  
     }
   ));

   const DeleteBudgetContainer = styled('div')(({ theme }) => ({
    position:"relative",
    display:"flex",
    justifyContent:"flex-end"
   
   }));


type CategoryBoxProps = {
    category : CategoryType,
   thisTimeStamp : string,
   sumForMonth: number,
   remainingForMonth: number,
   blockIndex: number,
   handleEntryClick: (entry: EntryType) => void,
   setCategoryDeleteDialogOpen: (bool : boolean) => void,
  };

const CategoryBox = ({category, thisTimeStamp, sumForMonth, remainingForMonth, blockIndex, handleEntryClick, setCategoryDeleteDialogOpen} : CategoryBoxProps) => {
    const {user, updateCategory, updateCategoryFund, updateCategoryStartingValue, currentHousehold} = useUser();
    const {monthEntries} = useEntry();
    const {color_series, color_series_text} = useAppContext();
     const [editState, setEditState] = React.useState(false);
     const [startingEditState, setStartingEditState] = React.useState(false);
     const [plannedEditState, setPlannedEditState] = React.useState(false);
     const [openIconPicker, setOpenIconPicker] = React.useState(false);
    const {renameEntryCategories} = useEntry();
    const theme = useTheme();
    const {t, i18n} = useTranslation();
    const {timeStampMonth, timeStampYear} = timeStampToDate(category.created);

    
    const filteredEntries = monthEntries.filter((entry : EntryType) => {
      if (entry.category !== category.label) return false;
      return true;  
    })


    const handleKeyPresses = (e : any) => {
      if (e.key === "Escape") cancelEdit();
      if (e.key === "Enter") finishEdit(e.target.value);
  
    }
    const handleStartingBalanceKeyPresses = (e : any) => {
      if (e.key === "Escape") setStartingEditState(false);
      if (e.key === "Enter") finishStartingBalanceEdit(e.target.value);
  
    }

    const finishEdit = (newLabel : string, checkLabel? : boolean) => {
        //We cancel the process when no name was added
        if (newLabel === "Label" && checkLabel) 
        {
        cancelEdit();
        return;
        }
        if (newLabel === category.label || newLabel.length < 1){
          cancelEdit();
          return;
        }
        renameEntryCategories(category.label,newLabel);
        const temp = category;
        temp.label = newLabel;
        temp.status = "okay";
        setEditState(false);
        updateCategory(temp, category, blockIndex);
      }
      const finishStartingBalanceEdit = (newLabel : string, checkLabel? : boolean) => {
        if (Number(newLabel) === category.startingBalance){
          setStartingEditState(false);
          return;
        }
        const cleanText = newLabel.replace(currentHousehold.currency,'');
        const newStart = parseFloat(cleanText.replace(/,/g,''));
        updateCategoryStartingValue(newStart, category, blockIndex);
        setStartingEditState(false);

      }

    const cancelEdit = () => {
        setEditState(false);
      }

      const handlePlannedKeyPresses = (e : any) => {
        if (e.key === "Escape") cancelPlannedEdit();
        if (e.key === "Enter") finishPlannedEdit(e.target.value);
    
      }
    
      const finishPlannedEdit = (newLabel : string, checkLabel? : boolean) => {
        if (newLabel === String(category.budget[thisTimeStamp]))
        {
          setPlannedEditState(false);
          return;
        }
        const cleanText = newLabel.replace(currentHousehold.currency,'');
        const fundNumber = parseFloat(cleanText.replace(/,/g,''));
        updateCategoryFund(fundNumber, category, thisTimeStamp, blockIndex);
        setPlannedEditState(false);
      }

      const cancelPlannedEdit = () => {
        setPlannedEditState(false);
      }
    
  return (
    <>
              {/* <Button sx={{padding: 0, minWidth: "auto", mb: 1, position: "absolute", right: "36px", top: "8px"}} aria-labelledby={t('close category window') || "close category window"}>
          <MoreVertIcon sx={{fontSize: "20px"}}/>
          </Button> */}
    <TopBox>
    <LabelBox>
      <div className='col-1'>
        <IconPickerButton sx={{backgroundColor: color_series[blockIndex % color_series.length]}} onClick={() => setOpenIconPicker(true)} aria-label={t('edit icon') || "edit icon"}>
        <Icon icon={category.icon} size={28} color={color_series_text[blockIndex % color_series.length]}/>
        </IconPickerButton>
      </div>
      <div className='col-2'>
      <EditableLabel editState={editState} label={category.label} handleKey={handleKeyPresses}
        handleBlur={finishEdit}
        handleOnClick={() => {setEditState(true)}}
        labelSx={{
            fontSize: "22px",
            margin: 0
        }}
        showEditable={true}
        />   
      </div>

    </LabelBox>
    <Typography sx={{fontSize: "14px", color: theme.palette.text.secondary}}>{t("Created")}: {t(timeStampMonth) + " " + timeStampYear}</Typography>
    <StartingBalanceBox>
    <Typography sx={{fontSize: "16px", mr: 2}}>{t("Initial Balance")}:</Typography>
    <EditableLabel editState={startingEditState} label={String(category.startingBalance)} handleKey={handleStartingBalanceKeyPresses}
        type='number'
        handleBlur={finishStartingBalanceEdit}
        handleOnClick={() => {setStartingEditState(true)}}
        labelSx={{
            fontSize: "16px",
            margin: 0
        }}
        showEditable={true}
        />  
    </StartingBalanceBox>
    <SecondRow>
    <MoneyCol>
    <Typography sx={{mr:"4px", mb: 0, fontSize: "16px"}}><span>{t("Planned")}:</span></Typography>
    <EditableLabel editState={plannedEditState} label={String(category.budget[thisTimeStamp])} handleKey={handlePlannedKeyPresses}
        type="number"
        handleBlur={finishPlannedEdit}
        handleOnClick={() => {setPlannedEditState(true)}}
        labelSx={{
          fontSize: "16px",
          margin: 0,
        }}/>
    </MoneyCol>
    <MoneyCol>
    <Typography sx={{margin:0, fontSize: "16px"}}><span>{t("Spent")}:</span> </Typography>
    <Typography sx={{margin:0, paddingTop:0.5, paddingBottom:0.5, fontSize: "16px"}}>{formatCurrency(sumForMonth, currentHousehold.currency)}</Typography>
    </MoneyCol>
    <MoneyCol>
    <Typography sx={{margin:0, fontSize: "16px"}}><span>{t("Remaining")}:</span></Typography>
    <Typography sx={{margin:0, paddingTop:0.5, paddingBottom:0.5, fontSize: "16px"}}>{formatCurrency(((remainingForMonth || 0) + category.budget[thisTimeStamp] + category.startingBalance) - sumForMonth, currentHousehold.currency)}</Typography>
    </MoneyCol>
    </SecondRow>
    </TopBox>
    <TransactionsContainer>
    <Typography variant='h6' ml={1}>{t("Transactions")}</Typography>
          {filteredEntries.map((entry : EntryType) => {
            return(
         
              <EntryContainer key={entry.id} sx={{mt:1}} onClick={(e) => {handleEntryClick(entry)}}>
              <div className='flex'>
              <Typography sx={{width: "50px"}}>{entry.date.toDate().toLocaleDateString(i18n.language)}</Typography>
              <Typography>{entry.merchant}</Typography>
              </div>
              <Typography> {formatCurrency(entry.payee[user.id], currentHousehold.currency)}</Typography>
              </EntryContainer>
          
            )
          })}
          <CarryOverContainer sx={{mt:1}}>
          <div className='flex'>
          <Typography>{t("Carryover from last month")}</Typography>
          </div>
          <Typography> {formatCurrency(remainingForMonth, currentHousehold.currency, true)}</Typography>
          </CarryOverContainer>

    <CategoryIconPicker open={openIconPicker} setOpen={setOpenIconPicker} category={category} blockIndex={blockIndex}/>
    </TransactionsContainer>
    <DeleteBudgetContainer><Button variant='outlined' onClick={() => setCategoryDeleteDialogOpen(true)} sx={{position: "relative", right: 0, height:"30px", fontSize:"14px", mt: 2}}>{t("Delete Category")}</Button></DeleteBudgetContainer>

    </>
  );
};

export default CategoryBox;
