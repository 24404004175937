import { useTheme } from "@mui/material";
import { Chart, LinearScale } from "chart.js";
import { SankeyController, Flow } from "chartjs-chart-sankey";
import React from 'react'
import { useAppContext } from "../../@common/contexts/AppContext";
import { useEntry } from "../../@common/contexts/EntryContext";
import { useUser } from "../../@common/contexts/UserContext";
import { categorySumForMonth, getSumforMonth } from "../../main/resources/CalcRessources";
import { makeTimeStamp } from "../../overview/resources/helpers";
import { externalLineTooltipHandler, externalSankeyTooltipHandler } from "../resources/GraphPlugins";
import { useTranslation } from "react-i18next";


function Sankey({monthOfInterest, yearOfInterest}) {
    Chart.register(LinearScale, SankeyController, Flow);
    const {blocks, user, currentHousehold} = useUser();
    const { allEntries } = useEntry();
    const {color_series} = useAppContext();
    const thisTimeStamp = makeTimeStamp(yearOfInterest,monthOfInterest);
    const theme = useTheme();
    const {t} = useTranslation();

    let activeBlocks = [...blocks];
    activeBlocks = activeBlocks.filter((item) => {
        if (item.status === "deleted" && Number(thisTimeStamp) >= Number(item.deleted)) return false;
        if (Number(item.created) > Number(thisTimeStamp)) return false;
        return true;
      })
      

    const createDataSet = React.useMemo(() => {
      const dataArray = [];
      let colorObj = {};
      colorObj[t("Spent")] = theme.palette.error.light;
      colorObj[t("Income")] = theme.palette.info.light;
      colorObj[t("Saved")] = theme.palette.success.light;


      //First Income to Expenses
      let incomeVal = getSumforMonth(user.id, "income", allEntries, monthOfInterest, yearOfInterest);
      let expenseVal = 0;

      //We go through every block
      activeBlocks.forEach((block, blockIndex) => {
        //Set Block color
        colorObj[block.name] = color_series[blockIndex];

        let s = 0;
        //We filter the categories to only find active ones
        let cats = block.categories.filter((categ) => {
          if (categ.status === "deleted" && Number(thisTimeStamp) >= Number(categ.deleted)) return false;
          if (Number(categ.created) > Number(thisTimeStamp)) return false;
          return true;
        })
          //We go through categories and create a sum
        cats.forEach(element => {
          //Set Category color
          colorObj[element.label] = color_series[blockIndex];
          
          let catSum = categorySumForMonth(user.id, allEntries, element.label, monthOfInterest, yearOfInterest);
            s = s + catSum;
          let catFlowVal = Math.round(Math.max(catSum,0) * 100) / 100;
          let catObject = {from: block.name, to: element.label, flow: catFlowVal};
          if (catFlowVal > 0) dataArray.push(catObject);
        });
        let flowVal = Math.round(Math.max(s,0) * 100) / 100;
        let tempObject = {from: t("Spent"), to: block.name, flow: flowVal};
        if (flowVal > 0) dataArray.push(tempObject);
        
        expenseVal = expenseVal + s;

      })

      if (incomeVal > expenseVal) {
        expenseVal = Math.round(Math.max(expenseVal,0) * 100) / 100;
        let incomeObject = {from: t("Income"), to: t("Spent"), flow: expenseVal};
        dataArray.push(incomeObject);

        let diff = Math.round(Math.max((incomeVal - expenseVal),0) * 100) / 100;
        let savedObject = {from: t("Income"), to: t("Saved"), flow: diff};
        dataArray.push(savedObject);
      }

      else {
        let incomeObject = {from: t("Income"), to: t("Spent"), flow: incomeVal};
        dataArray.push(incomeObject);
      }

      return {data:dataArray, color:colorObj};
    }, [user.id, allEntries, activeBlocks, monthOfInterest])



    React.useEffect(() => {
        var ctx = document.getElementById("chart").getContext("2d");
        const dataObject = createDataSet;
        const colors = dataObject.color;
   
    
        function getColor(name) {
          return colors[name] || color_series[0];
        }

        const myChart = new Chart(ctx, {
            type: "sankey",
            data: {
              datasets: [
                {
                  data: dataObject.data,
                  colorFrom: c => getColor(c.dataset.data[c.dataIndex].from),
                  colorTo: c => getColor(c.dataset.data[c.dataIndex].to),
                  borderWidth: 0,
                  color: theme.palette.text.primary,
                }
              ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                currency: currentHousehold.currency,
                layout: {padding: 16},
                plugins: {
                    legend: {
                      display: false,
                    },
                    tooltip: {
                      enabled: false,
                      external:  externalSankeyTooltipHandler,
                    },
                  },

            }
          });

          return() => {
            myChart.destroy();
          }
    
    }, [monthOfInterest])
    


  return (
    <div style={{height: "100%", width: "100%"}}>
  <canvas id="chart"></canvas>
    </div>
  )
}

export default Sankey