import { BrowserRouter } from "react-router-dom";
import { AppProvider } from "./@common/contexts/AppContext";
import { UserProvider } from "./@common/contexts/UserContext";
import AppRouter from "./@common/router/AppRouter";
import "./App.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import "./initI18next";

//LogRocket.init('nwmp96/balancey');

function App() {
  return (
    <UserProvider>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <AppProvider>
            <AppRouter />
          </AppProvider>
        </LocalizationProvider>
      </BrowserRouter>
    </UserProvider>
  );
}

export default App;
