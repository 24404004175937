import React from "react";
import { styled } from "@mui/system";
import Typography from "@mui/material/Typography";
import Navigation from "../navigation/Navigation";
import Footer from "../../home/components/Footer";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Page = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(10),
  display: "flex",
  flexDirection: "column",
  alignItems: "space-between",
  backgroundColor: theme.palette.homepage.light,
}));

const Section = styled("div")(({ theme }) => ({
  paddingLeft: theme.spacing(20),
  paddingRight: theme.spacing(20),
  minHeight: "80vh",

  //Small
  [theme.breakpoints.down("lg")]: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
  },
  //Mobile
  [theme.breakpoints.down("md")]: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
}));

const Container = styled("div")(({ theme }) => ({
  width: "80vw",
  maxWidth: "1000px",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  color: theme.palette.homepage.main,
  marginBottom: theme.spacing(20),

  "& .MuiTypography-root": {
    color: "inherit",
  },
}));

const LinkList = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: "flex",
  justifyContent: "flex-start",
}));

const FAQ = () => {
  const { t, i18n } = useTranslation();
  const { locale } = useParams();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navigation />
      <Page>
        <Section className="introduction">
          <Container>
            <LinkList>
              <NavLink
                to={`/en/faq`}
                style={{
                  fontFamily: "ApfelGrotezkRegular",
                  textDecoration: "none",
                  fontSize: "14px",
                }}
              >
                EN
              </NavLink>
              <hr style={{ marginInline: 10 }} />
              <NavLink
                to={`/de/faq`}
                style={{
                  fontFamily: "ApfelGrotezkRegular",
                  textDecoration: "none",
                  fontSize: "14px",
                }}
              >
                DE
              </NavLink>
            </LinkList>
            <Typography variant="h2" sx={{ mb: 2 }}>
              {t("Frequently Asked Questions", { lng: locale })}
            </Typography>
          </Container>
        </Section>
        <Footer />
      </Page>
    </>
  );
};

export default FAQ;
