import React, { ReactNode } from 'react';
import { styled } from '@mui/system';
import Navigation from './@common/navigation/Navigation';
import { Typography } from '@mui/material';

const TitleBox = styled('div')(({ theme }) => ({
  position: "relative",
  padding: theme.spacing(6,4),

      //Mobile screens
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(3,2),
        backgroundColor: theme.palette.background.paper,

        '& .MuiTypography-root':{
         textAlign: "center"
        }
       
       }
   }
 ));


const AppContainerStyle = styled('div')(({ theme }) => ({
width: "100%",
maxWidth: '1400px',
margin: 'auto',
position: "relative",

}));

const Content = styled('div')(({ theme }) => ({
  position: "relative",
  padding: theme.spacing(0, 4, 4, 4),
  //width: "100%",
  maxWidth: "1500px",
  minHeight: "92vh",

     //Small screens
     [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(3,3,20,3),
      width: "auto"
   
     },
    //Mobile screens
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1,1.5,18,1.5),
    }
}));



type Props = {
  title?: string;
  children: ReactNode;
};

const Layout = ({ children, title }: Props) => {
  return (
      <AppContainerStyle>
        <TitleBox>
          <Typography variant='h4' className='headerText'>{title}</Typography> 
        </TitleBox>
        <Content>
          {children}
        </Content>
      </AppContainerStyle>
  );
};

export default Layout;
