import React from 'react';
import AppContainer from '../../main/AppContainer';
import { EntryProvider } from '../contexts/EntryContext';

function AppPage() {
  return (
    <EntryProvider>
      <AppContainer />
    </EntryProvider>
  );
}

export default AppPage;
